import React, { useState } from "react";
import {
	Checkbox,
	Stack,
	TableCell,
	TableRow,
	Typography,
	Radio,
	useTheme,
	TextField,
	InputAdornment,
	FormControlLabel,
	RadioGroup,
} from "@mui/material";
import { TableCellText, Tooltip } from "../../../../../../../styles/twozo";
import { type } from "../../../../../../../utils/PermissionUtils";

export default function PermissionList(props) {
	const {
		isViewName,
		lists,
		openAccessMenu,
		onChange,
		handleAccessChange,
		handleChange,
		isEditable,
		handleInputChange,
	} = props;
	const theme = useTheme();

	const [isTooltipOpen, setIsTooltipOpen] = useState(false);
	const [hoveredId, setHoveredId] = useState("");

	const handleOpenTooltip = (event, id) => {
		setIsTooltipOpen(event.target.scrollWidth > event.target.clientWidth);
		setHoveredId(id);
	};

	const getSelectedAccessName = (selectedAccess) => {
		if (selectedAccess === "globalAccess") {
			return "Global Access";
		} else if (selectedAccess === "territoryAccess") {
			return "Territory Access";
		} else {
			return "Restricted Access";
		}
	};

	return (
		<React.Fragment>
			<TableRow style={{ height: "52px" }}>
				<TableCell colSpan={7}>
					<Typography fontSize={16} fontWeight={600}>
						{isViewName}
					</Typography>
				</TableCell>
			</TableRow>

			{lists?.map((list) => (
				<TableRow
					key={list.id}
					style={{
						width: "100%",
						opacity:
							isEditable || list?.permissions?.type === type.input
								? 1
								: 0.6,
						pointerEvents:
							isEditable || list?.permissions?.type === type.input
								? "auto"
								: "none",
					}}
				>
					<TableCell
						sx={{
							borderRight: "1px solid rgba(0, 0, 0, 0.1)",
							width: "200px",
						}}
					>
						<Stack sx={{ width: "200px" }}>
							<Tooltip
								title={
									hoveredId === list.id && isTooltipOpen
										? list.name
										: ""
								}
							>
								<TableCellText
									noWrap
									onMouseOver={(event) =>
										handleOpenTooltip(event, list.id)
									}
									onMouseLeave={() => {
										setIsTooltipOpen(false);
										setHoveredId("");
									}}
								>
									{list.name}
								</TableCellText>
							</Tooltip>
						</Stack>
					</TableCell>

					<TableCell
						sx={{
							borderRight: list?.permissions
								? "1px solid rgba(0, 0, 0, 0.1)"
								: null,
						}}
					>
						<FormControlLabel
							control={
								<Checkbox
									style={{
										paddingRight: "8px",
									}}
									checked={list.access}
									disabled={list?.accessDisable}
									onChange={(event) =>
										handleAccessChange(
											event,
											list.id,
											list.name
										)
									}
								/>
							}
							label={<TableCellText>Access</TableCellText>}
						/>
					</TableCell>

					{(function () {
						switch (list?.permissions?.type) {
							case type.input:
								return (
									<TableCell colSpan={5}>
										<Stack
											direction="row"
											alignItems="center"
										>
											{list.access ? (
												<TextField
													variant="standard"
													value={
														list.permissions.value
													}
													sx={{
														"& .MuiInputBase-input":
															{
																width: "50px",
															},
														"& .MuiInput-underline:before":
															{
																borderBottom:
																	"none",
															},
														"& .MuiInputBase-root":
															{
																borderBottom:
																	"none",
															},
														"&:hover": {
															"& .MuiInput-underline:before":
																{
																	borderBottom:
																		"none !important",
																},
														},
													}}
													InputProps={{
														endAdornment: (
															<InputAdornment position="end">
																<Typography
																	style={{
																		fontSize:
																			"14px",
																	}}
																>
																	{
																		list
																			.permissions
																			.placeHolder
																	}
																</Typography>
															</InputAdornment>
														),
													}}
													onChange={(event) =>
														handleInputChange(
															event,
															list.id
														)
													}
												/>
											) : (
												<Typography
													fontSize={12}
													fontWeight={400}
													style={{
														opacity: 0.4,
													}}
												>
													{
														list.permissions
															.noDataMessage
													}
												</Typography>
											)}
										</Stack>
									</TableCell>
								);
							case type.checkbox:
								return list.permissions.permission.map(
									(permission, index) =>
										!permission.selectedAccess ? (
											<TableCell
												key={permission.id}
												colSpan={
													index ===
													list.permissions.permission
														.length -
														1
														? 5 - index
														: 1
												}
											>
												<Stack
													direction="row"
													alignItems="center"
													spacing={1}
												>
													<FormControlLabel
														control={
															<Checkbox
																checked={
																	permission.value
																}
																disabled={
																	permission.disabled
																}
																style={{
																	paddingRight:
																		"8px",
																}}
																onClick={(
																	event
																) =>
																	onChange(
																		event,
																		permission,
																		list.id
																	)
																}
															/>
														}
														label={
															<TableCellText>
																{
																	permission.name
																}
															</TableCellText>
														}
													/>
												</Stack>
											</TableCell>
										) : (
											<TableCell
												key={permission.id}
												width="20%"
											>
												<TableCellText
													fontWeight={500}
													color={
														theme.palette.secondary
															.contrastText
													}
													onClick={(event) =>
														openAccessMenu(
															event,
															permission.selectedAccess,
															list.id,
															list.access
														)
													}
													style={{
														opacity: list.access
															? 1
															: 0.6,
													}}
												>
													{getSelectedAccessName(
														permission.selectedAccess
													)}
												</TableCellText>
											</TableCell>
										)
								);

							case type.radio:
								return (
									<TableCell colSpan={6}>
										{list.access &&
										list?.permissions?.permission?.length >=
											1 ? (
											<RadioGroup
												row
												value={
													list?.permissions?.permission?.find(
														(permission) =>
															permission.value ===
															true
													)?.name
												}
												onChange={(event) =>
													handleChange(event, list.id)
												}
											>
												{list.permissions.permission.map(
													(permission) => (
														<FormControlLabel
															key={permission.id}
															value={
																permission.name
															}
															control={
																<Radio
																	style={{
																		paddingTop: 0,
																		paddingBottom: 0,
																		paddingLeft: 0,
																		paddingRight:
																			"8px",
																	}}
																/>
															}
															label={
																<TableCellText
																	style={{
																		paddingRight:
																			"16px",
																	}}
																>
																	{
																		permission.name
																	}
																</TableCellText>
															}
														/>
													)
												)}
											</RadioGroup>
										) : null}
									</TableCell>
								);
							default:
								return null;
						}
					})()}

					{!list?.permissions ? <TableCell colSpan={5} /> : null}
				</TableRow>
			))}
		</React.Fragment>
	);
}
