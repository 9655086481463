import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { twozoStyles } from "../../../styles/twozo";
import { default as DropDownIcon } from "../../../assets/icons/dropDownCentered";
import { default as AddIcon } from "../../../assets/icons/add";
import WebFormList from "./WebFormList";

export default function WebForm() {
	const navigate = useNavigate();
	const classes = twozoStyles();
	const theme = useTheme();
	const menuBarRef = useRef(null);

	const [menuBarRowEndingPoistion, setMenuBarRowEndingPoistion] = useState(0);

	useEffect(() => {
		if (menuBarRef) {
			setMenuBarRowEndingPoistion(
				menuBarRef.current.getBoundingClientRect().top
			);
		}
	}, [menuBarRef]);

	return (
		<React.Fragment>
			<Box>
				<Box
					ref={menuBarRef}
					className={classes.menuBar}
					sx={{
						position: "sticky",
						top: `${menuBarRowEndingPoistion}px`,
						zIndex: 1,
						backgroundColor: "#ffffff",
					}}
				>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						height="100%"
						pl={1}
					>
						<Stack
							direction="row"
							spacing={1.5}
							alignItems="center"
						>
							<Box
								style={{ cursor: "pointer" }}
								onClick={() => navigate("/settings")}
							>
								<Typography
									fontWeight={600}
									color={theme.palette.secondary.contrastText}
								>
									Admin Settings
								</Typography>
							</Box>

							<Box style={{ transform: "rotate(-90deg)" }}>
								{DropDownIcon(16, 16)}
							</Box>

							<Typography fontWeight={600}>Web Forms</Typography>
						</Stack>

						<Button
							variant="contained"
							startIcon={AddIcon(20, 20, "#fff")}
							color="primary"
							disableElevation
							onClick={() =>
								navigate("/settings/webform/createwebform")
							}
						>
							Web Form
						</Button>
					</Stack>
				</Box>

				<WebFormList />
			</Box>
		</React.Fragment>
	);
}
