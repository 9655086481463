import { getImportUrl } from "../apiConfig";
import { assertError } from "../apiHelper";
import { apiClient } from "..";

export const uploadFile = async (file) => {
	let requestData = uploadFileRequest(file);
	return await postImportUploadManagementUrl(requestData);
};

export const readUploadedFile = async (id) => {
	let requestData = readUploadedFileRequest(id);
	return await postImportManagementUrl(requestData);
};

export const previewUploadedFile = async (id) => {
	let requestData = previewUploadedFileRequest(id);
	return await postImportManagementUrl(requestData);
};

export const importUploadedFile = async (uploadedFileData) => {
	let requestData = importFileRequest(uploadedFileData);
	return await postImportManagementUrl(requestData);
};

export const getImportFieldsList = async (sourceType) => {
	let requestData = getImportFieldsListRequest(sourceType);
	return await postImportManagementUrl(requestData);
};

export const getImportModulesList = async () => {
	let requestData = getImportModulesListRequest();
	return await postImportManagementUrl(requestData);
};

export const importFileStatistics = async (id) => {
	let requestData = importFileStatisticsRequest(id);
	return await postImportManagementUrl(requestData);
};

export const getImportHistoryData = async () => {
	let requestData = importContactHistoryRequest();
	return await postImportManagementUrl(requestData);
};

export const getImportStatus = async (id) => {
	let requestData = getImportStatusRequest(id);
	return await postImportManagementUrl(requestData);
};

export const updateFieldsMapping = async (data) => {
	let requestData = updateFieldsMappingRequest(data);
	return await postImportManagementUrl(requestData);
};

export const revertImportedFile = async (id) => {
	let requestData = revertImportRequest(id);
	return await postRevertManagementUrl(requestData);
};

export const getRevertImportStatus = async (id) => {
	let requestData = getRevertImportStatusRequest(id);
	return await postRevertManagementUrl(requestData);
};

export const stopImportedFile = async (id) => {
	let requestData = stopImportRequest(id);
	return await postImportManagementUrl(requestData);
};

export const deleteImportedFile = async (id) => {
	let requestData = deleteImportRequest(id);
	return await postImportManagementUrl(requestData);
};

const uploadFileRequest = (file) => {
	return {
		type: "create",
		data: {
			attachments: file,
		},
	};
};

const readUploadedFileRequest = (id) => {
	return {
		type: "read",
		data: {
			id: id,
		},
	};
};

const previewUploadedFileRequest = (id) => {
	return {
		type: "preview",
		data: {
			id: id,
		},
	};
};

const importFileRequest = (uploadedFileData) => {
	return {
		type: "import",
		data: {
			id: uploadedFileData.id,
			fieldsMap: uploadedFileData.fieldsMap,
			mergeDuplicates: uploadedFileData.mergeDuplicates,
		},
	};
};

const getImportFieldsListRequest = (sourceType) => {
	return {
		type: "fields",
		data: {
			source: sourceType,
		},
	};
};

const importFileStatisticsRequest = (id) => {
	return {
		type: "importStatistics",
		data: {
			id: id,
		},
	};
};

const importContactHistoryRequest = () => {
	return {
		type: "list",
		data: {},
	};
};

const getImportModulesListRequest = () => {
	return {
		type: "sources",
		data: {},
	};
};

const getImportStatusRequest = (id) => {
	return {
		type: "checkImportStatus",
		data: {
			id: id,
		},
	};
};

const updateFieldsMappingRequest = (data) => {
	return {
		type: "mappingUpdate",
		data: data,
	};
};

const revertImportRequest = (id) => {
	return {
		type: "revert",
		data: {
			importId: id,
		},
	};
};

const getRevertImportStatusRequest = (id) => {
	return {
		type: "checkRevertStatus",
		data: {
			id: id,
		},
	};
};

const stopImportRequest = (id) => {
	return {
		type: "stop",
		data: {
			id: id,
		},
	};
};

const deleteImportRequest = (id) => {
	return {
		type: "delete",
		data: {
			id: id,
		},
	};
};

const postImportManagementUrl = async (requestData) => {
	let importManagementUrl = getImportManagementUrl();
	let response = await apiClient.post(importManagementUrl, requestData);
	assertError(response);
	return response.data.data;
};

const postRevertManagementUrl = async (requestData) => {
	let importRevertUrl = getRevertManagementUrl();
	let response = await apiClient.post(importRevertUrl, requestData);
	assertError(response);
	return response.data.data;
};

const postImportUploadManagementUrl = async (requestData) => {
	let importManagementUrl = getImportManagementUrl();
	let response = await apiClient.post(importManagementUrl, requestData, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
	assertError(response);
	return response.data.data;
};

const getImportManagementUrl = () => {
	return getImportUrl() + "/mgmt";
};

const getRevertManagementUrl = () => {
	return "/revert/mgmt";
};
