import React from "react";
import {
	Box,
	Button,
	CircularProgress,
	Stack,
	Typography,
} from "@mui/material";
import { default as AddIcon } from "../../../../assets/icons/add";
import TerritoryList from "./TerritoryList";
import CustomSwipeableDrawer from "../../../Elements/CustomSwipeableDrawer";
import CreateTerritory from "./CreateTerritory";
import { useUserManagementContext } from "../UserManagementContext";

export default function Territory(props) {
	const {
		isCreateTerritoryDialogOpened,
		openCreateTerritoryDialog,
		closeCreateTerritoryDialog,
	} = props;

	const {
		listData: territoryListData,
		isLoadingListData: isLoadingTerritoryData,
	} = useUserManagementContext();

	return (
		<React.Fragment>
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isCreateTerritoryDialogOpened}
				onOpen={openCreateTerritoryDialog}
				onClose={closeCreateTerritoryDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box
					sx={{
						width: "50vw",
						minHeight: "100vh",
						backgroundColor: "#fff",
						borderRadius: "10px 0px 0px 10px",
					}}
				>
					<CreateTerritory onClose={closeCreateTerritoryDialog} />
				</Box>
			</CustomSwipeableDrawer>

			<Box style={{ height: "100%" }}>
				<Box pl={1} pb={1.5}>
					<Typography color="rgba(0, 0, 0, 0.6)" fontSize={14}>
						Creating a territory brings a group of customers and
						prospects into meaningful segments.
					</Typography>
				</Box>

				<Box
					sx={{
						border: "1px solid rgba(0, 0, 0, 0.1)",
						borderRadius: "8px",
						height: "94%",
					}}
				>
					{isLoadingTerritoryData ? (
						<Stack
							height="60vh"
							justifyContent="center"
							alignItems="center"
						>
							<CircularProgress />
						</Stack>
					) : !!territoryListData && territoryListData.length > 0 ? (
						<TerritoryList territoryListData={territoryListData} />
					) : (
						<Stack alignItems="center" pt={3}>
							<Typography fontSize={16}>
								It helps to allocate leads, contacts, and
								opportunities to specific sales representatives
								or account executives.
							</Typography>

							<Typography>
								The primary goal of the territory is to optimize
								sales and service operations by assigning
								specific territories or regions to salespeople,
								service agents, or
							</Typography>

							<Typography pb={3}>
								teams, based on various criteria.
							</Typography>

							<Button
								variant="contained"
								startIcon={AddIcon(20, 20, "#fff")}
								color="primary"
								disableElevation
								onClick={openCreateTerritoryDialog}
							>
								Create Territory
							</Button>
						</Stack>
					)}
				</Box>
			</Box>
		</React.Fragment>
	);
}
