import { useQuery } from "@tanstack/react-query";
import {
	getContactCompletedActivityKey,
	getContactToDoAcitivityKey,
} from "../../../../utils/queryKeys";
import { getActivitiesData } from "../../../../api/activity/activityApi";
import { modules } from "../../../../utils/common/ModulesName";

let contactSource = modules.CONTACT;

const useContactToDoActivities = (contactId, activityTypeIdsToFilter) => {
	let isDone = false;
	const contactToDoActivityKey = getContactToDoAcitivityKey(
		contactId,
		activityTypeIdsToFilter
	);
	return useQuery(
		contactToDoActivityKey,
		() =>
			getActivitiesData(
				contactId,
				isDone,
				contactSource,
				activityTypeIdsToFilter
			),
		{
			select: (data) => data?.activities,
		}
	);
};

const useContactCompletedActivities = (contactId, activityTypeIdsToFilter) => {
	let isDone = true;
	const contactCompletedActivityKey = getContactCompletedActivityKey(
		contactId,
		activityTypeIdsToFilter
	);
	return useQuery(
		contactCompletedActivityKey,
		() =>
			getActivitiesData(
				contactId,
				isDone,
				contactSource,
				activityTypeIdsToFilter
			),
		{
			select: (data) => data?.activities,
		}
	);
};

export { useContactToDoActivities, useContactCompletedActivities };
