import React from "react";
import {
	Timeline,
	TimelineConnector,
	timelineConnectorClasses,
	TimelineContent,
	TimelineDot,
	timelineDotClasses,
	TimelineItem,
	TimelineOppositeContent,
	timelineOppositeContentClasses,
	TimelineSeparator,
} from "@mui/lab";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { getIconByName } from "../../../../../../utils";
import { default as arrowIcon } from "../../../../../../assets/icons/rightArrow";
import { useAuditlogData } from "../../../../../../hooks/services/userManagement/user";

export default function AuditLog(props) {
	const { userId } = props;
	const theme = useTheme();
	const { data: auditlogData } = useAuditlogData(userId);

	const styles = {
		text: {
			fontSize: "15px",
		},
		textSecondary: {
			fontSize: "14px",
			color: "rgba(0, 0, 0, 0.6)",
		},
		highlightedText: {
			fontSize: "15px",
			fontWeight: 500,
		},
	};

	const getFormattedTime = (unixTime) => {
		return new Date(unixTime).toLocaleTimeString("en-us", {
			hour: "numeric",
			minute: "numeric",
		});
	};

	const getFormattedDate = (date) => {
		return new Date(date).toLocaleDateString(undefined, {
			day: "2-digit",
			month: "long",
			year: "numeric",
		});
	};

	const getNameByType = (name) => {
		return name.toLowerCase();
	};

	const getAuditLogContent = (event) => {
		if (!event?.entity) {
			return null;
		}

		switch (event.action) {
			case "ADDED":
				return (
					<Stack pt={1.25}>
						<Typography style={styles.text}>
							<span style={styles.highlightedText}>
								{event.entity.name}
							</span>{" "}
							{getNameByType(event.entity.type)} was created.
						</Typography>
					</Stack>
				);
			case "DELETED":
				return (
					<Stack pt={1.25}>
						<Typography style={styles.text}>
							<span style={styles.highlightedText}>
								{event.entity.name}
							</span>{" "}
							{getNameByType(event.entity.type)} was deleted.
						</Typography>
					</Stack>
				);
			case "UPDATED":
				return (
					<Stack pb={1}>
						<Typography style={styles.text}>
							<span style={styles.highlightedText}>
								{event.entity.name}
							</span>{" "}
							{getNameByType(event.entity.type)} was updated.
						</Typography>

						{event.auditAttribute.subAction === "ADDED" &&
							event.auditAttribute.fieldName === "Note" && (
								<Typography style={styles.textSecondary}>
									{event.auditAttribute.fieldName} was added.
								</Typography>
							)}
						{event.auditAttribute.subAction === "ADDED" &&
							event.auditAttribute.fieldName === "Tag" && (
								<Typography fontSize={14}>
									<span
										style={{
											fontWeight: 500,
										}}
									>
										Tags :{" "}
										{event.auditAttribute.newValue
											.map((item) => item)
											.join(", ")}
									</span>{" "}
									<span style={styles.textSecondary}>
										tag was added.
									</span>
								</Typography>
							)}

						{event.auditAttribute.subAction === "UPDATED" && (
							<Stack direction="row" alignItems="center">
								<Typography
									fontSize={14}
									fontWeight={500}
									style={{
										marginRight: "4px",
									}}
								>
									{event.auditAttribute.fieldName} :
								</Typography>
								<span
									style={{
										...styles.textSecondary,
										marginRight: "4px",
									}}
								>
									{event.auditAttribute.oldValue}
								</span>
								{arrowIcon(16, 16, "grey")}
								<span
									style={{
										...styles.textSecondary,
										marginLeft: "4px",
									}}
								>
									{event.auditAttribute.newValue}
								</span>
							</Stack>
						)}

						{event.auditAttribute.subAction === "REMOVED" && (
							<Typography style={styles.textSecondary}>
								{event.auditAttribute.fieldName} was added.
							</Typography>
						)}
					</Stack>
				);
			default:
				return null;
		}
	};

	return (
		<React.Fragment>
			<Timeline
				sx={{
					[`& .${timelineOppositeContentClasses.root}`]: {
						flex: 0,
						py: "18px",
						px: 2,
					},
					[`& .${timelineConnectorClasses.root}`]: {
						backgroundColor: theme.palette.secondary.main,
						margin: "-12px 0px",
					},
					[`& .${timelineDotClasses.root}`]: {
						boxShadow: "none",
					},
				}}
			>
				{auditlogData &&
					auditlogData?.map((eventsForDay, eventsForDayIndex) => (
						<React.Fragment key={eventsForDayIndex}>
							<TimelineItem
								style={{
									minHeight:
										eventsForDay.data.length === 0
											? "58px"
											: "40px",
								}}
							>
								<TimelineOppositeContent>
									<Box width="110px"></Box>
								</TimelineOppositeContent>
								<TimelineSeparator>
									<TimelineDot color="primary" />
									<TimelineConnector />
								</TimelineSeparator>
								<TimelineContent sx={{ py: 1, px: 3 }}>
									<Typography
										fontSize={14}
										fontWeight={500}
										color={
											theme.palette.secondary.contrastText
										}
									>
										{getFormattedDate(eventsForDay.date)}
									</Typography>
								</TimelineContent>
							</TimelineItem>

							{eventsForDay.data.map((event, eventIndex) => (
								<TimelineItem
									key={eventIndex}
									style={{
										minHeight:
											eventsForDay.data.length - 1 ===
											eventsForDayIndex
												? "75px"
												: "24px",
									}}
								>
									<TimelineOppositeContent>
										<Box width="100px">
											<Typography
												fontSize={14}
												fontWeight={500}
											>
												{getFormattedTime(
													event.actionTime
												)}
											</Typography>
										</Box>
									</TimelineOppositeContent>

									<TimelineSeparator>
										<TimelineDot color="secondary">
											{event?.entity
												? getIconByName(
														event.entity?.iconName
													)(20, 20, "#000")
												: ""}
										</TimelineDot>
										{eventsForDayIndex !==
											auditlogData.length - 1 ||
										eventIndex !==
											eventsForDay.data.length - 1 ? (
											<TimelineConnector />
										) : null}
									</TimelineSeparator>

									<TimelineContent>
										{getAuditLogContent(event)}
									</TimelineContent>
								</TimelineItem>
							))}
						</React.Fragment>
					))}
			</Timeline>
		</React.Fragment>
	);
}
