import React, { useState } from "react";
import { Box, IconButton, MenuItem, Typography, useTheme } from "@mui/material";
import { default as PlusIcon } from "../../../assets/icons/add";
import Menu from "../../Elements/Menu";

export default function Connector(props) {
	const { index, AddAction, AddCondition } = props;
	const theme = useTheme();
	const [connectorHovered, setConnectorHovered] = useState(false);
	const [moduleMenuElement, setModuleMenuElement] = useState(null);
	const isModuleMenuOpened = Boolean(moduleMenuElement);

	const OpenModuleMenu = (event) => {
		setModuleMenuElement(event.currentTarget);
	};

	const CloseModuleMenu = () => {
		setModuleMenuElement(null);
	};

	return (
		<React.Fragment>
			<Menu
				anchorEl={moduleMenuElement}
				open={isModuleMenuOpened}
				onClose={CloseModuleMenu}
				anchorOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				minWidth="250px"
			>
				<MenuItem
					style={{ minHeight: "40px" }}
					onClick={() => {
						AddCondition(index);
						CloseModuleMenu();
					}}
				>
					<Typography fontSize={13}>Add Condition</Typography>
				</MenuItem>

				<MenuItem
					style={{ minHeight: "40px" }}
					onClick={() => {
						AddAction(index);
						CloseModuleMenu();
					}}
				>
					<Typography fontSize={13}>Add Action</Typography>
				</MenuItem>
			</Menu>

			<Box
				onMouseEnter={() => {
					setConnectorHovered(true);
				}}
				onMouseLeave={() => {
					setConnectorHovered(false);
				}}
			>
				<Box
					style={{
						height: "30px",
						border:
							connectorHovered || moduleMenuElement
								? "none"
								: "1px solid rgba(0, 0, 0, 0.12)",
					}}
				>
					{(connectorHovered || !!moduleMenuElement) && (
						<IconButton
							style={{
								backgroundColor: theme.palette.secondary.main,
								border: "1px solid rgba(0, 0, 0, 0.12)",
								marginTop: "-4px",
							}}
							onClick={OpenModuleMenu}
						>
							{PlusIcon(20, 20, theme.palette.primary.main)}
						</IconButton>
					)}
				</Box>
			</Box>
		</React.Fragment>
	);
}
