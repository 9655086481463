import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
	createEmailTemplateTags,
	deleteEmailTemplateTags,
	getEmailTemplateTagsList,
	updateEmailTemplateTags,
} from "../../../../api/tag/email";
import {
	emailTemplateTagsDropdownKey,
	emailTemplateTagsListKey,
} from "../../../../utils/queryKeys";
import React from "react";

const transformEmailTemplateTagList = (tagsList) => {
	const formatEmailTemplateTagList = [];

	for (let index = 0; tagsList.length > index; index++) {
		let formatEmailTemplateTagListRequest = {
			lastUsedTime: tagsList[index].lastUsedTime,
			createdBy: {
				name: tagsList[index].createdBy.name,
			},
			createdTime: tagsList[index].createdTime,
			tag: tagsList[index].name,
			id: tagsList[index].id,
		};

		formatEmailTemplateTagList.push(formatEmailTemplateTagListRequest);
	}

	return formatEmailTemplateTagList;
};

const useEmailTemplateTagsList = () => {
	return useQuery(
		emailTemplateTagsListKey,
		() => getEmailTemplateTagsList(),
		{
			select: React.useCallback(
				(data) => transformEmailTemplateTagList(data.list),
				[]
			),
		}
	);
};

const useTemplateTagsDropdownList = () => {
	return useQuery(
		emailTemplateTagsDropdownKey,
		() => getEmailTemplateTagsList(),
		{
			select: (data) => data.list,
		}
	);
};

const useInvalidateEmailTemplateTagsList = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(emailTemplateTagsListKey);
	};
};

const useCreateEmailTemplateTagsMutation = () => {
	let invalidateTagsList = useInvalidateEmailTemplateTagsList();
	return useMutation(createEmailTemplateTags, {
		onSuccess: () => {
			invalidateTagsList();
		},
	});
};

const useUpdateEmailTemplateTagsMutation = () => {
	let invalidateTagsList = useInvalidateEmailTemplateTagsList();
	return useMutation(updateEmailTemplateTags, {
		onSuccess: () => {
			invalidateTagsList();
		},
	});
};

const useDeleteEmailTemplateTagsMutation = () => {
	let invalidateTagsList = useInvalidateEmailTemplateTagsList();
	return useMutation(deleteEmailTemplateTags, {
		onSuccess: () => {
			invalidateTagsList();
		},
	});
};

export {
	useEmailTemplateTagsList,
	useCreateEmailTemplateTagsMutation,
	useDeleteEmailTemplateTagsMutation,
	useUpdateEmailTemplateTagsMutation,
	useTemplateTagsDropdownList,
};
