import React, { useEffect, useRef, useState } from "react";
import ToolBar from "../../../EmailComposer/EmailComposerUI/ToolBar";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { default as AttachmentIcon } from "../../../../../assets/icons/attachment";
import LexicalAutoLinkPlugin from "../../../EmailComposer/EmailComposerUI/Plugin/AutoLinkPlugin";
import LexicalClickableLinkPlugin from "@lexical/react/LexicalClickableLinkPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { Box, useTheme } from "@mui/material";
import PlaceHoldersMenu from "../../../EmailComposer/EmailComposerUI/ToolBar/PlaceHoldersMenu";
import HtmlPlugin from "../../../EmailComposer/EmailComposerUI/Plugin/HtmlPlugin";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getSelection } from "lexical";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";

export default function TemplateInput(props) {
	const { initialTemplateContent } = props;

	const theme = useTheme();
	const templateInputRef = useRef();
	const [editor] = useLexicalComposerContext();

	const [isPlaceHolderMenuOpened, setIsPlaceHolderMenuOpened] =
		useState(false);
	const [templateInputHeight, setTemplateInputHeight] = useState(0);

	useEffect(() => {
		if (templateInputRef?.current) {
			setTemplateInputHeight(
				templateInputRef?.current?.getBoundingClientRect()?.height
			);
		}
	}, [templateInputRef]);

	const openPlaceHolderMenu = () => {
		setIsPlaceHolderMenuOpened(true);
	};

	const closePlaceHolderMenu = () => {
		setIsPlaceHolderMenuOpened(false);
	};

	const onSelectPlaceHolder = (placeHolderField) => {
		let placeHolderFieldName = `{${placeHolderField?.displayName}_${placeHolderField?.id}}`;

		editor.update(() => {
			const selection = $getSelection();
			if (selection) {
				selection.insertText(`$${placeHolderFieldName}`);
			}
		});
	};

	return (
		<React.Fragment>
			{/*Rich Text Editor*/}
			<Box>
				<ToolBar
					hiddenTemplateMenu={true}
					onClickPlaceHolder={openPlaceHolderMenu}
					focusPlaceHolderIcon={isPlaceHolderMenuOpened}
				/>

				<Box style={{ position: "relative" }}>
					<Box ref={templateInputRef}>
						<RichTextPlugin
							contentEditable={
								<ContentEditable
									style={{
										height: "347px",
										padding: "12px",
										overflowY: "auto",
										outline: "none",
										fontSize: "14px",
										fontFamily: "Lexend",
									}}
								/>
							}
							ErrorBoundary={LexicalErrorBoundary}
						/>
						<TabIndentationPlugin />
						<HistoryPlugin />
						<LexicalAutoLinkPlugin />
						<LexicalClickableLinkPlugin />
						<HtmlPlugin initialHtml={initialTemplateContent} />
						<AutoFocusPlugin />

						<Box p={2} display="flex" style={{ cursor: "pointer" }}>
							{AttachmentIcon(20, 20, "#666666")}
						</Box>
					</Box>

					<Box
						style={{
							position: "absolute",
							top: 0,
							bottom: 0,
							left: 0,
							right: 0,
							alignContent: "flex-end",
							justifySelf: "end",
							borderLeft: `1px solid ${theme.palette.divider}`,
							backgroundColor: "#fff",
							height: `${templateInputHeight}px`,
							zIndex: 2,
							marginBottom: "-2px",
						}}
						hidden={!isPlaceHolderMenuOpened}
					>
						<PlaceHoldersMenu
							closePlaceHolderMenu={closePlaceHolderMenu}
							onSelectPlaceHolder={onSelectPlaceHolder}
						/>
					</Box>
				</Box>
			</Box>
		</React.Fragment>
	);
}
