import React, { useState } from "react";
import { TableCellText } from "../../../../styles/twozo";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import Menu from "../../Menu";
import { getStatusProperties } from "../../../../utils/ActivitiesUtils";
import Can from "../../../Auth/Can";
import { PERMISSIONS } from "../../../../utils/Auth";
import CanNot from "../../../Auth/CanNot";

export default function ContactsOnBoard(props) {
	const { data, rowState } = props;
	const theme = useTheme();

	const [contactMenuElement, setContactMenuElement] = useState(null);
	const isContactMenuOpened = Boolean(contactMenuElement);

	const openContactMenu = (event) => {
		event.stopPropagation();
		setContactMenuElement(event.currentTarget);
	};

	const closeContactMenu = (event) => {
		event.stopPropagation();
		setContactMenuElement(null);
	};

	if (!data) {
		return null;
	}

	return (
		<React.Fragment>
			<Menu
				minWidth="240px"
				anchorEl={contactMenuElement}
				open={isContactMenuOpened}
				onClose={(event) => closeContactMenu(event)}
				style={{
					marginTop: "8px",
				}}
				menuListProps={{
					padding: "0px",
				}}
			>
				{data?.map((contact, index) => (
					<Stack
						key={index}
						direction="row"
						alignItems="center"
						pl={3}
						style={{ minHeight: "40px" }}
						onClick={closeContactMenu}
					>
						<TableCellText
							color={getStatusProperties(rowState).textColor}
						>
							{contact?.firstName}
						</TableCellText>
					</Stack>
				))}
			</Menu>

			<Can permission={PERMISSIONS.contact.view}>
				<Box
					onClick={
						data.length > 1
							? (event) => openContactMenu(event)
							: null
					}
				>
					<Stack direction="row" spacing={1} alignItems="center">
						<TableCellText>
							<span
								style={{
									color: getStatusProperties(rowState)
										.textColor,
								}}
							>
								{data?.[0]?.firstName}
							</span>
							&nbsp;
							{data.length > 1 && (
								<span
									style={{
										color: theme.palette.primary.main,
									}}
								>
									+{data.length - 1}
								</span>
							)}
						</TableCellText>
					</Stack>
				</Box>
			</Can>

			<CanNot permission={PERMISSIONS.contact.view}>
				<Typography> - </Typography>
			</CanNot>
		</React.Fragment>
	);
}
