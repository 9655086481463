import React from "react";
import BoardHeading from "../../BoardHeading";
import DealCardList from "../DealCardList";
import { useDealsByStage } from "../../../../hooks/services/deal";
import { Droppable } from "react-beautiful-dnd";
import { CircularProgress, Stack } from "@mui/material";

const styles = {
	table: {
		borderCollapse: "collapse",
		textAlign: "left",
		minWidth: "320px",
		width: "100%",
		height: "100%",
	},
	tableHead: {
		width: "320px",
		border: "1px solid rgba(0, 0, 0, 0.1)",
	},
	tableCell: {
		width: "320px",
		border: "1px solid rgba(0, 0, 0, 0.1)",
	},
};

export default function StageColumn(props) {
	const { stage, stageIndex, criteria } = props;

	const { data: dealsByStage, isLoading: isDealsLoading } = useDealsByStage(
		stage?.id,
		criteria
	);

	return (
		<React.Fragment>
			<table style={styles.table}>
				<thead>
					<tr>
						<th style={{ ...styles.tableHead, borderLeft: "none" }}>
							<BoardHeading
								name={dealsByStage?.name}
								amount={dealsByStage?.summary?.totalValue}
								dealSize={dealsByStage?.summary?.totalDeals}
								symbol={dealsByStage?.summary?.symbol}
							/>
						</th>
					</tr>
				</thead>

				<tbody>
					<tr
						style={{
							height: "100%",
						}}
					>
						<td style={{ ...styles.tableCell, borderLeft: "none" }}>
							<Droppable droppableId={stageIndex?.toString()}>
								{(droppableProvided) => (
									<div
										ref={droppableProvided.innerRef}
										{...droppableProvided.droppableProps}
										style={{
											height: "100%",
											overflow: "auto",
										}}
									>
										{isDealsLoading ? (
											<Stack
												justifyContent="center"
												alignItems="center"
												height="100%"
											>
												<CircularProgress size="30px" />
											</Stack>
										) : (
											<DealCardList
												deals={dealsByStage?.deals}
												pipelineStageId={stage?.id}
											/>
										)}
										{droppableProvided.placeholder}
									</div>
								)}
							</Droppable>
						</td>
					</tr>
				</tbody>
			</table>
		</React.Fragment>
	);
}
