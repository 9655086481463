import React, { useState } from "react";
import { Box } from "@mui/material";
import Table from "../../Elements/Table";
import Menu from "../Menu";
import SelectedMenu from "../SelectedMenu";
import { sourceType } from "../../../utils/queryKeys";
import { twozoStyles } from "../../../styles/twozo";
import CustomSwipeableDrawer from "../../Elements/CustomSwipeableDrawer";
import SwipeableSummary from "../SwipeableSummary";
import { modules } from "../../../utils/common/ModulesName";
import { dealViews } from "../../../utils/dealUtils";
import { default as MailIcon } from "../../../assets/icons/mail";
import { default as CallIcon } from "../../../assets/icons/call";
import { default as TaskIcon } from "../../../assets/icons/task";
import { default as EditIcon } from "../../../assets/icons/edit";
import { default as DeleteIcon } from "../../../assets/icons/delete";
import EditDeal from "../EditDeal";
import AddActivity from "../../Summary/Activities/AddActivity";
import { useDeleteMutation } from "../../../hooks/services/deal";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../utils/notification/notificationMessages";
import DeleteDialog from "../../Elements/DeleteDialog";
import { PERMISSIONS } from "../../../utils/Auth";
import { useAuth } from "../../../hooks/auth";
import EmailComposerDrawer from "../../Mail/EmailComposer/EmailComposerDrawer";
import { useModuleName } from "../../../hooks/modules";

export default function ListView(props) {
	const {
		handleViewToggleButtonGroup,
		renderDefaultPipeline,
		onImportClicked,
	} = props;

	const classes = twozoStyles();

	const [selectedRowDealId, setSelectedRowDealId] = useState(null);
	const [selectedRowContactId, setSelectedRowContactId] = useState(null);
	const [selectedRowCompanyId, setSelectedRowCompanyId] = useState(null);

	const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState(false);
	const [isEditFormDialogOpen, setIsEditFormDialogOpen] = useState(false);
	const [isEmailComposerOpened, setIsEmailComposerOpened] = useState(false);
	const [openAddActivityDialog, setOpenAddActivityDialog] = useState(false);
	const [
		isSwipeableDealSummaryDialogOpened,
		setIsSwipeableDealSummaryDialogOpened,
	] = useState(false);

	const { getModuleName } = useModuleName();

	// query call
	const { isUserAllowedFor } = useAuth();
	const hasShareViewPermissions = isUserAllowedFor(
		PERMISSIONS.deal.shareView
	);

	// delete mutation call
	const deleteMutation = useDeleteMutation();

	const noResultsMessage = {
		title: "No Deals Found",
		message: "Try resetting your filters or create new deal",
	};

	const openSwipeableDealSummary = (deal) => {
		toggleSwipeableDealSummaryDialog();
		setSelectedRowDealId(deal?.id);
	};

	const toggleSwipeableDealSummaryDialog = () => {
		setIsSwipeableDealSummaryDialogOpened(
			(isSwipeableDealSummaryDialogOpened) =>
				!isSwipeableDealSummaryDialogOpened
		);
	};

	const toggleEditFormDialog = () => {
		setIsEditFormDialogOpen((openEditFormDialog) => !openEditFormDialog);
	};

	const closeEmailComposer = () => {
		setIsEmailComposerOpened(false);
		setSelectedRowContactId(null);
		setSelectedRowDealId(null);
	};

	const toggleAddActivityDialog = () => {
		setOpenAddActivityDialog(
			(openAddCompanyDialog) => !openAddCompanyDialog
		);
	};

	const onEditClicked = (row) => {
		setSelectedRowDealId(row?.id);
		toggleEditFormDialog();
	};

	const onMailClicked = (row) => {
		setIsEmailComposerOpened(true);
		setSelectedRowContactId(row?.contact?.id);
		setSelectedRowDealId(row?.id);
	};

	const onAddActivityClicked = (row) => {
		setSelectedRowContactId(row?.contact?.id);
		setSelectedRowCompanyId(row?.company?.id);
		setSelectedRowDealId(row?.id);
		toggleAddActivityDialog();
	};

	const openDeleteDialog = () => {
		setIsDeleteDialogOpened(true);
	};

	const closeDeleteDialog = () => {
		setIsDeleteDialogOpened(false);
	};

	const handleDeleteDeal = () => {
		deleteMutation.mutate(selectedRowDealId, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.dealDeleted,
				});
				closeDeleteDialog();
			},
		});
	};

	const onDeleteClicked = (row) => {
		setSelectedRowDealId(row.id);
		openDeleteDialog();
	};

	const dealMenuOptions = [
		{
			id: 1,
			name: "Edit",
			icon: EditIcon,
			action: onEditClicked,
			permission: isUserAllowedFor(PERMISSIONS.deal.edit),
		},
		{
			id: 2,
			name: "Email",
			icon: MailIcon,
			action: onMailClicked,
			permission: isUserAllowedFor(PERMISSIONS.contact.view),
		},
		{
			id: 3,
			name: "Call",
			icon: CallIcon,
			action: () => {},
			permission: isUserAllowedFor(PERMISSIONS.contact.view),
		},
		{
			id: 4,
			name: "Add Activity",
			icon: TaskIcon,
			action: onAddActivityClicked,
			permission: true,
		},
		{
			id: 5,
			name: "Delete",
			icon: DeleteIcon,
			action: onDeleteClicked,
			permission: isUserAllowedFor(PERMISSIONS.deal.delete),
		},
	];

	const getTableMenuOptions = () => {
		let filteredTableMenuOptions = dealMenuOptions.filter(
			(option) => option.permission
		);

		return filteredTableMenuOptions;
	};

	let moduleName = getModuleName(modules.DEAL).toLowerCase();

	return (
		<React.Fragment>
			<DeleteDialog
				title={`Are you sure you want to delete the ${moduleName}?`}
				subtitle={`It will delete the ${moduleName} and the data associated with it. You can retrieve it from the Recycle Bin. It remains there for 90 days.`}
				open={isDeleteDialogOpened}
				onCancel={closeDeleteDialog}
				onDelete={handleDeleteDeal}
				disableDeleteButton={deleteMutation.isLoading}
			></DeleteDialog>

			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isEditFormDialogOpen}
				onOpen={toggleEditFormDialog}
				onClose={toggleEditFormDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<EditDeal
						dealId={selectedRowDealId}
						onClose={toggleEditFormDialog}
					/>
				</Box>
			</CustomSwipeableDrawer>

			{isEmailComposerOpened ? (
				<Box>
					<EmailComposerDrawer
						onClose={closeEmailComposer}
						composerValues={{ toAddress: [selectedRowContactId] }}
						sourceName={modules.DEAL}
						entityId={selectedRowDealId}
						linkConversationToDeal={true}
					/>
				</Box>
			) : null}

			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={openAddActivityDialog}
				onOpen={toggleAddActivityDialog}
				onClose={toggleAddActivityDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<AddActivity
						onClose={toggleAddActivityDialog}
						dealId={selectedRowDealId}
						companyId={selectedRowCompanyId}
						contactIds={[selectedRowContactId]}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: {
						boxShadow: "-3px 0px 14px rgba(0, 0, 0, 0.1)",
						borderRadius: "8px",
					},
				}}
				BackdropProps={{ invisible: true }}
				open={isSwipeableDealSummaryDialogOpened}
				onOpen={toggleSwipeableDealSummaryDialog}
				onClose={toggleSwipeableDealSummaryDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<SwipeableSummary
						onClose={toggleSwipeableDealSummaryDialog}
						dealId={selectedRowDealId}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<Table
				table={sourceType.DEAL}
				moduleName={modules.DEAL}
				additionalView={renderDefaultPipeline}
				menu={
					<Menu
						view={dealViews.listView}
						handleViewToggleButtonGroup={
							handleViewToggleButtonGroup
						}
						importClicked={onImportClicked}
					/>
				}
				selectedMenu={<SelectedMenu />}
				onRowClick={openSwipeableDealSummary}
				isActionsVisible={true}
				noResultsMessage={noResultsMessage}
				tableMenuOptions={getTableMenuOptions()}
				hasShareViewPermissions={hasShareViewPermissions}
			/>
		</React.Fragment>
	);
}
