import React, { useEffect, useState } from "react";
import {
	Box,
	Button,
	Checkbox,
	Divider,
	Grid,
	IconButton,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { twozoStyles } from "../../../../styles/twozo";
import { default as CloseIcon } from "../../../../assets/icons/close";
import { getActionFieldsKey } from "../../../../utils/queryKeys/workflow";
import { useQuery } from "@tanstack/react-query";
import { getActionFieldsData } from "../../../../api/workflow/workflowApi";
import Field from "../../Field";

export default function Fields(props) {
	const {
		toggleAddActivityDialog,
		triggerSourceId,
		actionSource,
		actionEvent,
		formName,
		fieldMapping,
		setFieldMappings,
	} = props;
	const theme = useTheme();
	const classes = twozoStyles();
	const [actionFields, setActionFields] = useState([]);
	const [fieldError, setFieldError] = useState([]);

	const actionFieldsKey = getActionFieldsKey(
		triggerSourceId,
		actionSource,
		actionEvent
	);
	const { data: actionFieldsData } = useQuery(
		actionFieldsKey,
		() => getActionFieldsData(triggerSourceId, actionSource, actionEvent),
		{
			staleTime: 600000,
			enabled: !(fieldMapping && fieldMapping.length > 0),
		}
	);

	useEffect(() => {
		if (actionFieldsData && actionFieldsData.fields) {
			let fieldError = [];
			actionFieldsData.fields?.forEach(() => {
				fieldError.push([false]);
			});
			setActionFields(actionFieldsData.fields);
			setFieldError(fieldError);
		}
	}, [actionFieldsData]);

	useEffect(() => {
		if (fieldMapping && fieldMapping.length > 0) {
			let fieldError = [];
			fieldMapping.forEach(() => {
				fieldError.push([false]);
			});
			setActionFields(fieldMapping);
			setFieldError(fieldError);
		}
	}, [fieldMapping]);

	const toggleEnableField = (fieldEnabled, fieldIndex) => {
		setActionFields((actionFields) => {
			if (!actionFields[fieldIndex][0]["config"]) {
				actionFields[fieldIndex][0]["config"] = {};
			}
			actionFields[fieldIndex][0]["config"]["isEnabled"] = fieldEnabled;
			return [...actionFields];
		});
	};

	const handleSave = () => {
		let hasError = false;
		let fieldError = [];
		actionFields.forEach((field) => {
			if (
				(field[0]?.config?.isRequired || field[0]?.config?.isEnabled) &&
				!(
					field[0].values &&
					field[0].values.length > 0 &&
					field[0].values[0].value
				)
			) {
				hasError = true;
				fieldError.push([true]);
			} else {
				fieldError.push([false]);
			}
		});
		setFieldError(fieldError);
		if (hasError) {
			return;
		}
		setFieldMappings([...actionFields]);
	};

	return (
		<React.Fragment>
			<Box className={classes.addDialogContainer}>
				<Box
					style={{
						height: "100%",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Box p={2}>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							spacing={2}
						>
							<Typography fontWeight={600}>{formName}</Typography>

							<IconButton
								onClick={toggleAddActivityDialog}
								size="small"
								style={{ padding: 0 }}
							>
								{CloseIcon(25, 25, theme.palette.primary.main)}
							</IconButton>
						</Stack>
					</Box>

					<Divider />

					<Box
						p={2}
						style={{
							flex: "1 1 auto",
							minHeight: 0,
							overflowY: "auto",
						}}
					>
						<Box
							style={{
								height: "100%",
								overflowY: "auto",
								backgroundColor: "#E7F7F0",
								borderRadius: "8px",
								border: "1px solid rgba(0, 0, 0, 0.1)",
							}}
							p={1}
						>
							<Grid container spacing={2} rowSpacing={1}>
								<Grid item xs={12} sm={6}>
									<Typography fontSize={14} fontWeight={600}>
										Fields
									</Typography>
								</Grid>

								<Grid item xs={12} sm={6}>
									<Typography fontSize={14} fontWeight={600}>
										Values
									</Typography>
								</Grid>

								{actionFields?.map((actionField, index) => (
									<React.Fragment
										key={
											actionSource +
											"-" +
											actionEvent +
											"-" +
											index
										}
									>
										<Grid item xs={12} sm={6}>
											<Stack
												direction="row"
												alignItems="center"
												spacing={0.5}
												px={1}
												style={{
													borderRadius: "8px",
													border: "1px solid rgba(0, 0, 0, 0.1)",
													backgroundColor: "#fff",
													minHeight: 42,
												}}
											>
												<Checkbox
													onChange={(event) =>
														toggleEnableField(
															event.target
																.checked,
															index
														)
													}
													disabled={
														actionField[0]?.config
															?.isRequired
													}
													checked={
														actionField[0]?.config
															?.isRequired ||
														actionField[0]?.config
															?.isEnabled
													}
												/>

												<Typography fontSize={14}>
													{actionField[0].displayName}
												</Typography>
											</Stack>
										</Grid>

										<Grid item xs={12} sm={6}>
											<Field
												field={actionField[0]}
												index={index}
												hasError={
													fieldError?.[index]?.[0]
												}
												metadata={{
													triggerSourceId,
													actionSource,
													actionEvent,
												}}
											/>
										</Grid>
									</React.Fragment>
								))}
							</Grid>
						</Box>
					</Box>

					<Divider />

					<Box p={2}>
						<Button
							variant="contained"
							disableElevation
							onClick={handleSave}
						>
							Save
						</Button>
					</Box>
				</Box>
			</Box>
		</React.Fragment>
	);
}
