import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
	dismissDuplicateCompany,
	getCompanyDuplicateList,
	getReviewDuplicateData,
	mergeCompanyData,
} from "../../../../api/company/CompanyDuplicates/companyDuplicatesApi";
import {
	companyDuplicateListKey,
	getCompanyReviewDuplicateKey,
} from "../../../../utils/queryKeys";

//Duplicate Company List
const useCompanyDuplicateList = () => {
	return useQuery(companyDuplicateListKey, () => getCompanyDuplicateList(), {
		select: (duplicateData) => duplicateData?.companies,
	});
};

//Merge Duplicate Companies
const useMergeDuplicateCompany = () => {
	let invalidateCompanyDuplicatesList = useInvalidateCompanyDuplicatelist();
	return useMutation(mergeCompanyData, {
		onSuccess: () => {
			invalidateCompanyDuplicatesList();
		},
	});
};

//Invalidate Duplicate company List
const useInvalidateCompanyDuplicatelist = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(companyDuplicateListKey);
	};
};

//Review Duplicates - Company
const useReviewCompanyDuplicateList = (id) => {
	return useQuery(
		getCompanyReviewDuplicateKey(id),
		() => getReviewDuplicateData(id),
		{
			enabled: !!id,
			select: (duplicateData) => duplicateData?.duplicates,
		}
	);
};

//Dismiss comapany dupliacte list
const useDismissDuplicateCompany = () => {
	let invalidateCompanyDuplicatesList = useInvalidateCompanyDuplicatelist();
	return useMutation(dismissDuplicateCompany, {
		onSuccess: () => {
			invalidateCompanyDuplicatesList();
		},
	});
};

export {
	useCompanyDuplicateList,
	useMergeDuplicateCompany,
	useReviewCompanyDuplicateList,
	useDismissDuplicateCompany,
};
