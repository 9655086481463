import React, { useEffect, useRef, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
	Stack,
	Box,
	Button,
	Divider,
	Checkbox,
	useTheme,
	Typography,
	TextField,
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
} from "@mui/material";
import { default as DropDownIcon } from "../../../../../assets/icons/dropDown";
import { default as CloseIcon } from "../../../../../assets/icons/close";
import { default as AddIcon } from "../../../../../assets/icons/add";
import { default as EditIcon } from "../../../../../assets/icons/edit";
import { default as DeleteIcon } from "../../../../../assets/icons/delete";
import { default as TickIcon } from "../../../../../assets/icons/tick";
import {
	TableCellText,
	TextButton,
	Tooltip,
} from "../../../../../styles/twozo";
import {
	deletePriceTag,
	updatePriceTag,
	getVariationPriceList,
	partialUpdateVariation,
} from "../../../../../api/product/variation/variationApi";
import CurrencyMenu from "../../../../Elements/CurrencyMenu";
import { getProductVariationKey } from "../../../../../utils/queryKeys";
import { useCurrenyList } from "../../../../../hooks/services/common/currency";
import Can from "../../../../Auth/Can";
import { PERMISSIONS } from "../../../../../utils/Auth";
import { useAuth } from "../../../../../hooks/auth";

export default function VariationCard(props) {
	const theme = useTheme();
	const variationCardRef = useRef(null);
	const {
		data,
		handleDeleteVariation,
		variationListKey,
		setVariationList,
		newVariationId,
		setNewVariationId,
	} = props;
	const queryClient = useQueryClient();

	const [variationData, setVariationData] = useState(data);
	const [isVariationOpened, setIsVariationOpened] = useState(
		newVariationId === variationData.id ? true : false
	);
	const [variationId, setVariationId] = useState(variationData.id);
	const [newCreatedPriceData, setNewCreatedPriceData] = useState([]);

	const [editedVariationId, setEditedVariationId] = useState(null);
	const [hoveredVariationId, setHoveredVariationId] = useState(null);

	const [selectedTableCell, setSelectedTableCell] = useState(null);
	const [hoveredPriceIndex, setHoveredPriceIndex] = useState(null);
	const [editedRowIndex, setEditedRowIndex] = useState(null);

	const [editedPriceValue, setEditedPriceValue] = useState(null);
	const [isPriceValueChanged, setIsPriceValueChanged] = useState(false);

	const [isAddNewPriceRestricted, setIsAddNewPriceRestricted] =
		useState(false);
	const [isAddPriceTooltipOpened, setIsAddPriceTooltipOpened] =
		useState(false);

	const { data: currencyOptions } = useCurrenyList();
	//SelectedCurrencies are list of currencies selected by all the priceTags of this variation, So order isn't followed here.
	const [selectedCurrencies, setSelectedCurrencies] = useState([]);

	const { isUserAllowedFor } = useAuth();

	const variationKey = getProductVariationKey(variationData.id);

	const { status: variationPriceApiStatus, data: variationPriceApiData } =
		useQuery(variationKey, () => getVariationPriceList(variationId), {
			enabled: !variationData.isVariationNotSaved,
			staleTime: 30000,
		});

	useEffect(() => {
		if (variationPriceApiStatus === "success") {
			if (variationPriceApiData.list) {
				setVariationData((variationData) => {
					if (!variationData.isVariationNotSaved) {
						variationData.priceTags = [
							...variationPriceApiData.list,
							...newCreatedPriceData,
						];
						return { ...variationData };
					}
				});
			}
		}
	}, [variationPriceApiData, variationPriceApiStatus, newCreatedPriceData]);

	useEffect(() => {
		let currenciesFromVariationData = [];

		for (let index = 0; index < variationData?.priceTags?.length; index++) {
			currenciesFromVariationData.push(
				variationData?.priceTags[index].currency.currencyId
			);
		}

		setSelectedCurrencies(currenciesFromVariationData);
	}, [variationData]);

	const onMouseOverVariation = (id) => {
		setHoveredVariationId(id);
	};
	const onMouseOutVariation = () => {
		setHoveredVariationId(null);
	};

	const onMouseOverPriceTag = (index) => {
		setHoveredPriceIndex(index);
	};
	const onMouseOutPriceTags = () => {
		setHoveredPriceIndex(null);
	};

	const noCurrencyToAddPrice = () => {
		return currencyOptions?.length === variationData?.priceTags?.length;
	};

	const isPriceAdditionRestricted = () => {
		return isAddNewPriceRestricted || noCurrencyToAddPrice();
	};

	const handleOpenCloseTable = (id) => {
		setIsVariationOpened((isVariationOpened) => !isVariationOpened);
		setVariationId(id);
	};

	const handleEditVariationName = (id) => {
		if (isUserAllowedFor(PERMISSIONS.product.edit)) {
			setEditedVariationId(id);
		}
	};

	const changeVariationName = (event) => {
		const { value } = event.target;

		setVariationData((variationData) => {
			variationData.name = value;
			return { ...variationData };
		});

		setVariationList((variationList) => {
			for (let index = 0; index < variationList.length; index++) {
				if (variationList[index].id === variationData.id) {
					variationList[index].name = value;
				}
			}
			return [...variationList];
		});
	};

	const handleUpdateName = () => {
		setEditedVariationId(null);
		if (!variationData.isVariationNotSaved) {
			partialUpdateMutation.mutate(getPartialUpdateRequestData());
		}
	};

	const getPartialUpdateRequestData = () => {
		let requestData = {
			productId: variationData.productId,
			id: variationData.id,
			variation: {
				name: variationData.name,
			},
		};
		return requestData;
	};

	const partialUpdateMutation = useMutation(
		(updatedData) => partialUpdateVariation(updatedData),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(variationListKey);
				setNewVariationId(null);
			},
		}
	);

	const deleteVariation = () => {
		handleDeleteVariation(variationData);
	};

	const getCurrencyOptionsForPriceTag = (priceData) => {
		return currencyOptions.filter(
			(currency) =>
				currency.id ===
					(priceData.isPriceNotSaved
						? priceData.currency
						: priceData.currency.currencyId) ||
				!selectedCurrencies.includes(currency.id)
		);
	};

	const deleteNewPrice = (index, priceData) => {
		setVariationData((variationData) => {
			variationData.priceTags.splice(index, 1);
			return { ...variationData };
		});

		setSelectedCurrencies((selectedCurrencies) =>
			selectedCurrencies.filter(
				(currencyId) => currencyId !== priceData.currency
			)
		);

		setNewCreatedPriceData((newCreatedPriceData) =>
			newCreatedPriceData.filter((data) => data !== priceData)
		);
		setIsAddNewPriceRestricted(false);
	};

	const handleEditePriceTags = (index) => {
		setEditedRowIndex(index);
		setSelectedTableCell(null);
		setIsPriceValueChanged(false);
		setIsAddNewPriceRestricted(true);
	};

	const handleTableCell = (event) => {
		setSelectedTableCell(event.currentTarget.id);
		setEditedPriceValue(event.target.value);
	};

	const handleAddPrice = () => {
		if (isPriceAdditionRestricted()) {
			return;
		}

		setNewCreatedPriceData((newCreatedPriceData) => {
			newCreatedPriceData.push({ ...getPriceData() });
			return [...newCreatedPriceData];
		});
		setIsAddNewPriceRestricted(true);
	};

	const getPriceData = () => {
		return {
			currency: "",
			price: "",
			tax: "",
			discount: "",
			isPriceNotSaved: true,
		};
	};

	const handleInputChange = (event, index) => {
		const { name, value } = event.target;

		setVariationData((variationData) => {
			variationData.priceTags[index][name] = value;
			return { ...variationData };
		});

		setVariationList((variationList) => {
			return [...variationList];
		});

		if (editedRowIndex === index) {
			if (editedPriceValue === value) {
				setIsPriceValueChanged(false);
			} else {
				setIsPriceValueChanged(true);
			}
		}
	};

	const handleUpdatePrice = (priceData) => {
		if (priceData.currency) {
			let requestData = getUpdateRequestData(priceData);

			updateMutation.mutate(requestData, {
				onSuccess: () => {
					setNewCreatedPriceData((newCreatedPriceData) =>
						newCreatedPriceData.filter((data) => data !== priceData)
					);
					setIsAddNewPriceRestricted(false);
				},
			});

			setEditedRowIndex(null);
			setSelectedTableCell(null);
		}
	};

	const getUpdateRequestData = (data) => {
		let requestData = {
			productId: variationData.productId,
			id: variationData.id,
			variation: {
				name: variationData.name,
				priceTags: [
					{
						id: data.id,
						unitPrice: data.price,
						currency: data.currency.currencyId || data.currency,
						tax: data.tax,
						discount: data.discount,
					},
				],
			},
		};
		return requestData;
	};

	const updateMutation = useMutation(
		async (updatedPrice) => updatePriceTag(updatedPrice),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(variationListKey);
				queryClient.invalidateQueries(variationKey);
			},
		}
	);

	const handleDeletePriceTags = (priceData) => {
		deleteMutation.mutate(priceData.id, {
			onSuccess: () => {
				setSelectedCurrencies((selectedCurrencies) =>
					selectedCurrencies.filter(
						(currencyId) => currencyId !== priceData.currency
					)
				);
			},
		});
	};

	const deleteMutation = useMutation(async (id) => deletePriceTag(id), {
		onSuccess: () => {
			queryClient.invalidateQueries(variationKey);
		},
	});

	const getCurrencyCode = (currencyDetails) => {
		let currency = currencyOptions?.find(
			(currency) => currency.id === currencyDetails.currencyId
		);
		if (currency) {
			return currency.code;
		}
	};

	const onCurrencyInputChange = (currency, index) => {
		let oldCurrencyId = variationData.priceTags[index].currency;
		let newCurrencyId = currency.id;

		//remove previous selected currency
		setSelectedCurrencies((selectedCurrencies) =>
			selectedCurrencies.filter((currency) => currency !== oldCurrencyId)
		);
		//add newly selected currency
		setSelectedCurrencies([...selectedCurrencies, newCurrencyId]);

		variationData.priceTags[index].currency = newCurrencyId;
		setVariationData({ ...variationData });

		setVariationList((variationList) => {
			return [...variationList];
		});

		if (editedRowIndex === index) {
			setIsPriceValueChanged(true);
		}
	};

	const styles = {
		variationContainer: {
			border: "1px solid rgba(0, 0, 0, 0.1)",
			borderRadius: "8px",
			cursor: "pointer",
		},
		editableTableCell: {
			borderBottom: `2px solid ${theme.palette.primary.main}`,
		},
		variationHeading: {
			fontSize: "14px",
		},
	};

	const isActionsEnable = () => {
		return (
			isUserAllowedFor(PERMISSIONS.product.edit) ||
			isUserAllowedFor(PERMISSIONS.product.delete)
		);
	};

	const isTableCellBorderRemoved = (index) => {
		return (
			index === variationData?.priceTags?.length - 1 &&
			!isUserAllowedFor(PERMISSIONS.product.edit)
		);
	};

	return (
		<React.Fragment>
			<Box sx={styles.variationContainer} my={2} ref={variationCardRef}>
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="space-between"
					onMouseOver={() => onMouseOverVariation(variationData.id)}
					onMouseOut={onMouseOutVariation}
					height="50px"
					px={2}
				>
					<Stack direction="row" spacing={2} alignItems="center">
						<Box
							display="flex"
							onClick={() =>
								handleOpenCloseTable(variationData.id)
							}
							sx={{
								transform: isVariationOpened
									? "rotate(180deg)"
									: null,
							}}
						>
							{DropDownIcon(20, 20)}
						</Box>
						<Stack
							sx={{ cursor: "text" }}
							alignItems="center"
							onClick={() =>
								handleEditVariationName(variationData.id)
							}
						>
							{editedVariationId === variationData.id ||
							newVariationId === variationData.id ? (
								<TextField
									variant="standard"
									defaultValue={variationData.name}
									autoFocus={true}
									InputProps={{
										disableUnderline: true,
									}}
									onChange={changeVariationName}
									onBlur={() =>
										handleUpdateName(variationData)
									}
									inputProps={{
										style: {
											padding: "0px",
											fontSize: "14px",
											fontWeight: 400,
											borderBottom:
												"1.5px solid rgba(0, 0, 0, 0.2)",
										},
									}}
								/>
							) : (
								<Typography style={styles.variationHeading}>
									{variationData.name}
								</Typography>
							)}
						</Stack>
					</Stack>
					{hoveredVariationId === variationData.id &&
					editedVariationId !== variationData.id ? (
						<Can permission={PERMISSIONS.product.delete}>
							<Box
								onClick={() => deleteVariation()}
								display="flex"
							>
								{CloseIcon(20, 20, "#000", "0.6")}
							</Box>
						</Can>
					) : null}
				</Stack>
				{isVariationOpened ? (
					<TableContainer>
						<Table>
							<TableBody>
								{variationData?.priceTags?.map(
									(priceData, index) => (
										<TableRow
											key={index}
											onMouseOver={() =>
												onMouseOverPriceTag(index)
											}
											onMouseOut={onMouseOutPriceTags}
										>
											<TableCell
												width="4%"
												padding="checkbox"
												align="center"
												sx={{
													py: "6px",
													pl: 2,
													borderBottom:
														isTableCellBorderRemoved(
															index
														) && "none",
												}}
											>
												<Stack>
													{priceData.isPriceNotSaved ? (
														<Box
															display="flex"
															onClick={() =>
																deleteNewPrice(
																	index,
																	priceData
																)
															}
														>
															{CloseIcon(
																20,
																20,
																"#000",
																"0.6"
															)}
														</Box>
													) : (
														<Checkbox />
													)}
												</Stack>
											</TableCell>

											<TableCell
												width="14%"
												id={`currency${index}`}
												onClick={
													editedRowIndex === index ||
													priceData.isPriceNotSaved
														? handleTableCell
														: null
												}
												sx={{
													py: "6px",
													borderBottom:
														selectedTableCell ===
															`currency${index}` &&
														styles.editableTableCell,
													border:
														isTableCellBorderRemoved(
															index
														) && "none",
												}}
											>
												{editedRowIndex === index ||
												priceData.isPriceNotSaved ? (
													<CurrencyMenu
														index={index}
														isProductDetailTable={
															true
														}
														currencyOptions={getCurrencyOptionsForPriceTag(
															priceData
														)}
														selectedCurrency={
															priceData.currency
																.currencyId ||
															priceData.currency
														}
														onCurrencyInputChange={
															onCurrencyInputChange
														}
													/>
												) : (
													<Typography
														sx={{
															fontSize: "14px",
															paddingLeft: "16px",
														}}
													>
														{getCurrencyCode(
															priceData.currency
														)}
													</Typography>
												)}
											</TableCell>

											<TableCell
												width="14%"
												align="right"
												id={`unitPrice${index}`}
												onClick={
													editedRowIndex === index ||
													priceData.isPriceNotSaved
														? handleTableCell
														: null
												}
												sx={{
													py: "6px",
													borderBottom:
														selectedTableCell ===
															`unitPrice${index}` &&
														styles.editableTableCell,
													border:
														isTableCellBorderRemoved(
															index
														) && "none",
												}}
											>
												{editedRowIndex === index ||
												priceData.isPriceNotSaved ? (
													<TextField
														variant="standard"
														name="price"
														placeholder="0"
														value={priceData.price}
														onChange={(event) =>
															handleInputChange(
																event,
																index
															)
														}
														InputProps={{
															disableUnderline: true,
														}}
														inputProps={{
															style: {
																fontSize:
																	"14px",
																textAlign:
																	"right",
															},
														}}
													/>
												) : (
													<TableCellText>
														{priceData.price}
													</TableCellText>
												)}
											</TableCell>

											<TableCell
												width="14%"
												align="right"
												id={`tax${index}`}
												onClick={
													editedRowIndex === index ||
													priceData.isPriceNotSaved
														? handleTableCell
														: null
												}
												sx={{
													py: "6px",
													borderBottom:
														selectedTableCell ===
															`tax${index}` &&
														styles.editableTableCell,
													border:
														isTableCellBorderRemoved(
															index
														) && "none",
												}}
											>
												{editedRowIndex === index ||
												priceData.isPriceNotSaved ? (
													<TextField
														variant="standard"
														name="tax"
														placeholder="0%"
														value={priceData.tax}
														onChange={(event) =>
															handleInputChange(
																event,
																index
															)
														}
														InputProps={{
															disableUnderline: true,
														}}
														inputProps={{
															style: {
																fontSize:
																	"14px",
																textAlign:
																	"right",
															},
														}}
													/>
												) : (
													<TableCellText>
														{priceData.tax}
													</TableCellText>
												)}
											</TableCell>

											<TableCell
												width="14%"
												align="right"
												id={`discount${index}`}
												onClick={
													editedRowIndex === index ||
													priceData.isPriceNotSaved
														? handleTableCell
														: null
												}
												sx={{
													py: "6px",
													borderBottom:
														selectedTableCell ===
															`discount${index}` &&
														styles.editableTableCell,
													border:
														isTableCellBorderRemoved(
															index
														) && "none",
												}}
											>
												{editedRowIndex === index ||
												priceData.isPriceNotSaved ? (
													<TextField
														variant="standard"
														name="discount"
														placeholder="0%"
														value={
															priceData.discount
														}
														onChange={(event) =>
															handleInputChange(
																event,
																index
															)
														}
														InputProps={{
															disableUnderline: true,
														}}
														inputProps={{
															style: {
																fontSize:
																	"14px",
																textAlign:
																	"right",
															},
														}}
													/>
												) : (
													<TableCellText>
														{priceData.discount}
													</TableCellText>
												)}
											</TableCell>

											<TableCell
												sx={{
													py: "0px",
													border:
														isTableCellBorderRemoved(
															index
														) && "none",
												}}
											>
												{isActionsEnable() ? (
													<Stack
														justifyContent="flex-end"
														direction="row"
														sx={{ width: "100%" }}
													>
														{hoveredPriceIndex ===
															index &&
														!priceData.isPriceNotSaved &&
														editedRowIndex !==
															index &&
														!isAddNewPriceRestricted ? (
															<Box
																style={{
																	height: "37px",
																	border: `1px solid ${theme.palette.primary.main}`,
																	borderRadius:
																		"8px",
																}}
																py={1}
																px={1.5}
															>
																<Stack
																	direction="row"
																	justifyContent="center"
																	alignItems="center"
																	style={{
																		height: "100%",
																	}}
																	spacing={
																		1.5
																	}
																>
																	<Can
																		permission={
																			PERMISSIONS
																				.product
																				.edit
																		}
																	>
																		<Box
																			display="flex"
																			onClick={() =>
																				handleEditePriceTags(
																					index
																				)
																			}
																		>
																			{EditIcon(
																				20,
																				20,
																				theme
																					.palette
																					.primary
																					.main
																			)}
																		</Box>
																	</Can>

																	<Can
																		permission={
																			PERMISSIONS
																				.product
																				.edit
																		}
																	>
																		<Can
																			permission={
																				PERMISSIONS
																					.product
																					.delete
																			}
																		>
																			<Divider orientation="vertical" />
																		</Can>
																	</Can>

																	<Can
																		permission={
																			PERMISSIONS
																				.product
																				.delete
																		}
																	>
																		<Box
																			display="flex"
																			onClick={() =>
																				handleDeletePriceTags(
																					priceData
																				)
																			}
																		>
																			{DeleteIcon(
																				20,
																				20,
																				theme
																					.palette
																					.error
																					.main
																			)}
																		</Box>
																	</Can>
																</Stack>
															</Box>
														) : null}

														{isPriceValueChanged &&
														editedRowIndex ===
															index &&
														!priceData.isPriceNotSaved ? (
															<Box>
																<Button
																	variant="contained"
																	color="secondary"
																	onClick={() =>
																		handleUpdatePrice(
																			priceData
																		)
																	}
																	startIcon={TickIcon(
																		20,
																		20,
																		"#2EA871"
																	)}
																	disableElevation
																>
																	Update
																</Button>
															</Box>
														) : null}

														<Box
															hidden={
																!priceData.isPriceNotSaved
															}
														>
															<Button
																variant="contained"
																color="secondary"
																startIcon={TickIcon(
																	20,
																	20,
																	"#2EA871"
																)}
																style={{
																	opacity:
																		priceData.currency
																			? 1
																			: 0.6,
																}}
																disableElevation
																onClick={() =>
																	handleUpdatePrice(
																		priceData
																	)
																}
																disabled={
																	updateMutation.isLoading
																}
															>
																Add
															</Button>
														</Box>
													</Stack>
												) : null}
											</TableCell>
										</TableRow>
									)
								)}

								<Can permission={PERMISSIONS.product.edit}>
									<TableRow>
										<TableCell
											sx={{ border: "none", py: "6px" }}
										/>
										<TableCell
											sx={{ border: "none", py: "6px" }}
										>
											<TextButton
												onClick={() => handleAddPrice()}
												startIcon={AddIcon(
													13,
													13,
													theme.palette.secondary
														.contrastText
												)}
												sx={{
													opacity:
														isPriceAdditionRestricted()
															? 0.6
															: 1,
												}}
											>
												<Tooltip
													open={
														isAddPriceTooltipOpened &&
														noCurrencyToAddPrice()
													}
													title="All Currencies already have a price"
													placement="top"
												>
													<Typography
														fontSize={13}
														fontWeight={500}
														style={{
															cursor: isPriceAdditionRestricted()
																? "not-allowed"
																: "auto",
														}}
														onMouseOver={() =>
															setIsAddPriceTooltipOpened(
																true
															)
														}
														onMouseLeave={() =>
															setIsAddPriceTooltipOpened(
																false
															)
														}
														color={
															theme.palette
																.secondary
																.contrastText
														}
													>
														Add More
													</Typography>
												</Tooltip>
											</TextButton>
										</TableCell>
									</TableRow>
								</Can>
							</TableBody>
						</Table>
					</TableContainer>
				) : null}
			</Box>
		</React.Fragment>
	);
}
