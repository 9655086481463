import React, { useCallback, useMemo } from "react";
import FieldList from "../FieldList";
import {
	Stack,
	Box,
	Typography,
	useTheme,
	Divider,
	CircularProgress,
	Switch,
} from "@mui/material";
import { default as InfoIcon } from "../../../../assets/icons/info";
import { default as DealsIcon } from "../../../../assets/icons/deals";
import { default as MultipleCurrencyIcon } from "../../../../assets/icons/multipleCurrency";
import { Tooltip } from "../../../../styles/twozo";
import { updateConfigModule } from "../../../../api/settings/settingsApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getFieldListSettingTypeKey } from "../../../../utils/queryKeys";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import {
	useInvalidateModuleList,
	useModuleList,
} from "../../../../hooks/services/common";
import { useModuleName } from "../../../../hooks/modules";
import { modules } from "../../../../utils/common/ModulesName";

export default function Products(props) {
	const { newFieldCreated, clearCreatedField, moduleName, fieldTypeOption } =
		props;
	const theme = useTheme();
	const queryClient = useQueryClient();
	const { data: moduleList, isLoading } = useModuleList();
	const invalidateModuleList = useInvalidateModuleList();
	const { getModuleName } = useModuleName();
	const viewName = getModuleName(modules.PRODUCT);

	const getModule = useCallback(
		(moduleName) => {
			let module = moduleList?.find(
				(module) => module.sourceName === moduleName
			);
			if (module) {
				return module;
			}
		},
		[moduleList]
	);

	const isEnabled = useMemo(() => {
		return getModule(moduleName)?.isEnabled;
	}, [getModule, moduleName]);

	const fieldListSettingTypeKey = getFieldListSettingTypeKey(moduleName);

	const handleProductInputChange = (event) => {
		const { checked } = event.target;
		updateMutation.mutate({ source: moduleName, isEnabled: checked });
	};

	const updateMutation = useMutation(
		async (updatedConfig) => updateConfigModule(updatedConfig),
		{
			onSuccess: (data) => {
				if (data.isEnabled) {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.productEnabed,
					});
				} else {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.productDisabled,
					});
				}
				// TODO notify user that the change is done successfully
				invalidateModuleList();
				queryClient.invalidateQueries(fieldListSettingTypeKey);
			},
			onError: (error) => {
				// TODO notify user that the change is unsuccessfully
				alert(error.message);
			},
		}
	);

	const FeatureText = (props) => (
		<Stack direction="row" justifyContent="center" spacing={2} p={2}>
			{props.icon(20, 20, theme.palette.primary.main)}

			<Typography fontSize={14}>{props.text}</Typography>
		</Stack>
	);

	return (
		<React.Fragment>
			{isLoading ? (
				<Stack
					alignItems="center"
					justifyContent="center"
					height="60vh"
				>
					<CircularProgress />
				</Stack>
			) : (
				<Stack spacing={2}>
					<Box
						sx={{
							p: 2,
							border: "1px solid rgba(0, 0, 0, 0.1)",
							borderRadius: "8px",
						}}
					>
						<Stack
							direction="row"
							alignItems="center"
							spacing={3.2}
						>
							<Switch
								onChange={(event) =>
									handleProductInputChange(event)
								}
								checked={isEnabled}
							/>
							<Stack
								direction="row"
								alignItems="center"
								spacing={0.5}
							>
								<Typography fontSize={16} fontWeight={500}>
									{viewName}
								</Typography>

								{isEnabled ? (
									<Box display="flex">
										<Tooltip
											PopperProps={{
												modifiers: [
													{
														name: "offset",
														options: {
															offset: [120, -8],
														},
													},
												],
											}}
											title={
												<Typography
													component="span"
													sx={{
														fontSize: "14px",
														fontWeight: 400,
													}}
												>
													Enables you to create
													products or services, price
													them and link them to deals.
													If you add products to a
													deal, the deal value will be
													calculated automatically
													based on the sum of product
													prices in that deal.{" "}
													<Typography
														component="a"
														sx={{
															fontSize: "14px",
															fontWeight: 400,
															color: theme.palette
																.primary.main,
														}}
													>
														Learn more about
														products in Twozo
													</Typography>
												</Typography>
											}
										>
											{InfoIcon(18, 18, "#000", 0.6)}
										</Tooltip>
									</Box>
								) : null}
							</Stack>
						</Stack>
					</Box>

					<Box px={2} hidden={isEnabled}>
						<Typography
							component="span"
							sx={{
								fontSize: "14px",
								fontWeight: 500,
								wordSpacing: "1px",
							}}
						>
							Enables you to create products or services, price
							them and link them to deals. If you add products to
							a deal, the deal value will be calculated
							automatically based on the sum of product prices in
							that deal.{" "}
							<Typography
								component="a"
								sx={{
									fontSize: "14px",
									fontWeight: 500,
									color: theme.palette.primary.main,
								}}
							>
								Learn more about products in Twozo{" "}
							</Typography>
						</Typography>

						<Stack alignItems="center">
							<Stack alignItems="flex-start">
								<FeatureText
									icon={MultipleCurrencyIcon}
									text="Create custom products in multiple currencies"
								/>

								<Divider style={{ width: "100%" }} />

								<FeatureText
									icon={DealsIcon}
									text="Link your products to deal in bulk"
								/>

								<Divider style={{ width: "100%" }} />

								<FeatureText
									icon={MultipleCurrencyIcon}
									text="Supports price variations"
								/>

								<Divider style={{ width: "100%" }} />
							</Stack>
						</Stack>
					</Box>
					<Box hidden={!isEnabled} pt={1}>
						<FieldList
							clearCreatedField={clearCreatedField}
							newFieldCreated={newFieldCreated}
							moduleName={moduleName}
							fieldTypeOption={fieldTypeOption}
						/>
					</Box>
				</Stack>
			)}
		</React.Fragment>
	);
}
