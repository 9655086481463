import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getDealTimelineKey } from "../../../../utils/queryKeys";
import { getDealTimelineData } from "../../../../api/timeline/timelineAPI";

const useDealTimelineData = (dealId, timelineIdsToFilter) => {
	const dealTimelineKey = getDealTimelineKey(dealId, timelineIdsToFilter);
	return useQuery(
		dealTimelineKey,
		() => getDealTimelineData(dealId, timelineIdsToFilter),
		{
			select: (data) => data?.timeline,
		}
	);
};

const useInvalidateDealTimelineData = (dealId) => {
	let queryClient = useQueryClient();
	const dealTimelineKey = getDealTimelineKey(dealId);
	return () => {
		queryClient.invalidateQueries(dealTimelineKey);
	};
};

export { useDealTimelineData, useInvalidateDealTimelineData };
