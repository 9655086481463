import { getDropdownKeyByFieldName, getTableKey } from "../commonQueryKeys";
import { getRootKey } from "../rootKey";
import { sourceType } from "../tableNames";

export const getDealKey = () => {
	return [...getRootKey(), sourceType.DEAL]; // Return the deal query key
};

export const getDealKanbanViewKey = () => {
	return [...getRootKey(), sourceType.DEAL, "kanbanView"];
};

// query keys
export const pipelineListKey = [...getDealKey(), "pipelineList"];

export const dealDropdownKey = [...getDealKey(), "dealDropdownData"];

export const pipelineDropdownKey = [...getDealKey(), "pipelineDropdownData"];

export const pipelineStagesDropdownKey = [
	...getDealKey(),
	"pipelineStagesDropdownData",
];

export const lostDropdownCacheKey = [...getDealKey(), "lostReasonDropdown"];

export const wonDropdownCacheKey = [...getDealKey(), "wonReasonDropdown"];

export const dealFieldListKey = [...getDealKey(), "dealFieldList"];

//query key methods
export const getDealTableKey = () => {
	return getTableKey(sourceType.DEAL);
};

export const getDealKeyById = (dealId) => {
	return [...getDealKey(), String(dealId)];
};

export const getDealDataKey = (dealId) => {
	return [...getDealKeyById(dealId), "details"];
};

export const getDealRelatedEntitiesKey = (dealId) => {
	return [...getDealKeyById(dealId), "relatedEntities"];
};

export const getDealListKey = () => {
	return [...getDropdownKeyByFieldName("dealList")];
};

export const getDealSummaryKey = (dealId) => {
	return [...getDealKeyById(dealId), "summary"];
};

export const getDealSummaryViewKey = (dealId, hideEmptyFields) => {
	return [...getDealKeyById(dealId), "summary", hideEmptyFields];
};

export const getDealTimelineKey = (dealId, filters) => {
	if (Array.isArray(filters) && filters.length > 0) {
		return [...getDealKeyById(dealId), "timeline", filters]; // Return the query key when the timeline filter is applied
	}
	return [...getDealKeyById(dealId), "timeline"]; // Return the query key when the timeline filter is not applied
};

export const getDealSwipeableSummaryKey = (dealId) => {
	return [...getDealKeyById(dealId), "swipeableSummary"];
};

export const getDealNotesKey = (dealId) => {
	return [...getDealKeyById(dealId), "notes"];
};

export const getDealFilesKey = (dealId) => {
	return [...getDealKeyById(dealId), "files"];
};

export const getDealsByStageKey = (stageId, filter) => {
	if (typeof filter === "object" && Object.keys(filter).length > 0) {
		return [
			...getDealKanbanViewKey(),
			String(stageId),
			"dealsByStage",
			filter,
		];
	}
	return [...getDealKanbanViewKey(), String(stageId), "dealsByStage"];
};

export const getStagesByPipelineKey = (pipelineId) => {
	return [...getDealKanbanViewKey(), String(pipelineId), "stagesByPipeline"];
};

export const getDealToDoActivityKey = (dealId, filters) => {
	if (Array.isArray(filters) && filters.length > 0) {
		return [...getDealKeyById(dealId), "todoActivities", filters]; // Return the query key when the activity filter is applied
	}
	return [...getDealKeyById(dealId), "todoActivities"]; // Return the query key when the activity filter is not applied
};

export const getDealCompletedActivityKey = (dealId, filters) => {
	if (Array.isArray(filters) && filters.length > 0) {
		return [...getDealKeyById(dealId), "completedActivities", filters]; // Return the query key when the activity filter is applied
	}
	return [...getDealKeyById(dealId), "completedActivities"]; // Return the query key when the activity filter is not applied
};

export const getDealUpcomingActivitiesKey = (dealId) => {
	return [...getDealKeyById(dealId), "upcomingActivities"];
};

export const getDealOverViewKey = (dealId) => {
	return [...getDealKeyById(dealId), "overView"];
};

export const getDealParticipantsDataKey = (dealId) => {
	return [...getDealKeyById(dealId), "participants"];
};

export const getDealProgressViewKey = (dealId) => {
	return [...getDealKeyById(dealId), "progressView"];
};

export const getMergeDealDetailsKey = (dealId) => {
	return [...getDealKeyById(dealId), "mergeDealDetails"];
};

export const getDealMergePreviewDataKey = (primaryDealId, secondaryDealId) => {
	return [
		...getDealKey(),
		String(primaryDealId),
		String(secondaryDealId),
		"preview",
	];
};

export const getContactsByDealsKey = (dealIds) => {
	return [...getDealKey(), dealIds, "contactsByDeals"];
};

export const getLinkedProductsToDealKey = (dealId) => {
	return [...getDealKey(), String(dealId), "linkedProducts"];
};

export const getDealParticipantsDropdownKey = (dealId) => {
	return [...getDealKeyById(dealId), "nonParticipantList"];
};

export const getDealForecastViewDataKey = (
	pipelineId,
	startDate,
	colomn,
	interval,
	dealState,
	dealField,
	filter
) => {
	if (
		filter &&
		typeof filter === "object" &&
		Object.keys(filter)?.length > 0
	) {
		return [
			...getDealKey(),
			"forecast_view",
			pipelineId,
			startDate,
			colomn,
			interval,
			dealState,
			dealField,
			filter,
		];
	}
	return [
		...getDealKey(),
		"forecast_view",
		pipelineId,
		startDate,
		colomn,
		interval,
		dealState,
		dealField,
	];
};

export const getDealFieldListKey = () => {
	return [...getDealKey(), "deal_list"];
};
