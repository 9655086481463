import React from "react";
import { useRelatedContactsList } from "../../../../hooks/services/company";
import RelatedContacts from "../../../Summary/RelatedContacts";
import { Box, Typography } from "@mui/material";

export default function CompanyRelatedContacts(props) {
	const { companyId } = props;

	// query call
	const { data: relatedContactsList, isLoading } =
		useRelatedContactsList(companyId);

	return (
		<React.Fragment>
			<Box p={1}>
				<Typography fontSize={14} fontWeight={600} pb={1.5}>
					Related Contacts
				</Typography>

				<RelatedContacts
					isLoading={isLoading}
					isCloseIconVisible={true}
					isAddParticipantsVisible={false}
					dealParticipantsData={relatedContactsList}
				/>
			</Box>
		</React.Fragment>
	);
}
