import React from "react";
import { Typography } from "@mui/material";
import TextComponent from "./FieldComponent/TextComponent";
import DropdownComponent from "./FieldComponent/DropdownComponent";
import TextAreaComponent from "./FieldComponent/TextAreaComponent";
import DateComponent from "./FieldComponent/DateComponent";

const fieldComponents = {
	SINGLE_STRING: TextComponent,
	DUAL_PICKER: DropdownComponent,
	OWNER: DropdownComponent,
	DROPDOWN: DropdownComponent,
	MULTI_DROPDOWN: DropdownComponent,
	TEXT: TextAreaComponent,
	DATE: DateComponent,
};

export default function Field(props) {
	const { field, metadata, style, ...other } = props;
	const FieldComponent = fieldComponents[field.fieldType];

	return (
		<React.Fragment>
			{FieldComponent ? (
				<FieldComponent
					field={field}
					metadata={metadata}
					style={style}
					{...other}
				/>
			) : (
				<Typography fontSize={14}>
					{field.fieldType} is not implemented
				</Typography>
			)}
		</React.Fragment>
	);
}
