import React, { useEffect, useMemo, useState } from "react";
import { alpha, Box, Button, Stack, ToggleButton } from "@mui/material";
import ToggleButtonGroup from "../../../../Elements/ToggleButtonGroup";
import Permissions from "./Permissions";
import FieldList from "./FieldList";
import AssignedUserList from "./AssignedUserList";
import { usePermissionContext } from "../PermissionContext";
import { getPermissionSetRequest } from "../../../../../utils/PermissionUtils";
import {
	useCreateRole,
	useRoleDetails,
	useUpdateRoleDetails,
} from "../../../../../hooks/services/userManagement/roles";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../utils/notification/notificationMessages";

export default function PermissionDialog(props) {
	const {
		selectedRole,
		isCreateRole,
		roleName,
		defaultSelectedAccess,
		isEditable,
		onClose,
	} = props;
	const [type, setType] = useState("Permissions");
	const [assignedUserDetails, setAssignedUserDetails] = useState([]);
	const { permissionState } = usePermissionContext();
	const updateMutation = useUpdateRoleDetails(selectedRole);
	const createMutation = useCreateRole();

	const {
		data: userData,
		isLoading: isUserDataLoading,
		status: userDataStatus,
	} = useRoleDetails(selectedRole, !isCreateRole);

	const isAsignUserDetailsLoading = !isCreateRole && isUserDataLoading;

	useEffect(() => {
		if (userDataStatus === "success") {
			updateAssignUserDetails(userData?.users || []);
		}
	}, [userDataStatus, userData]);

	const updateAssignUserDetails = (userDetails) => {
		setAssignedUserDetails(userDetails);
	};

	const handleToggleButtonGroup = (_, type) => {
		if (type) {
			setType(type);
		}
	};

	const assignedUserIds = useMemo(() => {
		if (assignedUserDetails.length > 0) {
			return assignedUserDetails?.map((user) => user?.id);
		} else return [];
	}, [assignedUserDetails]);

	const updateRole = () => {
		const request = getPermissionSetRequest(
			isCreateRole,
			roleName,
			selectedRole,
			permissionState,
			assignedUserIds
		);
		updateMutation.mutate(request, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.permissionUpdated,
				});
				onClose();
			},
			onError: (error) => {
				let errorMessage = error.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage ?? notificationMessage.errorMessage,
				});
			},
		});
	};

	const createRole = () => {
		const request = getPermissionSetRequest(
			isCreateRole,
			roleName,
			selectedRole,
			permissionState,
			assignedUserIds
		);
		createMutation.mutate(request, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.roleCreated,
				});
				onClose();
			},
			onError: (error) => {
				let errorMessage = error.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage ?? notificationMessage.errorMessage,
				});
			},
		});
	};

	const updateRoleDetails = () => {
		if (isCreateRole) {
			createRole();
		} else {
			updateRole();
		}
	};

	return (
		<React.Fragment>
			<Box
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box px={2} pt={2} pb={1}>
					<ToggleButtonGroup
						value={type}
						onChange={handleToggleButtonGroup}
					>
						<ToggleButton value="Permissions">
							Permissions
						</ToggleButton>
						<ToggleButton value="Fields">Fields</ToggleButton>
						<ToggleButton value="Assigned Users">
							Assigned Users
						</ToggleButton>
					</ToggleButtonGroup>
				</Box>

				<Box
					px={2}
					py={1}
					style={{
						flex: "1 1 auto",
						minHeight: "0px",
					}}
				>
					{(function () {
						switch (type) {
							case "Permissions":
								return (
									<Permissions
										roleId={selectedRole}
										isEditable={
											isCreateRole ? true : isEditable
										}
										isCreateRole={isCreateRole}
										defaultSelectedAccess={
											defaultSelectedAccess
										}
									/>
								);
							case "Fields":
								return <FieldList />;
							case "Assigned Users":
								return (
									<AssignedUserList
										assignedUserDetails={
											assignedUserDetails
										}
										updateAssignUserDetails={
											updateAssignUserDetails
										}
										isUserDataLoading={
											isAsignUserDetailsLoading
										}
									/>
								);
							default:
								return null;
						}
					})()}
				</Box>

				<Box>
					<Stack
						direction="row"
						justifyContent="flex-end"
						pt={1}
						pb={3}
						pr={4}
					>
						<Button
							variant="contained"
							disableElevation
							disabled={
								createMutation.isLoading ||
								updateMutation.isLoading
							}
							sx={{
								"&.Mui-disabled": {
									backgroundColor: "rgba(51, 188, 126)",
									color: alpha("#FFFFFF", 0.6),
								},
							}}
							onClick={() => updateRoleDetails()}
						>
							Save
						</Button>
					</Stack>
				</Box>
			</Box>
		</React.Fragment>
	);
}
