import { useTheme } from "@emotion/react";
import { Box, MenuItem, Select, Stack, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import MenuSearchBar from "../../MenuSearchBar";
import { default as SalesOwnerImage } from "../../../../assets/images/companyOwner/kumaresh.png";
import { useUserList } from "../../../../hooks/services/userManagement/user";
import styled from "@emotion/styled";
import { Controller, useForm } from "react-hook-form";
import { handleFieldValidation } from "../../../../utils/fieldValidation";
import { useCallback, forwardRef, useImperativeHandle } from "react";

const CustomSelect = styled(Select)(() => ({
	"& .MuiOutlinedInput-notchedOutline": {
		border: "none",
	},
	"&:hover .MuiOutlinedInput-notchedOutline": {
		border: "none",
	},
	"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
		border: "none",
	},
}));

const SalesOwnerEditComponent = forwardRef(
	({ value, field, onError, onSave, onClose }, ref) => {
		const theme = useTheme();
		const fieldId = field?.fieldId;

		// query call :-
		const { data: userList } = useUserList();

		const {
			clearErrors,
			control,
			formState: { errors },
			handleSubmit,
		} = useForm({ values: { [fieldId]: value }, reValidateMode: false });

		const [selectedUserId, setSelectedUserId] = useState(null);
		const [searchValue, setSearchValue] = useState("");
		const [filteredUserList, setFilteredUserList] = useState([]);

		const handleOwnerInputChange = (event, onChange) => {
			const { value } = event.target;
			onChange(value);
			setSelectedUserId(value);
			clearErrors(fieldId + "");
			onError(null);
		};

		const handleSearchValue = (event) => {
			const { value } = event.target;
			setSearchValue(value);
		};

		const selectedUserName = useMemo(() => {
			if (!selectedUserId) {
				return "";
			}
			const selectedUser = userList?.find(
				(user) => user.value === selectedUserId
			);
			return selectedUser ? selectedUser.name : "";
		}, [selectedUserId, userList]);

		useEffect(() => {
			if (value) {
				setSelectedUserId(value);
			} else if (field?.config?.isDefaultField) {
				let defaultUserData = userList?.find(
					(user) => user?.config?.isLoggedInUser
				);
				setSelectedUserId(defaultUserData?.value);
			}
		}, [value, userList]);

		useEffect(() => {
			const getFilteredUserList = () => {
				if (Array.isArray(userList)) {
					if (searchValue.trim()) {
						return userList?.filter((user) =>
							//filter for search
							user.name
								.toLowerCase()
								.startsWith(searchValue.toLowerCase())
						);
					}
					return userList;
				}
				return [];
			};
			setFilteredUserList(getFilteredUserList());
		}, [userList, searchValue]);

		const renderEmptyView = () => {
			return (
				<Stack
					alignItems="center"
					justifyContent="center"
					height="40px"
				>
					<Typography fontSize={13} color="rgba(0, 0, 0, 0.6)">
						{userList?.length === 0
							? "No Options Found"
							: "No Results Found"}
					</Typography>
				</Stack>
			);
		};

		const submitForm = useCallback(() => {
			return handleSubmit(
				(data) => {
					onSave(data, onClose);
					onError(null);
					return data;
				},
				(errors) => {
					if (errors[fieldId]) {
						onError(errors[fieldId]);
					}
				}
			)();
		}, [handleSubmit, fieldId, onError, onSave, onClose]);

		useImperativeHandle(ref, () => ({
			submitForm,
		}));

		return (
			<React.Fragment>
				<Controller
					control={control}
					name={fieldId + ""}
					defaultValue=""
					rules={handleFieldValidation(field)}
					render={({ field: { onChange, value } }) => (
						<CustomSelect
							size="small"
							fullWidth
							value={value}
							onChange={(event) =>
								handleOwnerInputChange(event, onChange)
							}
							MenuProps={{
								autoFocus: false,
								PaperProps: {
									style: {
										maxHeight: 300,
										marginTop: "4px",
										borderRadius: "8px",
									},
								},
							}}
							displayEmpty
							renderValue={() => (
								<Typography
									fontSize={14}
									color={
										selectedUserId
											? "#000"
											: "rgba(0, 0, 0, 0.6)"
									}
								>
									{selectedUserId
										? selectedUserName
										: "Select"}
								</Typography>
							)}
							onOpen={() => setSearchValue("")}
						>
							<Box
								p={0.5}
								marginTop="-8px"
								sx={{
									position: "sticky",
									top: 0,
									zIndex: 1,
									backgroundColor: "#fff",
								}}
							>
								<MenuSearchBar
									value={searchValue}
									onChange={handleSearchValue}
								/>
							</Box>

							{filteredUserList?.length > 0
								? filteredUserList?.map((user) => (
										<MenuItem
											value={user.value}
											key={user.value}
											style={{
												height: "40px",
												display: user?.config?.isActive
													? "block"
													: "none",
											}}
										>
											<Stack
												direction="row"
												spacing={1}
												alignItems="center"
											>
												<img
													src={SalesOwnerImage}
													alt="owner_image"
													height="24px"
													width="24px"
												/>

												<Typography fontSize={14}>
													{user.name}
												</Typography>
											</Stack>
										</MenuItem>
									))
								: renderEmptyView()}
						</CustomSelect>
					)}
				/>
				{errors[fieldId] ? (
					<Typography
						pl={1}
						pb={1.2}
						style={{
							fontSize: 13,
							marginLeft: "6px",
							color: theme.palette.error.main,
						}}
					>
						{errors[fieldId]?.message}
					</Typography>
				) : null}
			</React.Fragment>
		);
	}
);

SalesOwnerEditComponent.displayName = "SalesOwnerEditComponent";

export default SalesOwnerEditComponent;
