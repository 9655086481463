import React, { useState } from "react";
import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import ContactList from "./ContactList";

export default function FieldList() {
	const theme = useTheme();
	const [seletedModules, setSelectedModules] = useState(1);

	const handleSelectModule = (module) => {
		setSelectedModules(module.id);
	};

	const moduleList = [
		{
			id: 1,
			name: "Contacts",
		},
		{
			id: 2,
			name: "Companies",
		},
		{
			id: 3,
			name: "Deals",
		},
		{
			id: 4,
			name: "Products",
		},
	];

	return (
		<React.Fragment>
			<Box
				style={{
					display: "flex",
					flexDirection: "column",
					height: "100%",
					width: "100%",
				}}
			>
				<Box
					style={{
						flex: "1 1 auto",
						overflowY: "auto",
						minHeight: "0px",
					}}
				>
					<Stack direction="row" spacing={1} height="100%">
						<Box width="15%" overflow="auto">
							{moduleList.map((module) => (
								<Stack
									key={module.id}
									onClick={() => handleSelectModule(module)}
									style={{
										cursor: "pointer",
									}}
								>
									<Typography
										fontSize={13}
										p={1}
										style={{
											backgroundColor:
												module.id === seletedModules
													? theme.palette.secondary
															.main
													: "transparent",
											borderRadius: "8px",
										}}
									>
										{module.name}
									</Typography>
								</Stack>
							))}
						</Box>

						<Box
							width="85%"
							border="1px solid rgba(0, 0, 0, 0.10)"
							borderRadius="8px"
							overflow="auto"
						>
							<ContactList />
						</Box>
					</Stack>
				</Box>

				<Stack direction="row" justifyContent="flex-end" p={2}>
					<Button variant="contained" disableElevation>
						Save
					</Button>
				</Stack>
			</Box>
		</React.Fragment>
	);
}
