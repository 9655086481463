import React, { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
} from "../../../Elements/Accordian";
import AddParticipants from "../../../Summary/RelatedContacts/AddParticipants";
import RelatedContacts from "../../../Summary/RelatedContacts";
import {
	useAddParticipantMutation,
	useDealParticipants,
	useDeleteParticipantMutation,
	useParticipantsDropdownList,
} from "../../../../hooks/services/deal/dealParticipants";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";

export default function DealRelatedContacts(props) {
	const { dealId } = props;
	const [isExpended, setIsExpended] = useState(false);

	const { data: participantsDropdownList } =
		useParticipantsDropdownList(dealId);

	const { data: dealParticipantsData, isLoading } =
		useDealParticipants(dealId);

	const addParticipantMutation = useAddParticipantMutation(dealId);

	const deleteParticipantMutation = useDeleteParticipantMutation(dealId);

	const onChangeParticipantsMenu = (participant) => {
		let addParticipantRequestData = {
			dealId: dealId,
			contactId: participant?.value,
		};

		addParticipantMutation.mutate(addParticipantRequestData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.dealUpdated,
				});
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants?.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	const handleDeleteParticipant = (participantId) => {
		deleteParticipantMutation.mutate(participantId, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.dealUpdated,
				});
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	return (
		<React.Fragment>
			<Box>
				<Accordion
					expanded={isExpended}
					onChange={() => setIsExpended((isExpended) => !isExpended)}
				>
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						width="100%"
					>
						<AccordionSummary
							aria-controls="panel1d-content"
							id="panel1d-header"
							sx={{
								width: "90%",
							}}
						>
							<Typography fontSize={14} fontWeight={600}>
								Related Contacts
							</Typography>
						</AccordionSummary>

						{isExpended ? (
							<Stack alignItems="end" pr={2} width="10%">
								<AddParticipants
									participantsDropdownList={
										participantsDropdownList
									}
									onChangeParticipantsMenu={
										onChangeParticipantsMenu
									}
								/>
							</Stack>
						) : null}
					</Stack>

					<AccordionDetails style={{ paddingTop: "0px" }}>
						<RelatedContacts
							isLoading={isLoading}
							dealParticipantsData={dealParticipantsData}
							handleDeleteParticipant={handleDeleteParticipant}
						/>
					</AccordionDetails>
				</Accordion>
			</Box>
		</React.Fragment>
	);
}
