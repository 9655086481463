import React from "react";
import AddForm from "../../../Elements/AddForm";
import {
	removeFieldsWithEmptyValues,
	removeFieldsWithIdKey,
} from "../../../../utils/common";
import { enqueueSnackbar } from "notistack";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import {
	useCreateMutation,
	useContactData,
} from "../../../../hooks/services/contact";
import { modules } from "../../../../utils/common/ModulesName";
import { useContactFieldPermissions } from "../../../../hooks/modules/auth/contact";
import FieldPermissionContextProvider from "../../../Elements/FieldPermissionContext/FieldPermissionContextProvider";

export default function CloneContact(props) {
	const { contactId, onClose } = props;
	const moduleName = modules.CONTACT;
	const cloneMutation = useCreateMutation();

	const contactFieldPermissions = useContactFieldPermissions();

	const { data: contactData } = useContactData(contactId);

	const cloneContact = (clonedFormData) => {
		removeFieldsWithEmptyValues(clonedFormData);
		removeFieldsWithIdKey(clonedFormData);
		cloneMutation.mutate(clonedFormData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.contactCloned,
				});
				onClose();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.genericErrorMessage,
				});
			},
		});
	};

	return (
		<FieldPermissionContextProvider value={contactFieldPermissions}>
			<AddForm
				onSave={cloneContact}
				moduleName={moduleName}
				formData={contactData}
				onClose={onClose}
				isCloneMode={true}
			></AddForm>
		</FieldPermissionContextProvider>
	);
}
