import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { Draggable } from "react-beautiful-dnd";
import { PERMISSIONS } from "../../../../utils/Auth";
import { useAuth } from "../../../../hooks/auth";
import DealCard from "../../DealCard";

export default function DealCardList(props) {
	const { deals, pipelineStageId } = props;
	const navigate = useNavigate();
	const { pathname, search } = useLocation();
	const { isUserAllowedFor } = useAuth();

	const navigateDealDetailsPage = (dealId) => {
		navigate(dealId.toString(), {
			state: {
				prevPath: `${pathname}${search ? search : ""}`,
			},
		});
	};

	return (
		<React.Fragment>
			{Array.isArray(deals) && deals?.length > 0 ? (
				<Stack height="100%" p={1}>
					{deals.map((deal, index) => (
						<Draggable
							key={deal?.id}
							draggableId={deal?.id?.toString()}
							index={index}
							isDragDisabled={
								!isUserAllowedFor(PERMISSIONS.deal.edit)
							}
						>
							{(draggableProvided) => (
								<Box
									ref={draggableProvided.innerRef}
									{...draggableProvided.draggableProps}
									{...draggableProvided.dragHandleProps}
									sx={{
										marginBottom: "8px",
									}}
								>
									<DealCard
										deal={deal}
										pipelineStageId={pipelineStageId}
										onClick={() =>
											navigateDealDetailsPage(deal.id)
										}
										isLastDealCard={
											deals?.length - 1 === index
										}
									/>
								</Box>
							)}
						</Draggable>
					))}
				</Stack>
			) : (
				<Stack
					justifyContent="center"
					alignItems="center"
					height="100%"
					p={1}
				>
					<Typography
						fontSize="13px"
						style={{
							color: "rgba(0, 0, 0, 0.6)",
						}}
					>
						No Deals Found Here
					</Typography>
				</Stack>
			)}
		</React.Fragment>
	);
}
