import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
	dismissDulpicateContact,
	getContactDuplicateList,
	getReviewDuplicateData,
	mergeContactData,
} from "../../../../api/contacts/contactDuplicates/contactDuplicatesApi";
import {
	contactDuplicateListKey,
	getContactReviewDuplicateKey,
} from "../../../../utils/queryKeys";

//Duplicate Contact List
const useDuplicateContactList = () => {
	return useQuery(contactDuplicateListKey, () => getContactDuplicateList(), {
		select: (duplicateData) => duplicateData?.contacts,
	});
};

//Merge Duplicate Contacts
const useMergeDuplicateContact = () => {
	let invalidateContactDuplicateList = useInvalidateContactDuplicateList();
	return useMutation(mergeContactData, {
		onSuccess: () => {
			invalidateContactDuplicateList();
		},
	});
};

//Invalidate Duplicate contact list
const useInvalidateContactDuplicateList = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(contactDuplicateListKey);
	};
};

//Review Duplicates - Contact
const useReviewContactDuplicateList = (primaryContactId) => {
	let contactReviewDuplicateKey =
		getContactReviewDuplicateKey(primaryContactId);
	return useQuery(
		contactReviewDuplicateKey,
		() => getReviewDuplicateData(primaryContactId),
		{
			enabled: !!primaryContactId,
			select: (duplicateData) => duplicateData?.duplicates,
		}
	);
};

//Dismiss contact dupliacte list
const useDismissDuplicateContact = () => {
	let invalidateContactDuplicateList = useInvalidateContactDuplicateList();
	return useMutation(dismissDulpicateContact, {
		onSuccess: () => {
			invalidateContactDuplicateList();
		},
	});
};

export {
	useDismissDuplicateContact,
	useDuplicateContactList,
	useReviewContactDuplicateList,
	useMergeDuplicateContact,
};
