import React, { useState } from "react";
import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import {
	Tooltip,
	twozoStyles,
	SummaryFieldName,
} from "../../../../../../styles/twozo";
import Menu from "../../../../Menu";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../../../hooks/auth";
import { PERMISSIONS } from "../../../../../../utils/Auth";

export default function DealComponent(props) {
	const { summaryField } = props;
	const theme = useTheme();
	const classes = twozoStyles();
	const navigate = useNavigate();

	const { isUserAllowedFor } = useAuth();

	const [openToolTip, setOpenToolTip] = useState(false);
	const [dealMenuElement, setDealMenuElement] = useState(null);
	const isDealMenuOpened = Boolean(dealMenuElement);

	const styles = {
		inputContent: {
			width: "150px",
			"&:hover": { borderBottom: "1px solid rgba(0, 0, 0, 0.2)" },
			fontSize: "14px",
			fontWeight: 500,
			color: theme.palette.secondary.contrastText,
			cursor: "pointer",
			opacity: "0.4",
		},
	};

	const handleOpenTooltip = (event) => {
		setOpenToolTip(event.target.scrollWidth > event.target.clientWidth);
	};

	const openDealMenu = (event) => {
		setDealMenuElement(event.currentTarget);
	};

	const closeDealMenu = () => {
		setDealMenuElement(null);
	};

	const getDealState = (state) => {
		switch (state) {
			case "open":
				return "Open";
			case "won":
				return "Won";
			case "lost":
				return "Lost";
			default:
				return "Closed";
		}
	};

	return (
		<React.Fragment>
			<Menu
				anchorEl={dealMenuElement}
				open={isDealMenuOpened}
				onClose={closeDealMenu}
			>
				{Array.isArray(summaryField?.value?.deals) &&
					summaryField?.value?.deals.map((deal, index) => (
						<Box minWidth="364px" key={deal.id}>
							<Box p="12px 16px">
								<Stack
									direction="row"
									justifyContent="space-between"
									alignItems="center"
								>
									<Stack direction="column" spacing={0.5}>
										<Typography
											fontSize={13}
											fontWeight={500}
											color={theme.palette.primary.main}
											style={{
												cursor: "pointer",
											}}
											onClick={() =>
												navigate(`/deals/${deal.id}`)
											}
										>
											{deal?.title}
										</Typography>

										<Typography
											fontSize={12}
											fontWeight={400}
											style={{
												opacity: 0.6,
											}}
										>
											{deal?.pipelineStage?.name}
										</Typography>
									</Stack>

									<Typography fontSize={13} fontWeight={500}>
										{`${deal?.dealValue?.symbol} ${deal?.dealValue?.value}`}
									</Typography>
								</Stack>
							</Box>

							{summaryField?.value?.deals?.length - 1 !==
								index && <Divider />}
						</Box>
					))}
			</Menu>
			<td>
				<Typography fontSize={14} className={classes.summaryHead}>
					{summaryField.fieldName} :
				</Typography>
			</td>
			<td
				style={{
					padding: 0,
					display: "flex",
					alignItems: "center",
					cursor: "pointer",
				}}
			>
				{isUserAllowedFor(PERMISSIONS.deal.view) ? (
					<Box>
						{Array.isArray(summaryField?.value?.deals) ? (
							<Tooltip
								open={openToolTip}
								placement="bottom"
								title={summaryField.value}
							>
								<SummaryFieldName
									className={classes.summaryData}
									onMouseOver={handleOpenTooltip}
									onMouseLeave={() => setOpenToolTip(false)}
									onClick={openDealMenu}
									style={{
										color: theme.palette.primary.main,
									}}
								>
									{`${
										summaryField.value?.count
									} ${getDealState(
										summaryField.value?.state
									)} ${
										summaryField.value?.count > 1
											? "Deals"
											: "Deal"
									}`}
								</SummaryFieldName>
							</Tooltip>
						) : summaryField.config.readOnly ? (
							<SummaryFieldName style={{ opacity: 0.6 }}>
								-
							</SummaryFieldName>
						) : (
							<Typography sx={styles.inputContent}>
								Click To Enter
							</Typography>
						)}
					</Box>
				) : (
					<Typography
						fontSize={14}
						fontWeight={500}
						style={{
							opacity: 0.4,
							cursor: "not-allowed",
						}}
					>
						Access Denied
					</Typography>
				)}
			</td>
		</React.Fragment>
	);
}
