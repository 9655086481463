import {
	Box,
	Button,
	Divider,
	MenuItem,
	Stack,
	ToggleButton,
	Typography,
	useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { twozoStyles } from "../../../styles/twozo";
import { default as DropDownRight } from "../../../assets/icons/dropDownRight";
import { default as AddIcon } from "../../../assets/icons/add";
import { default as DropDownIcon } from "../../../assets/icons/dropDown";
import ToggleButtonGroup from "../../Elements/ToggleButtonGroup";
import Users from "./Users";
import Menu from "../../Elements/Menu";
import MenuSearchBar from "../../Elements/MenuSearchBar";
import UsersMenu from "./Users/Menu";
import SelectedMenu from "./Users/SelectedMenu";
import Roles from "./Roles";
import Territory from "./Territory";
import Teams from "./Teams";
import { useUserManagementContext } from "./UserManagementContext";
import { viewTypes } from "../../../utils/userManagementResources";
import Can from "../../Auth/Can";
import { PERMISSIONS } from "../../../utils/Auth";
import { useAuth } from "../../../hooks/auth";

export default function UserManagementList(props) {
	const { type, onViewTypeChange } = props;
	const theme = useTheme();
	const navigate = useNavigate();
	const classes = twozoStyles();
	const { listData, handleSearch, filterUserList, handleSort } =
		useUserManagementContext();
	const { isUserAllowedFor } = useAuth();

	const [selected, setSelected] = useState([]);
	const [data, setData] = useState([]);
	const [userFilterMenuElement, setUserFilterMenuElement] = useState(null);
	const isUserFilterMenuOpened = Boolean(userFilterMenuElement);
	const [isCreateRoleDialogOpened, setIsCreateRoleDialogOpened] =
		useState(false);
	const [userCount, setUserCount] = useState("");
	const [accountAdminCount, setAccountAdminCount] = useState("");
	const [searchValue, setSearchValue] = useState("");

	const userFilterConstraints = [
		{
			id: 1,
			name: "All Users",
		},
		{
			id: 2,
			name: "Active",
		},
		{
			id: 3,
			name: "Deactivated",
		},
		{
			id: 4,
			name: "Invited",
		},
	];

	const [selectedUserFilter, setSelectedUserFilter] = useState(
		userFilterConstraints[0]
	);

	const updateUsersCount = (totalUserCount, totalAccountAdminCount) => {
		setUserCount(totalUserCount);
		setAccountAdminCount(totalAccountAdminCount);
	};

	const handleToggleButtonGroup = (_, type) => {
		if (type) {
			onViewTypeChange(type);
		}
		setSelected([]);
		handleSort({});
	};

	const handleOpenAllUserMenu = (event) => {
		setUserFilterMenuElement(event.currentTarget);
	};

	const closeAllUserMenu = () => {
		setUserFilterMenuElement(null);
	};

	const openRoleDialog = () => {
		setIsCreateRoleDialogOpened(true);
	};

	const [isCreateTerritoryDialogOpened, setIsCreateTerritoryDialogOpened] =
		useState(false);

	const openCreateTerritoryDialog = () => {
		setIsCreateTerritoryDialogOpened(true);
	};

	const closeCreateTerritoryDialog = () => {
		setIsCreateTerritoryDialogOpened(false);
	};

	const [isCreateTeamDialogOpened, setIsCreateTeamDialogOpened] =
		useState(false);

	const openCreateTeamDialog = () => {
		setIsCreateTeamDialogOpened(true);
	};

	const closeCreateTeamDialog = () => {
		setIsCreateTeamDialogOpened(false);
	};

	const handleSearchUser = (event) => {
		const { value } = event.target;
		setSearchValue(value);
		handleSearch(value);
	};

	const selectUserFilter = (filterData) => {
		setSelectedUserFilter(filterData);
		filterUserList(filterData.id);
		closeAllUserMenu();
	};

	const userManagementToggleOptions = [
		{
			name: "Users",
			value: viewTypes.users,
			permission: isUserAllowedFor(PERMISSIONS.user.access),
		},
		{
			name: "Roles",
			value: viewTypes.roles,
			permission: isUserAllowedFor(PERMISSIONS.role.view),
		},
		{
			name: "Teams",
			value: viewTypes.teams,
			permission: true,
		},
		{
			name: "Territory",
			value: viewTypes.territory,
			permission: isUserAllowedFor(PERMISSIONS.territory.access),
		},
	];

	const getUserManagementToggleOptions = () => {
		return userManagementToggleOptions.filter(
			(userManagementToggleOption) =>
				userManagementToggleOption.permission
		);
	};

	return (
		<React.Fragment>
			<Menu
				anchorEl={userFilterMenuElement}
				open={isUserFilterMenuOpened}
				onClose={closeAllUserMenu}
				sx={{ mt: "5px", minWidth: "200px" }}
			>
				{userFilterConstraints.map((item) => (
					<MenuItem
						key={item.id}
						onClick={() => selectUserFilter(item)}
						style={{
							height: "40px",
							backgroundColor:
								selectedUserFilter.id === item.id
									? theme.palette.secondary.main
									: "transparent",
						}}
					>
						<Typography fontSize={13} fontWeight={400}>
							{item.name}
						</Typography>
					</MenuItem>
				))}
			</Menu>

			<Box
				style={{
					height: "calc(100% - 70px)", //The topbar width is handle as static value, In future It will be handle as dynamically
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box className={classes.menuBar}>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={2}
						height="100%"
					>
						<Stack direction="row" alignItems="center" spacing={1}>
							<Box
								pl={1}
								style={{ cursor: "pointer" }}
								onClick={() => navigate("/settings")}
							>
								<Typography
									fontWeight={500}
									fontSize={16}
									color={theme.palette.secondary.contrastText}
								>
									Settings
								</Typography>
							</Box>

							{DropDownRight(16, 16)}

							<Typography fontWeight={500} fontSize={16}>
								User Management
							</Typography>
						</Stack>

						<Can permission={PERMISSIONS.role.create}>
							{type === viewTypes.roles && (
								<Button
									variant="contained"
									startIcon={AddIcon(20, 20, "#fff")}
									color="primary"
									disableElevation
									onClick={openRoleDialog}
								>
									Role
								</Button>
							)}
						</Can>

						{type === viewTypes.territory &&
							!!listData &&
							listData?.length > 0 && (
								<Button
									variant="contained"
									startIcon={AddIcon(20, 20, "#fff")}
									color="primary"
									disableElevation
									onClick={openCreateTerritoryDialog}
								>
									Territory
								</Button>
							)}

						<Can permission={PERMISSIONS.team.access}>
							{type === viewTypes.teams &&
								!!listData &&
								listData?.length > 0 && (
									<Button
										variant="contained"
										startIcon={AddIcon(20, 20, "#fff")}
										color="primary"
										disableElevation
										onClick={openCreateTeamDialog}
									>
										Team
									</Button>
								)}
						</Can>

						{type === viewTypes.users && <UsersMenu />}
					</Stack>
				</Box>

				<Divider />

				<Stack
					direction="row"
					justifyContent="space-between"
					pb={1.5}
					pt={2}
					pl={2}
				>
					<Stack spacing={2} direction="row">
						<Box>
							<ToggleButtonGroup
								value={type}
								onChange={handleToggleButtonGroup}
							>
								{getUserManagementToggleOptions()?.map(
									(option, index) => (
										<ToggleButton
											key={index}
											value={option.value}
										>
											{option.name}
										</ToggleButton>
									)
								)}
							</ToggleButtonGroup>
						</Box>

						{type === viewTypes.users && (
							<Stack
								direction="row"
								spacing={2}
								alignItems="center"
							>
								<Box>
									<Button
										variant="contained"
										color="secondary"
										disableElevation
										endIcon={DropDownIcon(
											20,
											20,
											theme.palette.primary.main
										)}
										onClick={(event) =>
											handleOpenAllUserMenu(event)
										}
									>
										{`${selectedUserFilter.name} (${userCount})`}
									</Button>
								</Box>

								<Box sx={{ width: "300px" }}>
									<MenuSearchBar
										minHeight="38px"
										value={searchValue}
										onChange={handleSearchUser}
									/>
								</Box>
							</Stack>
						)}
					</Stack>

					<Box pr={2} hidden={selected.length <= 0}>
						<SelectedMenu
							selected={selected}
							setSelected={setSelected}
							data={data}
							accountAdminCount={accountAdminCount}
						/>
					</Box>
				</Stack>

				<Box
					px={1}
					style={{
						flex: "1 1 auto",
						minHeight: 0,
					}}
				>
					{(function () {
						switch (type) {
							case viewTypes.roles:
								return (
									<Roles
										isCreateRoleDialogOpened={
											isCreateRoleDialogOpened
										}
										setIsCreateRoleDialogOpened={
											setIsCreateRoleDialogOpened
										}
										openRoleDialog={openRoleDialog}
									/>
								);
							case viewTypes.users:
								return (
									<Users
										selected={selected}
										setSelected={setSelected}
										setData={setData}
										updateUsersCount={updateUsersCount}
									/>
								);
							case viewTypes.territory:
								return (
									<Territory
										isCreateTerritoryDialogOpened={
											isCreateTerritoryDialogOpened
										}
										openCreateTerritoryDialog={
											openCreateTerritoryDialog
										}
										closeCreateTerritoryDialog={
											closeCreateTerritoryDialog
										}
									/>
								);
							case viewTypes.teams:
								return (
									<Teams
										isCreateTeamDialogOpened={
											isCreateTeamDialogOpened
										}
										openCreateTeamDialog={
											openCreateTeamDialog
										}
										closeCreateTeamDialog={
											closeCreateTeamDialog
										}
									/>
								);
							default:
								return null;
						}
					})()}
				</Box>
			</Box>
		</React.Fragment>
	);
}
