import React, { useEffect, useState } from "react";
import {
	Box,
	Collapse,
	Divider,
	MenuItem,
	Select,
	Stack,
	ToggleButton,
	Typography,
} from "@mui/material";
import MenuSearchBar from "../../../../Elements/MenuSearchBar";
import ToggleButtonGroup from "../../../../Elements/ToggleButtonGroup";
import { default as DropdownIcon } from "../../../../../assets/icons/dropDownCentered";

const StepOptionList = ({
	option,
	selected,
	onFieldChange,
	hasSelectedValue,
}) => {
	const [listOpened, setListOpened] = useState(hasSelectedValue);

	const handleValueChange = (field) => {
		onFieldChange({
			dependsOn: option.dependsOn,
			value: field,
		});
	};

	return (
		<React.Fragment>
			<MenuItem
				style={{ minHeight: "40px" }}
				onClick={() => setListOpened((listOpened) => !listOpened)}
			>
				<Stack direction="row" alignItems="center" spacing={0.5}>
					<Typography fontSize={14}>{option.source}</Typography>
					<Box pt={0.5}>{DropdownIcon(14, 14, "#000")}</Box>
				</Stack>
			</MenuItem>

			<Collapse in={listOpened} timeout="auto" unmountOnExit>
				{option.fields.map((field, index) => (
					<MenuItem
						key={index}
						selected={field?.id === selected?.value?.id}
						style={{ minHeight: "40px" }}
						onClick={() => {
							handleValueChange(field);
						}}
					>
						<Typography fontSize={14} pl={4}>
							{field.name}
						</Typography>
					</MenuItem>
				))}
			</Collapse>
		</React.Fragment>
	);
};

export default function DropdownBaseComponent(props) {
	const {
		options,
		selected,
		hasStepOptions,
		stepOptions,
		onFieldChange,
		multiDropdown,
		hasError,
		style = {},
	} = props;
	const [optionType, setOptionType] = useState("field_options");
	const [searchValue, setSearchValue] = useState("");
	const [filterOptions, setFilterOptions] = useState([]);
	const hideSearchBar = options?.length === 0;

	useEffect(() => {
		if (selected && selected.dependsOn) {
			setOptionType("step_options");
		} else {
			setOptionType("field_options");
		}
	}, [selected]);

	useEffect(() => {
		if (options) {
			setFilterOptions(options);
		}
	}, [options]);

	const getFilteredOptionsList = (searchValue) => {
		if (searchValue.trim()) {
			return options?.filter((option) =>
				//filter for search
				option.name.toLowerCase().startsWith(searchValue.toLowerCase())
			);
		}
		return options;
	};

	const getSelectedValue = (selected) => {
		switch (typeof selected.value) {
			case "string":
				return selected.value;
			case "object":
				return selected.value.name;
			default:
				return "-Select-";
		}
	};

	const handleSearchValue = (event) => {
		const { value } = event.target;
		setSearchValue(value);
		setFilterOptions(getFilteredOptionsList(value));
	};

	const handleOptionsType = (_, type) => {
		if (type) {
			setOptionType(type);
		}
	};

	const renderEmptyView = (noOptionsFound = false) => {
		return (
			<Stack alignItems="center" justifyContent="center" height="40px">
				<Typography fontSize={13} color="rgba(0, 0, 0, 0.6)">
					{options?.length === 0 || noOptionsFound
						? "No Options Found"
						: "No Results Found"}
				</Typography>
			</Stack>
		);
	};

	const handleValueChange = (event) => {
		let fieldValue = {};

		if (multiDropdown) {
			for (let index = 0; index < options.length; index++) {
				let value = options[index].options.find(
					(option) => option.value === event.target.value
				);
				if (value) {
					fieldValue = {
						value: value,
					};
					break;
				}
			}
		} else {
			fieldValue = {
				value: options.find(
					(option) => option.value === event.target.value
				),
			};
		}
		onFieldChange(fieldValue);
	};

	return (
		<React.Fragment>
			<Select
				value={
					filterOptions.length > 0 ? selected?.value?.value || "" : ""
				}
				displayEmpty
				MenuProps={{
					autoFocus: false,
					PaperProps: {
						style: {
							maxHeight: 350,
						},
					},
				}}
				onOpen={() => setSearchValue("")}
				onChange={handleValueChange}
				renderValue={() => (
					<Typography
						fontSize={14}
						color={selected ? "#000" : "rgba(0, 0, 0, 0.4)"}
					>
						{selected ? getSelectedValue(selected) : "-Select-"}
					</Typography>
				)}
				fullWidth
				sx={{
					backgroundColor: "#fff",
					...style,
				}}
				error={hasError}
			>
				{hasStepOptions && (
					<Stack direction="row" justifyContent="center" pb={0.5}>
						<ToggleButtonGroup
							value={optionType}
							onChange={handleOptionsType}
						>
							<ToggleButton value="field_options">
								Field Options
							</ToggleButton>

							<ToggleButton value="step_options">
								Step Options
							</ToggleButton>
						</ToggleButtonGroup>
					</Stack>
				)}

				{optionType === "step_options" &&
					(stepOptions.length > 0
						? stepOptions.map((option, index) => {
								const hasSelectedValue =
									selected &&
									Array.isArray(option.fields) &&
									option.fields.some(
										(field) =>
											field?.id === selected?.value?.id
									);
								return (
									<StepOptionList
										key={index}
										option={option}
										hasSelectedValue={hasSelectedValue}
										selected={selected}
										onFieldChange={onFieldChange}
									/>
								);
							})
						: renderEmptyView(true))}

				{optionType === "field_options" && (
					<Box
						hidden={hideSearchBar}
						p={0.5}
						sx={{
							position: "sticky",
							top: 0,
							zIndex: 1,
							backgroundColor: "#fff",
						}}
					>
						<MenuSearchBar
							autoFocus
							value={searchValue}
							onChange={handleSearchValue}
						/>
					</Box>
				)}

				{optionType === "field_options" &&
					(filterOptions.length > 0
						? filterOptions?.map((option, index) => {
								if (!option.options) {
									return (
										<MenuItem
											value={
												!option.options
													? option.value
													: null
											}
											key={index}
											style={{ minHeight: "40px" }}
										>
											<Typography fontSize={14}>
												{option.name}
											</Typography>
										</MenuItem>
									);
								} else {
									let components = [];

									components.push(
										<Typography
											fontSize={13}
											fontWeight={500}
											px={1}
											py={0.5}
										>
											{option.name}
										</Typography>
									);

									option.options.forEach((option, index) => {
										components.push(
											<MenuItem
												value={option.value}
												key={index}
												style={{ minHeight: "40px" }}
											>
												<Typography
													fontSize={14}
													pl={2}
												>
													{option.name}
												</Typography>
											</MenuItem>
										);
									});

									components.push(
										<Divider
											sx={{ margin: "0px !important" }}
										/>
									);

									return components;
								}
							})
						: renderEmptyView())}
			</Select>
		</React.Fragment>
	);
}
