import React, { useMemo } from "react";
import { Link } from "@mui/material";
import { TableCellText } from "../../../../styles/twozo";
import { getStatusProperties } from "../../../../utils/ActivitiesUtils";
import { useTheme } from "@mui/material";
import { urlRegex } from "../../../../utils/validation";
import TooltipComponent from "../../TooltipComponent";

export default function Text(props) {
	const { data, rowState } = props;
	const theme = useTheme();

	if (!data) {
		return null;
	}

	let isValidLink = useMemo(() => urlRegex.test(data), [data]);

	return (
		<React.Fragment>
			{isValidLink ? (
				<Link
					href={data}
					onClick={(event) => event.stopPropagation()}
					target="_blank"
					style={{
						fontWeight: "500",
						textDecoration: "none",
						color: theme.palette.primary.main,
					}}
				>
					{data}
				</Link>
			) : (
				<TooltipComponent
					title={data}
					placement="top"
					width="572px"
					verticalDisplacement={-3}
				>
					<TableCellText
						maxWidth={"552px"}
						noWrap
						color={getStatusProperties(rowState, theme).textColor}
						style={{
							textDecorationLine:
								getStatusProperties(rowState)
									.textDecorationLine,
						}}
					>
						{data}
					</TableCellText>
				</TooltipComponent>
			)}
		</React.Fragment>
	);
}
