import React, { useEffect } from "react";
import TimeLine from "../../../Summary/Timeline";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import { useDealTimelineData } from "../../../../hooks/services/deal/summary/timeline";

export default function DealTimeLine(props) {
	const { dealId, timelineIdsToFilter } = props;

	const {
		isLoading: isLoadingTimelineDetails,
		data: dealTimelineData,
		status: dealTimelineStatus,
	} = useDealTimelineData(dealId, timelineIdsToFilter);

	useEffect(() => {
		if (dealTimelineStatus === "error") {
			enqueueSnackbar({
				variant: notificationVariants.error,
				message: notificationMessage.somethingWentWrong,
			});
		}
	}, [dealTimelineStatus]);

	return (
		<React.Fragment>
			<TimeLine
				timelineData={dealTimelineData}
				isLoadingTimelineDetails={isLoadingTimelineDetails}
			/>
		</React.Fragment>
	);
}
