import React from "react";
import Notes from "../../../Summary/Notes";
import {
	getContactNotesKey,
	getContactTimelineKey,
} from "../../../../utils/queryKeys";

export default function ContactNotes({ contactId }) {
	const contactNotesKey = getContactNotesKey(contactId);
	const contactTimeLineKey = getContactTimelineKey(contactId);

	return (
		<React.Fragment>
			<Notes
				notesKey={contactNotesKey}
				timeLineKey={contactTimeLineKey}
				source="Contact"
				entityId={contactId}
			/>
		</React.Fragment>
	);
}
