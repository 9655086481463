import { useState } from "react";
import { UserManagementContext } from "..";
import { useUserTableData } from "../../../../../hooks/services/userManagement/user";
import { useTeamsTableData } from "../../../../../hooks/services/userManagement/teams";
import { useTerritoryList } from "../../../../../hooks/services/userManagement/territory";
import { viewTypes } from "../../../../../utils/userManagementResources";
import { useRoleTableData } from "../../../../../hooks/services/userManagement/roles";
import { useAuth } from "../../../../../hooks/auth";

const UserManagementProvider = (props) => {
	const { viewType = "", children } = props;

	const [searchValue, setSearchValue] = useState("");
	const [filterId, setFilterId] = useState(1);
	const [fieldToSort, setFieldToSort] = useState({});

	const { isUserAllowedFor } = useAuth();
	const {
		data: listData,
		isLoading: isLoadingListData,
		status: listDataStatus,
	} = getUserManagementListData(
		viewType,
		filterId,
		searchValue,
		fieldToSort,
		isUserAllowedFor
	);

	const handleSearch = (value) => {
		setSearchValue(value);
	};

	const filterUserList = (value) => {
		setFilterId(value);
	};

	const handleSort = (value) => {
		setFieldToSort(value);
	};

	return (
		<UserManagementContext.Provider
			value={{
				listData: listData,
				isLoadingListData: isLoadingListData,
				listDataStatus: listDataStatus,
				handleSearch: handleSearch,
				filterUserList: filterUserList,
				handleSort: handleSort,
			}}
		>
			{children}
		</UserManagementContext.Provider>
	);
};

const getUserManagementListData = (
	viewType,
	filterId,
	searchValue,
	fieldToSort
) => {
	switch (viewType) {
		case viewTypes.users:
			return useUserTableData(filterId, searchValue, fieldToSort);
		case viewTypes.roles:
			return useRoleTableData(fieldToSort);
		case viewTypes.teams:
			return useTeamsTableData(fieldToSort);
		case viewTypes.territory:
			return useTerritoryList(fieldToSort);
		default:
			null;
	}
};

export { UserManagementProvider };
