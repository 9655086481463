export const notificationMessage = {
	accountSettingsAccountUpdated: "Account settings updated successfully.",

	contactAdded: "Contact added.",

	contactUpdated: "Contact updated.",

	contactDeleted: "Contact deleted.",

	contactCloned: "Contact cloned.",

	importSuccess: "Imported successfully.",

	importStopped: "Import stopped successfully.",

	importReverted: "Import reverted successfully.",

	importDeleted: "Import deleted successfully.",

	selectChoiceLimitError: "Maximum of 2 choices needed",

	selectChoiceError: "2 Choices must be added.",

	companyAdded: "Company added.",

	companyUpdated: "Company updated.",

	companyDeleted: "Company deleted.",

	companyCloned: "Company cloned.",

	dealAdded: "Deal added.",

	dealCloned: "Deal cloned.",

	dealStateUpdated: "Deal state updated.",

	expectedCloseDateUpdated: "Deal expected close date updated.",

	dealUpdated: "Deal updated.",

	dealDeleted: "Deal deleted.",

	pipelineCreated: "New pipeline created successfully.",

	pipelineDeleted: "Pipeline deleted successfully.",

	pipelineUpdated: "Pipeline updated successfully.",

	pipelineStageUpdated: "Pipeline stage updated successfully.",

	pipelineStageDeleted: "Pipeline stage deleted successfully.",

	pipelineErrorMessage: "Pipeline name should not be empty.",

	pipelineOrderUpdateError: "Default pipeline should be positioned at first.",

	productAdded: "Product added.",

	productUpdated: "Product updated.",

	productDeleted: "Product deleted.",

	productCloned: "Product Cloned.",

	productEnabed: "Product enabled.",

	productDisabled: "Product disabled.",

	pricesUpdated: "Prices updated for this product.",

	pricesDeleted: "Prices deleted for this product.",

	teamCreated: "Team created successfully.",

	teamDeleted: "Team deleted successfully.",

	teamUpdated: "Team updated successfully.",

	notesAdded: "You added a note.",

	notesUpdated: "You updated the note.",

	notesDeleted: "You deleted a note.",

	filesAdded: "You added a file.",

	filesDeleted: "You deleted the file.",

	activityAdded: "Activity added.",

	activityUpdated: "Activity updated.",

	activityDeleted: "Activity deleted.",

	activityTypeAdded: "You created a sales activity type.",

	activityTypeUpdated: "You updated the sales activity type.",

	activityTypeDeleted: "You deleted the sales activity type.",

	territoryCreated: "You created the Territory Successfully.",

	territoryUpdated: "You updated the Territory Successfully.",

	territoryDeleted: "You deleted the Territory Successfully.",

	fieldCreated: "You created the field Successfully.",

	fieldCreatedError: "Field name should not be empty.",

	fieldUpdated: "You updated the field Successfully.",

	fieldDeleted: "You deleted the field Successfully.",

	fieldNameError: "Field name should not be empty.",

	webFormAdded: "Web Form Added Successfully.",

	webFormUpdated: "Web Form Updated Successfully.",

	webFormDeleted: "Web Form Deleted Successfully.",

	webFormActivated: "Web Form Activated Successfully.",

	webFormDeactivated: "Web Form Deactivated Successfully.",

	tagCreated: "You added a tag.",

	tagUpdated: "You updated the tag.",

	tagDeleted: "You deleted the tag.",

	filterSaved: "Filter has been saved successfully.",

	filterDeleted: "Filter has been deleted successfully.",

	filterUpdated: "Filter has been updated successfully.",

	emailSyncMessage: "Email has been synced successfully.",

	emailDisconnectMessage: "Email has been disconnected successfully.",

	calendarSyncMessage: "Calendar has been synced successfully.",

	calendarDisconnectMessage: "Calendar has been disconnected successfully.",

	contactSyncMessage: "Contact has been synced successfully.",

	contactDisconnectMessage: "Contact has been disconnected successfully.",

	mailRead: "The conversation has been marked as read.",

	mailUnread: "The conversation has been marked as unread.",

	mailStarred: "The conversation has been starred.",

	mailUnstarred: "The conversation has been unstarred.",

	mailArchived: "The conversation has been archived.",

	mailUnarchived: "The conversation has been unarchived.",

	mailActive: "Conversation has been restored successfully.",

	mailUnactive: "Conversation moved to trash successfully.",

	mailDeleteForever: "Conversation has been deleted permanently.",

	mailMarkAsDone: "Conversation marked as done successfully.",

	discardDraft: "Conversation has been deleted successfully",

	mailSent: "Email has been sent successfully.",

	mailScheduled: "Email has been scheduled successfully.",

	draftMailSent: "The conversation has been saved as draft.",

	invalidMailAddress:
		"The email address you entered is not valid. Please provide a valid email address.",

	genericErrorMessage:
		"An unexpected error occurred. Please try again later.",

	fieldPositionChanged: "You changed the field positions.",

	errorMessage: "An unexpected error occurred. Please try again later.",

	logout: "You've successfully signed out!",

	somethingWentWrong: "Something went wrong. Please try again later",

	moduleRenameFailed: "Unable to rename module",

	moduleRenameSuccess: "You have renamed the module successfully.",

	roleUpdated: "Role name has been updated successfully!",

	userAssigned: "User has been assigned successfully!",

	userAdded: "User added successfully.",

	userUpdated: "User updated successfully.",

	tagsAdded: "Tags have been added successfully.",

	tagsRemoved: "Tags have been removed successfully.",

	activityTypeEnabled: "Activity type enabled successfully.",

	activityTypeDisabled: "Activity type disabled successfully.",

	activityTypeOrderUpdated: "Activity type order updated successfully.",

	dealMerge: "The deal has been merged successfully.",

	fieldValuesMerged:
		"The field values have been merged with the selected contact.",

	baseCurrencyUpdated: "Base currency has been updated successfully.",

	currencyDecimalUpdated: "Currency decimal has been updated successfully.",

	currencyAdded: "Currency has been added successfully.",

	currencyActivated: "Currency has been activated successfully.",

	currencyDeactivated: "Currency has been deactivated successfully.",

	companyDismissMessage:
		"Duplicate companies have been dismissed successfully.",

	contactDismissMessage:
		"Duplicate contacts have been dismissed successfully.",

	turnOffNotificationMessage:
		"You turned off the notification alerts successfully.",

	turnOnNotificationMessage:
		"You turned on the notification alerts successfully.",

	notificationSettingsSaved: "Notification settings saved successfully.",

	notificationSettingsReset: "Notification settings reset successfully.",

	emailTemplateCreated: "Email template created succesfully.",

	emailTemplateUpdated: "Email template updated succesfully.",

	emailTemplateCloned: "Email template cloned succesfully.",

	emailTemplateDeleted: "Email template deleted succesfully.",

	permissionUpdated: "Role updated successfully.",

	roleCreated: "Role created successfully.",

	recordsTransfered: "Records have been transferred successfully.",

	userDeactivated: "User deactivated successfully.",

	userReinvited: "Activation mail sent successfully.",

	roleDeleted: "Role deleted successfully.",

	userActivation: "Users activated successfully.",

	roleNameValidation:
		"Role creation failed. The name has already been taken.",

	profileUpdated: "Profile Updated Successfully",
};
