import React, { useEffect, useRef, useState } from "react";
import {
	Box,
	Checkbox,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Typography,
	IconButton,
	useTheme,
	CircularProgress,
	Badge,
} from "@mui/material";
import { default as FilterIcon } from "../../../assets/icons/filter";
import { default as MailIcon } from "../../../assets/icons/mail";
import { default as MailOpenedIcon } from "../../../assets/icons/mailOpenSimple";
import { default as MailSentIcon } from "../../../assets/icons/mailSent";
import { default as MailDraftIcon } from "../../../assets/icons/mailDraft";
import { default as MailSheduledIcon } from "../../../assets/icons/mailScheduled";
import { default as MailTrashIcon } from "../../../assets/icons/delete";
import { default as DealIcon } from "../../../assets/icons/deals";
import { default as AttachmentIcon } from "../../../assets/icons/attachment";
import { default as FavouriteOnIcon } from "../../../assets/icons/favouriteOn";
import { default as FavouriteOffIcon } from "../../../assets/icons/favouriteOff";
import { default as MoreIcon } from "../../../assets/icons/more";
import { Tooltip, twozoStyles } from "../../../styles/twozo";
import EmailFilter from "../EmailFilter";
import MailSearch from "../MailSearch";
import CustomSwipeableDrawer from "../../Elements/CustomSwipeableDrawer";
import { default as ContactImage } from "../../../assets/images/contact/unknownContact.png";
import { getFormattedDateMonthYearAndTime } from "../../../utils/DateUtils";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../utils/notification/notificationMessages";
import {
	useFilterData,
	useMailList,
	useUpdateMailState,
} from "../../../hooks/services/mail";
import SelectedMenu from "./SelectedMenu";
import EmailViewer from "./EmailViewer";
import EmailComposerDrawer from "../EmailComposer/EmailComposerDrawer";
import EmailTableRowMenu from "./EmailTableRowMenu";

export default function EmailList(props) {
	const {
		listHeader,
		mailListName,
		hasTrashed,
		menuConfig,
		hiddenBodyContent,
		filterConfig,
		HideFavoriteAction,
	} = props;
	const theme = useTheme();
	const classes = twozoStyles();
	const inboxRef = useRef(null);
	const [selectedMailIds, setSelectedMailIds] = useState([]);
	const [isMailViewerOpened, setIsMailViewerOpened] = useState(false);
	const [openedMailId, setOpenedMailId] = useState("");
	const [isEmailFilterOpened, setIsEmailFilterOpened] = useState(false);
	const [isEmailComposerOpened, setIsEmailComposerOpened] = useState(false);
	const [criteria, setCriteria] = useState({});
	const [isMailListEnabled, setIsMailListEnabled] = useState(false);
	const [filterCount, setFilterCount] = useState("");
	const [topBarHeight, setTopBarHeight] = useState(0);
	const [hoveredTableRowId, setHoveredTableRowId] = useState("");
	const [tableRowMenuElement, setTableRowMenuElement] = useState(null);
	const isTableRowMenuOpened = Boolean(tableRowMenuElement);
	const [selectedMailData, setSelectedMailData] = useState({});

	const messageCategory = {
		scheduled: "SCHEDULED",
		outbound: "OUTBOUND",
	};

	// get Mail Filter Data
	const {
		data: filterData,
		status: filterStatus,
		isLoading: isFilterDataLoading,
	} = useFilterData(mailListName);

	// get MailList
	const { data: mails, isLoading: isMailListLoading } = useMailList(
		mailListName,
		criteria,
		isMailListEnabled
	);

	useEffect(() => {
		if (filterStatus === "success") {
			setFilterCount(filterData?.filterCount);
			let initialFilters = { ...filterData };
			delete initialFilters?.filterCount;
			if (filterConfig && filterData) {
				setCriteria({ criteria: initialFilters });
			}
			setIsMailListEnabled(true);
		}
	}, [filterData, filterStatus]);

	const mailCount = mails?.length || 0;
	const isSelected = (id) => selectedMailIds.indexOf(id) !== -1;

	const toggleSelectMail = (event, mail) => {
		event.stopPropagation();
		const selectedIndex = selectedMailIds?.indexOf(mail.id);
		let newSelectedIds = [];

		if (selectedIndex === -1) {
			newSelectedIds = newSelectedIds.concat(selectedMailIds, mail.id);
		} else {
			newSelectedIds = [
				...selectedMailIds.slice(0, selectedIndex),
				...selectedMailIds.slice(selectedIndex + 1),
			];
		}
		setSelectedMailIds(newSelectedIds);
	};

	const updateMailStateMutation = useUpdateMailState(mailListName);

	const toggleFavouriteMail = (mail) => {
		const updatedMailStateRequest = {
			ids: [mail?.id],
			isStarred: !mail.isStarred,
		};
		updateMailStateMutation.mutate(updatedMailStateRequest, {
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	const clearSelectedMail = () => {
		setSelectedMailIds([]);
	};

	const toggleSelectAllMail = (event) => {
		const { checked } = event.target;

		if (checked) {
			const selectedMailIds = mails.map((mail) => mail.id);
			setSelectedMailIds(selectedMailIds);
		} else {
			setSelectedMailIds([]);
		}
	};

	const openMailViewerView = () => {
		setIsMailViewerOpened(true);
	};

	const closeMailViewerView = () => {
		setIsMailViewerOpened(false);
		setOpenedMailId("");
	};

	const toggleSwipeableFilter = () => {
		setIsEmailFilterOpened((isEmailFilterOpened) => !isEmailFilterOpened);
	};

	useEffect(() => {
		if (inboxRef) {
			setTopBarHeight(inboxRef.current.getBoundingClientRect().top);
		}
	}, [inboxRef]);

	const getMailIcon = (mailData) => {
		if (hasTrashed) {
			return MailTrashIcon(20, 20, "rgba(0, 0, 0, 0.3)");
		} else if (mailData?.messageCategory === messageCategory.scheduled) {
			return MailSheduledIcon(20, 20, "rgba(0, 0, 0, 0.3");
		} else if (mailData?.hasDraft) {
			return MailDraftIcon(20, 20, "rgba(0, 0, 0, 0.3)");
		} else if (mailData?.messageCategory === messageCategory.outbound) {
			return MailSentIcon(20, 20, "rgba(0, 0, 0, 0.3)");
		} else if (mailData?.isRead) {
			return MailOpenedIcon(20, 20, "rgba(0, 0, 0, 0.3)");
		} else {
			return MailIcon(20, 20, theme.palette.primary.main);
		}
	};

	const isMailOpened = (mail) => {
		return (
			mail.isRead ||
			mail.messageCategory === "OUTBOUND" ||
			mail.isScheduled ||
			mail.hasDraft ||
			hasTrashed
		);
	};

	const closeEmailComposer = () => {
		setIsEmailComposerOpened(false);
	};

	const handleOpenMail = (mail) => {
		if (mail.hasDraft) {
			setIsEmailComposerOpened(true);
		} else {
			setOpenedMailId(mail?.id);
			openMailViewerView();
			if (!mail?.isRead && !hasTrashed) {
				updateMailStateMutation.mutate({
					ids: [mail?.id],
					isRead: true,
				});
			}
		}
	};

	const handleFilterData = (selectedFilters) => {
		setCriteria({ criteria: selectedFilters });
		setFilterCount(Object.keys(selectedFilters).length);
	};

	const displayBodyContent = (mailData) => {
		return mailData.message && !hiddenBodyContent;
	};

	const onMouseOverTableRow = (mailId) => {
		setHoveredTableRowId(mailId);
	};

	const onMouseOutTableRow = () => {
		if (!isTableRowMenuOpened) {
			setHoveredTableRowId("");
		}
	};

	const openTableRowMenu = (event, selectedMail) => {
		event.stopPropagation();
		setTableRowMenuElement(event.currentTarget);
		setSelectedMailData(selectedMail);
	};

	const closeTableRowMenu = () => {
		setTableRowMenuElement(null);
		setHoveredTableRowId("");
	};

	const isActionsVisible = (mailId) => {
		return mailId === hoveredTableRowId;
	};

	return (
		<React.Fragment>
			{/*Mail Composer*/}
			<Box hidden={!isEmailComposerOpened}>
				<EmailComposerDrawer onClose={closeEmailComposer} />
			</Box>

			{/*Mail Filter*/}
			<CustomSwipeableDrawer
				sx={{
					"& .MuiDrawer-paper": {
						marginRight: "8px",
						marginTop: `${topBarHeight}px`,
						height: `calc(100vh - ${topBarHeight + 8}px)`,
						width: "260px",
					},
				}}
				variant="persistent"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent", zIndex: 2 },
				}}
				anchor="right"
				open={isEmailFilterOpened}
				onOpen={toggleSwipeableFilter}
				onClose={toggleSwipeableFilter}
			>
				<Box>
					<EmailFilter
						initialFilterData={criteria?.criteria}
						onChangeFilter={handleFilterData}
						filterConfig={filterConfig}
					/>
				</Box>
			</CustomSwipeableDrawer>

			{/*Mail Viewer*/}
			<CustomSwipeableDrawer
				anchor="right"
				BackdropProps={{ invisible: true }}
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isMailViewerOpened}
				onOpen={openMailViewerView}
				onClose={closeMailViewerView}
				disableSwipeToOpen
			>
				<Box className={classes.mailViewContainer}>
					<Box className={classes.mailViewCard}>
						<EmailViewer
							mailListName={mailListName}
							onClose={closeMailViewerView}
							mailId={openedMailId}
							isTrash={hasTrashed}
						/>
					</Box>
				</Box>
			</CustomSwipeableDrawer>

			<EmailTableRowMenu
				anchorEl={tableRowMenuElement}
				isTableRowMenuOpened={isTableRowMenuOpened}
				closeTableRowMenu={closeTableRowMenu}
				menuConfig={menuConfig}
				selectedMailData={selectedMailData}
				menuSpecificProps={{
					mailListName: mailListName,
					hasTrashMail: hasTrashed,
				}}
			/>

			<Box>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					py={1.25}
					style={{
						borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
					}}
				>
					<Stack direction="row" alignItems="center">
						<Box px={2}>
							<Checkbox
								checked={
									mails?.length > 0 &&
									mails?.length === selectedMailIds.length
								}
								indeterminate={
									selectedMailIds.length > 0 &&
									mails?.length > selectedMailIds.length
								}
								onChange={(event) => toggleSelectAllMail(event)}
							/>
						</Box>

						<Typography fontWeight={500}>{listHeader}</Typography>
					</Stack>

					<Stack
						direction="row"
						alignItems="center"
						spacing={3}
						pr={3}
					>
						{selectedMailIds?.length > 0 ? (
							<SelectedMenu
								mailCount={mailCount}
								mailListName={mailListName}
								clearSelectedMail={clearSelectedMail}
								selectedMailIds={selectedMailIds}
								menuConfig={menuConfig}
							/>
						) : null}

						<MailSearch />

						{filterConfig ? (
							filterCount ? (
								<Badge
									badgeContent={filterCount}
									color="primary"
								>
									<IconButton
										onClick={toggleSwipeableFilter}
										style={{ padding: "0px" }}
									>
										{FilterIcon(
											20,
											20,
											theme.palette.primary.main
										)}
									</IconButton>
								</Badge>
							) : (
								<IconButton onClick={toggleSwipeableFilter}>
									{FilterIcon(
										20,
										20,
										theme.palette.primary.main
									)}
								</IconButton>
							)
						) : null}
					</Stack>
				</Stack>

				<Box
					sx={{
						height: `calc(100vh - ${topBarHeight + 8}px)`,
						overflowY: "auto",
					}}
					ref={inboxRef}
					style={{
						marginRight: isEmailFilterOpened ? "260px" : null,
						transition: isEmailFilterOpened
							? theme.transitions.create("margin", {
									easing: theme.transitions.easing.easeOut,
									duration:
										theme.transitions.duration
											.enteringScreen,
								})
							: theme.transitions.create("margin", {
									easing: theme.transitions.easing.sharp,
									duration:
										theme.transitions.duration
											.leavingScreen,
								}),
					}}
				>
					{isMailListLoading || isFilterDataLoading ? (
						<Stack
							alignItems="center"
							justifyContent="center"
							height="80vh"
						>
							<CircularProgress />
						</Stack>
					) : (
						<Box>
							{mails?.length > 0 ? (
								<Table
									size="small"
									style={{
										width: "100%",
										tableLayout: "fixed",
									}}
								>
									<TableBody>
										{mails?.map((mail, index) => (
											<TableRow
												key={index}
												selected={
													isSelected(mail.id) ||
													mail.id === openedMailId
												}
												onMouseOver={() =>
													onMouseOverTableRow(mail.id)
												}
												onMouseOut={() =>
													onMouseOutTableRow()
												}
												sx={{
													height: "52px",
													backgroundColor:
														isMailOpened(mail)
															? "transparent"
															: "#FAFAFA",
													opacity: hasTrashed
														? 0.8
														: 1,
												}}
												onClick={() =>
													handleOpenMail(mail)
												}
											>
												<TableCell
													style={{
														width: "50px",
														borderLeft:
															isMailOpened(mail)
																? "none"
																: `4px solid ${theme.palette.primary.main}`,
														padding: isMailOpened(
															mail
														)
															? "6px 16px"
															: "6px 12px",
													}}
													onClick={(event) =>
														event.stopPropagation()
													}
												>
													<Checkbox
														checked={isSelected(
															mail.id
														)}
														onChange={(event) =>
															toggleSelectMail(
																event,
																mail
															)
														}
													/>
												</TableCell>

												<TableCell
													style={{
														width: "30px",
														padding: "6px 4px",
													}}
												>
													<Box display="flex">
														{getMailIcon(mail)}
													</Box>
												</TableCell>
												{!HideFavoriteAction ? (
													<TableCell
														style={{
															width: "40px",
															padding: "6px 8px",
														}}
														onClick={(event) =>
															event.stopPropagation()
														}
													>
														<Box display="flex">
															<IconButton
																style={{
																	padding:
																		"2px",
																}}
																onClick={() =>
																	toggleFavouriteMail(
																		mail
																	)
																}
															>
																{mail.isStarred
																	? FavouriteOnIcon(
																			20,
																			20,
																			theme
																				.palette
																				.primary
																				.main
																		)
																	: FavouriteOffIcon(
																			20,
																			20,
																			"rgba(0, 0, 0, 0.2)"
																		)}
															</IconButton>
														</Box>
													</TableCell>
												) : null}

												<TableCell
													style={{
														width: "180px",
														padding: "6px",
													}}
												>
													<Stack
														direction="row"
														alignItems="center"
														spacing={1}
													>
														<img
															src={ContactImage}
															alt="contact_image"
															height="24px"
															width="24px"
														/>

														<Typography
															sx={{
																color: hasTrashed
																	? "rgba(0, 0, 0, 0.6)"
																	: "#000",
																fontWeight:
																	isMailOpened(
																		mail
																	)
																		? 400
																		: 500,
																fontSize: 14,
															}}
															noWrap
														>
															{mail?.contact
																?.contactName
																? mail?.contact
																		?.contactName
																: mail?.email}
														</Typography>
													</Stack>
												</TableCell>

												<TableCell>
													<Stack
														direction="row"
														spacing={1}
													>
														{mail.hasDraft ? (
															<Typography
																fontSize={14}
																color="#EB5454"
															>
																[Draft]
															</Typography>
														) : null}

														{mail.conversationCount >
														1 ? (
															<Box>
																<Box
																	sx={{
																		border: "1px solid rgba(0, 0, 0, 0.1)",
																		height: "23px",
																		width: "23px",
																		textAlign:
																			"center",
																		borderRadius:
																			"100%",
																		color: theme
																			.palette
																			.secondary
																			.contrastText,
																	}}
																>
																	{
																		mail.conversationCount
																	}
																</Box>
															</Box>
														) : null}
														<Typography
															fontSize={14}
															noWrap
															sx={{
																color: hasTrashed
																	? "rgba(0, 0, 0, 0.6)"
																	: "#000",
															}}
														>
															{mail.subject
																? mail.subject
																: "(no subject)"}
															{displayBodyContent(
																mail
															) ? (
																<Typography
																	component="span"
																	fontSize={
																		14
																	}
																	style={{
																		color: "rgba(0, 0, 0, 0.6)",
																	}}
																>
																	&nbsp; -
																	&nbsp;
																	{
																		mail.message
																	}
																</Typography>
															) : null}
														</Typography>

														{mail.Opened &&
														mail.Opened.time ? (
															<Box
																sx={{
																	p: 0.5,
																	color: theme
																		.palette
																		.secondary
																		.contrastText,
																	backgroundColor:
																		theme
																			.palette
																			.secondary
																			.main,
																	borderRadius:
																		"6px",
																}}
															>
																<Tooltip
																	title={getFormattedDateMonthYearAndTime(
																		mail
																			.Opened
																			.time
																	)}
																>
																	<Typography
																		fontSize={
																			12
																		}
																		fontWeight={
																			500
																		}
																	>
																		Opened
																	</Typography>
																</Tooltip>
															</Box>
														) : null}

														{mail.Clicked &&
														mail.Clicked.time ? (
															<Box
																sx={{
																	p: 0.5,
																	color: theme
																		.palette
																		.secondary
																		.contrastText,
																	backgroundColor:
																		theme
																			.palette
																			.secondary
																			.main,
																	borderRadius:
																		"6px",
																}}
															>
																<Tooltip
																	title={getFormattedDateMonthYearAndTime(
																		mail
																			.Clicked
																			.time
																	)}
																>
																	<Typography
																		fontSize={
																			12
																		}
																		fontWeight={
																			500
																		}
																	>
																		Clicked
																	</Typography>
																</Tooltip>
															</Box>
														) : null}
													</Stack>
												</TableCell>

												<TableCell
													style={{
														width: "35px",
														padding: "0px",
													}}
												>
													{mail?.dealId && (
														<Box
															style={{
																display: "flex",
																justifyContent:
																	"flex-end",
															}}
														>
															<IconButton
																style={{
																	padding:
																		"2px",
																}}
															>
																{DealIcon(
																	18,
																	18,
																	theme
																		.palette
																		.primary
																		.main
																)}
															</IconButton>
														</Box>
													)}
												</TableCell>

												<TableCell
													style={{
														width: "35px",
														padding: "0px",
													}}
												>
													{mail.hasAttachment && (
														<Box
															style={{
																display: "flex",
																justifyContent:
																	"flex-end",
															}}
														>
															<IconButton
																style={{
																	padding:
																		"2px",
																}}
															>
																{AttachmentIcon(
																	18,
																	18,
																	theme
																		.palette
																		.primary
																		.main
																)}
															</IconButton>
														</Box>
													)}
												</TableCell>

												<TableCell
													style={{
														width: "200px",
														padding: "6px 16px",
														textAlign: "end",
														position: "relative",
													}}
												>
													<Typography
														fontSize={14}
														color={
															isMailOpened(mail)
																? "rgba(0, 0, 0, 0.6)"
																: "#000"
														}
														noWrap
													>
														{mail?.scheduledTime
															? `@ ${getFormattedDateMonthYearAndTime(
																	mail?.scheduledTime
																)}`
															: getFormattedDateMonthYearAndTime(
																	mail?.timeStamp
																)}
													</Typography>

													{isActionsVisible(
														mail.id
													) ? (
														<Box
															style={{
																position:
																	"absolute",
																top: 8,
																right: 12,
																bottom: 8,
																left: 135,
																border: `1px solid ${theme.palette.primary.main}`,
																borderRadius:
																	"8px",
																backgroundColor:
																	"#fff",
																alignContent:
																	"center",
																textAlign:
																	"center",
															}}
															onClick={(event) =>
																openTableRowMenu(
																	event,
																	mail
																)
															}
														>
															<IconButton size="small">
																{MoreIcon(
																	16,
																	16,
																	theme
																		.palette
																		.primary
																		.main
																)}
															</IconButton>
														</Box>
													) : null}
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							) : (
								<Stack
									alignItems="center"
									justifyContent="center"
									height="70vh"
								>
									<Typography
										fontSize={18}
										fontWeight={500}
										color="rgba(0, 0, 0, 0.6)"
									>
										No Conversations Found
									</Typography>
								</Stack>
							)}
						</Box>
					)}
				</Box>
			</Box>
		</React.Fragment>
	);
}
