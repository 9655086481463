import { getRootKey } from "../rootKey";

export const fieldTypeKey = [...getRootKey(), "fieldType"];

export const getComparatorKey = (fieldTypeId) => {
	return [...getRootKey(), String(fieldTypeId), "Comparator"];
};

export const getCriteriaFieldsKey = (triggerSource) => {
	return [...getRootKey(), String(triggerSource), "CriteriaFields"];
};

export const getActionListKey = (triggerSource) => {
	return [...getRootKey(), String(triggerSource), "ActionList"];
};

export const getActionFieldsKey = (
	triggerSource,
	actionSource,
	actionEvent
) => {
	return [
		...getRootKey(),
		String(triggerSource),
		String(actionSource),
		String(actionEvent),
		"ActionFields",
	];
};

export const getActionStepOptionKey = (triggerSource, fieldId, actions) => {
	return [
		...getRootKey(),
		String(triggerSource),
		String(fieldId),
		JSON.stringify(actions),
		"ActionStepOptions",
	];
};

export const getTemplateListKey = (templateId) => {
	return [...getRootKey(), String(templateId), "Template"];
};

export const getAllTemplateListKey = () => {
	return [...getRootKey(), "TemplateList"];
};

export const getWorkflowListKey = () => {
	return [...getRootKey(), "WorkflowList"];
};
