import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getConversationListKey } from "../../../utils/queryKeys/conversations";
import { getConversationsData } from "../../../api/conversations";
import { deleteSingleMail } from "../../../api/email";

const useConversationList = (sourceName, entityId) => {
	let conversationListKey = getConversationListKey(sourceName, entityId);
	return useQuery(
		conversationListKey,
		() => getConversationsData(sourceName, entityId),
		{
			enabled: !!entityId,
			select: (data) => data?.conversations,
		}
	);
};

const useInvalidateConversationList = (sourceName, entityId) => {
	let queryClient = useQueryClient();
	const conversationListKey = getConversationListKey(sourceName, entityId);
	return () => {
		queryClient.invalidateQueries(conversationListKey);
	};
};

const useDeleteMailForConversation = (sourceName, entityId) => {
	const invalidateConversationList = useInvalidateConversationList(
		sourceName,
		entityId
	);
	return useMutation(deleteSingleMail, {
		onSuccess: () => {
			invalidateConversationList();
		},
	});
};

export {
	useConversationList,
	useInvalidateConversationList,
	useDeleteMailForConversation,
};
