import React from "react";
import ContactPersonProfileImage from "../../../../assets/images/contact/profile.png";
import {
	Box,
	Checkbox,
	CircularProgress,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import { TableCellText, TableHeaderLabel } from "../../../../styles/twozo";
import { default as DropDownIcon } from "../../../../assets/icons/dropDownCentered";
import { getDateMonthAndYearFormat } from "../../../../utils/DateUtils";
import { useProductDealList } from "../../../../hooks/services/product/deal";

export default function Deals(props) {
	const { productId, selectedDealStateId } = props;

	const { data: dealList, isLoading: isDealListLoading } = useProductDealList(
		productId,
		selectedDealStateId
	);

	return (
		<React.Fragment>
			<Box>
				{isDealListLoading ? (
					<Stack
						justifyContent="center"
						alignItems="center"
						height="30vh"
					>
						<CircularProgress size="35px" />
					</Stack>
				) : (
					<TableContainer>
						<Table sx={{ minWidth: 650 }} size="small">
							<TableHead>
								<TableRow>
									<TableCell padding="checkbox"></TableCell>

									<TableCell width="25%">
										<Stack
											direction="row"
											spacing={0.5}
											alignItems="center"
										>
											<TableHeaderLabel>
												Title
											</TableHeaderLabel>

											{DropDownIcon(
												13,
												13,
												"rgba(0, 0, 0, 0.6)"
											)}
										</Stack>
									</TableCell>

									<TableCell width="15%">
										<Stack
											direction="row"
											spacing={0.5}
											alignItems="center"
										>
											<TableHeaderLabel>
												Deal Value
											</TableHeaderLabel>

											{DropDownIcon(
												13,
												13,
												"rgba(0, 0, 0, 0.6)"
											)}
										</Stack>
									</TableCell>

									<TableCell width="15%">
										<Stack
											direction="row"
											spacing={0.5}
											alignItems="center"
										>
											<TableHeaderLabel>
												Product Quantity
											</TableHeaderLabel>

											{DropDownIcon(
												13,
												13,
												"rgba(0, 0, 0, 0.6)"
											)}
										</Stack>
									</TableCell>

									<TableCell width="22%">
										<Stack
											direction="row"
											spacing={0.5}
											alignItems="center"
										>
											<TableHeaderLabel>
												Primary Contact
											</TableHeaderLabel>

											{DropDownIcon(
												13,
												13,
												"rgba(0, 0, 0, 0.6)"
											)}
										</Stack>
									</TableCell>

									<TableCell width="18%">
										<Stack
											direction="row"
											spacing={0.5}
											alignItems="center"
										>
											<TableHeaderLabel>
												Expected Close Date
											</TableHeaderLabel>

											{DropDownIcon(
												13,
												13,
												"rgba(0, 0, 0, 0.6)"
											)}
										</Stack>
									</TableCell>
								</TableRow>
							</TableHead>

							<TableBody>
								{dealList?.map((deal) => (
									<TableRow key={deal.id}>
										<TableCell padding="checkbox">
											<Checkbox />
										</TableCell>

										<TableCell>
											<TableCellText>
												{deal.title}
											</TableCellText>
										</TableCell>

										<TableCell>
											<TableCellText>
												{/* This $ "dollar" sign will be removed later */}
												${deal.dealValue.value}
											</TableCellText>
										</TableCell>

										<TableCell>
											<TableCellText>
												{deal.productQuantity}
											</TableCellText>
										</TableCell>

										<TableCell>
											<Stack
												direction="row"
												spacing={1}
												alignItems="center"
											>
												<img
													src={
														ContactPersonProfileImage
													}
													alt="profile"
													width="26px"
													height="26px"
												/>
												<Typography fontSize={14}>
													{
														deal.primaryContact
															.fullName
													}
												</Typography>
											</Stack>
										</TableCell>

										<TableCell>
											<TableCellText>
												{deal.expectedCloseDate
													? getDateMonthAndYearFormat(
															deal.expectedCloseDate
														)
													: "-"}
											</TableCellText>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				)}
			</Box>
		</React.Fragment>
	);
}
