import React, { useState } from "react";
import {
	Box,
	Button,
	Divider,
	LinearProgress,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { default as FilesUploadImportIcon } from "../../../.././assets/icons/filesUploadImport";
import { default as FilesIcon } from "../../../.././assets/icons/files";
import { useUploadFileData } from "../../../.././hooks/services/import";
import { styles } from "../Styles";

export default function Upload(props) {
	const { goNextStep, assignIdOnFileUpload } = props;
	const theme = useTheme();
	const uploadFileMutation = useUploadFileData();

	const [isUploading, setIsUploading] = useState(false);
	const [uploadedPercentage, setUploadedPercentage] = useState(0);
	const [uploadedFileDetail, setUploadedFileDetail] = useState(null);

	const fileUpload = (event) => {
		uploadFileMutation.mutate(event.target.files[0], {
			onSuccess: (data) => {
				if (data.createdRecords[0]) {
					assignIdOnFileUpload(data.createdRecords[0]);
				}
			},
		});
		setUploadedFileDetail(event.target.files[0]);
		setIsUploading(true);

		function increaseUploadPercentage(percentage) {
			setTimeout(() => {
				if (percentage < 100) {
					setUploadedPercentage((percentage) => percentage + 25);
					increaseUploadPercentage(percentage + 25);
				} else {
					goNextStep();
				}
			}, 1000);
		}
		increaseUploadPercentage(0);
	};

	return (
		<React.Fragment>
			<Stack direction="row" spacing={1} width="100%" height="100%">
				<Box style={styles.boxContainer} width="75%">
					{isUploading ? (
						<Stack
							alignItems="center"
							justifyContent="center"
							height="100%"
						>
							{FilesUploadImportIcon(
								40,
								40,
								theme.palette.primary.main
							)}

							<Box pt={1.5} />

							<Typography fontWeight={500}>
								{uploadedFileDetail.name}
							</Typography>

							<Box width="20%" pt={2}>
								<LinearProgress
									variant="determinate"
									value={uploadedPercentage}
									sx={{ borderRadius: "20px" }}
								/>
							</Box>
						</Stack>
					) : (
						<Stack
							alignItems="center"
							justifyContent="center"
							height="100%"
						>
							{FilesUploadImportIcon(
								40,
								40,
								theme.palette.primary.main
							)}

							<Box pt={1.5} />

							<Typography fontWeight={500}>
								Upload File
							</Typography>

							<Typography
								fontSize={14}
								color="rgba(0, 0, 0, 0.6)"
							>
								Only .xlsx, .xls and csv file types are
								supported
							</Typography>

							<Box pb={2} />

							<Button
								variant="contained"
								disableElevation
								component="label"
							>
								Upload or Drop File
								<input
									hidden
									accept=".xlsx, .csv, .xls"
									multiple
									type="file"
									onChange={fileUpload}
								/>
							</Button>
						</Stack>
					)}
				</Box>

				<Box width="25%">
					<Stack spacing={1}>
						<Box style={styles.boxContainer} p={2}>
							<Stack>
								<Typography style={styles.heading}>
									Learn Data Structure
								</Typography>

								<Typography style={styles.text}>
									Download and use our sample file to see
									exactly how we organize data.
								</Typography>

								<Box pt={1.5}>
									<Stack
										direction="row"
										alignItems="center"
										spacing={1.5}
										py={1}
									>
										{FilesIcon(
											18,
											18,
											theme.palette.primary.main,
											"2"
										)}

										<Typography
											style={styles.fileName}
											color={
												theme.palette.secondary
													.contrastText
											}
										>
											Sample.xlsx
										</Typography>
									</Stack>

									<Divider />

									<Stack
										direction="row"
										alignItems="center"
										spacing={1.5}
										py={1}
									>
										{FilesIcon(
											18,
											18,
											theme.palette.primary.main,
											"2"
										)}

										<Typography
											style={styles.fileName}
											color={
												theme.palette.secondary
													.contrastText
											}
										>
											Sample.csv
										</Typography>
									</Stack>
								</Box>
							</Stack>
						</Box>

						<Box style={styles.boxContainer} p={2}>
							<Typography style={styles.heading}>
								Fill in Mandatory Fields
							</Typography>

							<Typography style={styles.text}>
								Certain fields needs to be filled in order for
								your import to be successful. make sure they are
								complete before you start importing.
							</Typography>

							<Box pt={1} />

							<Typography
								style={styles.textButton}
								color={theme.palette.secondary.contrastText}
							>
								Learn More
							</Typography>
						</Box>

						<Box style={styles.boxContainer} p={2}>
							<Typography style={styles.heading}>
								Create Custom Fields
							</Typography>

							<Typography style={styles.text}>
								Some data fields may not be present by default.
								Make sure you create these custom fields before
								importing your data.
							</Typography>

							<Box pt={1} />

							<Typography
								style={styles.textButton}
								color={theme.palette.secondary.contrastText}
							>
								Learn More
							</Typography>
						</Box>
					</Stack>
				</Box>
			</Stack>
		</React.Fragment>
	);
}
