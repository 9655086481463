import React, {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useState,
} from "react";
import {
	Box,
	Stack,
	Table,
	TableContainer,
	TableCell,
	TableHead,
	TableRow,
	CircularProgress,
} from "@mui/material";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { TableHeaderLabel } from "../../../../styles/twozo";
import { default as DropDownIcon } from "../../../../assets/icons/dropDownCentered";
import VariationCard from "./VariationCard";
import {
	getVariationListData,
	deleteVariation,
} from "../../../../api/product/variation/variationApi";
import { createVariation } from "../../../../api/product/variation/variationApi";
import { getProductVariationListKey } from "../../../../utils/queryKeys";
import { useCurrenyList } from "../../../../hooks/services/common/currency";

const VariationList = (props, ref) => {
	const { productId } = props;
	const queryClient = useQueryClient();

	const [variationList, setVariationList] = useState([]);
	const [newVariationId, setNewVariationId] = useState(null);
	const variationListKey = getProductVariationListKey(productId);

	const { data: currencyOptions } = useCurrenyList();

	const {
		status: variationApiStatus,
		isLoading: isLoadingVariationData,
		data: variationApiData,
	} = useQuery(variationListKey, () => getVariationListData(productId), {
		enabled: !!productId,
		staleTime: 30000,
	});

	useEffect(() => {
		if (variationApiStatus === "success") {
			if (variationApiData.list) {
				setVariationList([...variationApiData.list]);
			}
		}
	}, [variationApiData, variationApiStatus]);

	const handleDeleteVariation = (variationData) => {
		deleteMutation.mutate(variationData.id);
	};

	const deleteMutation = useMutation(async (id) => deleteVariation(id), {
		onSuccess: () => {
			queryClient.invalidateQueries(variationListKey);
		},
	});

	useImperativeHandle(ref, () => ({
		handleCreateNewVariation: () => {
			createNewVariation();
		},
	}));

	const createNewVariation = () => {
		let requestData = getVartiationRequestData();
		createMutation.mutate(requestData);
	};

	const createMutation = useMutation(
		async (variationRequestData) => createVariation(variationRequestData),
		{
			onSuccess: (data) => {
				setNewVariationId(data?.createdRecords[0]);
				queryClient.invalidateQueries(variationListKey);
			},
		}
	);

	const getVartiationRequestData = () => {
		let requestData = {
			productId: productId,
			variation: {
				name: "New Variation",
				priceTags: [
					{
						unitPrice: "0",
						currency: currencyOptions[0].id,
						tax: "0",
						discount: "0",
					},
				],
			},
		};

		return requestData;
	};

	return (
		<React.Fragment>
			<Box>
				{isLoadingVariationData ? (
					<Stack
						alignItems="center"
						justifyContent="center"
						height="50vh"
					>
						<CircularProgress size="30px" />
					</Stack>
				) : (
					<Box>
						<TableContainer>
							<Table size="small">
								<TableHead>
									<TableRow>
										<TableCell width="4%" />

										<TableCell width="14%">
											<Stack
												direction="row"
												spacing={0.5}
												alignItems="center"
											>
												<TableHeaderLabel>
													Currency
												</TableHeaderLabel>

												{DropDownIcon(
													13,
													13,
													"rgba(0, 0, 0, 0.6)"
												)}
											</Stack>
										</TableCell>

										<TableCell width="14%">
											<Stack
												direction="row"
												spacing={0.5}
												alignItems="center"
												justifyContent="right"
											>
												<TableHeaderLabel>
													Price
												</TableHeaderLabel>

												{DropDownIcon(
													13,
													13,
													"rgba(0, 0, 0, 0.6)"
												)}
											</Stack>
										</TableCell>

										<TableCell width="14%">
											<Stack
												direction="row"
												spacing={0.5}
												alignItems="center"
												justifyContent="right"
											>
												<TableHeaderLabel>
													Tax%
												</TableHeaderLabel>

												{DropDownIcon(
													13,
													13,
													"rgba(0, 0, 0, 0.6)"
												)}
											</Stack>
										</TableCell>
										<TableCell width="14%">
											<Stack
												direction="row"
												spacing={0.5}
												alignItems="center"
												justifyContent="right"
											>
												<TableHeaderLabel>
													Discount%
												</TableHeaderLabel>

												{DropDownIcon(
													13,
													13,
													"rgba(0, 0, 0, 0.6)"
												)}
											</Stack>
										</TableCell>
										<TableCell />
									</TableRow>
								</TableHead>
							</Table>
						</TableContainer>

						<Box>
							{variationList?.map((variationData) => (
								<Box key={variationData.id} mx={1}>
									<VariationCard
										data={variationData}
										handleDeleteVariation={
											handleDeleteVariation
										}
										variationListKey={variationListKey}
										setVariationList={setVariationList}
										newVariationId={newVariationId}
										setNewVariationId={setNewVariationId}
									/>
								</Box>
							))}
						</Box>
					</Box>
				)}
			</Box>
		</React.Fragment>
	);
};

export default forwardRef(VariationList);
