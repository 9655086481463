import { assertError } from "../apiHelper";
import { getNoteUrl } from "../apiConfig";
import { apiClient } from "..";

export const createNote = async (data) => {
	let requestData = getCreateNoteRequest(data);
	return await postNotesManagementApi(requestData);
};

export const getNotes = async (data) => {
	let requestData = getNotesRequest(data);
	return await postNotesManagementApi(requestData);
};

export const updateNote = async (data) => {
	let requestData = getUpdateNoteRequest(data);
	return await postNotesManagementApi(requestData);
};

export const deleteNote = async (id) => {
	let requestData = getDeleteNoteRequest(id);
	return await postNotesManagementApi(requestData);
};

const getNotesRequest = (data) => {
	return {
		type: "getNotes",
		data: data,
	};
};

const getCreateNoteRequest = (data) => {
	return {
		type: "create",
		data: data,
	};
};

const getUpdateNoteRequest = (data) => {
	return {
		type: "update",
		data: data,
	};
};

const getDeleteNoteRequest = (id) => {
	return {
		type: "delete",
		data: {
			id: id,
		},
	};
};

const postNotesManagementApi = async (requestData) => {
	let notesUrl = getNotesManagementUrl();
	let response = await apiClient.post(notesUrl, requestData);
	assertError(response, notesUrl);
	return response.data.data;
};

const getNotesManagementUrl = () => {
	return getNoteUrl() + "/mgmt";
};
