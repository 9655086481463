import { getDropdownKeyByFieldName, getTableKey } from "../commonQueryKeys";
import { getRootKey } from "../rootKey";
import { sourceType } from "../tableNames";

export const getCompanyKey = () => {
	return [...getRootKey(), sourceType.COMPANY]; // Return the company query key
};

// query keys
export const companyDuplicateListKey = [
	...getCompanyKey(),
	"duplicateCompanies",
];

//query key methods
export const getCompanyTableKey = () => {
	return getTableKey(sourceType.COMPANY);
};

export const getCompanyKeyById = (companyId) => {
	return [...getCompanyKey(), String(companyId)];
};

export const getCompanyDataKey = (companyId) => {
	return [...getCompanyKeyById(companyId), "details"];
};

export const getCompanyListKey = () => {
	return [...getDropdownKeyByFieldName("companyList")];
};

export const getCompanySummaryKey = (companyId) => {
	return [...getCompanyKeyById(companyId), "summary"];
};

export const getCompanySummaryViewKey = (companyId, hideEmptyFields) => {
	return [...getCompanyKeyById(companyId), "summary", hideEmptyFields];
};

export const getRelatedContactsKey = (companyId) => {
	return [...getCompanyKeyById(companyId), "relatedContacts"];
};

export const getCompanyTimelineKey = (companyId, filters) => {
	if (Array.isArray(filters) && filters.length > 0) {
		return [...getCompanyKeyById(companyId), "timeline", filters]; // Return the query key when the timeline filter is applied
	}
	return [...getCompanyKeyById(companyId), "timeline"]; // Return the query key when the timeline filter is not applied
};

export const getCompanyNotesKey = (companyId) => {
	return [...getCompanyKeyById(companyId), "notes"];
};

export const getCompanyFilesKey = (companyId) => {
	return [...getCompanyKeyById(companyId), "files"];
};

export const getCompanyToDoAcitivityKey = (companyId, filters) => {
	if (Array.isArray(filters) && filters.length > 0) {
		return [...getCompanyKeyById(companyId), "todoActivities", filters]; // Return the query key when the activity filter is applied
	}
	return [...getCompanyKeyById(companyId), "todoActivities"]; // Return the query key when the activity filter is not applied
};

export const getCompanyCompletedActivityKey = (companyId, filters) => {
	if (Array.isArray(filters) && filters.length > 0) {
		return [
			...getCompanyKeyById(companyId),
			"completedActivities",
			filters,
		]; // Return the query key when the activity filter is applied
	}
	return [...getCompanyKeyById(companyId), "completedActivities"]; // Return the query key when the activity filter is not applied
};

export const getCompanyReviewDuplicateKey = (companyId) => {
	return [...getCompanyKeyById(companyId), "reviewDuplicates"];
};
