import React from "react";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import ActivitiesList from "./ActivitiesList";

export default function Activities(props) {
	const {
		toDoActivitiesKey,
		completedActivitiesKey,
		isLoadingTodoActivities,
		summaryKey,
		todoActivities,
		isLoadingCompletedActivities,
		completedActivities,
	} = props;

	return (
		<React.Fragment>
			{isLoadingCompletedActivities || isLoadingTodoActivities ? (
				<Stack
					alignItems="center"
					justifyContent="center"
					height="50vh"
				>
					<CircularProgress size="30px" />
				</Stack>
			) : (
				<>
					<Box>
						<Typography fontWeight={600} px={3} py={2}>
							Activities To do
						</Typography>
						{todoActivities?.length > 0 ? (
							<Box>
								<ActivitiesList
									activities={todoActivities}
									toDoActivitiesKey={toDoActivitiesKey}
									summaryKey={summaryKey}
									completedActivitiesKey={
										completedActivitiesKey
									}
								/>
							</Box>
						) : (
							<Stack
								direction="row"
								justifyContent="center"
								alignItems="center"
							>
								<Typography fontSize={14}>
									You have no upcoming activities.
								</Typography>
							</Stack>
						)}
					</Box>

					<Box>
						{completedActivities?.length > 0 ? (
							<Box>
								<Typography fontWeight={600} px={3} py={2}>
									Completed Activities
								</Typography>

								<ActivitiesList
									activities={completedActivities}
									toDoActivitiesKey={toDoActivitiesKey}
									summaryKey={summaryKey}
									completedActivitiesKey={
										completedActivitiesKey
									}
								/>
							</Box>
						) : null}
					</Box>
				</>
			)}
		</React.Fragment>
	);
}
