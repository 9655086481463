import React from "react";
import { TextField } from "@mui/material";

export default function TextComponent(props) {
	const { style = {}, field = {}, onChange, hasError, ...rest } = props;

	const handleChange = (e) => {
		const { value } = e.target;
		if (!field.values || !Array.isArray(field.values)) {
			field.values = [{ value: "" }];
		}
		field.values[0].value = value;
		if (onChange) {
			onChange(field.values);
		}
	};

	return (
		<React.Fragment>
			<TextField
				{...rest}
				placeholder="-Enter-"
				defaultValue={
					field.values && field.values.length > 0
						? field.values[0].value
						: ""
				}
				sx={{
					backgroundColor: "#fff",
					borderRadius: 2,
					...style,
				}}
				onChange={handleChange}
				error={hasError}
				fullWidth
			/>
		</React.Fragment>
	);
}
