import React, { useEffect } from "react";
import Activities from "../../../Summary/Activities";
import {
	getDealCompletedActivityKey,
	getDealKeyById,
	getDealToDoActivityKey,
} from "../../../../utils/queryKeys";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import {
	useDealCompletedActivities,
	useDealToDoActivities,
} from "../../../../hooks/services/deal/summary/activities";

export default function DealActivities(props) {
	const { dealId, activityTypeIdsToFilter } = props;
	const toDoActivitiesKey = getDealToDoActivityKey(
		dealId,
		activityTypeIdsToFilter
	);
	const completedActivitiesKey = getDealCompletedActivityKey(
		dealId,
		activityTypeIdsToFilter
	);
	const dealSummaryKey = getDealKeyById(dealId);

	// Todo Activities get API
	const {
		isLoading: isLoadingTodoActivities,
		data: todoActivities,
		status: todoActivitiesStatus,
	} = useDealToDoActivities(dealId, activityTypeIdsToFilter);

	// Completed Activities get API
	const {
		isLoading: isLoadingCompletedActivities,
		data: completedActivities,
		status: completedActivitiesStatus,
	} = useDealCompletedActivities(dealId, activityTypeIdsToFilter);

	// Handed Error Notification
	useEffect(() => {
		if (
			todoActivitiesStatus === "error" ||
			completedActivitiesStatus === "error"
		) {
			enqueueSnackbar({
				variant: notificationVariants.error,
				message: notificationMessage.somethingWentWrong,
			});
		}
	}, [todoActivitiesStatus, completedActivitiesStatus]);

	return (
		<React.Fragment>
			<Activities
				isLoadingTodoActivities={isLoadingTodoActivities}
				isLoadingCompletedActivities={isLoadingCompletedActivities}
				todoActivities={todoActivities}
				completedActivities={completedActivities}
				toDoActivitiesKey={toDoActivitiesKey}
				summaryKey={dealSummaryKey}
				completedActivitiesKey={completedActivitiesKey}
			/>
		</React.Fragment>
	);
}
