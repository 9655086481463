import React, { useState } from "react";
import {
	Box,
	IconButton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	useTheme,
} from "@mui/material";
import { default as DropDownIcon } from "../../../../assets/icons/dropDownCentered";
import { default as DeleteIcon } from "../../../../assets/icons/delete";
import { default as DealsIcon } from "../../../../assets/icons/deals";
import { default as ContactIcon } from "../../../../assets/icons/contact";
import DeleteDialog from "../../../Elements/DeleteDialog";
import { TableCellText, TableHeaderLabel } from "../../../../styles/twozo";
import UnKnownImage from "../../../../assets/images/contact/unknownContact.png";

const workflowList = [
	{
		id: "1",
		status: "success",
		name: "Notify Deal Owner",
		module: "deals",
		executeOn: "Created",
		actions: 1,
		salesOwner: {
			name: "Dinesh Kumar",
			image: UnKnownImage,
		},
	},
	{
		id: "2",
		status: "success",
		name: "Connect with Sales",
		module: "contacts",
		executeOn: "Created",
		actions: 3,
		salesOwner: {
			name: "Dinesh Kumar",
			image: UnKnownImage,
		},
	},
	{
		id: "3",
		status: "failure",
		name: "Deal Overdue",
		module: "deals",
		executeOn: "Updated",
		actions: 5,
		salesOwner: {
			name: "Dinesh Kumar",
			image: UnKnownImage,
		},
	},
];

export default function History() {
	const theme = useTheme();
	const [tableRowHovered, setTableRowHovered] = useState(null);
	const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);

	const onMouseOverTableRow = (id) => setTableRowHovered(id);
	const onMouseOutTableRow = () => setTableRowHovered(null);

	const openDeleteDialog = () => {
		setIsOpenDeleteDialog(true);
	};

	const closeDeleteDialog = () => {
		setIsOpenDeleteDialog(false);
	};

	const getModuleData = (module) => {
		let moduleData = {
			name: "",
			icon: () => {},
		};

		switch (module) {
			case "deals":
				moduleData["name"] = "Deals";
				moduleData["icon"] = DealsIcon;
				break;
			case "contacts":
				moduleData["name"] = "Contacts";
				moduleData["icon"] = ContactIcon;
				break;
			default:
				break;
		}

		return moduleData;
	};

	return (
		<React.Fragment>
			<DeleteDialog
				title="Are you sure you want to delete this automation?"
				subtitle="You can't undo this."
				open={isOpenDeleteDialog}
				onCancel={closeDeleteDialog}
				onDelete={closeDeleteDialog}
			/>

			<Box p={1} pt={0} height="100%">
				<Box
					sx={{
						border: "1px solid rgba(0, 0, 0, 0.1)",
						borderRadius: "8px",
						height: "100%",
						overflow: "auto",
					}}
				>
					<TableContainer>
						<Table sx={{ minWidth: 650 }} size="small">
							<TableHead>
								<TableRow>
									<TableCell>
										<TableHeaderLabel>
											Status
										</TableHeaderLabel>
									</TableCell>

									<TableCell width="30%">
										<Stack
											direction="row"
											alignItems="center"
											spacing={1}
										>
											<TableHeaderLabel>
												Workflow Name
											</TableHeaderLabel>

											{DropDownIcon(12, 12, "grey")}
										</Stack>
									</TableCell>

									<TableCell>
										<Stack
											direction="row"
											alignItems="center"
											spacing={1}
										>
											<TableHeaderLabel>
												Module
											</TableHeaderLabel>

											{DropDownIcon(12, 12, "grey")}
										</Stack>
									</TableCell>

									<TableCell>
										<Stack
											direction="row"
											alignItems="center"
											spacing={1}
										>
											<TableHeaderLabel>
												Execution Started on
											</TableHeaderLabel>

											{DropDownIcon(12, 12, "grey")}
										</Stack>
									</TableCell>

									<TableCell>
										<Stack
											direction="row"
											alignItems="center"
											spacing={1}
										>
											<TableHeaderLabel>
												Actions
											</TableHeaderLabel>

											{DropDownIcon(12, 12, "grey")}
										</Stack>
									</TableCell>

									<TableCell>
										<Stack
											direction="row"
											alignItems="center"
											spacing={1}
										>
											<TableHeaderLabel>
												Sales Owner
											</TableHeaderLabel>

											{DropDownIcon(12, 12, "grey")}
										</Stack>
									</TableCell>

									<TableCell width="5%"></TableCell>
								</TableRow>
							</TableHead>

							<TableBody>
								{workflowList.map((workflow) => (
									<TableRow
										key={workflow.id}
										onMouseOver={() =>
											onMouseOverTableRow(workflow.id)
										}
										onMouseOut={onMouseOutTableRow}
									>
										<TableCell>
											{workflow.status === "success" ? (
												<Typography
													fontSize={13}
													color={
														theme.palette.primary
															.main
													}
												>
													Success
												</Typography>
											) : (
												<Typography
													fontSize={13}
													color={
														theme.palette.error.main
													}
												>
													Failure
												</Typography>
											)}
										</TableCell>

										<TableCell>
											<Typography
												fontSize={14}
												fontWeight={500}
											>
												{workflow.name}
											</Typography>
										</TableCell>

										<TableCell>
											{(function () {
												const moduleData =
													getModuleData(
														workflow.module
													);
												return (
													<Stack
														direction="row"
														alignItems="center"
														spacing={1}
													>
														<Box display="flex">
															{moduleData.icon(
																20,
																20,
																"rgba(0, 0, 0, 0.6)"
															)}
														</Box>

														<TableCellText>
															{moduleData.name}
														</TableCellText>
													</Stack>
												);
											})()}
										</TableCell>

										<TableCell>
											<TableCellText>
												{workflow.executeOn}
											</TableCellText>
										</TableCell>

										<TableCell>
											<TableCellText>
												{workflow.actions}
											</TableCellText>
										</TableCell>

										<TableCell>
											<Stack
												direction="row"
												alignItems="center"
												spacing={1}
											>
												<img
													src={
														workflow.salesOwner
															.image
													}
													alt="img"
													width={26}
													height={26}
												/>
												<TableCellText>
													{workflow.salesOwner.name}
												</TableCellText>
											</Stack>
										</TableCell>

										<TableCell sx={{ py: 0 }}>
											<Stack alignItems="flex-end">
												<Box
													hidden={
														tableRowHovered !==
														workflow.id
													}
													style={{
														width: "fit-content",
														border: `1px solid ${theme.palette.primary.main}`,
														borderRadius: "8px",
														padding: "2px 8px",
													}}
												>
													<IconButton
														size="small"
														onClick={
															openDeleteDialog
														}
													>
														{DeleteIcon(
															20,
															20,
															theme.palette.error
																.main
														)}
													</IconButton>
												</Box>
											</Stack>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
			</Box>
		</React.Fragment>
	);
}
