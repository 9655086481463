import React, { useState } from "react";
import {
	Box,
	Button,
	Stack,
	Typography,
	IconButton,
	Divider,
	useTheme,
} from "@mui/material";
import { default as CloseIcon } from "../../../../../assets/icons/close";
import InfoIcon from "../../../../../assets/icons/info";
import CompanyCardList from "./CompanyCardList";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../utils/notification/notificationMessages";
import {
	useMergeDuplicateCompany,
	useReviewCompanyDuplicateList,
} from "../../../../../hooks/services/company/companyDuplicates";

const styles = {
	contentName: {
		fontSize: 14,
		fontWeight: 500,
	},
	subText: {
		fontSize: 13,
	},
	text: {
		fontSize: 14,
	},
};

export default function ReviewDuplicates(props) {
	const { primaryCompanyId, onClose } = props;
	const theme = useTheme();
	const [selectedCompany, setSelectedCompany] = useState({});
	const mergeDuplicateCompanyMutation = useMergeDuplicateCompany();

	const errorNotificationTitle = "Unable to merge";

	const { data: duplicateRecords } =
		useReviewCompanyDuplicateList(primaryCompanyId);

	const handleSelectCompany = (company) => {
		setSelectedCompany(company);
	};

	const getMergeRequestData = () => {
		let mergeCompanyRequestData = {
			primaryCompanyId: selectedCompany.id,
			duplicateCompanyIds: [],
		};

		for (let duplicateData of duplicateRecords) {
			if (duplicateData.id !== selectedCompany.id) {
				mergeCompanyRequestData.duplicateCompanyIds.push(
					duplicateData.id
				);
			}
		}
		return mergeCompanyRequestData;
	};

	const handleMergeCompany = () => {
		if (selectedCompany?.id) {
			let mergeCompanyRequestData = getMergeRequestData();
			mergeDuplicateCompanyMutation.mutate(mergeCompanyRequestData, {
				onSuccess: () => {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message:
							duplicateRecords?.length === 2
								? `${
										duplicateRecords.length - 1
									} company have been merged successfully`
								: `${
										duplicateRecords.length - 1
									} companies have been merged successfully`,
					});
					onClose();
				},
				onError: (error) => {
					let errorMessage = error.message;
					enqueueSnackbar({
						variant: notificationVariants.error,
						title: errorNotificationTitle,
						message: errorMessage
							? errorMessage
							: notificationMessage.errorMessage,
					});
				},
			});
		}
	};

	return (
		<React.Fragment>
			<Box
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box p={2}>
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						spacing={2}
					>
						<Typography fontWeight={600}>
							Review Possible Duplicates
						</Typography>
						<IconButton
							onClick={onClose}
							size="small"
							style={{ padding: 0 }}
						>
							{CloseIcon(25, 25, theme.palette.primary.main)}
						</IconButton>
					</Stack>
				</Box>

				<Divider />

				<Box
					style={{
						flex: "1 1 auto",
						minHeight: 0,
						overflowY: "auto",
					}}
				>
					<Box
						m={2}
						p={2}
						style={{
							backgroundColor: "#E7F7F0",
							borderRadius: "8px",
						}}
					>
						<Stack direction="row" alignItems="center" spacing={2}>
							<IconButton>
								{InfoIcon(20, 20, theme.palette.primary.main)}
							</IconButton>
							<Stack>
								<Typography style={styles.text}>
									We check for an exact match in the field:
									Company name
								</Typography>
							</Stack>
						</Stack>
					</Box>

					<Box ml={2.5} p={1}>
						<Typography style={styles.subText} fontWeight={500}>
							Which Company would you like to retain?
						</Typography>
					</Box>

					<Stack px={2} py={1} spacing={1}>
						{duplicateRecords?.map((companyData) => (
							<Box key={companyData.id}>
								<CompanyCardList
									companyData={companyData}
									isSelected={
										selectedCompany.id === companyData.id
									}
									handleSelectCompany={handleSelectCompany}
								/>
							</Box>
						))}
					</Stack>
				</Box>

				<Divider />

				<Box p={2}>
					<Button
						variant="contained"
						disableElevation
						onClick={handleMergeCompany}
						disabled={
							!selectedCompany.id ||
							mergeDuplicateCompanyMutation.isLoading
						}
						sx={{
							"&.Mui-disabled": {
								backgroundColor: theme.palette.primary.main,
								color: "rgba(255,255,255, 0.6)",
							},
						}}
					>
						Merge
					</Button>
				</Box>
			</Box>
		</React.Fragment>
	);
}
