import React, { useEffect } from "react";
import TimeLine from "../../../Summary/Timeline";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import { useCompanyTimelineData } from "../../../../hooks/services/company/summary/timeline";

export default function CompanyTimeline(props) {
	const { companyId, timelineIdsToFilter } = props;

	const {
		isLoading: isLoadingTimelineDetails,
		data: companyTimelineData,
		status: companyTimelineStatus,
	} = useCompanyTimelineData(companyId, timelineIdsToFilter);

	useEffect(() => {
		if (companyTimelineStatus === "error") {
			enqueueSnackbar({
				variant: notificationVariants.error,
				message: notificationMessage.somethingWentWrong,
			});
		}
	}, [companyTimelineStatus]);

	return (
		<React.Fragment>
			<TimeLine
				timelineData={companyTimelineData}
				isLoadingTimelineDetails={isLoadingTimelineDetails}
			/>
		</React.Fragment>
	);
}
