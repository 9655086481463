import React, { useEffect, useState } from "react";
import DropdownBaseComponent from "../DropdownBaseComponent";
import { useQuery } from "@tanstack/react-query";
import { getDropdownKeyByFieldId } from "../../../../../utils/queryKeys";
import { getFieldDropdownData } from "../../../../../api/fields/config/configApi";
import { getActionStepOptionKey } from "../../../../../utils/queryKeys/workflow";
import { getActionStepOptionData } from "../../../../../api/workflow/workflowApi";

export default function DropdownComponent(props) {
	const { field, metadata, style, onChange, hasError } = props;
	const [selected, setSelected] = useState(null);

	useEffect(() => {
		setSelected(
			field.values && field.values.length > 0 ? field.values[0] : null
		);
	}, []);

	const { data: options } = useQuery(
		getDropdownKeyByFieldId(field.fieldId),
		() => getFieldDropdownData({ name: field.dropdown.name }),
		{
			enabled: !!field?.fieldId,
			select: (data) => {
				//TODO - Get consistent value from dropdown API
				return (
					data?.options?.map((option) => {
						if (option.id) {
							option["value"] = option["id"];
						} else if (option.value) {
							option["id"] = option["value"];
						}
						return option;
					}) || []
				);
			},
		}
	);

	const { data: stepOptions } = useQuery(
		getActionStepOptionKey(metadata.triggerSourceId, field.fieldId, [
			{ source: metadata.actionSource, event: metadata.actionEvent },
		]),
		() =>
			getActionStepOptionData(metadata.triggerSourceId, field.fieldId, [
				{ source: metadata.actionSource, event: metadata.actionEvent },
			]),
		{
			enabled: !!(
				!metadata ||
				(field?.fieldId && field?.config?.hasStepOptions)
			),
			select: (data) => data?.stepOptions,
		}
	);

	const onFieldChange = (value) => {
		field["values"] = [value];
		setSelected(value);
		if (onChange) {
			onChange([value]);
		}
	};

	return (
		<React.Fragment>
			<DropdownBaseComponent
				hasStepOptions={field?.config?.hasStepOptions}
				selected={selected}
				options={options || []}
				onFieldChange={onFieldChange}
				multiDropdown={field.fieldType === "MULTI_DROPDOWN"}
				stepOptions={stepOptions || []}
				style={style}
				hasError={hasError}
			/>
		</React.Fragment>
	);
}
