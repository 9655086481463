import { getDropdownKeyByFieldName, getTableKey } from "../commonQueryKeys";
import { getRootKey } from "../rootKey";
import { sourceType } from "../tableNames";

export const getContactKey = () => {
	return [...getRootKey(), sourceType.CONTACT]; // Return the contact query key
};

// query keys
export const contactDuplicateListKey = [
	...getContactKey(),
	"duplicatesContact",
];

//query key methods
export const getContactTableKey = () => {
	return getTableKey(sourceType.CONTACT);
};

export const getContactKeyById = (contactId) => {
	return [...getContactKey(), String(contactId)];
};

export const getContactDataKey = (contactId) => {
	return [...getContactKeyById(contactId), "details"];
};

export const getContactListKey = () => {
	return [...getDropdownKeyByFieldName("contactList")];
};

export const getContactListWithEmailsKey = () => {
	return [...getDropdownKeyByFieldName("contactListWithEmails")];
};

export const getContactSummaryKey = (contactId) => {
	return [...getContactKeyById(contactId), "summary"];
};

export const getContactSummaryViewKey = (contactId, hideEmptyFields) => {
	return [...getContactKeyById(contactId), "summary", hideEmptyFields];
};

export const getContactTimelineKey = (contactId, filters) => {
	if (Array.isArray(filters) && filters.length > 0) {
		return [...getContactKeyById(contactId), "timeline", filters]; // Return the query key when the timeline filter is applied
	}
	return [...getContactKeyById(contactId), "timeline"]; // Return the query key when the timeline filter is not applied
};

export const getContactNotesKey = (contactId) => {
	return [...getContactKeyById(contactId), "notes"];
};

export const getContactFilesKey = (contactId) => {
	return [...getContactKeyById(contactId), "files"];
};

export const getContactToDoAcitivityKey = (contactId, filters) => {
	if (Array.isArray(filters) && filters.length > 0) {
		return [...getContactKeyById(contactId), "todoActivities", filters]; // Return the query key when the activity filter is applied
	}
	return [...getContactKeyById(contactId), "todoActivities"]; // Return the query key when the activity filter is not applied
};

export const getContactCompletedActivityKey = (contactId, filters) => {
	if (Array.isArray(filters) && filters.length > 0) {
		return [
			...getContactKeyById(contactId),
			"completedActivities",
			filters,
		]; // Return the query key when the activity filter is applied
	}
	return [...getContactKeyById(contactId), "completedActivities"]; // Return the query key when the activity filter is not applied
};

export const getContactReviewDuplicateKey = (contactId) => {
	return [...getContactKeyById(contactId), "reviewDuplicates"];
};

export const getCheckForDuplicatesKey = (duplicateRequestData) => {
	return [...getContactKey(), duplicateRequestData, "duplicateKey"];
};
