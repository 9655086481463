import { apiClient } from "../..";
import { getCompanyUrl } from "../../apiConfig";
import { assertError } from "../../apiHelper";

export const getCompanyDuplicateList = async () => {
	let requestData = getCompanyDuplicateRequestData();
	return await postCompanyDuplicateManagementApi(requestData);
};

export const getReviewDuplicateData = async (id) => {
	let requestData = getReviewDuplicateRequestData(id);
	return await postCompanyDuplicateManagementApi(requestData);
};

export const mergeCompanyData = async ({
	primaryCompanyId,
	duplicateCompanyIds,
}) => {
	let requestData = getMergeCompanyRequestData(
		primaryCompanyId,
		duplicateCompanyIds
	);
	return await postCompanyDuplicateManagementApi(requestData);
};

export const dismissDuplicateCompany = async (id) => {
	let requestData = getDismissDuplicateCompanyRequest(id);
	return await postCompanyDuplicateManagementApi(requestData);
};

const getCompanyDuplicateRequestData = () => {
	return {
		type: "list",
		data: {},
	};
};

const getReviewDuplicateRequestData = (id) => {
	return {
		type: "review",
		data: {
			companyId: id,
		},
	};
};

const getMergeCompanyRequestData = (primaryCompanyId, duplicateCompanyIds) => {
	return {
		type: "merge",
		data: {
			primaryCompanyId: primaryCompanyId,
			duplicateCompanyIds: duplicateCompanyIds,
		},
	};
};

const getDismissDuplicateCompanyRequest = (id) => {
	return {
		type: "dismiss",
		data: {
			companyId: id,
		},
	};
};

const postCompanyDuplicateManagementApi = async (requestData) => {
	let companyDuplicateManagementUrl = getCompanyDuplicateManagementUrl();
	let response = await apiClient.post(
		companyDuplicateManagementUrl,
		requestData
	);
	assertError(response);
	return response.data.data;
};

const getCompanyDuplicateManagementUrl = () => {
	return getCompanyUrl() + "/duplicate/mgmt";
};
