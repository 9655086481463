import { useQuery } from "@tanstack/react-query";
import { getProductDealList } from "../../../../api/product/deals/dealsApi";
import { getProductDealListKey } from "../../../../utils/queryKeys";

const useProductDealList = (productId, dealStateIds) => {
	const dealListKey = getProductDealListKey(productId, dealStateIds);
	return useQuery(
		dealListKey,
		() => getProductDealList(productId, dealStateIds),
		{
			select: (data) => data.list,
		}
	);
};

export { useProductDealList };
