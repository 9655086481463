import React, { useMemo, useState } from "react";
import {
	Box,
	Checkbox,
	CircularProgress,
	MenuItem,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	useTheme,
} from "@mui/material";
import {
	TableCellText,
	TableHeaderLabel,
} from "../../../../../../styles/twozo";
import { default as DropdownIcon } from "../../../../../../assets/icons/dropDownCentered";
import { default as CloseIcon } from "../../../../../../assets/icons/close";
import UnKnownImage from "../../../../../../assets/images/contact/unknownContact.png";
import Menu from "../../../../../Elements/Menu";
import { useUserDetailsList } from "../../../../../../hooks/services/userManagement/user";
import { getDateDifferenceWithHourAndMinute } from "../../../../../../utils/DateUtils";

export default function AssignedUserList(props) {
	const { isUserDataLoading, assignedUserDetails, updateAssignUserDetails } =
		props;
	const theme = useTheme();
	const [userMenuElement, setUserMenuElement] = useState(null);
	const isActivityMenuOpened = Boolean(userMenuElement);
	const [selectedIndex, setSelectedIndex] = useState(null);
	const { data: userList } = useUserDetailsList();

	const openUserMenu = (event, index) => {
		setUserMenuElement(event.currentTarget);
		setSelectedIndex(index);
	};

	const closeUserMenu = () => {
		setUserMenuElement(null);
	};

	const assignedUserHeader = [
		{
			id: 1,
			name: "Users",
		},
		{
			id: 2,
			name: "Email",
		},
		{
			id: 3,
			name: "Last Login",
		},
	];

	const addAssignUser = () => {
		const userListLength = assignedUserDetails?.length;

		const newUser = {
			id: userListLength + 1,
			isUserNotAdded: true,
		};
		updateAssignUserDetails([...assignedUserDetails, newUser]);
	};

	const deleteUser = (index) => {
		updateAssignUserDetails((assignedUserDetails) => {
			assignedUserDetails.splice(index, 1);
			return [...assignedUserDetails];
		});
	};

	const selectUser = (selectedUser) => {
		let updatedAssignUserDetails = assignedUserDetails.map(
			(user, index) => {
				if (index === selectedIndex) {
					return {
						name: selectedUser.name,
						email: selectedUser.email,
						role: {
							name: selectedUser.role.name,
							id: selectedUser.role.id,
						},
						isUserNotAdded: false,
						id: selectedUser.id,
						...(selectedUser.lastLoginTime && {
							lastLoginTime: selectedUser.lastLoginTime,
						}),
					};
				}
				return user;
			}
		);
		updateAssignUserDetails([...updatedAssignUserDetails]);
		setUserMenuElement(null);
		setSelectedIndex(null);
	};

	const filteredUserList = useMemo(() => {
		const assignedUserIds =
			assignedUserDetails?.length > 0
				? assignedUserDetails?.map((user) => user.id)
				: [];
		const users =
			userList?.length > 0
				? userList?.filter((user) => !assignedUserIds.includes(user.id))
				: [];
		return users;
	}, [assignedUserDetails, userList]);

	const filterUnaddedUsers = useMemo(() => {
		return assignedUserDetails?.filter((user) => user.isUserNotAdded) || [];
	}, [assignedUserDetails]);

	const isUserAdditionEnabled =
		filterUnaddedUsers.length > 0 || filteredUserList.length === 0;

	return (
		<React.Fragment>
			<Menu
				minWidth="300px"
				anchorEl={userMenuElement}
				open={isActivityMenuOpened}
				onClose={closeUserMenu}
				style={{ marginTop: "12px" }}
				PaperProps={{ style: { maxHeight: "500px" } }}
			>
				{filteredUserList?.length > 0
					? filteredUserList?.map((user, index) => (
							<MenuItem
								key={index}
								style={{ minHeight: "40px" }}
								onClick={() => selectUser(user)}
							>
								<Stack
									direction="row"
									alignItems="center"
									spacing={1}
								>
									<img
										src={UnKnownImage}
										alt="img"
										width="20px"
										height="20px"
									/>
									<Typography fontSize={13}>
										{user.name}
									</Typography>
								</Stack>
							</MenuItem>
						))
					: null}
			</Menu>

			<Box
				style={{
					display: "flex",
					flexDirection: "column",
					height: "100%",
					width: "100%",
				}}
			>
				{isUserDataLoading ? (
					<Box
						style={{
							height: "100%",
						}}
						border="1px solid rgba(0, 0, 0, 0.10)"
						borderRadius={1}
					>
						<Stack
							alignItems="center"
							justifyContent="center"
							height="100%"
						>
							<CircularProgress />
						</Stack>
					</Box>
				) : (
					<Box
						style={{
							flex: "1 1 auto",
							overflowY: "auto",
							minHeight: "0px",
						}}
						border="1px solid rgba(0, 0, 0, 0.1)"
						borderRadius={1}
					>
						<TableContainer>
							<Table sx={{ minWidth: 650 }} size="small">
								<TableHead>
									<TableRow>
										<TableCell padding="checkbox">
											<Checkbox />
										</TableCell>
										{assignedUserHeader.map((header) => (
											<TableCell key={header.id}>
												<Stack
													direction="row"
													alignItems="center"
													spacing={1}
												>
													<TableHeaderLabel>
														{header.name}
													</TableHeaderLabel>
													{DropdownIcon(
														12,
														12,
														"rgba(0, 0, 0, 0.5)"
													)}
												</Stack>
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									<TableRow>
										<TableCell></TableCell>
										<TableCell colSpan={4}>
											<Typography
												fontWeight={500}
												fontSize={13}
												color={
													theme.palette.secondary
														.contrastText
												}
												onClick={addAssignUser}
												style={{
													pointerEvents:
														isUserAdditionEnabled
															? "none"
															: "auto",
													cursor: isUserAdditionEnabled
														? "not-allowed"
														: "pointer",
													opacity:
														isUserAdditionEnabled
															? 0.6
															: 1,
												}}
											>
												+ Assign User
											</Typography>
										</TableCell>
									</TableRow>

									{assignedUserDetails?.map(
										(userDetail, index) => (
											<TableRow key={userDetail.id}>
												<TableCell padding="checkbox">
													{userDetail.isUserNotAdded ? (
														<Stack
															alignItems="center"
															onClick={() =>
																deleteUser(
																	index
																)
															}
														>
															{CloseIcon(
																20,
																20,
																"rgba(0, 0, 0, 0.6)"
															)}
														</Stack>
													) : (
														<Checkbox />
													)}
												</TableCell>

												<TableCell
													style={{
														borderBottom:
															selectedIndex ===
															index
																? `2px solid ${theme.palette.primary.main}`
																: null,
													}}
												>
													{userDetail.isUserNotAdded ? (
														<Box
															onClick={(event) =>
																openUserMenu(
																	event,
																	index
																)
															}
														>
															<Typography
																fontSize={14}
																fontWeight={400}
																color="rgba(0, 0, 0, 0.6)"
															>
																Select
															</Typography>
														</Box>
													) : (
														<Stack
															direction="row"
															alignItems="center"
															spacing={1}
														>
															<img
																src={
																	UnKnownImage
																}
																alt="img"
																width="26px"
																height="26px"
															/>
															<TableCellText>
																{
																	userDetail.name
																}
															</TableCellText>
														</Stack>
													)}
												</TableCell>

												<TableCell>
													<TableCellText>
														{userDetail.email
															? userDetail.email
															: "-"}
													</TableCellText>
												</TableCell>
												<TableCell>
													<TableCellText>
														{userDetail.lastLoginTime
															? getDateDifferenceWithHourAndMinute(
																	userDetail.lastLoginTime
																)
															: "-"}
													</TableCellText>
												</TableCell>
											</TableRow>
										)
									)}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
				)}
			</Box>
		</React.Fragment>
	);
}
