import React, { useState } from "react";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Divider,
	IconButton,
	MenuItem,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { default as Contact1 } from "../../../../assets/images/contact/profile.png";
import { default as Contact2 } from "../../../../assets/images/contact/profile2.png";
import { default as Contact3 } from "../../../../assets/images/contact/profile3.png";
import LinkedinLogo from "../../../../assets/images/socialMedia/linkedin.png";
import FacebookLogo from "../../../../assets/images/socialMedia/facebook.png";
import { default as DropdownIcon } from "../../../../assets/icons/dropDownCentered";
import { default as ActivityIcon } from "../../../../assets/icons/addActivity";
import { default as CallIcon } from "../../../../assets/icons/call";
import { default as DealIcon } from "../../../../assets/icons/deals";
import { default as CloseIcon } from "../../../../assets/icons/close";
import { default as TimeIcon } from "../../../../assets/icons/time";
import { default as AddIcon } from "../../../../assets/icons/add";
import Menu from "../../../Elements/Menu";

const styles = {
	socialMediaLogoContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: "50px",
		width: 20,
		height: 20,
		cursor: "pointer",
	},
};

export default function MailInfo() {
	const theme = useTheme();
	const [showDeal, setShowDeal] = useState(false);
	const [expandedContact, setExpandedContact] = useState(false);
	const [addContactMenuElement, setAddContactMenuElement] = useState(null);
	const openAddContactMenu = Boolean(addContactMenuElement);

	const handleContactOpen = (thread) => (event, newThread) => {
		setExpandedContact(newThread ? thread : false);
	};

	const OpenAddContactMenu = (event) => {
		setAddContactMenuElement(event.currentTarget);
	};

	const CloseAddContactMenu = () => {
		setAddContactMenuElement(null);
	};

	const contactInfos = [
		{
			id: "1",
			newContact: false,
			emailId: "kate.jane@crm.com",
			name: "Kate Jane",
			company: "Drift",
			phone: "+91 9876543210",
			contactImage: Contact1,
		},
		{
			id: "2",
			newContact: false,
			emailId: "kadin.lubin@thelaunchclub.com",
			name: "Kadin Lubin",
			company: "Drift",
			phone: "+91 9876543210",
			contactImage: Contact2,
		},
		{
			id: "3",
			newContact: true,
			emailId: "kuber.kriatin@crm.com",
			name: "Kuber Criatin",
			contactImage: Contact3,
		},
	];

	return (
		<React.Fragment>
			<Menu
				minWidth="200px"
				anchorEl={addContactMenuElement}
				open={openAddContactMenu}
				onClose={CloseAddContactMenu}
				style={{
					marginTop: "8px",
				}}
			>
				<MenuItem style={{ height: "40px" }}>
					<Typography fontSize={13}>to Existing Contact</Typography>
				</MenuItem>

				<MenuItem style={{ height: "40px" }}>
					<Typography fontSize={13}>New Contact</Typography>
				</MenuItem>
			</Menu>

			<Stack spacing={2}>
				<Box>
					<Typography fontSize={13} fontWeight={500} pl={0.5} pb={1}>
						Related Contacts
					</Typography>

					<Box>
						{contactInfos.map((contactInfo) => (
							<Accordion
								key={contactInfo.id}
								disableGutters
								elevation={0}
								expanded={expandedContact === contactInfo.id}
								onChange={handleContactOpen(contactInfo.id)}
								sx={{
									border: `1px solid ${theme.palette.divider}`,
									"&:first-child": {
										borderRadius: "8px 8px 0px 0px",
									},
									"&:not(:last-child)": {
										borderBottom: 0,
									},
									"&:last-child": {
										borderRadius: "0px 0px 8px 8px",
									},
									"&:before": {
										display: "none",
									},
								}}
							>
								<AccordionSummary
									expandIcon={DropdownIcon(
										16,
										16,
										"rgba(0, 0, 0, 0.6)"
									)}
								>
									<Stack
										direction="row"
										alignItems="center"
										justifyContent="space-between"
										spacing={0.5}
										width="100%"
										pr={1}
									>
										<Stack
											direction="row"
											alignItems="center"
											spacing={2}
											width="100%"
										>
											<img
												src={contactInfo.contactImage}
												width="20px"
												height="20px"
												alt="contact"
											/>
											<Typography fontSize={13}>
												{contactInfo.emailId}
											</Typography>
										</Stack>

										{expandedContact === contactInfo.id &&
											!contactInfo.newContact && (
												<IconButton
													size="small"
													style={{ padding: 0 }}
												>
													{ActivityIcon(
														16,
														18,
														theme.palette.primary
															.main
													)}
												</IconButton>
											)}
									</Stack>
								</AccordionSummary>

								<AccordionDetails style={{ paddingTop: 0 }}>
									{contactInfo.newContact ? (
										<Box>
											<Stack
												direction="row"
												alignItems="center"
												justifyContent="space-between"
												spacing={1}
												width="100%"
											>
												<Typography
													fontWeight={600}
													color={
														theme.palette.secondary
															.contrastText
													}
													pr={0.5}
												>
													{contactInfo.name}
												</Typography>

												<Button
													variant="outlined"
													size="small"
													style={{ height: "30px" }}
													onClick={OpenAddContactMenu}
												>
													Add
												</Button>
											</Stack>
										</Box>
									) : (
										<Box>
											<Stack
												direction="row"
												alignItems="center"
												spacing={1}
											>
												<Typography
													fontWeight={600}
													color={
														theme.palette.secondary
															.contrastText
													}
													pr={0.5}
												>
													{contactInfo.name}
												</Typography>

												<Box
													style={{
														...styles.socialMediaLogoContainer,
														backgroundColor:
															"#225982",
													}}
													onClick={() => {}}
												>
													<img
														src={LinkedinLogo}
														alt={"linkedin"}
														width="11px"
														height="11px"
													/>
												</Box>

												<Box
													style={{
														...styles.socialMediaLogoContainer,
														backgroundColor:
															"#4267B2",
													}}
													onClick={() => {}}
												>
													<img
														src={FacebookLogo}
														alt={"facebook"}
														width="11px"
														height="11px"
													/>
												</Box>
											</Stack>

											<Typography fontSize={14} pb={1}>
												{contactInfo.company}
											</Typography>

											<Stack
												direction="row"
												alignItems="center"
												spacing={1}
											>
												{CallIcon(
													16,
													16,
													theme.palette.secondary
														.contrastText
												)}

												<Typography
													fontSize={14}
													fontWeight={500}
													color={
														theme.palette.secondary
															.contrastText
													}
												>
													{contactInfo.phone}
												</Typography>
											</Stack>
										</Box>
									)}
								</AccordionDetails>
							</Accordion>
						))}
					</Box>
				</Box>

				<Box>
					<Typography fontSize={13} fontWeight={500} pl={0.5} pb={1}>
						Related Deal
					</Typography>

					{!showDeal ? (
						<Box
							style={{
								backgroundColor: "#fff",
								border: `1px solid ${theme.palette.divider}`,
								borderRadius: "8px",
							}}
							p={2}
						>
							<Button
								variant="outlined"
								startIcon={DealIcon(
									20,
									20,
									theme.palette.primary.main
								)}
								onClick={() => setShowDeal(true)}
							>
								Link to a Deal
							</Button>
						</Box>
					) : (
						<Box
							style={{
								backgroundColor: "#fff",
								border: `1px solid ${theme.palette.divider}`,
								borderRadius: "8px",
							}}
						>
							<Box p={2}>
								<Stack
									direction="row"
									alignItem="center"
									justifyContent="space-between"
								>
									<Typography
										fontSize={14}
										fontWeight={500}
										color={theme.palette.primary.main}
									>
										Deal for Vendor Management
									</Typography>

									<IconButton
										size="small"
										style={{ padding: 0 }}
										onClick={() => setShowDeal(false)}
									>
										{CloseIcon(
											20,
											20,
											theme.palette.primary.main
										)}
									</IconButton>
								</Stack>

								<Typography
									fontSize={12}
									color="rgba(0, 0, 0, 0.6)"
								>
									Needs Analysis
								</Typography>

								<Stack
									direction="row"
									alignItem="center"
									justifyContent="space-between"
									pt={2}
								>
									<Stack
										direction="row"
										alignItem="center"
										spacing={0.5}
									>
										<Box display="flex" pt={0.1}>
											{TimeIcon(
												16,
												16,
												theme.palette.error.main
											)}
										</Box>

										<Typography
											fontSize={13}
											fontWeight={500}
											color={theme.palette.error.main}
										>
											13 Mar (1m overdue)
										</Typography>
									</Stack>

									<Typography fontSize={13} fontWeight={500}>
										$ 1,440
									</Typography>
								</Stack>
							</Box>

							<Divider />

							<Box>
								<MenuItem
									style={{
										height: "40px",
										borderRadius: "0px 0px 8px 8px",
									}}
								>
									<Stack
										direction="row"
										alignItems="center"
										spacing={1}
									>
										<Box display="flex">
											{AddIcon(
												20,
												20,
												theme.palette.secondary
													.contrastText
											)}
										</Box>
										<Typography
											fontSize={13}
											fontWeight={500}
											color={
												theme.palette.secondary
													.contrastText
											}
										>
											Add Activity
										</Typography>
									</Stack>
								</MenuItem>
							</Box>
						</Box>
					)}
				</Box>
			</Stack>
		</React.Fragment>
	);
}
