import React, { useState } from "react";
import {
	Box,
	IconButton,
	MenuItem,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import Menu from "../../../Elements/Menu";
import MenuSearchBar from "../../../Elements/MenuSearchBar";
import { default as AddIcon } from "../../../../assets/icons/add";
import ContactImage from "../../../../assets/images/contact/contact2.png";

export default function AddParticipants(props) {
	const { participantsDropdownList, onChangeParticipantsMenu } = props;
	const theme = useTheme();

	const [participants, setParticipants] = useState([]);
	const [hoveredParticipantId, setHoveredParticipantId] = useState(null);
	const [searchedParticipantsValue, setSearchedParticipantsValue] =
		useState("");
	const [participantsMenuElement, setParticipantsMenuElement] =
		useState(null);
	const isParticipantsMenuOpened = Boolean(participantsMenuElement);

	const onMouseOverParticipantsList = (participant) => {
		setHoveredParticipantId(participant?.value);
	};

	const onMouseOutParticipantsList = () => {
		setHoveredParticipantId(null);
	};

	const openParticipantsMenu = (event) => {
		setParticipantsMenuElement(event.currentTarget);
		setSearchedParticipantsValue("");
		setParticipants([]);
	};

	const closeParticipantsMenu = () => {
		setParticipantsMenuElement(null);
	};

	const handleSearchParticipants = (event) => {
		const { value } = event.target;
		setSearchedParticipantsValue(value);

		let filteredParticipants = participantsDropdownList?.filter(
			(participant) =>
				participant.name.toLowerCase().startsWith(value.toLowerCase())
		);

		if (value) {
			setParticipants(filteredParticipants);
		} else {
			setParticipants([]);
		}
	};

	const handleAddParticipant = (participant) => {
		setSearchedParticipantsValue("");
		setParticipants([]);
		closeParticipantsMenu();
		onChangeParticipantsMenu(participant);
	};

	const renderEmptyView = () => {
		if (searchedParticipantsValue) {
			return (
				<Stack
					alignItems="center"
					justifyContent="center"
					style={{
						minHeight: "120px",
						minWidth: "200px",
					}}
				>
					<Typography fontSize={13} color="rgba(0, 0, 0, 0.6)">
						Match not found
					</Typography>
				</Stack>
			);
		}
		return (
			<Box
				style={{
					minHeight: "40px",
					minWidth: "250px",
				}}
			></Box>
		);
	};

	return (
		<React.Fragment>
			<Menu
				minWidth="250px"
				anchorEl={participantsMenuElement}
				open={isParticipantsMenuOpened}
				onClose={closeParticipantsMenu}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				sx={{ transform: "translateX(40px)" }}
			>
				<Box p={0.5}>
					<MenuSearchBar
						value={searchedParticipantsValue}
						onChange={handleSearchParticipants}
						placeholder="Enter Name"
					/>
				</Box>

				{participants?.length > 0 ? (
					<Box sx={{ maxHeight: "240px", overflowY: "auto" }}>
						{participants?.map((participant) => (
							<MenuItem
								key={participant?.value}
								sx={{
									height: "40px",
								}}
								onClick={() =>
									handleAddParticipant(participant)
								}
								onMouseOver={() =>
									onMouseOverParticipantsList(participant)
								}
								onMouseOut={onMouseOutParticipantsList}
							>
								<Stack
									direction="row"
									justifyContent="space-between"
									alignItems="center"
									width="100%"
								>
									<Stack
										direction="row"
										spacing={2}
										alignItems="center"
									>
										<img
											src={ContactImage}
											alt="contact_image"
											height="24px"
											width="24px"
										/>

										<Typography fontSize={13}>
											{participant?.name}
										</Typography>
									</Stack>

									{hoveredParticipantId ===
									participant?.value ? (
										<Box display="flex">
											{AddIcon(
												20,
												20,
												theme.palette.primary.main
											)}
										</Box>
									) : null}
								</Stack>
							</MenuItem>
						))}
					</Box>
				) : (
					renderEmptyView()
				)}
			</Menu>

			<Box>
				<IconButton size="small" onClick={openParticipantsMenu}>
					{AddIcon(20, 20, theme.palette.primary.main)}
				</IconButton>
			</Box>
		</React.Fragment>
	);
}
