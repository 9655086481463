import React, { useState } from "react";
import {
	Box,
	CircularProgress,
	IconButton,
	MenuItem,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	useTheme,
} from "@mui/material";
import {
	TableCellText,
	TableHeaderLabel,
	Tooltip,
	twozoStyles,
} from "../../../../styles/twozo";
import UnKnownImage from "../../../../assets/images/contact/unknownContact.png";
import { default as InfoIcon } from "../../../../assets/icons/info";
import { default as MoreIcon } from "../../../../assets/icons/more";
import { default as RenameIcon } from "../../../../assets/icons/edit";
import { default as PermissionIcon } from "../../../../assets/icons/custom";
import { default as AssignUserIcon } from "../../../../assets/icons/profileAdd";
import { default as DropDownIcon } from "../../../../assets/icons/dropDownCentered";
import { default as CallIcon } from "../../../../assets/icons/call";
import { default as MailIcon } from "../../../../assets/icons/mail";
import { default as DeleteIcon } from "../../../../assets/icons/delete";
import AssignUser from "./AssignUser";
import Menu from "../../../Elements/Menu";
import RenameRole from "./RenameRole";
import CustomSwipeableDrawer from "../../../Elements/CustomSwipeableDrawer";
import CreateRole from "./CreateRole";
import FullScreenDialog from "../../../Elements/FullScreenDialog";
import PermissionDialog from "./PermissionDialog";
import {
	useDeleteRole,
	useRolesUserList,
} from "../../../../hooks/services/userManagement/roles";
import { getDayMonthDateAndTimeFormat } from "../../../../utils/DateUtils";
import { useUserManagementContext } from "../UserManagementContext";
import { PERMISSIONS } from "../../../../utils/Auth";
import Can from "../../../Auth/Can";
import { PermissionProvider } from "./PermissionContext";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";

export default function Roles(props) {
	const {
		isCreateRoleDialogOpened,
		setIsCreateRoleDialogOpened,
		openRoleDialog,
	} = props;
	const theme = useTheme();
	const classes = twozoStyles();
	const deleteRoleMutation = useDeleteRole();

	const styles = {
		noPadding: {
			px: 0,
		},
	};

	//Roles Menu
	const [tableRowHovered, setTableRowHovered] = useState(null);
	const [hoveredRowId, setHoveredRowId] = useState(null);
	const [selectedRole, setSelectedRole] = useState("");

	const [rolesMenuElement, setRolesMenuElement] = useState(null);
	const isRolesMenuOpened = Boolean(rolesMenuElement);

	const [isRenameRole, setIsRenameRole] = useState(false);
	const [isDeletable, setIsDeletable] = useState(false);

	const onMouseOverTableRow = (role) => {
		setTableRowHovered(role.id);
		setIsRenameRole(role.isRenamable);
		setIsDeletable(role.isDeletable);
	};
	const onMouseOutTableRow = () => setTableRowHovered(null);

	//User Menu
	const [userMenuElement, setUserMenuElement] = useState(null);
	const isUserMenuOpened = Boolean(userMenuElement);
	const [userCardElement, setUserCardElement] = useState(null);
	const isUserCardOpened = Boolean(userCardElement);
	const [hoveredUserId, setHoveredUserId] = useState(null);
	const [rolesUserId, setRolesUserId] = useState("");
	const [selectedUser, setSelectedUser] = useState({});

	//Assign User
	const [openAssignUserDrawer, setOpenAssignUserDrawer] = useState(false);

	//Rename Role
	const [isRenameRoleDialogOpened, setIsRenameRoleDialogOpened] =
		useState(false);

	const {
		listData: rolesListData,
		isLoadingListData: isLoadingRolesListData,
		handleSort: handleSort,
	} = useUserManagementContext();
	const { data: usersList, isLoading } = useRolesUserList(rolesUserId);

	const openRolesMenu = (event, role) => {
		setRolesMenuElement(event.currentTarget);
		setHoveredRowId(role.id);
		setSelectedRole(role);
	};

	const closeRolesMenu = () => {
		setRolesMenuElement(null);
		setHoveredRowId(null);
	};

	const openUserMenu = (event, id) => {
		setRolesUserId(id);
		setUserMenuElement(event.currentTarget);
	};

	const closeUserMenu = () => {
		setUserMenuElement(null);
	};

	const openUserCard = (event, user) => {
		setUserCardElement(event.currentTarget);
		setSelectedUser(user);
	};

	const closeUserCard = () => {
		setUserCardElement(null);
	};

	const toggleAssignUserDrawer = () => {
		setRolesMenuElement(null);
		setHoveredRowId(null);
		setOpenAssignUserDrawer(
			(openAssignUserDrawer) => !openAssignUserDrawer
		);
	};

	const openRenameRoleDialog = () => {
		setIsRenameRoleDialogOpened(
			(isRenameRoleDialogOpened) => !isRenameRoleDialogOpened
		);
		setRolesMenuElement(null);
		setHoveredRowId(null);
	};

	const closeRenameRoleDialog = () => {
		setIsRenameRoleDialogOpened(
			(isRenameRoleDialogOpened) => !isRenameRoleDialogOpened
		);
		setSelectedRole("");
	};

	//Permission Dialog
	const [isPermissionDialogOpened, setIsPermissionDialogOpened] =
		useState(false);
	const [selectedAccess, setSelectedAccess] = useState("");

	const openPermissionDialog = () => {
		setRolesMenuElement(null);
		setHoveredRowId(null);
		setIsPermissionDialogOpened(true);
	};

	const closeRoleDialog = () => {
		setIsCreateRoleDialogOpened(false);
	};

	const closePermissionDialog = () => {
		setIsPermissionDialogOpened(false);
	};

	const handleAssignUser = (_, role) => {
		setSelectedRole(role);
	};

	const onNextClick = (roleIdToClone, roleName, access) => {
		setSelectedRole({
			name: roleName,
			id: roleIdToClone,
			isCreateRole: true,
		});
		setSelectedAccess(access);
		setIsCreateRoleDialogOpened(false);
		setIsPermissionDialogOpened(true);
	};

	const sortTypes = {
		ascending: "asc",
		decending: "desc",
	};

	const [tableHeader, setTableHeader] = useState([
		{
			id: 1,
			displayName: "Roles",
			name: "name",
			config: {
				width: "30%",
			},
		},
		{
			id: 2,
			displayName: "Users",
			name: "users",
			config: {
				width: "20%",
			},
		},
		{
			id: 3,
			displayName: "Created by",
			name: "createdBy",
			config: {
				width: "25%",
			},
		},
		{
			id: 4,
			displayName: "Updated by",
			name: "updatedBy",
			config: {
				width: "20%",
			},
		},
		{
			id: 5,
			displayName: "",
			config: {
				width: "5%",
			},
		},
	]);

	const isActionColumn = (index, tableHeader) => {
		return index === tableHeader.length - 1;
	};

	const toggleSort = (sortIndex) => {
		let sortRequest = {};

		if (tableHeader[sortIndex].sort === sortTypes.ascending) {
			sortRequest = {
				field: tableHeader[sortIndex].name,
				type: sortTypes.decending,
			};
		} else {
			sortRequest = {
				field: tableHeader[sortIndex].name,
				type: sortTypes.ascending,
			};
		}

		handleSort(sortRequest);

		setTableHeader((headers) => {
			const updatedHeaders = [...headers];

			updatedHeaders.forEach((column, index) => {
				if (index === sortIndex) {
					column.sort =
						column.sort === sortTypes.ascending
							? sortTypes.decending
							: sortTypes.ascending;
				} else {
					column.sort = sortTypes.decending;
				}
			});

			return updatedHeaders;
		});
	};

	//Role Delete
	const handleDeleteRole = () => {
		setRolesMenuElement(null);
		setHoveredRowId("");
		deleteRoleMutation.mutate(hoveredRowId, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.roleDeleted,
				});
			},
			onError: (error) => {
				let errorMessage = error.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage
						? errorMessage
						: notificationMessage.genericErrorMessage,
				});
			},
		});
	};

	return (
		<React.Fragment>
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isCreateRoleDialogOpened}
				onOpen={openRoleDialog}
				onClose={closeRoleDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<CreateRole
						closeRoleDialog={closeRoleDialog}
						onNextClick={onNextClick}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<PermissionProvider>
				<FullScreenDialog
					open={isPermissionDialogOpened && !isCreateRoleDialogOpened}
					onClose={closePermissionDialog}
					title={selectedRole?.name}
				>
					<PermissionDialog
						selectedRole={selectedRole?.id}
						isCreateRole={selectedRole?.isCreateRole}
						roleName={selectedRole?.name}
						defaultSelectedAccess={selectedAccess}
						isEditable={selectedRole?.isRenamable}
						onClose={closePermissionDialog}
					/>
				</FullScreenDialog>
			</PermissionProvider>

			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isRenameRoleDialogOpened}
				onOpen={openRenameRoleDialog}
				onClose={closeRenameRoleDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<RenameRole
						onClose={closeRenameRoleDialog}
						selectedRole={selectedRole}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={openAssignUserDrawer}
				onOpen={toggleAssignUserDrawer}
				onClose={toggleAssignUserDrawer}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box
					sx={{
						width: "50vw",
						minHeight: "100vh",
						backgroundColor: "#fff",
						borderRadius: "10px 0px 0px 10px",
					}}
				>
					<AssignUser
						onClose={toggleAssignUserDrawer}
						selectedRole={selectedRole}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<Menu
				minWidth="200px"
				anchorEl={rolesMenuElement}
				open={isRolesMenuOpened}
				onClose={closeRolesMenu}
				style={{ marginTop: "8px" }}
			>
				<Can permission={PERMISSIONS.role.edit}>
					<MenuItem
						sx={{ pl: 1, py: 0.5 }}
						onClick={openPermissionDialog}
					>
						<IconButton>
							{PermissionIcon(20, 20, theme.palette.primary.main)}
						</IconButton>
						<Typography
							fontSize={13}
							fontWeight={500}
							color={theme.palette.secondary.contrastText}
							pl={1}
						>
							Manage Permissions
						</Typography>
					</MenuItem>
				</Can>

				<MenuItem
					sx={{ pl: 1, py: 0.5 }}
					onClick={toggleAssignUserDrawer}
				>
					<IconButton>
						{AssignUserIcon(20, 20, theme.palette.primary.main)}
					</IconButton>
					<Typography
						fontSize={13}
						fontWeight={500}
						color={theme.palette.secondary.contrastText}
						pl={1}
					>
						Assign User
					</Typography>
				</MenuItem>

				<Can permission={PERMISSIONS.role.edit}>
					{isRenameRole && (
						<MenuItem
							sx={{ pl: 1, py: 0.5 }}
							onClick={() => openRenameRoleDialog()}
						>
							<IconButton>
								{RenameIcon(20, 20, theme.palette.primary.main)}
							</IconButton>
							<Typography
								fontSize={13}
								fontWeight={500}
								color={theme.palette.secondary.contrastText}
								pl={1}
							>
								Rename Role
							</Typography>
						</MenuItem>
					)}
				</Can>

				<Can permission={PERMISSIONS.role.delete}>
					{isDeletable && (
						<MenuItem
							sx={{ pl: 1, py: 0.5 }}
							onClick={() => handleDeleteRole()}
						>
							<IconButton>
								{DeleteIcon(20, 20, theme.palette.primary.main)}
							</IconButton>
							<Typography
								fontSize={13}
								fontWeight={500}
								color={theme.palette.secondary.contrastText}
								pl={1}
							>
								Delete
							</Typography>
						</MenuItem>
					)}
				</Can>
			</Menu>

			{/* userMenu */}
			<Menu
				minWidth="200px"
				anchorEl={userMenuElement}
				open={isUserMenuOpened}
				onClose={closeUserMenu}
			>
				{isLoading ? (
					<Stack
						minWidth="300px"
						justifyContent="center"
						alignItems="center"
					>
						<CircularProgress size="30px" />
					</Stack>
				) : (
					usersList?.map((user) => (
						<Stack
							key={user.id}
							minWidth="300px"
							minHeight="40px"
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							px={2}
							onMouseOver={() => setHoveredUserId(user.id)}
							onMouseOut={() => setHoveredUserId(null)}
							backgroundColor={
								user.id === hoveredUserId
									? "#F4F5F5"
									: "transparent"
							}
						>
							<Stack
								direction="row"
								alignItems="center"
								spacing={1}
							>
								<img
									src={UnKnownImage}
									alt="img"
									width={20}
									height={20}
								/>
								<Typography fontSize={13} pl={1}>
									{user.name}
								</Typography>
							</Stack>

							<IconButton
								onClick={(event) => openUserCard(event, user)}
							>
								{InfoIcon(16, 16, "rgba(0, 0, 0, 0.6)")}
							</IconButton>
						</Stack>
					))
				)}
			</Menu>

			{/* userCard */}
			<Menu
				minWidth="320px"
				anchorEl={userCardElement}
				open={isUserCardOpened}
				onClose={closeUserCard}
			>
				<Box px={2} py={1}>
					<Stack direction="row" spacing={2}>
						<Stack>
							<img
								src={UnKnownImage}
								alt="img"
								width={45}
								height={45}
							/>
						</Stack>

						<Stack>
							<Typography
								fontSize={18}
								fontWeight={600}
								color={theme.palette.secondary.contrastText}
							>
								{selectedUser.name}
							</Typography>

							{!!selectedUser.phone && (
								<Stack
									direction="row"
									alignItems="center"
									spacing={1}
									pt={0.5}
								>
									{CallIcon(
										16,
										16,
										theme.palette.primary.main
									)}

									<Typography
										fontSize={14}
										fontWeight={500}
										color={
											theme.palette.secondary.contrastText
										}
									>
										{selectedUser?.phone}
									</Typography>
								</Stack>
							)}

							{!!selectedUser.email && (
								<Stack
									direction="row"
									alignItems="center"
									spacing={1}
								>
									{MailIcon(
										16,
										16,
										theme.palette.primary.main
									)}

									<Typography
										fontSize={14}
										fontWeight={500}
										color={
											theme.palette.secondary.contrastText
										}
									>
										{selectedUser.email}
									</Typography>
								</Stack>
							)}
						</Stack>
					</Stack>
				</Box>
			</Menu>

			<Box style={{ height: "100%" }}>
				<Box pl={1} sx={{ height: "6%" }}>
					<Typography color="rgba(0, 0, 0, 0.6)" fontSize={14}>
						Assign Roles and permissions to different set of users
						from here.
					</Typography>
				</Box>

				<Box
					sx={{
						border: "1px solid rgba(0, 0, 0, 0.1)",
						borderRadius: "8px",
						height: "94%",
					}}
				>
					<TableContainer>
						<Table sx={{ minWidth: 650 }} size="small">
							<TableHead>
								<TableRow>
									{tableHeader.map((header, index) => (
										<TableCell
											key={header.id}
											width={header.config.width}
											sx={
												header.id === 2
													? styles.noPadding
													: {}
											}
											onClick={() => toggleSort(index)}
										>
											<Stack
												direction="row"
												alignItems="center"
												spacing={1}
											>
												<TableHeaderLabel>
													{header.displayName}
												</TableHeaderLabel>
												<Box display="flex">
													{!isActionColumn(
														index,
														tableHeader
													) ? (
														header.sort ===
														sortTypes.ascending ? (
															<Box
																style={{
																	transform:
																		"rotate(180deg)",
																}}
																pt={0.5}
															>
																{DropDownIcon(
																	13,
																	13,
																	"rgba(0, 0, 0, 0.6)"
																)}
															</Box>
														) : (
															DropDownIcon(
																13,
																13,
																"rgba(0, 0, 0, 0.6)"
															)
														)
													) : (
														""
													)}
												</Box>
											</Stack>
										</TableCell>
									))}
								</TableRow>
							</TableHead>

							<TableBody>
								{isLoadingRolesListData ? (
									<TableRow
										style={{
											height: "60vh",
										}}
									>
										<TableCell
											colSpan={5}
											sx={{ borderBottom: 0 }}
										>
											<Stack
												height="100%"
												justifyContent="center"
												alignItems="center"
												width="100%"
											>
												<CircularProgress />
											</Stack>
										</TableCell>
									</TableRow>
								) : (
									rolesListData?.map((role) => (
										<TableRow
											key={role.id}
											onMouseOver={() =>
												onMouseOverTableRow(role)
											}
											onMouseOut={onMouseOutTableRow}
										>
											<TableCell>
												<TableCellText>
													{role.name}
												</TableCellText>
											</TableCell>

											<TableCell
												sx={{ px: 0 }}
												onClick={
													role.usersCount > 0
														? (event) =>
																openUserMenu(
																	event,
																	role.id
																)
														: toggleAssignUserDrawer
												}
											>
												<TableCellText
													fontWeight={500}
													color={
														theme.palette.secondary
															.contrastText
													}
													onClick={(event) =>
														handleAssignUser(
															event,
															role
														)
													}
												>
													{role.usersCount
														? `${role.usersCount} User`
														: "+ Assign User"}
												</TableCellText>
											</TableCell>

											<TableCell>
												{!!role.createdBy && (
													<Tooltip
														title={getDayMonthDateAndTimeFormat(
															role.createdTime
														)}
														placement="right"
													>
														<Stack
															direction="row"
															alignItems="center"
															spacing={1}
															width="fit-content"
														>
															<img
																src={
																	UnKnownImage
																}
																alt="img"
																width={26}
																height={26}
															/>

															<TableCellText>
																{
																	role
																		.createdBy
																		.name
																}
															</TableCellText>
														</Stack>
													</Tooltip>
												)}
											</TableCell>

											<TableCell>
												{!!role.updatedBy && (
													<Tooltip
														title={getDayMonthDateAndTimeFormat(
															role.updatedTime
														)}
														placement="right"
													>
														<Stack
															direction="row"
															alignItems="center"
															spacing={1}
															width="fit-content"
														>
															<img
																src={
																	UnKnownImage
																}
																alt="img"
																width={26}
																height={26}
															/>

															<TableCellText>
																{
																	role
																		.updatedBy
																		.name
																}
															</TableCellText>
														</Stack>
													</Tooltip>
												)}
											</TableCell>

											<TableCell sx={{ px: 0, py: 0 }}>
												<Stack alignItems="center">
													<Box
														hidden={
															hoveredRowId !==
																role.id &&
															tableRowHovered !==
																role.id
														}
														style={{
															width: "fit-content",
															border: `1px solid ${theme.palette.primary.main}`,
															borderRadius: "8px",
															padding: "2px 8px",
														}}
													>
														<IconButton
															size="small"
															onClick={(event) =>
																openRolesMenu(
																	event,
																	role
																)
															}
														>
															{MoreIcon(
																20,
																20,
																theme.palette
																	.primary
																	.main
															)}
														</IconButton>
													</Box>
												</Stack>
											</TableCell>
										</TableRow>
									))
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
			</Box>
		</React.Fragment>
	);
}
