import {
	Stack,
	ToggleButton,
	Box,
	Divider,
	Typography,
	useTheme,
} from "@mui/material";
import React, { useState } from "react";
import ToggleButtonGroup from "../../Elements/ToggleButtonGroup";
import DuplicateContact from "./DuplicateContact";
import DuplicateCompany from "./DuplicateCompany";
import DropDownRight from "../../../assets/icons/dropDownRight";
import { twozoStyles } from "../../../styles/twozo";
import { useNavigate } from "react-router-dom";

export default function ManageDuplicates(props) {
	const { moduleName, navigateURL } = props;
	const theme = useTheme();
	const navigate = useNavigate();
	const classes = twozoStyles();
	const [type, setType] = useState("Contact");

	const handleViewToggleButtonGroup = (e, type) => {
		if (type) {
			setType(type);
		}
	};

	return (
		<React.Fragment>
			<Box className={classes.menuBar}>
				<Stack
					height="100%"
					direction="row"
					alignItems="center"
					spacing={1}
				>
					<Box
						pl={1}
						onClick={() => navigate(navigateURL)}
						style={{ cursor: "pointer" }}
					>
						<Typography
							fontWeight={500}
							color={theme.palette.secondary.contrastText}
						>
							{moduleName}
						</Typography>
					</Box>

					{DropDownRight(16, 16)}

					<Typography fontWeight={500}>Manage Duplicates</Typography>
				</Stack>
			</Box>

			<Divider />

			<Box pt={2}>
				<Stack spacing={2}>
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="center"
					>
						<ToggleButtonGroup
							value={type}
							onChange={handleViewToggleButtonGroup}
						>
							<ToggleButton value="Contact">
								Contacts
							</ToggleButton>
							<ToggleButton value="Company">
								Companies
							</ToggleButton>
						</ToggleButtonGroup>
					</Stack>
					<Stack>
						{(function () {
							switch (type) {
								case "Contact":
									return <DuplicateContact />;
								case "Company":
									return <DuplicateCompany />;
								default:
									return null;
							}
						})()}
					</Stack>
				</Stack>
			</Box>
		</React.Fragment>
	);
}
