import { assertError } from "../apiHelper";
import { getFileUrl } from "../apiConfig";
import { apiClient } from "..";

export const getFiles = async (data) => {
	let requestData = getFilesRequest(data);
	return await postFileManagementApi(requestData);
};

export const uploadFilesData = async (data) => {
	let requestData = getUploadFilesRequest(data);
	return await postFileUploadManagementApi(requestData);
};

export const deleteFile = async (id) => {
	let requestData = getDeleteRequest(id);
	return await postFileManagementApi(requestData);
};

const getFilesRequest = (data) => {
	return {
		type: "getFiles",
		data: data,
	};
};

const getUploadFilesRequest = (data) => {
	return {
		type: "upload",
		entityId: data.id,
		source: data.source,
		data: {
			attachments: data.files,
		},
	};
};

const getDeleteRequest = (id) => {
	return {
		type: "delete",
		data: {
			id: id,
		},
	};
};

const postFileManagementApi = async (requestData) => {
	let fileManagementUrl = getFileManagementUrl();
	let response = await apiClient.post(fileManagementUrl, requestData);
	assertError(response, fileManagementUrl);
	return response.data.data;
};

const postFileUploadManagementApi = async (requestData) => {
	let fileManagementUrl = getFileManagementUrl();
	let response = await apiClient.post(fileManagementUrl, requestData, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
	assertError(response);
	return response.data.data;
};

const getFileManagementUrl = () => {
	return getFileUrl() + "/mgmt";
};
