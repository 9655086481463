import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
	getUserListKey,
	getUserDataKey,
	getUserTableDataWithSortKey,
	getUserSummaryKey,
	getUserAuditLogKey,
	getUserToDoAcitivityKey,
	getUserCompletedActivityKey,
	getUserDetailsKey,
	getUserTableDataKey,
} from "../../../../utils/queryKeys/userManagement/user";
import {
	activateUser,
	addUser,
	deactivateUser,
	getAuditlog,
	getUserData,
	getUserDetailsList,
	getUserList,
	getUserSummaryData,
	getUserTableData,
	transferUserRecords,
	updateUser,
	userPartialBulkUpdate,
	userReinvite,
} from "../../../../api/userManagement/user/userApi";
import { getUserActivities } from "../../../../api/activity/activityApi";

const useUserTableData = (stateId, searchValue, fieldToSort) => {
	return useQuery(
		getUserTableDataWithSortKey(stateId, searchValue, fieldToSort),
		() => getUserTableData(stateId, searchValue, fieldToSort)
	);
};

const useUserDetailsList = () => {
	let userDetailsListKey = getUserDetailsKey();
	return useQuery(userDetailsListKey, () => getUserDetailsList(), {
		select: (data) => data.users,
	});
};

const useUserList = () => {
	return useQuery(getUserListKey(), () => getUserList(), {
		select: (userList) => userList?.options,
	});
};

const useUserData = (id) => {
	return useQuery(getUserDataKey(id), () => getUserData(id));
};

const useUserSummaryData = (id) => {
	return useQuery(getUserSummaryKey(id), () => getUserSummaryData(id));
};

const useAuditlogData = (id) => {
	return useQuery(getUserAuditLogKey(id), () => getAuditlog(id), {
		select: (auditlogData) => auditlogData?.list,
	});
};

const useUserToDoActivities = (id) => {
	let isDone = false;
	return useQuery(
		getUserToDoAcitivityKey(id),
		() => getUserActivities(id, isDone),
		{
			select: (userActivities) => userActivities?.activities,
		}
	);
};

const useUserCompletedActivties = (id) => {
	let isDone = true;
	return useQuery(
		getUserCompletedActivityKey(id),
		() => getUserActivities(id, isDone),
		{
			select: (userActivities) => userActivities?.activities,
		}
	);
};

const useInvalidateUserList = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(getUserTableDataKey());
	};
};

const useInvalidateUserDropdownList = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(getUserListKey());
	};
};

const useUserActivateMutation = () => {
	let invalidateUserList = useInvalidateUserList();
	let invalidateUserDropdownList = useInvalidateUserDropdownList();
	return useMutation(activateUser, {
		onSuccess: () => {
			invalidateUserList();
			invalidateUserDropdownList();
		},
	});
};

const useAddUserMutation = () => {
	let invalidateUserList = useInvalidateUserList();
	return useMutation(addUser, {
		onSuccess: () => {
			invalidateUserList();
		},
	});
};

const useUpdateUserMutation = () => {
	let invalidateUserList = useInvalidateUserList();
	return useMutation(updateUser, {
		onSuccess: () => {
			invalidateUserList();
		},
	});
};

const useUserPartialBulkUpdate = () => {
	let invalidateUserList = useInvalidateUserList();
	return useMutation(userPartialBulkUpdate, {
		onSuccess: () => {
			invalidateUserList();
		},
	});
};

const useTransferUserRecordMutation = () => {
	let invalidateUserList = useInvalidateUserList();
	let invalidateUserDropdownList = useInvalidateUserDropdownList();
	return useMutation(transferUserRecords, {
		onSuccess: () => {
			invalidateUserList();
			invalidateUserDropdownList();
		},
	});
};

const useDeactivateUserMutation = () => {
	let invalidateUserList = useInvalidateUserList();
	let invalidateUserDropdownList = useInvalidateUserDropdownList();
	return useMutation(deactivateUser, {
		onSuccess: () => {
			invalidateUserList();
			invalidateUserDropdownList();
		},
	});
};

const useUserReinviteMutation = () => {
	return useMutation(userReinvite);
};

export {
	useUserTableData,
	useUserList,
	useAddUserMutation,
	useUserData,
	useUpdateUserMutation,
	useUserSummaryData,
	useAuditlogData,
	useUserToDoActivities,
	useUserCompletedActivties,
	useUserPartialBulkUpdate,
	useUserDetailsList,
	useTransferUserRecordMutation,
	useDeactivateUserMutation,
	useUserReinviteMutation,
	useUserActivateMutation,
};
