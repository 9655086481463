import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import EmailRecipientInput from "../EmailRecipientInput";

export default function BCCAddress(props) {
	const { recipients, value, handleRecipientsChange } = props;

	return (
		<React.Fragment>
			<Stack direction="row" alignItems="center" px={2} spacing={2}>
				<Box>
					<Typography fontSize={13} noWrap>
						Bcc :
					</Typography>
				</Box>

				<Box flexGrow={1}>
					<EmailRecipientInput
						recipients={recipients || []}
						value={value}
						handleRecipientsChange={handleRecipientsChange}
					/>
				</Box>
			</Stack>
		</React.Fragment>
	);
}
