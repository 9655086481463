import React, { useState } from "react";
import Files from "../../../Summary/Files";
import {
	getContactFilesKey,
	getContactTimelineKey,
} from "../../../../utils/queryKeys";

export default function ContactFiles({ contactId }) {
	const [isAddFileDialogOpened, setIsAddFileDialogOpened] = useState(false);
	const contactFileKey = getContactFilesKey(contactId);
	const contactTimelineKey = getContactTimelineKey(contactId);

	return (
		<React.Fragment>
			<Files
				isAddFileDialogOpened={isAddFileDialogOpened}
				setIsAddFileDialogOpened={setIsAddFileDialogOpened}
				fileKey={contactFileKey}
				timelineKey={contactTimelineKey}
				entityId={contactId}
				source="Contact"
			/>
		</React.Fragment>
	);
}
