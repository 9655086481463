import React from "react";
import Dialog from "../../../Elements/Dialog";
import { Box, Button, Typography, alpha, Stack, useTheme } from "@mui/material";
import { default as CloseIcon } from "../../../../assets/icons/close";

export default function AddFile(props) {
	const {
		isAddFileDialogOpened,
		onCloseAddFileDialog,
		onSaveAddedFiles,
		onFilesChange,
		addedFiles,
		isSaveButtonDisabled,
	} = props;
	const theme = useTheme();

	const styles = {
		uploadFileName: {
			backgroundColor: alpha(theme.palette.primary.main, 0.12),
			padding: "4px 8px",
			borderRadius: "8px",
			alignItems: "center",
			justifyContent: "center",
			cursor: "pointer",
		},
		button: {
			"&:active": {
				color: alpha("#FFFFFF", 0.6),
			},
		},
	};

	const handleAddFile = (event) => {
		if (event.target.files) {
			let updatedFiles = [...addedFiles, ...event.target.files];
			onFilesChange(updatedFiles);
		}
	};

	const handleRemoveFile = (index) => {
		let updatedFiles = addedFiles.filter(
			(_, addedFileIndex) => addedFileIndex !== index
		);
		onFilesChange(updatedFiles);
	};

	const isSaveButtonDisable = () => {
		return addedFiles?.length === 0 || isSaveButtonDisabled;
	};

	return (
		<React.Fragment>
			<Dialog open={isAddFileDialogOpened} title="Add Files">
				<Box p={2}>
					<Stack spacing={1}>
						<Stack
							sx={{
								width: "100%",
								height: "144px",
								border: "1px solid rgba(0,0,0,0.1)",
								borderRadius: "8px",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<Button
								variant="text"
								disableElevation
								component="label"
							>
								<Typography
									sx={{
										color: alpha("#000000", 0.6),
										fontSize: "13px",
									}}
								>
									Drop your files here to add to this contact
								</Typography>
								<input
									hidden
									type="file"
									onChange={(event) => handleAddFile(event)}
									multiple
								/>
							</Button>
						</Stack>

						{addedFiles?.length > 0 ? (
							<Stack
								direction="row"
								spacing={0.5}
								flexWrap="wrap"
							>
								{addedFiles?.map((file, index) => (
									<Stack
										sx={styles.uploadFileName}
										direction="row"
										spacing={0.5}
										key={index}
									>
										<Stack>
											<Typography
												fontSize={13}
												style={{
													wordBreak: "break-word",
												}}
											>
												{file.name}
											</Typography>
										</Stack>
										<Stack
											onClick={() =>
												handleRemoveFile(index)
											}
										>
											{CloseIcon(16, 16, "#000000")}
										</Stack>
									</Stack>
								))}
							</Stack>
						) : null}

						<Box p={1}>
							<Stack
								direction="row"
								justifyContent="flex-end"
								spacing={2}
							>
								<Button
									onClick={onCloseAddFileDialog}
									variant="contained"
									color="secondary"
									disableElevation
								>
									Cancel
								</Button>

								<Button
									variant="contained"
									onClick={() => onSaveAddedFiles(addedFiles)}
									disableElevation
									disabled={isSaveButtonDisable()}
									sx={{
										"&.Mui-disabled": {
											backgroundColor:
												theme.palette.primary.main,
											color: "rgba(255, 255, 255, 0.6)",
										},
									}}
								>
									Save
								</Button>
							</Stack>
						</Box>
					</Stack>
				</Box>
			</Dialog>
		</React.Fragment>
	);
}
