import React, { useState } from "react";
import {
	Box,
	Checkbox,
	IconButton,
	MenuItem,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import ContactProfileImage from "../../../../assets/images/contact/unknownContact.png";
import { default as InfoIcon } from "../../../../assets/icons/info";
import { default as MoreIcon } from "../../../../assets/icons/more";
import DeleteDialog from "../../../Elements/DeleteDialog";
import { default as DealsIcon } from "../../../../assets/icons/deals";
import { default as CompanyIcon } from "../../../../assets/icons/company";
import { default as RoundCheckBoxOffIcon } from "../../../../assets/icons/roundCheckBoxOff";
import { default as RoundCheckBoxOnIcon } from "../../../../assets/icons/roundCheckBoxOn";
import { getIconByName } from "../../../../utils";
import EditActivity from "../EditActivity";
import { Tooltip, twozoStyles } from "../../../../styles/twozo";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
	deleteActivity,
	partialUpdateActivity,
} from "../../../../api/activity/activityApi";
import Menu from "../../../Elements/Menu";
import {
	getHourAndMinuteFormat,
	getMonthAndDateFormat,
} from "../../../../utils/DateUtils";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import CustomSwipeableDrawer from "../../../Elements/CustomSwipeableDrawer";
import ContactCard from "../../../Elements/ContactCard";
import { useNavigate } from "react-router-dom";
import Can from "../../../Auth/Can";
import { PERMISSIONS } from "../../../../utils/Auth";

export default function ActivitiesList(props) {
	const {
		activities,
		toDoActivitiesKey,
		completedActivitiesKey,
		summaryKey,
	} = props;
	const theme = useTheme();
	const classes = twozoStyles();
	const queryClient = useQueryClient();
	const navigate = useNavigate();

	const [selectedContactDetails, setSelectedContactDetails] = useState({});
	const [contactCardMenuElement, setContactCardMenuElement] = useState(null);
	const isContactCardOpened = Boolean(contactCardMenuElement);
	const [contactListMenuElement, setContactListMenuElement] = useState(null);
	const isContactListMenuOpened = Boolean(contactListMenuElement);
	const [supplementaryContacts, setSupplementaryContacts] = useState([]);
	const [isDeleteActivityDialogOpened, setIsDeleteActivityDialogOpened] =
		useState(false);
	const [isEditActivityDialogOpened, setIsEditActivityDialogOpened] =
		useState(false);
	const [selectedActivity, setSelectedActivity] = useState({});
	const [activityMenuElement, setActivityMenuElement] = useState(null);
	const isActivityMenuOpened = Boolean(activityMenuElement);
	const [hoveredActivityId, setHoveredActivityId] = useState("");
	const successNotificationTitle = "Success!";

	const maximumContactLimit = 5;
	const styles = {
		text: {
			fontSize: "14px",
			fontWeight: 500,
		},
		subText: {
			fontSize: "14px",
			fontWeight: 400,
		},
		smallText: {
			fontSize: "13px",
			fontWeight: 400,
		},
		coloredText: {
			fontSize: "14px",
			fontWeight: 500,
			color: theme.palette.secondary.contrastText,
		},
		activityMenu: {
			fontSize: "13px",
			fontWeight: 500,
			color: theme.palette.primary.main,
		},
		ownerText: {
			fontSize: "14px",
			color: "rgba(0, 0, 0, 0.6)",
			fontWeight: 400,
		},
	};

	const updateActivityStatus = (activity) => {
		updateMutation.mutate({ id: activity.id, isDone: !activity.isDone });
	};

	const updateMutation = useMutation(
		async (updateActivity) => partialUpdateActivity(updateActivity),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(toDoActivitiesKey);
				queryClient.invalidateQueries(completedActivitiesKey);
				if (summaryKey) {
					queryClient.invalidateQueries(summaryKey);
				}
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.activityUpdated,
				});
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.errorMessage,
				});
			},
		}
	);

	const openActivityMenu = (event, activity) => {
		setActivityMenuElement(event.currentTarget);
		setSelectedActivity(activity);
	};

	const toggleEditActivityDialog = () => {
		setIsEditActivityDialogOpened(
			(isEditActivityDialogOpened) => !isEditActivityDialogOpened
		);
		setActivityMenuElement(null);
	};

	const closeActivityMenu = () => {
		setActivityMenuElement(null);
		setSelectedActivity({});
	};

	const handleOpenDeleteActivityDialog = () => {
		setIsDeleteActivityDialogOpened(true);
		setActivityMenuElement(null);
	};

	const handleDeleteActivity = () => {
		deleteMutation.mutate(selectedActivity.id);
	};

	const closeDeleteActivityDialog = () => {
		setIsDeleteActivityDialogOpened(false);
		setActivityMenuElement(null);
	};

	const deleteMutation = useMutation(
		async (activityId) => deleteActivity(activityId),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(toDoActivitiesKey);
				queryClient.invalidateQueries(completedActivitiesKey);
				enqueueSnackbar({
					variant: notificationVariants.error,
					title: successNotificationTitle,
					message: notificationMessage.activityDeleted,
				});
				setIsDeleteActivityDialogOpened(false);
				setActivityMenuElement(null);
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.errorMessage,
				});
			},
		}
	);

	const getActivityStatus = (activity) => {
		return activity.isDone ? "Mark as not Completed" : "Mark as Completed";
	};

	const openContactDetailsMenu = (event, contactData) => {
		setContactCardMenuElement(event.currentTarget);
		setSelectedContactDetails(contactData);
	};

	const closeContactDetailsMenu = () => {
		setContactCardMenuElement(null);
		setSelectedContactDetails({});
	};

	const openContactListMenu = (event, contacts) => {
		setContactListMenuElement(event.currentTarget);
		setSupplementaryContacts(contacts?.slice(maximumContactLimit));
	};

	const closeContactListMenu = () => {
		setContactListMenuElement(null);
		setSupplementaryContacts([]);
	};

	const navigateToCompanyDetails = (companyId) => {
		navigate(`/companies/${companyId?.toString()}`);
	};

	const navigateToDealDetails = (dealId) => {
		navigate(`/deals/${dealId?.toString()}`);
	};

	const openEditActivityDrawer = (activityData) => {
		setIsEditActivityDialogOpened(true);
		setSelectedActivity(activityData);
	};

	const onMouseOverActivityTitle = (activityData) => {
		setHoveredActivityId(activityData?.id);
	};

	const onMouseOutActivityTitle = () => {
		setHoveredActivityId("");
	};

	const getTextDecoration = (activity) => {
		if (activity?.isDone) {
			return "line-through";
		} else if (hoveredActivityId === activity?.id) {
			return "underline";
		} else {
			return "none";
		}
	};

	return (
		<React.Fragment>
			<Menu
				minWidth="320px"
				anchorEl={contactCardMenuElement}
				open={isContactCardOpened}
				onClose={closeContactDetailsMenu}
				style={{
					marginTop: "6px",
				}}
			>
				<ContactCard contactDetails={selectedContactDetails} />
			</Menu>

			{/*  ContactsMenu  */}
			<Menu
				minWidth="250px"
				anchorEl={contactListMenuElement}
				open={isContactListMenuOpened}
				onClose={closeContactListMenu}
			>
				{supplementaryContacts?.map((contact) => (
					<MenuItem
						key={contact?.id}
						sx={{
							height: "40px",
						}}
					>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							width="100%"
						>
							<Stack
								direction="row"
								spacing={2}
								alignItems="center"
							>
								<img
									src={ContactProfileImage}
									alt="contact_image"
									height="24px"
									width="24px"
								/>

								<Typography fontSize={13}>
									{contact?.fullName}
								</Typography>
							</Stack>

							<Box
								onClick={(event) =>
									openContactDetailsMenu(event, contact)
								}
								display="flex"
							>
								{InfoIcon(16, 16, "rgba(0, 0, 0, 0.6)")}
							</Box>
						</Stack>
					</MenuItem>
				))}
			</Menu>

			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isEditActivityDialogOpened}
				onOpen={toggleEditActivityDialog}
				onClose={toggleEditActivityDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<EditActivity
						onClose={toggleEditActivityDialog}
						toDoActivitesKey={toDoActivitiesKey}
						completedActivitesKey={completedActivitiesKey}
						activityId={selectedActivity?.id}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<DeleteDialog
				open={isDeleteActivityDialogOpened}
				onDelete={handleDeleteActivity}
				onCancel={closeDeleteActivityDialog}
				title={`Are You sure You Want to Delete Activity - ${selectedActivity.title}?`}
				subtitle="This will delete all the related details associated with this"
			/>

			<Menu
				minWidth="160px"
				anchorEl={activityMenuElement}
				open={isActivityMenuOpened}
				onClose={closeActivityMenu}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				sx={{ transform: "translateX(30px)" }}
				style={{
					marginTop: "2px",
				}}
			>
				<MenuItem
					style={{ height: "40px" }}
					onClick={toggleEditActivityDialog}
				>
					<Typography style={styles.activityMenu}>Edit</Typography>
				</MenuItem>
				<MenuItem
					style={{ height: "40px" }}
					onClick={handleOpenDeleteActivityDialog}
				>
					<Typography style={styles.activityMenu}>Delete</Typography>
				</MenuItem>
			</Menu>

			<table
				style={{
					width: "100%",
					borderCollapse: "collapse",
				}}
			>
				<tbody>
					{activities?.map((activity, index) => (
						<tr
							style={{
								borderBottom:
									activities.length === index + 1
										? "none"
										: "1px solid rgba(0, 0, 0, 0.1)",
								height: "72px",
								cursor: "pointer",
							}}
							key={index}
						>
							<td
								style={{
									width: "5%",
									padding: "8px 8px 26px 16px",
								}}
							>
								<Tooltip
									placement="top"
									title={getActivityStatus(activity)}
								>
									<Checkbox
										icon={RoundCheckBoxOffIcon(20, 20)}
										checkedIcon={RoundCheckBoxOnIcon(
											20,
											20,
											theme.palette.primary.main
										)}
										checked={activity.isDone}
										onClick={() =>
											updateActivityStatus(activity)
										}
									/>
								</Tooltip>
							</td>

							<td
								style={{
									width: "11%",
									opacity: activity?.isDone ? 0.6 : 1,
									paddingRight: "24px",
								}}
							>
								<Stack height="100%">
									<Typography
										style={styles.text}
										color={
											activity?.isOverDue
												? theme.palette.error.main
												: "#000"
										}
										textAlign="end"
									>
										{getMonthAndDateFormat(
											activity.startTime
										)}
									</Typography>

									<Typography
										style={styles.smallText}
										color={
											activity?.isOverDue
												? theme.palette.error.main
												: "#000"
										}
										textAlign="end"
									>
										{getHourAndMinuteFormat(
											activity.startTime
										)}
									</Typography>
								</Stack>
							</td>

							<td
								style={{
									width: "5%",
									paddingBottom: "12px",
									opacity: activity?.isDone ? 0.6 : 1,
								}}
							>
								<Box height="100%">
									<IconButton size="small">
										{getIconByName(
											activity.activityType?.icon?.name
										)(
											20,
											20,
											activity?.isOverDue
												? theme.palette.error.main
												: theme.palette.secondary
														.contrastText
										)}
									</IconButton>
								</Box>
							</td>

							<td
								width="80%"
								style={{
									paddingBottom: !activity?.note?.note
										? "18px"
										: "0px",
								}}
							>
								<Box height="100%">
									<Stack width="100%" direction="row">
										<Stack
											width="50%"
											direction="row"
											alignItems="center"
										>
											<Box
												onClick={() =>
													openEditActivityDrawer(
														activity
													)
												}
												onMouseOver={() =>
													onMouseOverActivityTitle(
														activity
													)
												}
												onMouseOut={() =>
													onMouseOutActivityTitle(
														activity
													)
												}
											>
												<Typography
													style={styles.text}
													component="span"
													sx={{
														textDecoration:
															getTextDecoration(
																activity
															),
														color: activity?.isOverDue
															? theme.palette
																	.error.main
															: "#000",
													}}
												>
													{activity.title}
												</Typography>
											</Box>

											{activity?.assignee && (
												<Typography
													style={styles.ownerText}
													component="span"
													pl={0.5}
												>
													- {activity?.assignee?.name}
												</Typography>
											)}
										</Stack>

										<Stack
											width="20%"
											direction="row"
											alignItems="center"
											style={{
												opacity: activity?.isDone
													? 0.6
													: 1,
											}}
										>
											<Can
												permission={
													PERMISSIONS.deal.view
												}
											>
												{!!activity?.deal && (
													<Stack
														direction="row"
														alignItems="center"
														spacing={0.5}
														onClick={() =>
															navigateToDealDetails(
																activity.deal
																	?.id
															)
														}
													>
														<Box display="flex">
															{DealsIcon(
																18,
																18,
																theme.palette
																	.secondary
																	.contrastText
															)}
														</Box>

														<Typography
															style={
																styles.coloredText
															}
														>
															{
																activity.deal
																	?.title
															}
														</Typography>
													</Stack>
												)}
											</Can>
										</Stack>

										<Stack
											width="20%"
											direction="row"
											alignItems="center"
											spacing={0.5}
											style={{
												opacity: activity?.isDone
													? 0.6
													: 1,
											}}
										>
											<Can
												permission={
													PERMISSIONS.contact.view
												}
											>
												{activity.contacts?.length > 0
													? activity.contacts.map(
															(contact, index) =>
																index <
																maximumContactLimit ? (
																	<Box
																		display="flex"
																		key={
																			index
																		}
																		onClick={(
																			event
																		) =>
																			openContactDetailsMenu(
																				event,
																				contact
																			)
																		}
																		style={{
																			marginRight:
																				"-12px",
																		}}
																	>
																		<img
																			src={
																				ContactProfileImage
																			}
																			alt="profile"
																			width="26px"
																			height="26px"
																			style={{
																				border: "1px solid #ffffff",
																				borderRadius:
																					"100%",
																			}}
																		/>
																	</Box>
																) : null
														)
													: null}
											</Can>

											<Box
												pl={1}
												onClick={(event) =>
													openContactListMenu(
														event,
														activity?.contacts
													)
												}
											>
												{activity?.contacts?.length >
												maximumContactLimit ? (
													<Typography
														fontSize={14}
														fontWeight={500}
														color={
															theme.palette
																.secondary
																.contrastText
														}
													>
														{`+${
															activity.contacts
																.length -
															maximumContactLimit
														}`}
													</Typography>
												) : null}
											</Box>
										</Stack>

										<Can
											permission={
												PERMISSIONS.company.view
											}
										>
											<Stack
												width="20%"
												direction="row"
												alignItems="center"
												style={{
													opacity: activity?.isDone
														? 0.6
														: 1,
												}}
											>
												{!!activity?.company && (
													<Stack
														direction="row"
														alignItems="center"
														spacing={0.5}
														onClick={() =>
															navigateToCompanyDetails(
																activity.company
																	?.id
															)
														}
													>
														{CompanyIcon(
															18,
															18,
															theme.palette
																.secondary
																.contrastText
														)}

														<Typography
															style={
																styles.coloredText
															}
														>
															{
																activity.company
																	?.name
															}
														</Typography>
													</Stack>
												)}
											</Stack>
										</Can>

										<Stack
											alignItems="end"
											width="10%"
											borderRadius="100%"
										>
											<Box>
												<IconButton
													size="small"
													onClick={(event) =>
														openActivityMenu(
															event,
															activity
														)
													}
													sx={{
														background:
															"transparent",
														borderRadius: "100%",
														p: 0,
														mr: 2,
													}}
												>
													{MoreIcon(
														20,
														20,
														"#000000",
														0.6
													)}
												</IconButton>
											</Box>
										</Stack>
									</Stack>

									<Typography
										style={styles.subText}
										width="90%"
										color={
											activity?.isOverDue
												? theme.palette.error.main
												: "#000"
										}
									>
										{activity?.note?.note}
									</Typography>
								</Box>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</React.Fragment>
	);
}
