import { Routes, Route } from "react-router-dom";
import { history } from "./../history";
import Home from "./../components/Home";
import FourNotFour from "./../components/FourNotFour";
import Layout from "./../components/Layout";
import Contacts from "./../components/Contacts";
import Companies from "./../components/Companies";
import Products from "./../components/Products";
import Settings from "./../components/Settings";
import Sent from "./../components/Mail/Sent";
import Scheduled from "./../components/Mail/Scheduled";
import Starred from "./../components/Mail/Starred";
import Drafts from "./../components/Mail/Drafts";
import Trash from "./../components/Mail/Trash";
import Archive from "./../components/Mail/Archive";
import ContactsSummary from "./../components/Contacts/Summary";
import CompaniesSummary from "./../components/Companies/Summary";
import ProductDetails from "./../components/Products/ProductDetails";
import ActivitiesSetting from "./../components/Settings/Company/Activities";
import DealsSummary from "./../components/Deals/Summary";
import UserSummary from "./../components/Settings/UserManagement/Users/Summary";
import WebForm from "./../components/Settings/WebForm";
import WebFormBuilder from "./../components/Settings/WebForm/WebFormBuilder";
import EmailSync from "./../components/Settings/Email/Sync";
import Mail from "./../components/Mail";
import Inbox from "./../components/Mail/Inbox";
import AwaitingResponse from "./../components/Mail/AwaitingResponse";
import TeamInbox from "./../components/Mail/TeamInbox";
import EmailTemplateLanding from "../components/Mail/EmailTemplateLanding";
import Metrics from "./../components/Mail/BulkEmail/Metrics";
import BulkScheduled from "./../components/Mail/BulkEmail/Scheduled";
import BulkDraft from "./../components/Mail/BulkEmail/Draft";
import Opens from "./../components/Mail/Emailtracking/Opens";
import Clicks from "./../components/Mail/Emailtracking/Clicks";
import Bounces from "./../components/Mail/Emailtracking/Bounces";
import Activities from "./../components/Activities";
import Reports from "./../components/Reports";
import AdminSettingsTeamInbox from "./../components/Settings/TeamInbox";
import Tags from "./../components/Settings/Tags";
import TermOfService from "./../components/TermsOfService";
import PrivacyPolicy from "./../components/PrivacyPolicy";
import WorkflowAutomation from "./../components/Settings/WorkflowAutomation";
import Workflow from "./../components/Workflow";
import AccountSettings from "./../components/Settings/AccountSettings";
import Currency from "./../components/Settings/Currency";
import ReportsList from "./../components/Reports/ReportsList";
import EditWebForm from "./../components/Settings/WebForm/EditWebForm";
import ReportView from "./../components/Reports/ReportView";
import { UserManagement } from "./../components/Settings/UserManagement";
import ManageDuplicatesCompany from "./../components/Companies/ManageDuplicates";
import ManageDuplicatesContact from "./../components/Contacts/ManageDuplicates";
import Deals from "./../components/Deals";
import DataFieldsLanding from "./../components/Settings/DataFieldsLanding";
import UnAuthorizedRoutePage from "../components/Auth/UnAuthorizedRoutePage";
import SetPassword from "../components/SetPassword";
import ProtectedRoute from "./ProtectedRoute";
import { PERMISSIONS } from "../utils/Auth";
import Signup from "../components/Home/Signup";
import ForgotPassword from "../components/Home/Login/ForgotPassword";
import CheckInbox from "../components/Home/Login/CheckInbox";
import Login from "../components/Home/Login";

export const Router = (props) => {
	return (
		<Routes history={history}>
			<Route path="/">
				<Route index element={<Home />} />
				<Route path="login" element={<Login />} />
				<Route path="signup" element={<Signup />} />
				<Route path="forgot-password" element={<ForgotPassword />} />
				<Route path="check-inbox" element={<CheckInbox />} />

				<Route path="terms-of-service" element={<TermOfService />} />
				<Route path="privacy-policy" element={<PrivacyPolicy />} />
				<Route path="setPassword" element={<SetPassword />} />

				<Route
					path="*"
					element={
						<ProtectedRoute
							permission={
								PERMISSIONS.allowlistedEntity
									.allowlistedPermission
							}
						>
							<Layout {...props} history={history} />
						</ProtectedRoute>
					}
				>
					<Route
						path="unauthorized"
						element={<UnAuthorizedRoutePage />}
					/>
					<Route path="deals">
						<Route
							index
							element={
								<ProtectedRoute
									permission={PERMISSIONS.deal.view}
								>
									<Deals />
								</ProtectedRoute>
							}
						/>
						<Route
							path=":dealId"
							element={
								<ProtectedRoute
									permission={PERMISSIONS.deal.view}
								>
									<DealsSummary />
								</ProtectedRoute>
							}
						/>
					</Route>

					<Route path="contacts">
						<Route
							index
							element={
								<ProtectedRoute
									permission={PERMISSIONS.contact.view}
								>
									<Contacts />
								</ProtectedRoute>
							}
						/>
						<Route
							path=":contactId"
							element={
								<ProtectedRoute
									permission={PERMISSIONS.contact.view}
								>
									<ContactsSummary />
								</ProtectedRoute>
							}
						/>
						<Route
							path="duplicate"
							element={<ManageDuplicatesContact />}
						/>
					</Route>

					<Route path="companies">
						<Route
							index
							element={
								<ProtectedRoute
									permission={PERMISSIONS.company.view}
								>
									<Companies />
								</ProtectedRoute>
							}
						/>
						<Route
							path=":companyId"
							element={
								<ProtectedRoute
									permission={PERMISSIONS.company.view}
								>
									<CompaniesSummary />
								</ProtectedRoute>
							}
						/>
						<Route
							path="duplicate"
							element={<ManageDuplicatesCompany />}
						/>
					</Route>

					<Route path="activities" element={<Activities />} />

					<Route path="products">
						<Route
							index
							element={
								<ProtectedRoute
									permission={PERMISSIONS.product.view}
								>
									<Products />
								</ProtectedRoute>
							}
						/>

						<Route
							path=":productId"
							element={
								<ProtectedRoute
									permission={PERMISSIONS.product.view}
								>
									<ProductDetails />
								</ProtectedRoute>
							}
						/>
					</Route>

					<Route path="settings">
						<Route index element={<Settings />} />
						<Route
							path="datafields"
							element={<DataFieldsLanding />}
						/>
						<Route
							path="workflow-management"
							element={<WorkflowAutomation />}
						/>
						<Route
							path="account-settings"
							element={<AccountSettings />}
						/>
						<Route
							path="workflow/:workflowId"
							element={<Workflow />}
						/>
						<Route path="user-management">
							<Route index element={<UserManagement />} />
							<Route path=":userId" element={<UserSummary />} />
						</Route>
						<Route
							path="company/activities"
							element={
								<ProtectedRoute
									permission={PERMISSIONS.activityType.view}
								>
									<ActivitiesSetting />
								</ProtectedRoute>
							}
						/>
						<Route path="webform">
							<Route index element={<WebForm />} />
							<Route
								path=":webformId"
								element={<EditWebForm />}
							/>
							<Route
								path="createwebform"
								element={<WebFormBuilder />}
							/>
						</Route>
						<Route path="email" element={<EmailSync />} />
						<Route
							path="team-inbox"
							element={<AdminSettingsTeamInbox />}
						/>
						<Route path="tags" element={<Tags />} />
						<Route path="currency" element={<Currency />} />
					</Route>

					<Route
						path="mail"
						element={<Mail {...props} history={history} />}
					>
						<Route path="inbox" element={<Inbox />} />
						<Route
							path="awaiting-response"
							element={<AwaitingResponse />}
						/>
						<Route path="sent" element={<Sent />} />
						<Route path="scheduled" element={<Scheduled />} />
						<Route path="starred" element={<Starred />} />
						<Route path="drafts" element={<Drafts />} />
						<Route path="archive" element={<Archive />} />
						<Route path="trash" element={<Trash />} />
						<Route path="team-inbox" element={<TeamInbox />} />
						<Route
							path="email-templates"
							element={<EmailTemplateLanding />}
						/>
						<Route path="bulk-email">
							<Route path="metrics" element={<Metrics />} />
							<Route
								path="scheduled"
								element={<BulkScheduled />}
							/>
							<Route path="drafts" element={<BulkDraft />} />
						</Route>
						<Route path="email-tracking">
							<Route path="opens" element={<Opens />} />
							<Route path="clicks" element={<Clicks />} />
							<Route path="bounces" element={<Bounces />} />
						</Route>
						<Route path="*" element={<FourNotFour />} />
					</Route>

					<Route
						path="reports"
						element={<Reports {...props} history={history} />}
					>
						<Route path=":reportName" element={<ReportsList />} />
						<Route
							path=":reportName/:reportId"
							element={<ReportView />}
						/>
						<Route path="*" element={<FourNotFour />} />
					</Route>
					<Route path="*" element={<FourNotFour />} />
				</Route>
			</Route>
		</Routes>
	);
};
