import { useQuery } from "@tanstack/react-query";
import {
	getCompanyCompletedActivityKey,
	getCompanyToDoAcitivityKey,
} from "../../../../utils/queryKeys";
import { getActivitiesData } from "../../../../api/activity/activityApi";
import { modules } from "../../../../utils/common/ModulesName";

let companySource = modules.COMPANY;

const useCompanyToDoActivities = (companyId, activityTypeIdsToFilter) => {
	let isDone = false;
	const companyToDoActivityKey = getCompanyToDoAcitivityKey(
		companyId,
		activityTypeIdsToFilter
	);
	return useQuery(
		companyToDoActivityKey,
		() =>
			getActivitiesData(
				companyId,
				isDone,
				companySource,
				activityTypeIdsToFilter
			),
		{
			select: (data) => data?.activities,
		}
	);
};

const useCompanyCompletedActivities = (companyId, activityTypeIdsToFilter) => {
	let isDone = true;
	const companyCompletedActivityKey = getCompanyCompletedActivityKey(
		companyId,
		activityTypeIdsToFilter
	);
	return useQuery(
		companyCompletedActivityKey,
		() =>
			getActivitiesData(
				companyId,
				isDone,
				companySource,
				activityTypeIdsToFilter
			),
		{
			select: (data) => data?.activities,
		}
	);
};

export { useCompanyToDoActivities, useCompanyCompletedActivities };
