import { Divider, Stack, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { SummaryFieldName, twozoStyles } from "../../../../../../styles/twozo";
import { useNavigate } from "react-router-dom";
import Menu from "../../../../Menu";
import Can from "../../../../../Auth/Can";
import { PERMISSIONS } from "../../../../../../utils/Auth";
import CanNot from "../../../../../Auth/CanNot";

export default function ProductComponent(props) {
	const { summaryField } = props;
	const classes = twozoStyles();
	const theme = useTheme();
	const navigate = useNavigate();

	const [isFieldClicked, setIsFieldClicked] = useState(false);
	const [isProductFieldHovered, setIsProductFieldHovered] = useState(false);
	const [productMenuElement, setProductMenuElement] = useState(null);
	const isProductMenuElement = Boolean(productMenuElement);

	const openProductMenu = (event) => {
		setProductMenuElement(event.currentTarget);
		setIsFieldClicked(true);
	};

	const closeProductMenu = () => {
		setProductMenuElement(null);
		setIsFieldClicked(false);
	};

	return (
		<React.Fragment>
			<Menu
				minWidth="350px"
				anchorEl={productMenuElement}
				open={isProductMenuElement}
				onClose={closeProductMenu}
				style={{ marginTop: "8px" }}
				sx={{
					"& .MuiMenu-list": {
						paddingTop: "4px",
						paddingBottom: "4px",
					},
				}}
			>
				{summaryField.value?.items?.map((productDetail, index) => (
					<Stack key={productDetail.id}>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							width="100%"
							height="40px"
							px={2}
						>
							<Typography
								color={theme.palette.secondary.contrastText}
								fontSize={13}
								fontWeight={500}
								style={{
									cursor: "pointer",
								}}
								onClick={() =>
									navigate(
										`/products/${productDetail.product.id}`
									)
								}
							>
								{productDetail.product.name}
							</Typography>

							<Typography fontSize={13} fontWeight={500}>
								{productDetail.symbol} {productDetail.price}
							</Typography>
						</Stack>
						{index < summaryField.value.items.length - 1 && (
							<Divider />
						)}
					</Stack>
				))}
			</Menu>

			<td>
				<Typography fontSize={14} className={classes.summaryHead}>
					{summaryField.fieldName} :
				</Typography>
			</td>

			<Can permission={PERMISSIONS.product.view}>
				<td
					style={{
						padding: 0,
						display: "flex",
						alignItems: "center",
					}}
				>
					{summaryField?.value?.count > 0 ? (
						<SummaryFieldName
							onClick={openProductMenu}
							className={classes.summaryData}
							color={theme.palette.secondary.contrastText}
							style={{
								cursor: "pointer",
								borderBottom:
									isProductFieldHovered || isFieldClicked
										? "1px solid rgba(0, 0, 0, 0.2)"
										: "none",
							}}
							onMouseOver={() => setIsProductFieldHovered(true)}
							onMouseLeave={() => setIsProductFieldHovered(false)}
						>
							{summaryField.value.count}{" "}
							{summaryField.value.count === 1
								? "Product"
								: "Products"}
						</SummaryFieldName>
					) : (
						<SummaryFieldName>-</SummaryFieldName>
					)}
				</td>
			</Can>

			<CanNot permission={PERMISSIONS.product.view}>
				<td
					style={{
						display: "flex",
						alignItems: "center",
					}}
				>
					<Typography
						fontSize={14}
						fontWeight={500}
						style={{
							opacity: 0.4,
							cursor: "not-allowed",
						}}
					>
						Access Denied
					</Typography>
				</td>
			</CanNot>
		</React.Fragment>
	);
}
