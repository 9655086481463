import React from "react";
import { TableCellText } from "../../../../styles/twozo";
import { getStatusProperties } from "../../../../utils/ActivitiesUtils";
import CanNot from "../../../Auth/CanNot";
import { PERMISSIONS } from "../../../../utils/Auth";
import Can from "../../../Auth/Can";

export default function Contact(props) {
	const { data, rowState } = props;

	if (!data) {
		return null;
	}

	return (
		<React.Fragment>
			<Can permission={PERMISSIONS.contact.view}>
				<TableCellText color={getStatusProperties(rowState).textColor}>
					{data?.firstName} {data?.lastName}
				</TableCellText>
			</Can>

			<CanNot permission={PERMISSIONS.contact.view}>
				<TableCellText>-</TableCellText>
			</CanNot>
		</React.Fragment>
	);
}
