import React, { useEffect, useState } from "react";
import {
	Box,
	Divider,
	IconButton,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { default as DeleteIcon } from "../../../assets/icons/delete";
import ConditionList from "./ConditionList";
import { getCriteriaFieldsKey } from "../../../utils/queryKeys/workflow";
import { useQuery } from "@tanstack/react-query";
import { getCriteriaFieldsData } from "../../../api/workflow/workflowApi";

export default function Condition(props) {
	const {
		styles,
		triggerSourceId,
		triggerEventId,
		deleteAction,
		index,
		conditionSetData,
		orConditionSetData,
	} = props;
	const theme = useTheme();
	const [activityFields, setActivityFields] = useState([]);
	const [comparatorsData, setComparatorsData] = useState({});
	const [orConditionSet, setOrConditionSet] = useState(
		orConditionSetData || []
	);
	const [conditionSet, setConditionSet] = useState(conditionSetData || []);

	const criteriaFieldsKey = getCriteriaFieldsKey(triggerSourceId);
	const { data: criteriaFieldsData } = useQuery(
		criteriaFieldsKey,
		() => getCriteriaFieldsData(triggerSourceId, triggerEventId),
		{ staleTime: 600000 }
	);

	useEffect(() => {
		if (criteriaFieldsData && criteriaFieldsData.totalRecords) {
			setActivityFields(criteriaFieldsData.criteriaFields);
			setComparatorsData(criteriaFieldsData.comparator);
		}
	}, [criteriaFieldsData]);

	const addCondition = () => {
		setConditionSet((conditionSet) => [
			...conditionSet,
			{
				comparator: {},
				field: {},
				connector: 2,
				value: [],
			},
		]);
	};

	const deleteCondition = (index) => {
		if (conditionSet.length > 1) {
			setConditionSet((conditionSet) => [
				...conditionSet.slice(0, index),
				...conditionSet.slice(index + 1),
			]);
		}
	};

	const addOrCondition = () => {
		setOrConditionSet((orConditionSet) => [
			...orConditionSet,
			{
				comparator: {},
				field: {},
				connector: 2,
				value: [],
			},
		]);
	};

	const deleteOrCondition = (index) => {
		setOrConditionSet((orConditionSet) => [
			...orConditionSet.slice(0, index),
			...orConditionSet.slice(index + 1),
		]);
	};

	const onDelete = () => {
		deleteAction(index);
	};

	return (
		<React.Fragment>
			<Box
				style={{
					border: "1px solid rgba(0, 0, 0, 0.12)",
					borderRadius: "8px",
					minWidth:
						conditionSet.length > 1 || orConditionSet.length > 0
							? "700px"
							: "500px",
				}}
			>
				<Stack
					direction="row"
					justifyContent="space-between"
					py={1.5}
					px={2}
				>
					<Typography style={styles.title}>Set Condition</Typography>

					<IconButton
						size="small"
						style={{
							padding: 0,
						}}
						onClick={onDelete}
					>
						{DeleteIcon(20, 20, theme.palette.error.main)}
					</IconButton>
				</Stack>

				<Divider />

				<Box p={2} pb={1}>
					<ConditionList
						conditionSet={conditionSet}
						addCondition={addCondition}
						deleteCondition={deleteCondition}
						triggerEventId={triggerEventId}
						activityFields={activityFields}
						comparatorsData={comparatorsData}
						hideNewConditionSetButton={orConditionSet.length > 0}
						addNewConditionSet={addOrCondition}
					/>
				</Box>

				{orConditionSet.length > 0 && (
					<>
						<Divider />

						<Stack direction="row" alignItems="center">
							<Typography
								fontSize={14}
								fontWeight={500}
								color={theme.palette.secondary.contrastText}
								p={2}
							>
								OR
							</Typography>

							<Divider orientation="vertical" flexItem />

							<Box p={2} pb={1}>
								<ConditionList
									conditionSet={orConditionSet}
									addCondition={addOrCondition}
									deleteCondition={deleteOrCondition}
									triggerEventId={triggerEventId}
									activityFields={activityFields}
									comparatorsData={comparatorsData}
									hideNewConditionSetButton={true}
									addNewConditionSet={addOrCondition}
								/>
							</Box>
						</Stack>
					</>
				)}
			</Box>
		</React.Fragment>
	);
}
