import React, { useEffect, useRef, useState } from "react";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { default as InfoIcon } from "../../../../../assets/icons/info";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import LexicalAutoLinkPlugin from "../Plugin/AutoLinkPlugin";
import LexicalClickableLinkPlugin from "@lexical/react/LexicalClickableLinkPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { $getRoot, $getSelection, $insertNodes } from "lexical";
import { $generateNodesFromDOM } from "@lexical/html";
import {
	usePlaceHolderFieldsWithValues,
	useTemplateDataWithValues,
} from "../../../../../hooks/services/mail/emailTemplate";
import { modules } from "../../../../../utils/common/ModulesName";
import { hexToRgba } from "../../../../../styles/twozo";
import PlaceHoldersMenu from "../ToolBar/PlaceHoldersMenu";
import TemplateMenu from "../ToolBar/TemplateMenu";
import ToolBar from "../ToolBar";

export default function RichTextEditor(props) {
	const {
		onChangeBodyContent,
		composerFooterRef,
		toAddress,
		onUpdateSubject,
		showComposerError,
		onUpdateComposerError,
		subject,
	} = props;
	const composerInputRef = useRef();
	const theme = useTheme();
	const [editor] = useLexicalComposerContext();

	const [menuBarHeight, setMenuBarHeight] = useState(0);
	const [isPlaceHolderMenuOpened, setIsPlaceHolderMenuOpened] =
		useState(false);
	const [isTempleteMenuOpened, setIsTemplateMenuOpened] = useState(false);
	const [selectedTemplateId, setSelectedTemplateId] = useState("");
	const [isReplacableTemplateData, setIsReplacableTemplateData] =
		useState(false);

	const { data: placeHolderFieldsValueForContact } =
		usePlaceHolderFieldsWithValues(
			modules.CONTACT.toLowerCase(),
			toAddress?.[0]?.value
		);
	const { data: placeHolderFieldsValueForCompany } =
		usePlaceHolderFieldsWithValues(
			modules.COMPANY.toLowerCase(),
			toAddress?.[0]?.value
		);
	const { data: placeHolderFieldsValueForDeal } =
		usePlaceHolderFieldsWithValues(
			modules.DEAL.toLowerCase(),
			toAddress?.[0]?.value
		);

	const firstRecipientId = toAddress?.[0]?.value;
	const { data: templateDataWithValue } = useTemplateDataWithValues(
		selectedTemplateId,
		firstRecipientId
	);

	useEffect(() => {
		if (templateDataWithValue && isReplacableTemplateData) {
			onUpdateSubject(templateDataWithValue?.subject);

			editor.update(() => {
				$getRoot().clear();
				const parser = new DOMParser();

				const dom = parser.parseFromString(
					templateDataWithValue?.content,
					"text/html"
				);
				const nodes = $generateNodesFromDOM(editor, dom);
				$getRoot().select();
				$insertNodes(nodes);
			});
			setIsReplacableTemplateData(false);
			setSelectedTemplateId("");
		}
	}, [templateDataWithValue, isReplacableTemplateData]);

	useEffect(() => {
		if (!composerFooterRef?.current) return;

		const resizeObserver = new ResizeObserver(() => {
			setMenuBarHeight(
				composerFooterRef?.current?.getBoundingClientRect()?.height +
					composerInputRef?.current?.getBoundingClientRect()?.height
			);
		});

		resizeObserver.observe(composerFooterRef.current);
		return () => resizeObserver.disconnect(); // clean up
	}, []);

	const openPlaceHolderMenu = () => {
		setIsPlaceHolderMenuOpened(true);
		closeTemplateMenu();
	};

	const closePlaceHolderMenu = () => {
		setIsPlaceHolderMenuOpened(false);
	};

	const getPlaceHolderValue = (placeHolderFieldsValue, placeHolderField) => {
		let selectedPlaceholderValue = placeHolderFieldsValue?.find(
			(field) => field?.id === placeHolderField?.id
		);
		if (selectedPlaceholderValue?.value) {
			return selectedPlaceholderValue?.value;
		}
		return "";
	};

	const onSelectPlaceHolder = (placeHolderField, placeHolderSource) => {
		if (toAddress.length > 1) {
			onUpdateComposerError();
		} else {
			let placeHolderFieldValue = "";
			if (placeHolderSource === modules.CONTACT) {
				let selectedPlaceholderValue = getPlaceHolderValue(
					placeHolderFieldsValueForContact?.fields,
					placeHolderField
				);
				placeHolderFieldValue = selectedPlaceholderValue;
			} else if (placeHolderSource === modules.COMPANY) {
				let selectedPlaceholderValue = getPlaceHolderValue(
					placeHolderFieldsValueForCompany?.fields,
					placeHolderField
				);
				placeHolderFieldValue = selectedPlaceholderValue;
			} else if (placeHolderSource === modules.DEAL) {
				let selectedPlaceholderValue = getPlaceHolderValue(
					placeHolderFieldsValueForDeal?.fields,
					placeHolderField
				);
				placeHolderFieldValue = selectedPlaceholderValue;
			}
			editor.update(() => {
				const selection = $getSelection();
				if (selection) {
					selection.insertText(placeHolderFieldValue);
				}
			});
		}
	};

	const openTemplateMenu = () => {
		setIsTemplateMenuOpened(true);
		closePlaceHolderMenu();
	};

	const closeTemplateMenu = () => {
		setIsTemplateMenuOpened(false);
	};

	const onApplyTemplate = (templateData, isReplacable, templateId) => {
		if (templateData?.content) {
			editor.update(() => {
				$getRoot().clear();
				const parser = new DOMParser();

				const dom = parser.parseFromString(
					templateData.content,
					"text/html"
				);
				const nodes = $generateNodesFromDOM(editor, dom);
				$getRoot().select();
				$insertNodes(nodes);
			});
		}
		setSelectedTemplateId(templateId);
		setIsReplacableTemplateData(isReplacable);
		onUpdateSubject(templateData?.subject);
	};

	return (
		<React.Fragment>
			<Box>
				<ToolBar
					onClickPlaceHolder={openPlaceHolderMenu}
					onClickTemplates={openTemplateMenu}
					focusTemplateIcon={isTempleteMenuOpened}
					focusPlaceHolderIcon={isPlaceHolderMenuOpened}
				/>
			</Box>

			{showComposerError ? (
				<Stack
					direction="row"
					style={{
						backgroundColor: hexToRgba(
							theme.palette.error.main,
							0.2
						),
						height: "60px",
						borderRadius: "8px",
						margin: "16px",
						padding: "0px 16px",
					}}
					alignItems="center"
					spacing={2}
				>
					<Box>{InfoIcon(20, 20, theme.palette.error.main)}</Box>

					<Typography fontSize={14}>
						You cannot use placeholders with multiple direct
						recipients. Please use Cc or Bcc to send a copy to other
						recipients.
					</Typography>
				</Stack>
			) : null}

			<Box style={{ position: "relative" }} ref={composerInputRef}>
				<Box>
					<RichTextPlugin
						contentEditable={
							<ContentEditable
								style={{
									height: "350px",
									padding: "12px",
									overflowY: "auto",
									outline: "none",
									fontSize: "14px",
									fontFamily: "Lexend",
								}}
							/>
						}
						ErrorBoundary={LexicalErrorBoundary}
					/>
					<OnChangePlugin onChange={onChangeBodyContent} />
					<AutoFocusPlugin />
					<TabIndentationPlugin />
					<HistoryPlugin />
					<LexicalAutoLinkPlugin />
					<LexicalClickableLinkPlugin />
				</Box>

				<Box
					style={{
						position: "absolute",
						top: 0,
						bottom: 0,
						left: 0,
						right: 0,
						alignContent: "flex-end",
						justifySelf: "end",
						borderLeft: `1px solid ${theme.palette.divider}`,
						backgroundColor: "#fff",
						height: `${menuBarHeight}px`,
						zIndex: 2,
					}}
					hidden={!isPlaceHolderMenuOpened && !isTempleteMenuOpened}
				>
					{isPlaceHolderMenuOpened ? (
						<PlaceHoldersMenu
							closePlaceHolderMenu={closePlaceHolderMenu}
							onSelectPlaceHolder={onSelectPlaceHolder}
						/>
					) : null}

					{isTempleteMenuOpened ? (
						<TemplateMenu
							closeTemplateMenu={closeTemplateMenu}
							firstRecipientId={firstRecipientId}
							onApplyTemplate={onApplyTemplate}
							subject={subject}
						/>
					) : null}
				</Box>
			</Box>
		</React.Fragment>
	);
}
