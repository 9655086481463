import { Box } from "@mui/material";
import FieldEdit from "../../../../../FieldEdit";
import { Typography, useTheme } from "@mui/material";
import React, { useMemo } from "react";
import {
	twozoStyles,
	SummaryFieldName,
} from "../../../../../../../styles/twozo";
import MonetaryFieldEditForm from "../../../../../FieldEditFormComponents/MonetaryFieldEditForm";
import { useSummaryContext } from "../../../../../../Summary/SummaryContext";

export default function MonetaryFieldValue(params) {
	const { summaryField, subFields } = params;
	const theme = useTheme();
	const classes = twozoStyles();

	// Summary Context:-
	const { updateSummaryField, entityId } = useSummaryContext();

	const getSubFieldObject = (subFieldName) => {
		let subFieldObj;
		if (!!subFields && Array.isArray(subFields)) {
			subFields.forEach((field) => {
				if (field.name === subFieldName) {
					subFieldObj = field;
				}
			});
		}

		return subFieldObj;
	};

	const monetaryValueField = getSubFieldObject("VALUE");

	const getMonetaryRequestData = (monetaryValue) => {
		return {
			[summaryField?.fieldId]: {
				...monetaryValue,
			},
			id: entityId,
		};
	};

	const hasValueOrIsChanged = (monetaryValue) => {
		if (summaryField.value) {
			const { PRICE, CURRENCY } = summaryField.value;
			return (
				(monetaryValue[PRICE?.id] !== PRICE?.value &&
					!!monetaryValue[PRICE?.id]) ||
				(monetaryValue[CURRENCY?.id] !== CURRENCY?.value &&
					!!monetaryValue[CURRENCY?.id])
			);
		} else {
			return !!monetaryValue[monetaryValueField?.id];
		}
	};

	const handleSave = (monetaryValue, onClose) => {
		if (hasValueOrIsChanged(monetaryValue)) {
			let requestData = getMonetaryRequestData(monetaryValue);
			updateSummaryField(requestData, onClose);
		} else {
			onClose();
		}
	};

	const monetaryFieldLabel = (onFieldClicked) => {
		return (
			<Box onClick={onFieldClicked}>
				{summaryField?.value ? (
					<SummaryFieldName className={classes.summaryData}>
						{summaryField.value?.CURRENCY?.symbol}{" "}
						{summaryField.value?.PRICE?.value}
					</SummaryFieldName>
				) : summaryField.config.readOnly ? (
					<SummaryFieldName style={{ opacity: 0.6 }}>
						-
					</SummaryFieldName>
				) : (
					<Typography
						sx={{
							"&:hover": {
								borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
							},
						}}
						fontSize="14px"
						fontWeight={500}
						style={{
							color: theme.palette.secondary.contrastText,
							opacity: "0.4",
						}}
						width="150px"
					>
						Click To Enter
					</Typography>
				)}
			</Box>
		);
	};

	const modifiedMonetaryStructure = useMemo(() => {
		if (summaryField.value) {
			const { CURRENCY, PRICE } = summaryField.value;
			return {
				[CURRENCY?.id]: CURRENCY?.value,
				[PRICE?.id]: PRICE?.value,
			};
		}
		return "";
	}, [summaryField]);

	return (
		<FieldEdit field={summaryField} fieldLabel={monetaryFieldLabel}>
			<MonetaryFieldEditForm
				onSave={handleSave}
				field={summaryField}
				subFields={subFields}
				value={modifiedMonetaryStructure}
			/>
		</FieldEdit>
	);
}
