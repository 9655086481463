import { apiClient } from "../..";
import { getAppUrl } from "../../apiConfig";
import { assertError } from "../../apiHelper";

export const getNotificationsData = async () => {
	let requestData = getNotificationsRequestData();
	return await postNotificationApi(requestData);
};

export const getNotificationAlertState = async () => {
	let requestData = getNotificationAlertStateRequestData();
	return await postNotificationApi(requestData);
};

export const getReminderNotificationData = async () => {
	let requestData = getReminderNotificationRequestData();
	return await postNotificationApi(requestData);
};

export const updateNotificationAlertState = async (notificationState) => {
	let requestData =
		getUpdateNotificationAlertStateRequestData(notificationState);
	return await postNotificationApi(requestData);
};

export const updateMarkAllAsRead = async () => {
	let requestData = getUpdateMarkAllAsReadRequestData();
	return await postNotificationApi(requestData);
};

export const getPendingNotification = async () => {
	let requestData = getPendingNotificationRequestData();
	return await postNotificationApi(requestData);
};

export const updatePendingNotification = async () => {
	let requestData = getUpdatePendingNotificationRequestData();
	return await postNotificationApi(requestData);
};

export const getTopNotifications = async () => {
	let requestData = getTopNotificationsRequestData();
	return await postNotificationApi(requestData);
};

export const updateMarkAsRead = async (notificationId) => {
	let requestData = getUpdateMarkAsReadRequestData(notificationId);
	return await postNotificationApi(requestData);
};

const getNotificationsRequestData = () => {
	return {
		type: "get",
		data: {},
	};
};

const getNotificationAlertStateRequestData = () => {
	return {
		type: "getState",
		data: {},
	};
};

const getReminderNotificationRequestData = () => {
	return {
		type: "reminderNotifications",
		data: {},
	};
};

const getUpdateNotificationAlertStateRequestData = (notificationState) => {
	return {
		type: "updateState",
		data: {
			notificationState: notificationState,
		},
	};
};

const getUpdateMarkAllAsReadRequestData = () => {
	return {
		type: "markAllAsRead",
		data: {},
	};
};

const getPendingNotificationRequestData = () => {
	return {
		type: "pendingNotification",
		data: {},
	};
};

const getUpdatePendingNotificationRequestData = () => {
	return {
		type: "updatePendingNotification",
		data: {},
	};
};

const getTopNotificationsRequestData = () => {
	return {
		type: "getTopNotifications",
		data: {},
	};
};

const getUpdateMarkAsReadRequestData = (notificationId) => {
	return {
		type: "markAsRead",
		data: {
			id: notificationId,
		},
	};
};

const postNotificationApi = async (requestData) => {
	let appNotificationUrl = getAppNotificationUrl();
	let response = await apiClient.post(appNotificationUrl, requestData);
	assertError(response, appNotificationUrl);
	return response.data.data;
};

const getAppNotificationUrl = () => {
	return getAppUrl() + "/notification";
};
