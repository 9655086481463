import { Box, Button, Stack, Grid, CircularProgress } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import React, { useState, useEffect } from "react";
import Row from "../AddForm/Row";
import { useGroupedFormFields } from "../../../hooks/services/common/field";

export default function GroupFieldForm({
	moduleName,
	onSave,
	onClose,
	fieldId,
	formData,
	isSaving,
	isGroupFieldDataLoading,
}) {
	const [fields, setFields] = useState([]);
	const [subFields, setSubFields] = useState([]);

	const formMethods = useForm({
		values: formData,
		reValidateMode: false, // validation triggered only when the form Submit
	});

	const {
		status: formFieldsAPIstatus,
		isLoading: isLoadingFormFields,
		data: formFieldsData,
	} = useGroupedFormFields(moduleName, fieldId);

	useEffect(() => {
		if (formFieldsAPIstatus === "success") {
			if (formFieldsData.fields) {
				setFields(formFieldsData.fields);
			}

			if (formFieldsData.subFields) {
				setSubFields(formFieldsData.subFields);
			}
		}
	}, [formFieldsData, formFieldsAPIstatus]);

	return (
		<React.Fragment>
			{isLoadingFormFields || isGroupFieldDataLoading ? (
				<Stack
					alignItems="center"
					justifyContent="center"
					height="244px"
					width="400px"
				>
					<CircularProgress size="30px" />
				</Stack>
			) : (
				<Box
					style={{
						maxHeight: "404px",
						width: "400px",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<FormProvider {...formMethods}>
						<Box
							p={2}
							style={{
								flex: "1 1 auto",
								minHeight: 0,
								overflowY: "auto",
							}}
						>
							<Grid
								container
								direction="row"
								justifyContent="flex-start"
								alignItems="baseline"
								spacing={2}
							>
								{fields.map((field, index) => (
									<Row
										field={field}
										subFields={subFields}
										key={index}
									/>
								))}
							</Grid>
						</Box>
					</FormProvider>

					<Stack
						direction="row"
						justifyContent="flex-end"
						alignItems="center"
						spacing={2}
						pr={2}
						pb={2}
					>
						<Stack direction="row" spacing={2} pr={1}>
							<Box>
								<Button
									variant="contained"
									disableElevation
									onClick={formMethods.handleSubmit(onSave)}
									disabled={isSaving}
								>
									Update
								</Button>
							</Box>

							<Box>
								<Button
									variant="contained"
									color="secondary"
									disableElevation
									onClick={onClose}
									disabled={isSaving}
								>
									Cancel
								</Button>
							</Box>
						</Stack>
					</Stack>
				</Box>
			)}
		</React.Fragment>
	);
}
