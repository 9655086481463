import React, { useEffect, useState } from "react";
import {
	Divider,
	Stack,
	Typography,
	IconButton,
	useTheme,
	MenuItem,
	Box,
} from "@mui/material";
import { default as MoreIcon } from "../../../assets/icons/more";
import { default as CloseIcon } from "../../../assets/icons/close";
import { DwarfButton, hexToRgba } from "../../../styles/twozo";
import UpdateField from "../../Elements/UpdateField";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteMultipleDeals } from "../../../api/deal/dealApi";
import DeleteDialog from "../../Elements/DeleteDialog";
import DealAssignOwner from "./assignOwner";
import { getDealTableKey } from "../../../utils/queryKeys";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../utils/notification/notificationMessages";
import Menu from "../../Elements/Menu";
import AddTags from "../../Tags/AddTags";
import RemoveTags from "../../Tags/RemoveTags";
import {
	useContactsByDeals,
	usePartialBulkUpdateDeal,
} from "../../../hooks/services/deal";
import { modules } from "../../../utils/common/ModulesName";
import EmailComposerDrawer from "../../Mail/EmailComposer/EmailComposerDrawer";
import { PERMISSIONS } from "../../../utils/Auth";
import { useAuth } from "../../../hooks/auth";

export default function SelectedMenu(props) {
	const { selected, data, setSelected, deSelectAll } = props;
	const theme = useTheme();
	const queryClient = useQueryClient();
	const dealTableKey = getDealTableKey();
	// query call:-
	const { isUserAllowedFor } = useAuth();
	const updateMutation = usePartialBulkUpdateDeal();

	const [isEmailComposerOpened, setIsEmailComposerOpened] = useState(false);
	const [isUpdateFieldDialogOpened, setIsUpdateFieldDialogOpened] =
		useState(false);
	const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
	const [isAssignOwnerDialogOpened, setIsAssignOwnerDialogOpened] =
		useState(false);

	const [isAddTagsDialogOpened, setIsAddTagsDialogOpened] = useState(false);
	const [isRemoveTagsDialogOpened, setIsRemoveTagsDialogOpened] =
		useState(false);
	const [selectedDealMenuElement, setSelectedDealMenuElement] =
		useState(null);
	const isSelectedDealMenuOpened = Boolean(selectedDealMenuElement);

	const [contactIds, setContactIds] = useState([]);
	const moduleName = modules.DEAL;

	const { data: contactsByDeals, status: contactsByDealsStatus } =
		useContactsByDeals(selected);

	useEffect(() => {
		if (contactsByDealsStatus === "success") {
			if (contactsByDeals.length > 0) {
				let dealAssociatedcontactIds = contactsByDeals.map(
					(contactData) => contactData?.contactId
				);
				setContactIds([...new Set(dealAssociatedcontactIds)]);
			}
		}
	}, [contactsByDealsStatus, contactsByDeals]);

	const handleOpenUpdateFieldDialog = () => {
		setIsUpdateFieldDialogOpened(true);
	};

	const handleCloseUpdateFieldDialog = () => {
		setIsUpdateFieldDialogOpened(false);
	};

	const openSelectedDealMenu = (event) => {
		setSelectedDealMenuElement(event.currentTarget);
	};

	const closeSelectedDealMenu = () => {
		setSelectedDealMenuElement(null);
	};

	const handleSaveUpdateField = (formData) => {
		updateMutation.mutate(formData, {
			onSuccess: () => {
				handleCloseUpdateFieldDialog();
				if (selected.length > 1) {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: `${selected.length} deals updated.`,
					});
				} else {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.dealUpdated,
					});
				}
				setSelected([]);
			},
			onError: (error) => {
				let errorMessage = error?.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage ?? notificationMessage.errorMessage,
				});
			},
		});
	};

	const handleDeleteDeals = () => {
		setIsDeleteDialogVisible(true);
	};

	const cancelDelete = () => {
		setIsDeleteDialogVisible(false);
	};

	const performDeleteConfirm = () => {
		deleteDealsMutation.mutate(selected);
	};

	const deleteDealsMutation = useMutation(
		async (dealIds) => deleteMultipleDeals(dealIds),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(dealTableKey);
				if (selected.length > 1) {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: `${selected.length} deals deleted.`,
					});
				} else {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.dealDeleted,
					});
				}
				deSelectAll();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.errorMessage,
				});
			},
		}
	);

	const openAssignOwnerDialog = () => {
		setIsAssignOwnerDialogOpened(true);
	};

	const closeAssignOwnerDialog = () => {
		setIsAssignOwnerDialogOpened(false);
	};

	const onOwnerUpdated = () => {
		deSelectAll();
	};

	const openAddTagsDialog = () => {
		setSelectedDealMenuElement(null);
		setIsAddTagsDialogOpened(true);
	};

	const closeAddTagsDialog = () => {
		setIsAddTagsDialogOpened(false);
	};

	const openRemoveTagsDialog = () => {
		setSelectedDealMenuElement(null);
		setIsRemoveTagsDialogOpened(true);
	};

	const closeRemoveTagsDialog = () => {
		setIsRemoveTagsDialogOpened(false);
	};

	const onTagsAdded = () => {
		setSelected([]);
		closeAddTagsDialog();
	};

	const onTagsRemoved = () => {
		setSelected([]);
		closeRemoveTagsDialog();
	};

	const openEmailComposer = () => {
		setIsEmailComposerOpened(true);
	};

	const closeEmailComposer = () => {
		setIsEmailComposerOpened(false);
		setSelected([]);
	};

	const handleUnSelectDeal = () => {
		setSelected([]);
	};

	const menuOptions = [
		{
			name: "Export",
			action: () => {},
			permission: isUserAllowedFor(PERMISSIONS.deal.exportRecords),
		},
		{
			name: "Add Tags",
			action: openAddTagsDialog,
			permission: isUserAllowedFor(PERMISSIONS.deal.edit),
		},
		{
			name: "Remove Tags",
			action: openRemoveTagsDialog,
			permission: isUserAllowedFor(PERMISSIONS.deal.edit),
		},
	];

	const selectedMenuOptions = [
		{
			name: "Send Email",
			action: openEmailComposer,
			permission: isUserAllowedFor(PERMISSIONS.contact.view),
		},
		{
			name: "Assign Owner",
			action: openAssignOwnerDialog,
			permission: isUserAllowedFor(PERMISSIONS.deal.bulkAssignRecords),
		},
		{
			name: "Update Field",
			action: handleOpenUpdateFieldDialog,
			permission: isUserAllowedFor(PERMISSIONS.deal.bulkUpdateRecords),
		},
		{
			name: "Delete",
			action: handleDeleteDeals,
			permission: isUserAllowedFor(PERMISSIONS.deal.delete),
		},
	];

	const getMenuOptions = () => {
		let filteredMenuOptions = menuOptions.filter(
			(option) => option.permission
		);
		return filteredMenuOptions;
	};

	const getSelectedMenuOptions = () => {
		let filteredSelectedMenuOptions = selectedMenuOptions.filter(
			(option) => option.permission
		);
		return filteredSelectedMenuOptions;
	};

	return (
		<React.Fragment>
			<Box>
				<Menu
					minWidth="200px"
					anchorEl={selectedDealMenuElement}
					open={isSelectedDealMenuOpened}
					onClose={closeSelectedDealMenu}
					style={{
						marginTop: "8px",
					}}
				>
					{getMenuOptions()?.map((option, index) => (
						<MenuItem
							style={{ height: "40px" }}
							onClick={option.action}
							key={index}
						>
							<Typography
								fontSize={13}
								color={theme.palette.secondary.contrastText}
							>
								{option.name}
							</Typography>
						</MenuItem>
					))}
				</Menu>
			</Box>
			{isEmailComposerOpened ? (
				<EmailComposerDrawer
					onClose={closeEmailComposer}
					isBulkMailEnabled={true}
					composerValues={{ toAddress: contactIds }}
					sourceName={modules.DEAL}
				/>
			) : null}

			<UpdateField
				selected={selected}
				moduleName={moduleName}
				open={isUpdateFieldDialogOpened}
				onSave={handleSaveUpdateField}
				onClose={handleCloseUpdateFieldDialog}
				isSaving={updateMutation.isLoading}
			/>

			<DealAssignOwner
				open={isAssignOwnerDialogOpened}
				onClose={closeAssignOwnerDialog}
				dealIds={selected}
				onOwnerUpdated={onOwnerUpdated}
			/>

			<AddTags
				sourceName={moduleName}
				isAddTagsDialogOpened={isAddTagsDialogOpened}
				closeAddTagsDialog={closeAddTagsDialog}
				selectedIds={selected}
				onTagsAdded={onTagsAdded}
			/>

			<RemoveTags
				sourceName={moduleName}
				isRemoveTagsDialogOpened={isRemoveTagsDialogOpened}
				closeRemoveTagsDialog={closeRemoveTagsDialog}
				selectedIds={selected}
				onTagsRemoved={onTagsRemoved}
			/>

			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				height="100%"
				py={2}
			>
				<Stack
					direction="row"
					alignItems="center"
					spacing={2}
					height="100%"
				>
					<Stack direction="row" spacing={0.5}>
						<Typography fontSize={14} fontWeight={500}>
							{selected.length} Selected
						</Typography>

						<Typography
							fontSize={14}
							color={hexToRgba("#000", 0.6)}
						>
							of {data.length}
						</Typography>

						<IconButton
							disableRipple
							style={{ cursor: "pointer", padding: 0 }}
							onClick={handleUnSelectDeal}
						>
							{CloseIcon(18, 18, "#000", 0.6)}
						</IconButton>
					</Stack>

					<Divider
						orientation="vertical"
						style={{ height: "20px" }}
					/>

					{getSelectedMenuOptions()?.map((option, index) => (
						<DwarfButton
							key={index}
							variant="contained"
							color="secondary"
							disableElevation
							onClick={option.action}
						>
							{option.name}
						</DwarfButton>
					))}

					<DeleteDialog
						title="Are you sure you want to delete the selected deals?"
						subtitle="It will delete all the selected deals and the data associated with it"
						open={isDeleteDialogVisible}
						onCancel={cancelDelete}
						onDelete={performDeleteConfirm}
					></DeleteDialog>

					{getMenuOptions()?.length > 0 && (
						<IconButton size="small" onClick={openSelectedDealMenu}>
							{MoreIcon(20, 20, theme.palette.primary.main)}
						</IconButton>
					)}
				</Stack>
			</Stack>
		</React.Fragment>
	);
}
