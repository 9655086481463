import { Stack, Typography, useTheme } from "@mui/material";
import React, { useMemo, useState } from "react";
import Menu from "../../../../../Menu";
import { getDropdownDataByName } from "../../../../../../../utils/dropdownData";
import MultiEmailEditForm from "../../../../../FieldEditFormComponents/MultiEmailEditForm";
import { useSummaryContext } from "../../../../../../Summary/SummaryContext";
import { SummaryFieldName } from "../../../../../../../styles/twozo";
import TooltipComponent from "../../../../../TooltipComponent";

export const MultiEmailFieldValue = (props) => {
	const { subFields, summaryField } = props;

	// Summary Context:-
	const { updateSummaryField, entityId, hasEditPermission, isSaving } =
		useSummaryContext();

	const theme = useTheme();
	const [emailMenuElement, setEmailMenuElement] = useState(null);
	const openEmailMenu = Boolean(emailMenuElement);

	const emailValue = "EMAIL_VALUE";
	const emailType = "EMAIL_TYPE";
	const emailTypeOptions = getDropdownDataByName(emailType);

	const openEditForm = (event) => {
		if (!hasEditPermission) {
			return;
		}
		setEmailMenuElement(event.currentTarget);
	};

	const getEmailFieldLabel = () => {
		let primaryEmailValue = summaryField?.value?.find(
			(row) => row.isPrimary
		)?.[emailValue]?.value;
		if (primaryEmailValue) {
			return (
				<Stack direction="row" alignItems="center">
					<TooltipComponent
						title={primaryEmailValue}
						placement="top"
						verticalDisplacement={-4}
					>
						<Typography
							onClick={openEditForm}
							fontSize={14}
							fontWeight={500}
							maxWidth="126px"
							noWrap
						>
							{primaryEmailValue}{" "}
						</Typography>
					</TooltipComponent>

					{summaryField?.value?.length > 1 && (
						<Typography
							onClick={openEditForm}
							style={{
								color: theme.palette.primary.main,
								fontSize: 14,
							}}
						>
							+{summaryField.value.length - 1}
						</Typography>
					)}
				</Stack>
			);
		}
		return summaryField.config.readOnly ? (
			<SummaryFieldName style={{ opacity: 0.6 }}>-</SummaryFieldName>
		) : (
			<Typography
				width="150px"
				sx={{
					"&:hover": {
						borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
					},
				}}
				fontSize="14px"
				fontWeight={500}
				style={{
					color: theme.palette.secondary.contrastText,
					opacity: "0.4",
				}}
				onClick={openEditForm}
			>
				Click to Add
			</Typography>
		);
	};

	const closeEmailMenu = () => {
		setEmailMenuElement(null);
	};

	const modifiedEmailStructure = useMemo(() => {
		const { fieldId, value } = summaryField;

		if (!value) {
			return { id: entityId };
		}
		const convertedData = value.map((item) => {
			const { EMAIL_VALUE, EMAIL_TYPE, isPrimary = false, id } = item;

			let defaultEmailType = emailTypeOptions.find((field) => {
				return EMAIL_TYPE?.value === field?.value;
			});

			return {
				[EMAIL_VALUE?.id]: EMAIL_VALUE.value,
				[EMAIL_TYPE?.id]: defaultEmailType?.value,
				isPrimary: isPrimary,
				id: id,
			};
		});

		return {
			[fieldId]: convertedData,
			id: entityId,
		};
	}, [summaryField]);

	const handleSave = (emailsData) => {
		updateSummaryField(emailsData, closeEmailMenu);
	};

	return (
		<React.Fragment>
			{getEmailFieldLabel()}

			<Menu
				open={openEmailMenu}
				anchorEl={emailMenuElement}
				onClose={closeEmailMenu}
				sx={{ marginTop: "3px", minWidth: "400px" }}
			>
				<MultiEmailEditForm
					subFields={subFields}
					field={summaryField}
					onClose={closeEmailMenu}
					onSave={handleSave}
					isSaving={isSaving}
					value={modifiedEmailStructure}
				/>
			</Menu>
		</React.Fragment>
	);
};
