import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import SingleDateTimePicker from "../SingleDateTimePicker";
import dayjs from "dayjs";

export default function DateTimeRangePicker({
	onChange,
	value,
	error,
	inputRef,
	fromTextFieldSx,
	toTextFieldSx,
}) {
	const [dateTimeRangeValue, setDateTimeRangeValue] = useState({});

	useEffect(() => {
		if (value) {
			setDateTimeRangeValue(value);
		}
	}, [value]);

	const handleDateTimeRangeValueChange = (dateTimeRangeValue) => {
		if (onChange) {
			onChange(dateTimeRangeValue);
		}
	};

	const onStartDateTimeChange = (value) => {
		//here value is a dayjs object, valueOf gives unixtimestamp
		let newValue = { ...dateTimeRangeValue, from: value.valueOf() };
		setDateTimeRangeValue(newValue);
		handleDateTimeRangeValueChange(newValue);
	};

	const onEndDateTimeChange = (value) => {
		//here value is a dayjs object, valueOf gives unixtimestamp
		let newValue = { ...dateTimeRangeValue, to: value.valueOf() };
		setDateTimeRangeValue(newValue);
		handleDateTimeRangeValueChange(newValue);
	};

	const getParsedValue = (value) => {
		return value ? dayjs(value) : null;
	};

	const handleDisableToField = (date) => {
		return date < getParsedValue(dateTimeRangeValue.from);
	};

	const handleDisableFromField = (date) => {
		if (dateTimeRangeValue.to) {
			return date > getParsedValue(dateTimeRangeValue.to);
		}
	};

	return (
		<React.Fragment>
			<Box width="100%" position="relative">
				<SingleDateTimePicker
					textFieldProps={{
						sx: {
							width: "50%",
							"& .MuiOutlinedInput-notchedOutline": {
								borderRadius: "8px 0px 0px 8px",
							},
							...fromTextFieldSx,
						},
					}}
					inputRef={inputRef}
					onChange={(value) => {
						onStartDateTimeChange(value);
					}}
					error={error}
					value={getParsedValue(dateTimeRangeValue.from)}
					shouldDisableDate={handleDisableFromField}
					shouldDisableMonth={handleDisableFromField}
					shouldDisableYear={handleDisableFromField}
				/>
				<Box
					style={{
						display: "inline",
						position: "absolute",
						marginTop: "10px",
						marginLeft: "-8px",
						backgroundColor: "#fff",
						zIndex: 2,
					}}
				>
					<Typography fontSize={14} color={"rgba(0, 0, 0, 0.6)"}>
						To
					</Typography>
				</Box>
				<SingleDateTimePicker
					textFieldProps={{
						sx: {
							width: "50%",
							"& .MuiOutlinedInput-notchedOutline": {
								borderRadius: "0px 8px 8px 0px",
								marginLeft: "-1px",
							},
							...toTextFieldSx,
						},
						inputProps: {
							style: { textAlign: "right", fontSize: "14px" },
						},
					}}
					inputRef={inputRef}
					slotProps={{ inputAdornment: { position: "end" } }}
					popperProps={{ placement: "bottom-end" }}
					onChange={(value) => {
						onEndDateTimeChange(value);
					}}
					error={error}
					value={getParsedValue(dateTimeRangeValue.to)}
					minDate={getParsedValue(dateTimeRangeValue.from)} //Prevents the selection of all values before minDate
					shouldDisableDate={handleDisableToField}
					shouldDisableMonth={handleDisableToField}
					shouldDisableYear={handleDisableToField}
				/>
			</Box>
		</React.Fragment>
	);
}
