import { useModuleList } from "../services/common";

const useModuleName = () => {
	let { data: moduleList } = useModuleList();

	const getModuleName = (moduleName) => {
		let module = moduleList?.find(
			(module) => module.sourceName === moduleName
		);

		if (module) {
			if (module?.alternateName) {
				return module?.alternateName;
			}
			return module?.sourceName;
		} else {
			return "";
		}
	};

	return { getModuleName };
};

export { useModuleName };
