import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
	getDealCompletedActivityKey,
	getDealToDoActivityKey,
} from "../../../../utils/queryKeys";
import {
	deleteActivity,
	getActivitiesData,
	partialUpdateActivity,
} from "../../../../api/activity/activityApi";
import { modules } from "../../../../utils/common/ModulesName";

let dealSource = modules.DEAL;

const useDealToDoActivities = (dealId, activityTypeIdsToFilter) => {
	let isDone = false;
	const dealToDoActivityKey = getDealToDoActivityKey(
		dealId,
		activityTypeIdsToFilter
	);
	return useQuery(
		dealToDoActivityKey,
		() =>
			getActivitiesData(
				dealId,
				isDone,
				dealSource,
				activityTypeIdsToFilter
			),
		{
			select: (data) => data?.activities,
		}
	);
};

const useDealCompletedActivities = (dealId, activityTypeIdsToFilter) => {
	let isDone = true;
	const dealCompletedActivityKey = getDealCompletedActivityKey(
		dealId,
		activityTypeIdsToFilter
	);
	return useQuery(
		dealCompletedActivityKey,
		() =>
			getActivitiesData(
				dealId,
				isDone,
				dealSource,
				activityTypeIdsToFilter
			),
		{
			select: (data) => data?.activities,
		}
	);
};

//Invalidate DealToDoActivities List
const useInvalidateDealToDoActivities = (dealId) => {
	let queryClient = useQueryClient();
	const dealToDoActivityKey = getDealToDoActivityKey(dealId);
	return () => {
		queryClient.invalidateQueries(dealToDoActivityKey);
	};
};

//Invalidate DealCompletedActivities List
const useInvalidateDealCompletedActivities = (dealId) => {
	let queryClient = useQueryClient();
	const dealCompletedActivityKey = getDealCompletedActivityKey(dealId);
	return () => {
		queryClient.invalidateQueries(dealCompletedActivityKey);
	};
};

//delete Activity mutation
const useDeleteActivity = (dealId) => {
	let invalidateToDoActivities = useInvalidateDealToDoActivities(dealId);
	let invalidateCompletedActivities =
		useInvalidateDealCompletedActivities(dealId);
	return useMutation(deleteActivity, {
		onSuccess: () => {
			invalidateCompletedActivities();
			invalidateToDoActivities();
		},
	});
};

//update Activity Status
const useUpdateActivityStatus = (dealId) => {
	let invalidateToDoActivities = useInvalidateDealToDoActivities(dealId);
	let invalidateCompletedActivities =
		useInvalidateDealCompletedActivities(dealId);
	return useMutation(partialUpdateActivity, {
		onSuccess: () => {
			invalidateCompletedActivities();
			invalidateToDoActivities();
		},
	});
};

export {
	useDealToDoActivities,
	useDealCompletedActivities,
	useInvalidateDealToDoActivities,
	useInvalidateDealCompletedActivities,
	useDeleteActivity,
	useUpdateActivityStatus,
};
