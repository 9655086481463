import React from "react";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { twozoStyles } from "../../../../styles/twozo";
import TableList from "./TableList";
import { useWebFormListData } from "../../../../hooks/services/webform";

export default function WebFormList() {
	const classes = twozoStyles();

	const isActive = true;
	const isDeactive = false;

	const {
		data: activatedWebformList,
		isLoading: isLoadingActivatedWebForms,
	} = useWebFormListData(isActive);

	const {
		data: deactivatedWebformList,
		isLoading: isLoadingDeactivatedWebForms,
	} = useWebFormListData(isDeactive);

	return (
		<React.Fragment>
			{isLoadingActivatedWebForms || isLoadingDeactivatedWebForms ? (
				<Stack
					alignItems="center"
					justifyContent="center"
					p={1}
					height="60vh"
				>
					<CircularProgress size="30px" />
				</Stack>
			) : (
				<Box>
					<Box className={classes.menuBar}>
						<Stack pl={1}>
							<Typography fontWeight={600} fontSize="18px">
								Web Forms
							</Typography>
							<Typography
								color="rgba(0, 0, 0, 0.6)"
								fontSize="14px"
							>
								Make it easy for your visitors to register their
								interest in your product, service or program
								shared as a link or embedded to your website
							</Typography>
						</Stack>
					</Box>
					<TableList
						webFormList={activatedWebformList}
						isActive={true}
					/>

					<Box>
						<Typography
							pt={3}
							pl={3}
							fontWeight={600}
							fontSize="16px"
						>
							Deactivated Web Forms
						</Typography>
						<TableList webFormList={deactivatedWebformList} />
					</Box>
				</Box>
			)}
		</React.Fragment>
	);
}
