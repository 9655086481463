import React, { useState, useEffect } from "react";
import {
	Box,
	CircularProgress,
	Divider,
	IconButton,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { default as AddIcon } from "../../../assets/icons/add";
import { default as FileIcon } from "../../../assets/icons/files";
import { default as ImageIcon } from "../../../assets/icons/image";
import { default as CloseIcon } from "../../../assets/icons/close";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
	deleteFile,
	getFiles,
	uploadFilesData,
} from "../../../api/file/fileApi";
import {
	getDateMonthAndYearFormat,
	getHourAndMinuteFormat,
} from "../../../utils/DateUtils";
import AddFile from "./AddFile";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../utils/notification/notificationMessages";
import TooltipComponent from "../../Elements/TooltipComponent";

export default function Files(props) {
	const theme = useTheme();
	const queryClient = useQueryClient();
	const {
		fileKey,
		timelineKey,
		entityId,
		source,
		summaryKey,
		isAddFileDialogOpened,
		setIsAddFileDialogOpened,
		isAddButtonDiabled,
	} = props;

	const [listOfFiles, setListOfFiles] = useState([]);
	const [addedFiles, setAddedFiles] = useState([]);
	const [hoveredFileId, setHoveredFileId] = useState(null);

	const imageExtension = ["png", "jpg", "jpeg", "gif"];
	const successNotificationTitle = "Success!";

	const {
		status: fileApiStatus,
		isLoading: isLoadingFileData,
		data: fileApiData,
	} = useQuery(
		fileKey,
		() => getFiles({ entityId: entityId, source: source }),
		{
			enabled: !!entityId,
		}
	);

	useEffect(() => {
		if (fileApiStatus === "success") {
			if (fileApiData.files) {
				setListOfFiles(fileApiData.files);
			}
		}
	}, [fileApiData, fileApiStatus]);

	const uploadFiles = (files) => {
		if (files.length > 0) {
			uploadMutation.mutate({
				id: entityId,
				source: source,
				files: files,
			});
		}
	};

	const uploadMutation = useMutation(
		async (uploadedFileData) => uploadFilesData(uploadedFileData),
		{
			onSuccess: () => {
				queryClient.invalidateQueries(fileKey);
				queryClient.invalidateQueries(timelineKey);
				if (summaryKey) {
					queryClient.invalidateQueries(summaryKey);
				}
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.filesAdded,
				});
				handleCloseAddFileDialog();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.errorMessage,
				});
			},
		}
	);

	const handleDeleteFile = (event, id) => {
		event.stopPropagation();
		deleteMutation.mutate(id);
	};

	const deleteMutation = useMutation(async (id) => deleteFile(id), {
		onSuccess: () => {
			queryClient.invalidateQueries(fileKey);
			queryClient.invalidateQueries(timelineKey);
			if (summaryKey) {
				queryClient.invalidateQueries(summaryKey);
			}
			enqueueSnackbar({
				variant: notificationVariants.error,
				title: successNotificationTitle,
				message: notificationMessage.filesDeleted,
			});
		},
		onError: () => {
			enqueueSnackbar({
				variant: notificationVariants.error,
				message: notificationMessage.errorMessage,
			});
		},
	});

	const onMouseOverFile = (id) => setHoveredFileId(id);
	const onMouseOutFile = () => setHoveredFileId(null);

	const handleOpenAddFileDialog = () => {
		setIsAddFileDialogOpened(true);
	};

	const handleCloseAddFileDialog = () => {
		setIsAddFileDialogOpened(false);
		setAddedFiles([]);
	};

	const handleDownloadFile = (fileId) => {
		window.location.href = `/api/file/download?fileId=${fileId}`;
	};

	const onFilesChange = (updatedFiles) => {
		setAddedFiles(updatedFiles);
	};

	return (
		<React.Fragment>
			<Box>
				<AddFile
					isAddFileDialogOpened={isAddFileDialogOpened}
					onCloseAddFileDialog={handleCloseAddFileDialog}
					onSaveAddedFiles={uploadFiles}
					onFilesChange={onFilesChange}
					addedFiles={addedFiles}
					disableSaveButton={uploadMutation.isLoading}
				/>
				<Box hidden={isAddButtonDiabled}>
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						p={1}
					>
						<Typography fontSize={14} fontWeight={600}>
							Files
						</Typography>

						<IconButton
							size="small"
							onClick={handleOpenAddFileDialog}
						>
							{AddIcon(20, 20, theme.palette.primary.main)}
						</IconButton>
					</Stack>
				</Box>

				{isLoadingFileData ? (
					<Stack alignItems="center" p={5}>
						<CircularProgress size="30px" />
					</Stack>
				) : listOfFiles.length > 0 ? (
					<Box
						sx={{
							maxBlockSize: "250px",
							overflowY: "auto",
							mx: isAddButtonDiabled ? -2 : -1,
							cursor: "pointer",
						}}
					>
						{listOfFiles?.map((file, index) => (
							<Box key={file.id}>
								<Stack
									direction="row"
									alignItems="center"
									justifyContent="space-between"
									onMouseOver={() => onMouseOverFile(file.id)}
									onMouseOut={onMouseOutFile}
									sx={{
										backgroundColor:
											file.id === hoveredFileId
												? "#F4F5F5"
												: null,
									}}
									px={2}
									py={1.5}
									onClick={() => handleDownloadFile(file.id)}
								>
									<Stack
										direction="row"
										alignItems="center"
										spacing={2}
									>
										<Box display="flex">
											{imageExtension.includes(
												file?.extension?.toLowerCase()
											)
												? ImageIcon(23, 23)
												: FileIcon(23, 23)}
										</Box>

										<Stack>
											<TooltipComponent
												title={file.fileName}
												placement="top"
												width="300px"
												verticalDisplacement={-3}
											>
												<Typography
													fontSize={13}
													fontWeight={500}
													maxWidth={"245px"}
													noWrap
												>
													{file.fileName}
												</Typography>
											</TooltipComponent>

											<Stack direction="row" spacing={1}>
												<Typography
													fontSize={12}
													color={"rgba(0, 0, 0, 0.6)"}
												>
													{file.ownerName}
												</Typography>

												<Typography
													fontSize={12}
													color={"rgba(0, 0, 0, 0.6)"}
												>
													&#8226;
												</Typography>

												<Typography
													fontSize={12}
													color={"rgba(0, 0, 0, 0.6)"}
												>
													{getDateMonthAndYearFormat(
														file.uploadedTime
													)}
													,{" "}
													{getHourAndMinuteFormat(
														file.uploadedTime
													)}
												</Typography>
											</Stack>
										</Stack>
									</Stack>
									{hoveredFileId === file.id ? (
										<IconButton
											size="small"
											onClick={(event) =>
												handleDeleteFile(event, file.id)
											}
										>
											{CloseIcon(
												20,
												20,
												theme.palette.primary.main
											)}
										</IconButton>
									) : null}
								</Stack>

								{listOfFiles.length - 1 !== index ? (
									<Box px={1}>
										<Divider
											sx={{
												opacity: hoveredFileId
													? 0
													: null,
											}}
										/>
									</Box>
								) : null}
							</Box>
						))}
					</Box>
				) : (
					<Stack alignItems="center" justifyContent="center" pb={1}>
						<Typography fontSize={13} color="rgba(0, 0, 0, 0.6)">
							No files found.
						</Typography>
					</Stack>
				)}
			</Box>
		</React.Fragment>
	);
}
