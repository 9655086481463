import React, { useState } from "react";
import { Avatar, Badge, Box, Stack, Typography } from "@mui/material";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
} from "../../../Elements/Accordian";
import ContactImage from "../../../../assets/images/contact/unknownContact.png";
import { default as CallIcon } from "../../../../assets/icons/call";
import { default as MailIcon } from "../../../../assets/icons/mail";
import Menu from "../../../Elements/Menu";
import { useCollaboratorList } from "../../../../hooks/services/activities";

export default function Collaborators(props) {
	const { activityId } = props;
	const [isExpand, setIsExpand] = useState(false);
	const [contactDetailsMenuElement, setContactDetailsMenuElement] =
		useState(null);
	const isContactDetailsMenuOpened = Boolean(contactDetailsMenuElement);
	const { data: collaboratorList } = useCollaboratorList(activityId);

	const [collaborator, setCollaborator] = useState({});

	const openContactMenu = (event, collaborator) => {
		setContactDetailsMenuElement(event.currentTarget);
		setCollaborator(collaborator);
	};

	const closeContactMenu = () => {
		setContactDetailsMenuElement(null);
		setCollaborator(collaborator);
	};

	return (
		<React.Fragment>
			<Menu
				minWidth="320px"
				anchorEl={contactDetailsMenuElement}
				open={isContactDetailsMenuOpened}
				onClose={closeContactMenu}
				style={{
					marginTop: "6px",
				}}
			>
				<Box px={2} py={1}>
					<Stack direction="row" spacing={2}>
						<Avatar
							alt="contact"
							src={ContactImage}
							sx={{ width: 45, height: 45 }}
						/>

						<Stack>
							<Typography fontSize={18} fontWeight={600}>
								{collaborator.name}
							</Typography>

							{collaborator?.phone ? (
								<Stack
									direction="row"
									alignItems="center"
									spacing={1}
									pt={1}
								>
									{CallIcon(16, 16, "rgba(0, 0, 0, 0.6)")}

									<Typography
										fontSize={14}
										fontWeight={500}
										color="rgba(0, 0, 0, 0.6)"
									>
										{collaborator?.phone}
									</Typography>
								</Stack>
							) : null}

							<Stack
								direction="row"
								alignItems="center"
								spacing={1}
								pt={0.5}
							>
								{MailIcon(16, 16, "rgba(0, 0, 0, 0.6)")}

								<Typography
									fontSize={14}
									fontWeight={500}
									color="rgba(0, 0, 0, 0.6)"
								>
									{collaborator.email}
								</Typography>
							</Stack>
						</Stack>
					</Stack>
				</Box>
			</Menu>

			<Box>
				<Accordion
					expanded={isExpand}
					onChange={() => setIsExpand((isExpand) => !isExpand)}
				>
					<AccordionSummary
						aria-controls="panel1d-content"
						id="panel1d-header"
					>
						<Typography fontSize={14} fontWeight={600}>
							Collaborators
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						{collaboratorList?.length > 0 ? (
							<Stack
								direction="row"
								spacing={1.5}
								overflow="auto"
							>
								{collaboratorList?.map((collaborator) => (
									<Box
										key={collaborator.id}
										style={{ cursor: "pointer" }}
									>
										<Badge
											overlap="circular"
											anchorOrigin={{
												vertical: "top",
												horizontal: "right",
											}}
										>
											<Avatar
												alt="contact"
												src={ContactImage}
												sx={{ width: 32, height: 32 }}
												onClick={(event) =>
													openContactMenu(
														event,
														collaborator
													)
												}
											/>
										</Badge>
									</Box>
								))}
							</Stack>
						) : (
							<Stack alignItems="center" justifyContent="center">
								<Typography
									fontSize={13}
									color="rgba(0, 0, 0, 0.6)"
								>
									No collaborators found.
								</Typography>
							</Stack>
						)}
					</AccordionDetails>
				</Accordion>
			</Box>
		</React.Fragment>
	);
}
