import { useInvalidateContactTableData } from "../../contact";
import { useInvalidateCompanyTableData } from "../../company";
import { useInvalidateProductTableData } from "../../product";
import { useInvalidateDealTableData } from "../../deal";
import { useInvalidateActivityList } from "../../activities";

export const useInvalidateAllModuleTables = () => {
	let contactTableList = useInvalidateContactTableData();
	let companyTableList = useInvalidateCompanyTableData();
	let productTableList = useInvalidateProductTableData();
	let dealTableList = useInvalidateDealTableData();
	let activityTableList = useInvalidateActivityList();

	return () => {
		contactTableList();
		companyTableList();
		productTableList();
		dealTableList();
		activityTableList();
	};
};
