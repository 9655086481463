import React, {
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useState,
} from "react";
import {
	DealTimelineButton,
	hexToRgba,
	TextButton,
	Tooltip,
} from "../../../../styles/twozo";
import {
	Box,
	MenuItem,
	Stack,
	Step,
	StepConnector,
	stepConnectorClasses,
	StepLabel,
	Stepper,
	tooltipClasses,
	Typography,
	useTheme,
} from "@mui/material";
import { default as DropdownIcon } from "../../../../assets/icons/dropDownCentered";
import { default as ProbabilityIcon } from "../../../../assets/icons/probability";
import { default as FlagIcon } from "../../../../assets/icons/flag";
import { styled } from "@mui/material/styles";
import Menu from "../../../Elements/Menu";
import Dialog from "../../../Elements/Dialog";
import ClosedLost from "../ClosedLost";
import ClosedWon from "../ClosedWon";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import dayjs from "dayjs";
import { DateCalendar } from "@mui/x-date-pickers";
import { getDateMonthAndYearFormat } from "../../../../utils/DateUtils";
import {
	useDealProgressViewData,
	useUpdateExpectedCloseDateMutation,
	useUpdatePipelineMutation,
	useUpdateStageMutation,
} from "../../../../hooks/services/deal";
import { usePipelineList } from "../../../../hooks/services/deal/pipeline";
import { PERMISSIONS } from "../../../../utils/Auth";
import { useAuth } from "../../../../hooks/auth";
import Can from "../../../Auth/Can";

const CustomConnector = styled(StepConnector)(({ theme }) => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 10,
		left: "calc(-50% + 12px)",
		right: "calc(50% + 12px)",
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: theme.palette.primary.main,
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: theme.palette.primary.main,
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		borderColor: theme.palette.primary.main,
		borderTopWidth: 2,
	},
}));

const CustomStepIconRoot = styled("div")(({ theme }) => ({
	display: "flex",
	height: 22,
	alignItems: "center",
	zIndex: 2,
	"& .StepIcon-dummy": {
		width: "8px",
		height: "8px",
		backgroundColor: theme.palette.primary.main,
		borderRadius: "50%",
	},
	"& .StepIcon-completed": {
		width: "16px",
		height: "16px",
		backgroundColor: theme.palette.primary.main,
		borderRadius: "50%",
		border: "3px solid #fff",
	},
	"& .StepIcon-lost": {
		width: "16px",
		height: "16px",
		backgroundColor: theme.palette.error.main,
		borderRadius: "50%",
		border: "3px solid #fff",
	},
	"& .StepIcon-circle": {
		width: "16px",
		height: "16px",
		borderRadius: "50%",
		border: "1px solid #5C6360",
	},
}));

const predefinedStages = {
	won: "Won",
	lost: "Lost",
};

function CustomStepIcon(props) {
	const { active, completed, stage, stages, dummy, changeActiveStep } = props;
	const [stagesMenuElement, setStagesMenuElement] = useState(null);
	const [isStageTooltipOpened, setIsStageTooltipOpened] = useState(false);
	const [hoveredStageIndex, setHoveredStageIndex] = useState(null);
	const isStageMenuOpened = Boolean(stagesMenuElement);
	const [hoveredStageId, setHoveredStageId] = useState(null);
	// query call :-
	const { isUserAllowedFor } = useAuth();
	const isEditActionEnabled = isUserAllowedFor(PERMISSIONS.deal.edit);

	const openStagesMenu = (event) => {
		if (isEditActionEnabled) {
			setStagesMenuElement(event.currentTarget);
		}
	};

	const closeStagesMenu = () => {
		setStagesMenuElement(null);
	};

	const onMouseOverStage = (event, index) => {
		setIsStageTooltipOpened(
			event.target.scrollWidth > event.target.clientWidth
		);
		setHoveredStageIndex(index);
	};

	const onMouseOutStage = () => {
		setIsStageTooltipOpened(false);
		setHoveredStageIndex(null);
	};

	const handleSelectStage = (stage) => {
		closeStagesMenu();
		changeActiveStep(stage);
	};

	return (
		<React.Fragment>
			<Menu
				minWidth="250px"
				anchorEl={stagesMenuElement}
				open={isStageMenuOpened}
				onClose={closeStagesMenu}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				style={{
					marginTop: "4px",
					maxHeight: "248px",
				}}
			>
				{stages &&
					stages.map((stageData, index) => (
						<MenuItem
							key={stageData.id}
							style={{ height: "40px", maxWidth: "250px" }}
							selected={stageData.id === stage.id}
							onClick={() => handleSelectStage(stageData)}
							onMouseOver={(event) =>
								onMouseOverStage(event, index)
							}
							onMouseOut={onMouseOutStage}
						>
							<Tooltip
								placement="right"
								title={stageData.name}
								open={
									index === hoveredStageIndex &&
									isStageTooltipOpened
								}
								PopperProps={{
									modifiers: [
										{
											name: "offset",
											options: {
												offset: [0, 20],
											},
										},
									],
								}}
							>
								<Typography
									fontSize={13}
									maxWidth="100%"
									noWrap
								>
									{stageData.name}
								</Typography>
							</Tooltip>
						</MenuItem>
					))}
			</Menu>

			{dummy ? (
				<CustomStepIconRoot>
					<Box className="StepIcon-dummy" />
				</CustomStepIconRoot>
			) : (
				<Tooltip
					open={hoveredStageId === stage.id}
					title={stage.name}
					placement="top"
				>
					<CustomStepIconRoot
						onMouseOver={() => setHoveredStageId(stage.id)}
						onMouseOut={() => setHoveredStageId(null)}
						sx={{ cursor: "pointer" }}
						onClick={() => {
							setHoveredStageId(null);
							changeActiveStep(stage);
						}}
					>
						{completed ? (
							<Box className="StepIcon-completed" />
						) : active ? (
							<DealTimelineButton
								startIcon={
									<Box
										className={
											stage.name === predefinedStages.lost
												? "StepIcon-lost"
												: "StepIcon-completed"
										}
									/>
								}
								endIcon={
									!isEditActionEnabled
										? null
										: DropdownIcon(16, 16, "#fff")
								}
								variant="contained"
								color={
									stage.name === predefinedStages.lost
										? "error"
										: "primary"
								}
								onClick={(event) => openStagesMenu(event)}
								disableElevation
							>
								<Typography maxWidth="200px" noWrap>
									{stage.name}
								</Typography>
							</DealTimelineButton>
						) : (
							<Box className="StepIcon-circle" />
						)}
					</CustomStepIconRoot>
				</Tooltip>
			)}
		</React.Fragment>
	);
}

const StyledTooltip = styled(Tooltip)(() => ({
	[`& .${tooltipClasses.tooltipArrow}`]: {
		left: "310px !important",
	},
}));

const DealStagesTimeline = forwardRef((props, ref) => {
	const { dealId } = props;
	const theme = useTheme();
	const [activeStep, setActiveStep] = useState(0);
	const [pipelineMenuElement, setPipelineMenuElement] = useState(null);
	const isPipelineMenuOpened = Boolean(pipelineMenuElement);
	const [datePickerMenuElement, setDatePickerMenuElement] = useState(null);
	const isDatePickerMenuOpened = Boolean(datePickerMenuElement);
	const [selectedStageId, setSelectedStageId] = useState("");
	const [selectedPipeline, setSelectedPipeline] = useState({});
	const [expectedCloseDate, setExpectedCloseDate] = useState("");
	const [isWonDialogOpened, setIsWonDialogOpened] = useState(false);
	const [isLostDialogOpened, setIsLostDialogOpened] = useState(false);
	const [
		isSelectedPipelineTooltipOpened,
		setIsSelectedPipelineTooltipOpened,
	] = useState(false);

	// query call :-
	const { isUserAllowedFor } = useAuth();
	const isEditActionEnabled = isUserAllowedFor(PERMISSIONS.deal.edit);

	const { data: pipelineList } = usePipelineList();
	const hidePipelineDropdown =
		pipelineList?.length === 1 || !isEditActionEnabled;

	const updateStageMutation = useUpdateStageMutation(dealId);

	const updateExpectedCloseDateMutation =
		useUpdateExpectedCloseDateMutation(dealId);

	const updatePipelineMutation = useUpdatePipelineMutation(dealId);

	const { data: dealProgressViewData, status: dealProgressViewStatus } =
		useDealProgressViewData(dealId);

	const stages = dealProgressViewData?.progress;

	const handleActiveStage = useCallback(() => {
		if (stages) {
			const initialStageIndex = stages?.indexOf(
				stages?.find((stage) => stage?.status === "ACTIVE")
			);
			setSelectedStageId(stages?.[initialStageIndex]?.id);
			setActiveStep(initialStageIndex + 1);
		}
	}, [stages]);

	useEffect(() => {
		if (dealProgressViewStatus === "success") {
			if (dealProgressViewData) {
				handleActiveStage();
				setExpectedCloseDate(dealProgressViewData?.expectedCloseDate);
			}
		}
	}, [dealProgressViewData, dealProgressViewStatus, handleActiveStage]);

	useEffect(() => {
		if (pipelineList) {
			const initialPipeline = pipelineList?.find(
				(pipeline) => pipeline?.name === dealProgressViewData?.name
			);
			setSelectedPipeline(initialPipeline);
		}
	}, [pipelineList, dealProgressViewData]);

	useImperativeHandle(ref, () => ({
		setClosedWon: () => {
			changeActiveStepByNumber(stages.length + 2);
		},
		setClosedLost: () => {
			changeActiveStepByNumber(stages.length + 1);
		},
	}));

	const changeActiveStep = (stage) => {
		if (!isEditActionEnabled) {
			return;
		}

		let stageId = stage?.id;
		let isStageChanged = selectedStageId !== stage.id;

		for (let index = 0; index < stages.length + 1; index++) {
			if (stageId === stages[index].id) {
				changeActiveStepByNumber(index + 1);
				break;
			}
		}

		if (isStageChanged) {
			if (stage.name === predefinedStages.won) {
				setIsWonDialogOpened(true);
			} else if (stage.name === predefinedStages.lost) {
				setIsLostDialogOpened(true);
			} else {
				let stageUpdateRequestData = getStageUpdateRequest(stageId);
				handleUpdateStage(stageUpdateRequestData);
			}
			setSelectedStageId(stageId);
		}
	};

	const handleUpdateStage = (stageUpdateRequestData) => {
		updateStageMutation.mutate(stageUpdateRequestData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.dealUpdated,
				});
				setIsLostDialogOpened(false);
				setIsWonDialogOpened(false);
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	const changeActiveStepByNumber = (activeStep) => {
		setActiveStep(activeStep);
	};

	const getStageUpdateRequest = (stageId) => {
		let requestData = {};
		requestData.id = dealId;
		requestData.pipelineStageId = stageId;
		requestData.pipelineId = selectedPipeline?.id;
		return requestData;
	};

	const openPipelineMenu = (event) => {
		if (!hidePipelineDropdown) {
			setPipelineMenuElement(event?.currentTarget);
		}
		setIsSelectedPipelineTooltipOpened(false);
	};

	const closePipelineMenu = () => {
		setPipelineMenuElement(null);
	};

	const handleSelectPipeline = (pipeline) => {
		setSelectedPipeline(pipeline);
		setPipelineMenuElement(null);
		updatePipelineMutation.mutate(
			{ dealId: dealId, pipelineId: pipeline?.id },
			{
				onSuccess: () => {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.dealUpdated,
					});
				},
				onError: () => {
					enqueueSnackbar({
						variant: notificationVariants.error,
						message: notificationMessage.somethingWentWrong,
					});
				},
			}
		);
	};

	const handleExpectedCloseDate = (event) => {
		const value = event.valueOf();
		setExpectedCloseDate(value);
		updateExpectedCloseDateMutation.mutate(
			{ dealId: dealId, expectedCloseDate: value },
			{
				onSuccess: () => {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.dealUpdated,
					});
					setDatePickerMenuElement(null);
				},
				onError: () => {
					enqueueSnackbar({
						variant: notificationVariants.error,
						message: notificationMessage.somethingWentWrong,
					});
				},
			}
		);
	};

	const closeWonDialog = () => {
		setIsWonDialogOpened(false);
		handleActiveStage();
	};

	const closeLostDialog = () => {
		setIsLostDialogOpened(false);
		handleActiveStage();
	};

	const openDatePickerMenu = (event) => {
		setDatePickerMenuElement(event.currentTarget);
	};

	const closeDatePickerMenu = () => {
		setDatePickerMenuElement(null);
	};

	const onMouseOverSelectedPipeline = (event) => {
		setIsSelectedPipelineTooltipOpened(
			event.target.scrollWidth > event.target.clientWidth
		);
	};

	const onMouseOutSelectedPipeline = () => {
		setIsSelectedPipelineTooltipOpened(false);
	};

	return (
		<React.Fragment>
			<Menu
				minWidth="150px"
				anchorEl={datePickerMenuElement}
				open={isDatePickerMenuOpened}
				onClose={closeDatePickerMenu}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
				style={{ marginTop: "8px" }}
			>
				<DateCalendar
					value={dayjs(expectedCloseDate)}
					onChange={handleExpectedCloseDate}
					showDaysOutsideCurrentMonth
				/>
			</Menu>

			<Dialog open={isWonDialogOpened} title="Closed Won">
				<ClosedWon
					dealId={dealId}
					pipelineId={selectedPipeline?.id}
					pipelineStageId={selectedStageId}
					handleDealWon={handleUpdateStage}
					disableSaveButton={updateStageMutation.isLoading}
					closeWonDialog={closeWonDialog}
				/>
			</Dialog>

			<Dialog open={isLostDialogOpened} title="Closed Lost">
				<ClosedLost
					dealId={dealId}
					pipelineId={selectedPipeline?.id}
					pipelineStageId={selectedStageId}
					handleDealClose={handleUpdateStage}
					disableSaveButton={updateStageMutation.isLoading}
					closeLostDialog={closeLostDialog}
				/>
			</Dialog>

			<Box>
				<Menu
					anchorEl={pipelineMenuElement}
					open={isPipelineMenuOpened}
					onClose={closePipelineMenu}
					style={{
						maxHeight: "305px",
						// marginLeft: "67px"
					}}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "left",
					}}
					transformOrigin={{
						vertical: "right",
						horizontal: "left",
					}}
				>
					<Box minWidth="250px">
						{pipelineList?.map((pipeline) => (
							<MenuItem
								key={pipeline.id}
								onClick={() => handleSelectPipeline(pipeline)}
								style={{ minHeight: "40px", maxWidth: "400px" }}
							>
								<Typography
									fontSize={13}
									style={{
										whiteSpace: "normal",
										overflowWrap: "break-word",
										width: "100%",
									}}
								>
									{pipeline.name}
								</Typography>
							</MenuItem>
						))}
					</Box>
				</Menu>
			</Box>

			<Box
				sx={{
					backgroundColor: hexToRgba(
						theme.palette.primary.main,
						0.12
					),
					borderRadius: "12px",
					pt: 0.5,
				}}
			>
				<Stack
					direction="row"
					justifyContent="space-between"
					px={2}
					width="100%"
				>
					<TextButton onClick={openPipelineMenu}>
						<Stack
							direction="row"
							alignItems="center"
							spacing={0.5}
							onMouseOver={onMouseOverSelectedPipeline}
							onMouseOut={onMouseOutSelectedPipeline}
						>
							<StyledTooltip
								placement="top"
								title={selectedPipeline?.name}
								open={isSelectedPipelineTooltipOpened}
							>
								<Typography
									fontSize={13}
									fontWeight={500}
									maxWidth="667px"
									noWrap
								>
									{selectedPipeline?.name}
								</Typography>
							</StyledTooltip>

							{!hidePipelineDropdown
								? DropdownIcon(
										16,
										16,
										theme.palette.primary.main
									)
								: null}
						</Stack>
					</TextButton>

					<Stack direction="row" alignItems="center" spacing={1}>
						<Can permission={PERMISSIONS.deal.edit}>
							<Stack
								direction="row"
								alignItems="center"
								spacing={0.5}
							>
								{expectedCloseDate
									? FlagIcon(
											14,
											14,
											theme.palette.primary.main
										)
									: null}

								<Box
									onClick={openDatePickerMenu}
									style={{
										cursor: "pointer",
										alignItems: "center",
										borderRadius: "8px",
									}}
									py={0.5}
									px={1}
								>
									<Typography
										fontSize={13}
										fontWeight={500}
										color={
											theme.palette.secondary.contrastText
										}
									>
										{expectedCloseDate
											? getDateMonthAndYearFormat(
													expectedCloseDate
												)
											: "Set Expected Close Date"}
									</Typography>
								</Box>

								<Typography
									fontSize={13}
									fontWeight={500}
									color={theme.palette.secondary.contrastText}
								>
									•
								</Typography>
							</Stack>
						</Can>

						<Stack
							direction="row"
							alignItems="center"
							spacing={0.5}
							color={theme.palette.primary.main}
						>
							{ProbabilityIcon(
								14,
								14,
								theme.palette.primary.main
							)}

							<Typography fontSize={13} fontWeight={500}>
								{dealProgressViewData?.probability
									? dealProgressViewData?.probability
									: "0"}
								%
							</Typography>
						</Stack>
					</Stack>
				</Stack>

				<Box pt={2}>
					<Stepper
						activeStep={activeStep}
						alternativeLabel
						connector={<CustomConnector />}
					>
						<Step>
							<StepLabel
								StepIconComponent={CustomStepIcon}
								StepIconProps={{ dummy: true }}
							></StepLabel>
						</Step>

						{stages?.map((stage, index) => (
							<Step key={stage.id}>
								<StepLabel
									StepIconComponent={CustomStepIcon}
									StepIconProps={{
										stages: stages,
										stage: stage,
										changeActiveStep: changeActiveStep,
									}}
								>
									{index + 1 <= activeStep ? (
										<Typography
											style={{
												color: "grey",
												fontSize: "11px",
												fontWeight: 500,
											}}
										>
											{stage?.days === 0 || stage?.days
												? `${stage.days} Days`
												: ""}
										</Typography>
									) : (
										// Dummy placeholder for space if no date available
										<Typography>&nbsp;</Typography>
									)}
								</StepLabel>
							</Step>
						))}

						<Step>
							<StepLabel
								StepIconComponent={CustomStepIcon}
								StepIconProps={{ dummy: true }}
							></StepLabel>
						</Step>
					</Stepper>
				</Box>
			</Box>
		</React.Fragment>
	);
});

DealStagesTimeline.displayName = "DealStagesTimeline";

export default DealStagesTimeline;
