import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
	getContactDataKey,
	getCheckForDuplicatesKey,
	getContactTableKey,
	getContactSummaryKey,
	getContactSummaryViewKey,
	getContactListWithEmailsKey,
} from "../../../utils/queryKeys";
import {
	getDuplicateContactList,
	mergeDuplicateContact,
} from "../../../api/contacts/checkForDuplicates/checkForDuplicatesApi";
import {
	createContact,
	getContactData,
	assignOwnerToContacts,
	getContactSummaryData,
	deleteContact,
	updateContactSummary,
	getContactListWithEmails,
	partialUpdateContact,
	updateContact,
} from "../../../api/contacts/contactsApi";
import { getContactListKey } from "../../../utils/queryKeys";
import { getContactList } from "../../../api/contacts/contactsApi";
import { useInvalidateContactTimeline } from "./summary/timeline";
import { modules } from "../../../utils/common/ModulesName";
import { useInvalidateGroupFieldData } from "../common/field";

//contact details
const useContactData = (id) => {
	return useQuery(getContactDataKey(id), () => getContactData(id));
};

const useContactList = (isContactListEnabled) => {
	return useQuery(getContactListKey(), () => getContactList(), {
		select: (data) => data?.options,
		enabled: Boolean(isContactListEnabled),
	});
};

const useContactListWithEmails = (isContactListEnabled) => {
	return useQuery(
		getContactListWithEmailsKey(),
		() => getContactListWithEmails(),
		{
			select: (data) => data?.options,
			enabled: Boolean(isContactListEnabled),
		}
	);
};

const useContactDuplicateList = (contactDetails) => {
	return useQuery(getCheckForDuplicatesKey(contactDetails), () =>
		getDuplicateContactList(contactDetails)
	);
};

const useContactSummaryData = (contactId, hideEmptyFields) => {
	let contactSummaryKey = getContactSummaryViewKey(
		contactId,
		hideEmptyFields
	);
	return useQuery(contactSummaryKey, () =>
		getContactSummaryData(contactId, hideEmptyFields)
	);
};

const useInvalidateContactData = (contactId) => {
	let queryClient = useQueryClient();
	const contactDataKey = getContactDataKey(contactId);
	return () => {
		queryClient.invalidateQueries(contactDataKey);
	};
};

const useInvalidateContactSummaryData = (contactId) => {
	let queryClient = useQueryClient();
	const contactSummaryKey = getContactSummaryKey(contactId);
	return () => {
		queryClient.invalidateQueries(contactSummaryKey);
	};
};

const useEditContact = (contactId) => {
	let invalidateSummaryData = useInvalidateContactSummaryData(contactId);
	let invalidateContactTimeline = useInvalidateContactTimeline(contactId);
	let invalidateContactData = useInvalidateContactData(contactId);
	let invalidateContactTableData = useInvalidateContactTableData();
	return useMutation(updateContactSummary, {
		onSuccess: () => {
			invalidateContactTableData();
			invalidateSummaryData();
			invalidateContactData();
			invalidateContactTimeline();
		},
	});
};

//Invalidate Duplicate List
const useInvalidateDuplicateList = (contactDetails) => {
	let queryClient = useQueryClient();
	const duplicateListKey = getCheckForDuplicatesKey(contactDetails);
	return () => {
		queryClient.invalidateQueries(duplicateListKey);
	};
};

//Check for duplicates mutation
const useMergeDuplicateContactMutation = (contactDetails) => {
	let invalidateContactTableData = useInvalidateContactTableData();
	let invalidateDuplicateList = useInvalidateDuplicateList(contactDetails);
	return useMutation(mergeDuplicateContact, {
		onSuccess: () => {
			invalidateContactTableData();
			invalidateDuplicateList();
		},
	});
};

//Invalidate contact Table
const useInvalidateContactTableData = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries({ queryKey: getContactTableKey() });
	};
};

//contact Create or Clone
const useCreateMutation = () => {
	let invalidateContactTableData = useInvalidateContactTableData();
	return useMutation(createContact, {
		onSuccess: () => {
			invalidateContactTableData();
		},
	});
};

// partial update
const usePartialUpdateContact = (contactId) => {
	let invalidateSummaryData = useInvalidateContactSummaryData(contactId);
	let invalidateGroupFieldData = useInvalidateGroupFieldData(
		modules.CONTACT,
		contactId
	);
	return useMutation(partialUpdateContact, {
		onSuccess: () => {
			invalidateSummaryData();
			invalidateGroupFieldData();
		},
	});
};

// partial bulk update
const usePartialBulkUpdateContact = () => {
	let invalidateContactTableData = useInvalidateContactTableData();
	return useMutation(updateContact, {
		onSuccess: () => {
			invalidateContactTableData();
		},
	});
};

//Assign owner mutation
const useAssignOwnerToContacts = () => {
	let invalidateContactTableData = useInvalidateContactTableData();
	return useMutation(assignOwnerToContacts, {
		onSuccess: () => {
			invalidateContactTableData();
		},
	});
};

const useDeleteContact = () => {
	let invalidateContactTableData = useInvalidateContactTableData();
	return useMutation(deleteContact, {
		onSuccess: () => {
			invalidateContactTableData();
		},
	});
};

export {
	useContactDuplicateList,
	useMergeDuplicateContactMutation,
	useContactData,
	useCreateMutation,
	useAssignOwnerToContacts,
	useContactList,
	useDeleteContact,
	useInvalidateContactTableData,
	useContactSummaryData,
	useEditContact,
	useContactListWithEmails,
	usePartialUpdateContact,
	usePartialBulkUpdateContact,
};
