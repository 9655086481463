import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { handleFieldValidation } from "../../../../../../utils/fieldValidation";
import { ContactBaseComponent } from "./ContactBaseComponent";

export default function ContactComponent({
	field,
	inputRef,
	fieldSpecificProps: { isIconNeeded = true } = {},
}) {
	const {
		control,
		clearErrors,
		formState: { errors },
	} = useFormContext();

	return (
		<React.Fragment>
			<Controller
				name={field.id + ""}
				control={control}
				defaultValue=""
				rules={handleFieldValidation(field)}
				render={({ field: { value, onChange } }) => (
					<ContactBaseComponent
						onChange={onChange}
						value={value}
						field={field}
						inputRef={inputRef}
						error={errors[field.id]}
						clearErrors={clearErrors}
						isIconNeeded={isIconNeeded}
					/>
				)}
			></Controller>
		</React.Fragment>
	);
}
