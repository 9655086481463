import React, { useEffect, useState } from "react";
import {
	Box,
	Button,
	Divider,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { FormFieldName, hexToRgba, twozoStyles } from "../../styles/twozo";
import { default as EditIcon } from "../../assets/icons/edit";
import Trigger from "./Trigger";
import Action from "./Action";
import End from "./End";
import Connector from "./Connector";
import Condition from "./Condition";
import { useNavigate, useSearchParams } from "react-router-dom";
import Menu from "../Elements/Menu";
import { getTemplateListKey } from "../../utils/queryKeys/workflow";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
	createWorkflow,
	getTemplateData,
} from "../../api/workflow/workflowApi";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../utils/notification/notificationConfig";

const workflowType = {
	Trigger: "trigger",
	Condition: "condition",
	Action: "action",
	End: "end",
};

const defaultTriggerData = {
	type: workflowType.Trigger,
	sourceId: "",
	eventId: "",
};

const defaultConditionData = {
	type: workflowType.Condition,
	conditionSet: [
		{
			comparator: {},
			field: {},
			connector: 2,
			value: [],
		},
	],
	orConditionSet: [],
};

const defaultActionData = {
	type: workflowType.Action,
	source: {},
	event: {},
	fieldMapping: [],
};

const defaultEndData = {
	type: workflowType.End,
};

const defaultWorkflows = [
	{
		id: "1",
		...defaultTriggerData,
	},
];

export default function Workflow() {
	const theme = useTheme();
	let [queryParams] = useSearchParams();
	const templateId = queryParams.get("templateId");
	const navigate = useNavigate();
	const classes = twozoStyles();
	const [workflowName, setWorkflowName] = useState("Workflow 1");
	const [workflowDescription, setWorkflowDescription] = useState("");
	const [newWorkflowName, setNewWorkflowName] = useState("Workflow 1");
	const [newWorkflowDescription, setNewWorkflowDescription] = useState("");
	const [workflowMenuHovered, setWorkflowMenuHovered] = useState(false);
	const [workflowMenuElement, setWorkflowMenuElement] = useState(null);
	const isWorkflowMenuOpened = Boolean(workflowMenuElement);
	const [workflows, setWorkflows] = useState(defaultWorkflows);

	const createWorkflowMutation = useMutation(
		async (workflowData) => createWorkflow(workflowData),
		{
			onSuccess: () => {
				closeSnackbar();
				enqueueSnackbar({
					variant: notificationVariants.success,
					title: "Success!",
					preventDuplicate: true,
					message: "Workflow created successfully",
				});
			},
			onError: (error) => {
				error = JSON.parse(error.message);
				closeSnackbar();

				error?.validationErrors.forEach((validationError) => {
					enqueueSnackbar({
						variant: notificationVariants.error,
						title: "Error!",
						// autoHideDuration: 6000,
						message: validationError,
					});
				});
			},
		}
	);

	const templateKey = getTemplateListKey(templateId);
	const { data: templateData, isLoading: isLoadingTemplateData } = useQuery(
		templateKey,
		() => getTemplateData(templateId),
		{
			staleTime: 600000,
			enabled: !!templateId,
			select: (data) => data?.template,
		}
	);

	useEffect(() => {
		if (!isLoadingTemplateData && templateData) {
			let workflowData = [];

			setWorkflowName(templateData.name);
			setWorkflowDescription(templateData.description);

			// Add trigger
			workflowData.push({
				id: "trigger",
				type: workflowType.Trigger,
				sourceId: templateData.trigger.source,
				eventId: templateData.trigger.event,
			});

			// Add Condition
			if (templateData.conditions && templateData.conditions.length > 0) {
				workflowData.push({
					id: "condition",
					type: workflowType.Condition,
					conditionSet: templateData.conditions[0].criteria || [],
					orConditionSet:
						templateData.conditions.length > 1
							? templateData.conditions[1].criteria || []
							: [],
				});
			}

			// Add Action
			if (templateData.actions && templateData.actions.length > 0) {
				templateData.actions.forEach((action, index) => {
					workflowData.push({
						id: "action-" + index,
						type: workflowType.Action,
						source: action.source,
						event: action.event,
						fieldMapping: action.fieldMapping,
					});
				});
			}

			//Add End
			workflowData.push({
				id: "end",
				type: workflowType.End,
			});

			setWorkflows(workflowData);
		}
	}, [templateData, isLoadingTemplateData]);

	const styles = {
		text: {
			fontSize: "13px",
			fontWeight: 500,
		},
		title: {
			fontSize: "14px",
			fontWeight: 500,
		},
		textButton: {
			fontSize: "14px",
			fontWeight: 500,
			color: theme.palette.secondary.contrastText,
			cursor: "pointer",
			userSelect: "none",
		},
		selectTextButton: {
			fontSize: "14px",
			fontWeight: 500,
			color: theme.palette.secondary.contrastText,
			opacity: 0.6,
			cursor: "pointer",
			userSelect: "none",
		},
	};

	const OpenWorkflowMenu = (event) => {
		setNewWorkflowName(workflowName);
		setNewWorkflowDescription(workflowDescription);
		setWorkflowMenuElement(event.currentTarget);
	};

	const CloseWorkflowMenu = () => {
		setWorkflowMenuElement(null);
	};

	const SaveWorkflowNameAndDescription = () => {
		setWorkflowName(newWorkflowName);
		setWorkflowDescription(newWorkflowDescription);
		CloseWorkflowMenu();
	};

	const AddWorkflow = (newWorkflowType, index) => {
		let newWorkflow = {
			id: workflows.length + 1 + "",
		};

		switch (newWorkflowType) {
			case workflowType.Trigger:
				newWorkflow = {
					...newWorkflow,
					...defaultTriggerData,
				};
				break;
			case workflowType.Condition:
				newWorkflow = {
					...newWorkflow,
					...defaultConditionData,
				};
				break;
			case workflowType.Action:
				newWorkflow = {
					...newWorkflow,
					...defaultActionData,
				};
				break;
			case workflowType.End:
				newWorkflow = {
					...newWorkflow,
					...defaultEndData,
				};
				break;
			default:
				break;
		}

		setWorkflows((workflows) => [
			...workflows.slice(0, index),
			newWorkflow,
			...workflows.slice(index),
		]);
	};

	const AddAction = (index) => {
		AddWorkflow(workflowType.Action, index);
	};

	const deleteAction = (index) => {
		setWorkflows((workflows) => [
			...workflows.slice(0, index),
			...workflows.slice(index + 1),
		]);
	};

	const AddCondition = (index) => {
		AddWorkflow(workflowType.Condition, index);
	};

	const setTrigger = (index, sourceId, eventId) => {
		let updatedWorkflow = [...workflows];
		updatedWorkflow[index]["sourceId"] = sourceId;
		updatedWorkflow[index]["eventId"] = eventId;

		//Remove conditions and actions if trigger source or event changed
		updatedWorkflow = [updatedWorkflow[index]];
		setWorkflows(updatedWorkflow);

		if (updatedWorkflow.length === 1) {
			AddWorkflow(workflowType.Action, index + 1);
		}
	};

	const setAction = (index, source, event) => {
		let updatedWorkflow = [...workflows];
		updatedWorkflow[index]["source"]["id"] = source.id;
		updatedWorkflow[index]["source"]["name"] = source.name;
		if (event) {
			updatedWorkflow[index]["event"]["id"] = event.id;
			updatedWorkflow[index]["event"]["name"] = event.name;
		} else {
			updatedWorkflow[index]["event"] = {};
		}
		setWorkflows(updatedWorkflow);

		if (index === workflows.length - 1)
			AddWorkflow(workflowType.End, index + 1);
	};

	const setFieldMappings = (index, fieldMappings) => {
		let updatedWorkflow = [...workflows];
		updatedWorkflow[index]["fieldMapping"] = fieldMappings;
		setWorkflows(updatedWorkflow);
	};

	const getCriteriaFromCondition = (conditionSet) => {
		let criteria = [];
		conditionSet.forEach((condition) => {
			let conditionData = {};
			conditionData["fieldId"] = condition.field.id;
			conditionData["connector"] = 1; // Statically setting connector to 1 (AND) since there is no OR condition
			conditionData["comparator"] = condition.comparator.id;
			if (condition.values) {
				conditionData["values"] = condition.values;
			}
			criteria.push(conditionData);
		});
		return criteria;
	};

	const saveWorkflow = () => {
		let workflowRequestData = {};
		workflowRequestData["name"] = workflowName;
		workflowRequestData["description"] = workflowDescription;
		if (templateId) {
			workflowRequestData["templateId"] = templateId;
		}
		workflowRequestData["isActive"] = true;
		workflowRequestData["trigger"] = {};
		workflowRequestData["actions"] = [];

		workflows.forEach((workflow) => {
			switch (workflow.type) {
				case workflowType.Trigger:
					workflowRequestData.trigger["source"] = workflow.sourceId;
					workflowRequestData.trigger["event"] = workflow.eventId;
					break;
				case workflowType.Condition:
					workflowRequestData["conditions"] = [];
					if (
						workflow.conditionSet &&
						workflow.conditionSet.length > 0
					) {
						let criteria = getCriteriaFromCondition(
							workflow.conditionSet
						);
						workflowRequestData["conditions"].push({ criteria });
					}

					if (
						workflow.orConditionSet &&
						workflow.orConditionSet.length > 0
					) {
						let criteria = getCriteriaFromCondition(
							workflow.orConditionSet
						);
						workflowRequestData["conditions"].push({ criteria });
					}
					break;
				case workflowType.Action:
					(function () {
						let action = {};
						action["source"] = workflow.source.id;
						action["event"] = workflow.event.id;
						action["fieldMapping"] = [];
						workflow.fieldMapping.forEach((fieldMapping) => {
							fieldMapping = fieldMapping[0];
							let fieldMappingData = {
								fieldId: fieldMapping.fieldId,
								isEnabled:
									fieldMapping.config?.isEnabled ||
									fieldMapping.config?.isRequired ||
									false,
								isRequired:
									fieldMapping.config?.isRequired || false,
								values: fieldMapping.values || [],
							};
							action["fieldMapping"].push([fieldMappingData]);
						});
						workflowRequestData.actions.push(action);
					})();
					break;
			}
		});
		createWorkflowMutation.mutate(workflowRequestData);
	};

	return (
		<React.Fragment>
			<Menu
				anchorEl={workflowMenuElement}
				open={isWorkflowMenuOpened}
				onClose={CloseWorkflowMenu}
				minWidth="500px"
				style={{ marginTop: "-8px" }}
			>
				<Stack spacing={2} p={2}>
					<Stack spacing={0.5}>
						<FormFieldName>Title</FormFieldName>
						<TextField
							placeholder="Enter title"
							value={newWorkflowName}
							onChange={(e) => setNewWorkflowName(e.target.value)}
							fullWidth
						/>
					</Stack>

					<Stack spacing={0.5}>
						<FormFieldName>Description</FormFieldName>
						<TextField
							placeholder="Enter description here"
							value={newWorkflowDescription}
							onChange={(e) =>
								setNewWorkflowDescription(e.target.value)
							}
							fullWidth
						/>
					</Stack>

					<Stack
						direction="row"
						justifyContent="flex-end"
						spacing={2}
						p={1}
					>
						<Button
							variant="contained"
							color="secondary"
							onClick={CloseWorkflowMenu}
							disableElevation
						>
							Cancel
						</Button>

						<Button
							variant="contained"
							color="primary"
							onClick={SaveWorkflowNameAndDescription}
							disableElevation
						>
							Save
						</Button>
					</Stack>
				</Stack>
			</Menu>

			<Box
				style={{
					height: "calc(100% - 64px)", //TODO: The top bar height is handle as static value, In future It will be handled as dynamically
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box className={classes.menuBar}>
					<Stack
						direction="row"
						alignItems="center"
						spacing={2}
						height="100%"
					>
						<Box
							p={1}
							onClick={OpenWorkflowMenu}
							onMouseEnter={() => setWorkflowMenuHovered(true)}
							onMouseLeave={() => setWorkflowMenuHovered(false)}
							style={{ cursor: "pointer" }}
						>
							<Stack
								direction="row"
								alignItems="center"
								spacing={1}
							>
								<Typography fontWeight={500}>
									{workflowName}
								</Typography>

								{(workflowMenuHovered ||
									!!workflowMenuElement) && (
									<Box display="flex">
										{EditIcon(
											16,
											16,
											theme.palette.primary.main
										)}
									</Box>
								)}
							</Stack>

							<Typography
								fontSize={14}
								color={
									workflowDescription
										? "rgba(0, 0, 0, 0.6)"
										: hexToRgba(
												theme.palette.primary.main,
												0.6
											)
								}
								style={{ cursor: "pointer" }}
							>
								{workflowDescription
									? workflowDescription
									: "Add description"}
							</Typography>
						</Box>
					</Stack>
				</Box>

				<Divider />

				<Box
					style={{
						flexGrow: 1,
						overflowY: "auto",
						height: "0px", // Need height for the overflowY to work. Refer - https://stackoverflow.com/a/14964944/8653179
					}}
				>
					<Stack alignItems="center" p={4}>
						{workflows.map((workflow, index) => {
							switch (workflow.type) {
								case workflowType.Trigger:
									return (
										<Trigger
											key={workflow.id}
											index={index}
											styles={styles}
											sourceId={workflow.sourceId}
											eventId={workflow.eventId}
											setTrigger={setTrigger}
										/>
									);
								case workflowType.Condition:
									return (
										<React.Fragment key={workflow.id}>
											<Connector
												index={index}
												AddAction={AddAction}
												AddCondition={AddCondition}
											/>
											<Condition
												index={index}
												styles={styles}
												triggerSourceId={
													workflows[0].sourceId
												}
												triggerEventId={
													workflows[0].eventId
												}
												deleteAction={deleteAction}
												conditionSetData={
													workflow.conditionSet
												}
												orConditionSetData={
													workflow.orConditionSet
												}
											/>
										</React.Fragment>
									);
								case workflowType.Action:
									return (
										<React.Fragment key={workflow.id}>
											<Connector
												index={index}
												AddAction={AddAction}
												AddCondition={AddCondition}
											/>
											<Action
												index={index}
												styles={styles}
												triggerSourceId={
													workflows[0].sourceId
												}
												triggerEventId={
													workflows[0].eventId
												}
												setAction={setAction}
												setFieldMappings={
													setFieldMappings
												}
												source={workflow.source}
												event={workflow.event}
												fieldMapping={
													workflow.fieldMapping
												}
											/>
										</React.Fragment>
									);
								case workflowType.End:
									return (
										<React.Fragment key={workflow.id}>
											<Connector
												index={index}
												AddAction={AddAction}
												AddCondition={AddCondition}
											/>
											<End
												index={index}
												styles={styles}
											/>
										</React.Fragment>
									);
							}
							return null;
						})}
					</Stack>
				</Box>

				<Divider />

				<Box className={classes.menuBar}>
					<Stack
						direction="row"
						justifyContent="flex-end"
						alignItems="center"
						spacing={2}
						height="100%"
					>
						<Button
							variant="contained"
							color="secondary"
							onClick={() => navigate(-1)}
							disableElevation
						>
							Cancel
						</Button>

						<Button
							variant="contained"
							color="primary"
							onClick={saveWorkflow}
							disabled={createWorkflowMutation.isLoading}
							disableElevation
							style={{
								minWidth: "112px",
							}}
						>
							Save
						</Button>
					</Stack>
				</Box>
			</Box>
		</React.Fragment>
	);
}
