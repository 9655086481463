import React, { useState } from "react";
import {
	Box,
	Stack,
	Typography,
	IconButton,
	MenuItem,
	TextField,
	Autocomplete,
	Chip,
	Popper,
	useTheme,
	ListSubheader,
} from "@mui/material";
import { default as closeIcon } from "../../../../../../assets/icons/close";
import { default as DropDownIcon } from "../../../../../../assets/icons/dropDown";
import Menu from "../../../../Menu";
import { default as CloseIcon } from "../../../../../../assets/icons/close";
import { getDropdownDataByName } from "../../../../../../utils/dropdownData";
import {
	useAdditionalOptions,
	useValueDropDown,
} from "../../../../../../hooks/services/common/table/filter";
import {
	fieldType,
	filterConnectorsList,
} from "../../../../../../utils/FilterUtills";

const CustomPopper = function (props) {
	return (
		<Popper
			{...props}
			style={{ ...props.style, padding: "7px 0px", width: "202px" }}
		/>
	);
};

export default function FilterFields(props) {
	const {
		connector,
		fieldList,
		filterData,
		selectedField,
		comparator,
		selectedIndex,
		activateEditMode,
		updateFilterCondition,
		updateSelectedFieldDetails,
		updateSelectedIndex,
		updateSelectedSource,
	} = props;

	const theme = useTheme();

	const styles = {
		header: {
			alignItems: "center",
			justifyContent: "space-between",
			width: "218px",
			paddingLeft: "10px",
		},
		data: {
			alignItems: "center",
			justifyContent: "space-between",
			width: "218px",
			height: "38px",
			padding: "0px 10px",
			backgroundColor: "rgba(51, 188, 126, 0.12)",
		},
	};

	//FieldList
	const [fieldListMenuElement, setFieldListMenuElement] = useState(null);
	const isOpenFieldListMenu = Boolean(fieldListMenuElement);

	const openFieldMenu = (event, index) => {
		setFieldListMenuElement(event.currentTarget);
		updateSelectedIndex(index);
		updateSelectedSource(filterData[index].sourceId.value);
	};

	const closeFieldMenu = () => {
		setFieldListMenuElement(null);
	};

	const handleSelectField = (data) => {
		const updatedFilterData = [...filterData];
		const selectedFieldData = { ...updatedFilterData[selectedIndex] };
		//empty the values
		selectedFieldData.comparatorId.value = { id: "", name: "" };
		selectedFieldData.additionalOption.displayName = "";
		selectedFieldData.additionalOption.value = { name: "", entityId: "" };
		selectedFieldData.valueEntityId.value = "";
		selectedFieldData.value.value = "";

		//update the selected Value
		selectedFieldData.fieldData = data;
		selectedFieldData.fieldId.value.id = data.fieldId ? data.fieldId : "";
		selectedFieldData.fieldId.value.name = data.fieldId ? data.name : "";

		selectedFieldData.optionId.value.id = data.optionId
			? data.optionId
			: "";
		selectedFieldData.optionId.value.name = data.optionId ? data.name : "";

		updatedFilterData[selectedIndex] = selectedFieldData;
		updateFilterCondition(updatedFilterData);
		updateSelectedFieldDetails(selectedFieldData.fieldData);
		setFieldListMenuElement(null);
		activateEditMode();
	};

	//Comparator
	const [comparatorMenuElement, setComparatorMenuElement] = useState(null);
	const isComparatorMenuOpen = Boolean(comparatorMenuElement);

	const openComparatorMenu = (event, index) => {
		updateSelectedFieldDetails(filterData[index].fieldData);
		setComparatorMenuElement(event.currentTarget);
		updateSelectedIndex(index);
	};

	const closeComparatorMenu = () => {
		setComparatorMenuElement(null);
	};

	const handleSelectComparator = (data) => {
		const updatedFilterData = [...filterData];
		const selectedFieldData = { ...updatedFilterData[selectedIndex] };
		//empty values
		selectedFieldData.valueEntityId.value = "";
		selectedFieldData.value.value = "";

		//update selected values
		selectedFieldData.comparatorId.value.id = data.id;
		selectedFieldData.comparatorId.value.name = data.name;
		updatedFilterData[selectedIndex] = selectedFieldData;
		updateFilterCondition(updatedFilterData);
		setComparatorMenuElement(null);
		activateEditMode();
	};

	//Additional Options
	const [additionalOptionsMenuElement, setAdditionalOptionsMenuElement] =
		useState(null);
	const isAdditionalOptionsMenuElement = Boolean(
		additionalOptionsMenuElement
	);

	const openAdditionalOptionsMenu = (event, index) => {
		updateSelectedFieldDetails(filterData[index].fieldData);
		setAdditionalOptionsMenuElement(event.currentTarget);
		updateSelectedIndex(index);
	};

	const closeAdditionalOptionsMenu = () => {
		setAdditionalOptionsMenuElement(null);
	};

	const handleSelectOption = (data) => {
		const updatedFilterData = [...filterData];
		const selectedFieldData = { ...updatedFilterData[selectedIndex] };
		//empty values
		selectedFieldData.valueEntityId.value = "";
		selectedFieldData.value.value = "";

		//update selected Values
		selectedFieldData.additionalOption.displayName = data.displayName;
		selectedFieldData.additionalOption.value.name = data.name;
		selectedFieldData.additionalOption.value.entityId = data.id;
		selectedFieldData.additionaloptions = data;
		updatedFilterData[selectedIndex] = selectedFieldData;
		updateFilterCondition(updatedFilterData);
		setAdditionalOptionsMenuElement(null);
		activateEditMode();
	};

	const getFieldTypeId = () => {
		if (
			!!selectedField?.additionalOptionsDropdown &&
			selectedField.additionalOptionsDropdown[0]?.requiredFields &&
			selectedField.additionalOptionsDropdown[0]?.requiredFields[0] ===
				"fieldTypeId"
		) {
			return selectedField.fieldType?.id;
		}
	};

	const fieldTypeId = getFieldTypeId();

	const { data: additionaloptions } = useAdditionalOptions(
		fieldTypeId,
		selectedField?.additionalOptionsDropdown
	);

	// Value DropDown
	const [dropDownMenuElement, setDropDownMenuElement] = useState(null);
	const isDropDownMenuElement = Boolean(dropDownMenuElement);

	const openDropDownMenu = (event, index) => {
		updateSelectedFieldDetails(filterData[index].fieldData);
		setDropDownMenuElement(event.currentTarget);
		updateSelectedIndex(index);
	};

	const closeDropDownMenu = () => {
		setDropDownMenuElement(null);
	};

	const handleSelectDropDownData = (data) => {
		const updatedFilterData = [...filterData];
		const selectedFieldData = { ...updatedFilterData[selectedIndex] };
		selectedFieldData.valueEntityId.value = data.value
			? data.value
			: data.id;
		selectedFieldData.value.value = data.name;
		updatedFilterData[selectedIndex] = selectedFieldData;
		updateFilterCondition(updatedFilterData);
		setDropDownMenuElement(null);
		activateEditMode();
	};

	const handleSelectMultipleData = (data, index) => {
		const updatedFilterData = [...filterData];

		data.forEach((selectedValue) => {
			const updatedFieldData = { ...updatedFilterData[index] };
			updatedFieldData.valueEntityId.value = selectedValue.value
				? selectedValue.value
				: selectedValue.id;
			updatedFieldData.value.value = selectedValue.name;
			updatedFilterData[index] = updatedFieldData;
		});
		updateFilterCondition(updatedFilterData);
		activateEditMode();
	};

	const selectedDropDownId = selectedField.fieldId
		? selectedField.fieldId
		: selectedField.optionId;
	const selectedDropDownName = selectedField.valueDropdown?.valueDropdownName
		? selectedField.valueDropdown?.valueDropdownName
		: null;

	const getDropdownRequest = () => {
		if (selectedField?.valueDropdown) {
			if (
				!!selectedField?.valueDropdown?.requiredFields &&
				selectedField?.valueDropdown?.requiredFields[0] === "id"
			) {
				return { id: selectedDropDownId };
			} else if (
				!!selectedField?.valueDropdown?.requiredFields &&
				selectedField?.valueDropdown?.requiredFields[0] === "sourceId"
			) {
				return {
					name: selectedDropDownName,
					sourceId: filterData[selectedIndex]?.sourceId?.value?.id,
				};
			} else {
				return { name: selectedDropDownName };
			}
		}
	};

	const { data: dropDownData } = useValueDropDown(
		getDropdownRequest(),
		selectedDropDownId,
		!!selectedField?.valueDropdown
	);

	//Textfield
	const handleInputChange = (event, index) => {
		const { name: fieldName, value } = event.target;
		const updatedFilterData = [...filterData];
		const updatedFilterValue = {
			...updatedFilterData[index][fieldName],
			value,
		};
		updatedFilterData[index] = {
			...updatedFilterData[index],
			[fieldName]: updatedFilterValue,
		};
		updateFilterCondition(updatedFilterData);
		activateEditMode();
	};

	const handleNumberInputChange = (event, index) => {
		const { name: fieldName, value } = event.target;
		const numericValue = value.replace(/[^0-9]/g, "");
		let parsed = parseInt(numericValue);

		const updatedFilterData = [...filterData];
		const updatedFilterValue = {
			...updatedFilterData[index][fieldName],
			value: isNaN(parsed) ? "" : parsed,
		};
		updatedFilterData[index] = {
			...updatedFilterData[index],
			[fieldName]: updatedFilterValue,
		};
		updateFilterCondition(updatedFilterData);
		activateEditMode();
	};

	//Time and TimeRange DropDown
	const time = getDropdownDataByName("TIME");
	const [timeMenuElement, setTimeMenuElement] = useState(null);
	const isOpenTimeMenu = Boolean(timeMenuElement);

	const opentimeMenu = (event, index) => {
		updateSelectedFieldDetails(filterData[index].fieldData);
		setTimeMenuElement(event.currentTarget);
		updateSelectedIndex(index);
	};

	const closeTimeMenu = () => {
		setTimeMenuElement(null);
	};

	const handleSelectDropDown = (data) => {
		const selectedFieldData = filterData[selectedIndex];
		selectedFieldData.valueEntityId.value = data.value;
		selectedFieldData.value.value = data.name;
		updateFilterCondition([...filterData]);
		setTimeMenuElement(null);
		activateEditMode();
	};

	//currency dropdown
	const currencyName = getDropdownDataByName("CURRENCY");
	const [currencyMenuElement, setCurrencyMenuElement] = useState(null);
	const isOpenCurrencyMenu = Boolean(currencyMenuElement);

	const openCurrencyMenu = (event, index) => {
		updateSelectedFieldDetails(filterData[index].fieldData);
		setCurrencyMenuElement(event.currentTarget);
		updateSelectedIndex(index);
	};

	const closeCurrencyMenu = () => {
		setCurrencyMenuElement(null);
	};

	const handleSelectCurrency = (data) => {
		const selectedFieldData = filterData[selectedIndex];
		selectedFieldData.valueEntityId.value = data.value;
		selectedFieldData.value.value = data.name;
		selectedFieldData.selectedCurrency = data;
		updateFilterCondition([...filterData]);
		setCurrencyMenuElement(null);
		activateEditMode();
	};

	// Remove Filter
	const removeSingleFilterCondition = (index) => {
		filterData.splice(index, 1);
		updateFilterCondition([...filterData]);
		activateEditMode();
	};

	const [focusTextField, setFocusTextField] = useState(false);

	const onFocusTextField = (index) => {
		setFocusTextField(true);
		updateSelectedIndex(index);
	};

	const onBlurTextField = (index) => {
		setFocusTextField(false);
		updateSelectedIndex(index);
	};

	const handleOpenDropdownMenu = (data, event, index) => {
		if (
			data.fieldData.fieldType.name === fieldType.time ||
			data.fieldData.fieldType.name === fieldType.timeRange
		) {
			opentimeMenu(event, index);
		} else if (
			data.fieldData.fieldType.name === fieldType.monetary ||
			data.fieldData.fieldType.name === fieldType.premiumProduct
		) {
			openCurrencyMenu(event, index);
		} else openDropDownMenu(event, index);
	};

	const getField = (data, index) => {
		switch (data.fieldData.fieldType.name) {
			case fieldType.multiSelect:
			case fieldType.tags:
			case fieldType.contactsOnboard:
				return (
					<Autocomplete
						size="small"
						multiple
						disableClearable
						forcePopupIcon={false}
						PopperComponent={CustomPopper}
						options={dropDownData || []}
						getOptionLabel={(option) => option.name}
						noOptionsText="No Results Found"
						onChange={(_, data) =>
							handleSelectMultipleData(data, index)
						}
						renderTags={(dropDownData, getTagProps) =>
							dropDownData.map((option, index) => (
								<Chip
									key={index}
									size="small"
									label={
										<Typography fontSize={14} color="#000">
											{option.name ? option.name : option}
										</Typography>
									}
									deleteIcon={CloseIcon(16, 16, "#000")}
									sx={{
										borderRadius: "8px",
										backgroundColor: "#FFF",
									}}
									{...getTagProps({
										index,
									})}
								/>
							))
						}
						renderOption={(props, option) => (
							<Box {...props}>
								<Typography fontSize={14}>
									{option.name ? option.name : option}
								</Typography>
							</Box>
						)}
						renderInput={(params) => (
							<TextField
								sx={{
									backgroundColor: "rgba(51, 188, 126, 0.12)",
									padding: "5px 10px 0px 10px",
									width: "218px",
									minHeight: "38px",
									borderRadius: "0px 0px 8px 8px",
									borderBottom:
										selectedIndex === index &&
										focusTextField
											? `1px solid ${theme.palette.primary.main}`
											: null,
								}}
								{...params}
								variant="standard"
								InputProps={{
									...params.InputProps,
									disableUnderline: true,
									style: {
										fontSize: "14px",
									},
								}}
								placeholder={
									data.fieldData.fieldType.name ===
									fieldType.tags
										? "Enter"
										: "Select"
								}
								onFocus={() => onFocusTextField(index)}
								onBlur={() => onBlurTextField(index)}
							/>
						)}
					/>
				);
			//Single Select
			case fieldType.contact:
			case fieldType.contactOnboard:
			case fieldType.company:
			case fieldType.companyOnboard:
				return (
					<Autocomplete
						size="small"
						disableClearable
						forcePopupIcon={false}
						PopperComponent={CustomPopper}
						options={dropDownData || []}
						getOptionLabel={(option) => option.name}
						onChange={(_, data) => handleSelectDropDown(data)}
						noOptionsText="No Results Found"
						renderTags={(dropDownData, getTagProps) =>
							dropDownData?.map((option, index) => (
								<Chip
									key={index}
									size="small"
									label={
										<Typography fontSize={14} color="#000">
											{option.name ? option.name : option}
										</Typography>
									}
									deleteIcon={CloseIcon(16, 16, "#000")}
									sx={{
										borderRadius: "8px",
										backgroundColor: "#FFF",
									}}
									{...getTagProps({
										index,
									})}
								/>
							))
						}
						renderOption={(props, option) => (
							<Box {...props} sx={{ height: "40px" }}>
								<Typography fontSize={14}>
									{option.name ? option.name : option}
								</Typography>
							</Box>
						)}
						renderInput={(params) => (
							<TextField
								sx={{
									backgroundColor: "rgba(51, 188, 126, 0.12)",
									padding: "5px 10px 0px 10px",
									width: "218px",
									minHeight: "38px",
									borderRadius: "0px 0px 8px 8px",
									borderBottom:
										selectedIndex === index &&
										focusTextField
											? `1px solid ${theme.palette.primary.main}`
											: null,
								}}
								{...params}
								variant="standard"
								InputProps={{
									...params.InputProps,
									disableUnderline: true,
									style: {
										fontSize: "14px",
									},
								}}
								placeholder="Search"
								onFocus={() => onFocusTextField(index)}
								onBlur={() => onBlurTextField(index)}
							/>
						)}
					/>
				);
			case fieldType.time:
			case fieldType.timeRange:
			case fieldType.premiumProduct:
			case fieldType.owner:
			case fieldType.date:
			case fieldType.dropdown:
			case fieldType.territory:
				return (
					<Stack
						direction="row"
						style={{
							...styles.data,
							cursor: "pointer",
							borderRadius: "0px 0px 8px 8px",
							borderBottom:
								(selectedIndex === index &&
									!!dropDownMenuElement) ||
								(selectedIndex === index && !!timeMenuElement)
									? `1px solid ${theme.palette.primary.main}`
									: null,
						}}
						onClick={(event) =>
							handleOpenDropdownMenu(data, event, index)
						}
					>
						{data.value.value ? (
							<Typography fontSize={14}>
								{data.selectedCurrency?.code
									? data.selectedCurrency.code
									: data.value.value}
							</Typography>
						) : (
							<Typography
								sx={{
									fontSize: "14px",
									color: "rgba(0,0,0,0.4)",
								}}
							>
								Select
							</Typography>
						)}
						{DropDownIcon(12, 12)}
					</Stack>
				);
			case fieldType.singleNumber:
			case fieldType.monetary:
				return (
					<Stack
						direction="row"
						style={{
							...styles.data,
							borderRadius: "0px 0px 8px 8px",
							borderBottom:
								selectedIndex === index && focusTextField
									? `1px solid ${theme.palette.primary.main}`
									: null,
						}}
					>
						<TextField
							name={data.value.fieldName}
							value={data.value.value}
							onChange={(event) =>
								handleNumberInputChange(event, index)
							}
							variant="standard"
							InputProps={{
								disableUnderline: true,
							}}
							placeholder="Enter Here"
							onFocus={() => onFocusTextField(index)}
							onBlur={() => onBlurTextField(index)}
						/>
					</Stack>
				);
			default:
				return (
					<Stack
						direction="row"
						style={{
							...styles.data,
							borderRadius: "0px 0px 8px 8px",
							borderBottom:
								selectedIndex === index && focusTextField
									? `1px solid ${theme.palette.primary.main}`
									: null,
						}}
					>
						<TextField
							name={data.value.fieldName}
							value={data.value.value}
							onChange={(event) =>
								handleInputChange(event, index)
							}
							variant="standard"
							InputProps={{
								disableUnderline: true,
							}}
							placeholder="Enter Here"
							onFocus={() => onFocusTextField(index)}
							onBlur={() => onBlurTextField(index)}
						/>
					</Stack>
				);
		}
	};

	return (
		<React.Fragment>
			{/* time */}
			<Menu
				minWidth="202px"
				anchorEl={timeMenuElement}
				open={isOpenTimeMenu}
				onClose={closeTimeMenu}
				style={{ transform: "translateY(3px)" }}
				PaperProps={{
					style: {
						maxHeight: "400px",
					},
				}}
			>
				{time.map((data, index) => (
					<MenuItem
						style={{ height: "40px" }}
						key={index}
						onClick={() => handleSelectDropDown(data)}
					>
						<Typography fontSize={13}>{data.name}</Typography>
					</MenuItem>
				))}
			</Menu>

			{/* currency */}
			<Menu
				minWidth="202px"
				anchorEl={currencyMenuElement}
				open={isOpenCurrencyMenu}
				onClose={closeCurrencyMenu}
				style={{ transform: "translateY(3px)" }}
				PaperProps={{
					style: {
						maxHeight: "400px",
					},
				}}
			>
				{currencyName.map((data, index) => (
					<MenuItem
						style={{ height: "40px" }}
						key={index}
						onClick={() => handleSelectCurrency(data)}
					>
						<Stack
							width="300px"
							direction="row"
							justifyContent="space-between"
						>
							<Typography fontSize={14} fontWeight={400}>
								{data.name}
							</Typography>
							<Typography fontSize={14} fontWeight={400}>
								{data.code}
							</Typography>
						</Stack>
					</MenuItem>
				))}
			</Menu>

			{/* fieldList */}
			<Menu
				minWidth="202px"
				anchorEl={fieldListMenuElement}
				open={isOpenFieldListMenu}
				onClose={closeFieldMenu}
				style={{ transform: "translateX(8px) translateY(-1px)" }}
				PaperProps={{
					style: {
						maxHeight: "400px",
					},
				}}
			>
				{fieldList?.map((data, index) => (
					<MenuItem
						style={{ height: "40px" }}
						key={index}
						onClick={() => handleSelectField(data)}
					>
						<Typography fontSize={13}>{data.name}</Typography>
					</MenuItem>
				))}
			</Menu>

			{/* comparator */}
			<Menu
				minWidth="202px"
				anchorEl={comparatorMenuElement}
				open={isComparatorMenuOpen}
				onClose={closeComparatorMenu}
				style={{ transform: "translateX(8px) translateY(-1px)" }}
				PaperProps={{
					style: {
						maxHeight: "400px",
					},
				}}
			>
				{comparator?.map((data, index) => (
					<MenuItem
						style={{ height: "40px" }}
						key={index}
						onClick={() => handleSelectComparator(data)}
					>
						<Typography fontSize={13}>{data.name}</Typography>
					</MenuItem>
				))}
			</Menu>

			{/* additionalOption */}
			<Menu
				minWidth="202px"
				anchorEl={additionalOptionsMenuElement}
				open={isAdditionalOptionsMenuElement}
				onClose={closeAdditionalOptionsMenu}
				style={{ transform: "translateX(8px) translateY(-1px)" }}
				PaperProps={{
					style: {
						maxHeight: "400px",
					},
				}}
			>
				{additionaloptions?.map((data, index) => (
					<MenuItem
						style={{ height: "40px" }}
						key={index}
						onClick={() => handleSelectOption(data)}
					>
						<Typography fontSize={13}>
							{data.displayName}
						</Typography>
					</MenuItem>
				))}
			</Menu>

			{/* ValuedropDown */}
			<Menu
				minWidth="202px"
				anchorEl={dropDownMenuElement}
				open={isDropDownMenuElement}
				onClose={closeDropDownMenu}
				style={{ transform: "translateX(8px) translateY(-1px)" }}
				PaperProps={{
					style: {
						maxHeight: "400px",
					},
				}}
			>
				{selectedDropDownName === "dateIntervalList"
					? dropDownData?.map((data, index) => (
							<Stack key={index}>
								<ListSubheader>
									<Typography
										fontSize={13}
										color="#000"
										fontWeight={600}
									>
										{data.category}
									</Typography>
								</ListSubheader>

								{data?.days?.map((day) => (
									<MenuItem
										key={day.id}
										value={day.id}
										onClick={() =>
											handleSelectDropDownData(day)
										}
									>
										<Typography fontSize={13}>
											{day.name}
										</Typography>
									</MenuItem>
								))}
							</Stack>
						))
					: dropDownData?.map((data, index) => (
							<MenuItem
								style={{ height: "40px" }}
								key={index}
								onClick={() => handleSelectDropDownData(data)}
							>
								<Typography fontSize={13}>
									{data.name}
								</Typography>
							</MenuItem>
						))}
			</Menu>

			{filterData?.map((data, index) => (
				<Box key={index}>
					{(connector === filterConnectorsList.AND &&
						data.connectorId.value.name ===
							filterConnectorsList.AND) ||
					(connector === filterConnectorsList.OR &&
						data.connectorId.value.name ===
							filterConnectorsList.OR) ? (
						<Box p={1}>
							<Stack>
								<Stack
									direction="row"
									style={styles.header}
									key={index}
								>
									<Typography fontSize={13} fontWeight={500}>
										{data.sourceId.value.name}
									</Typography>
									<IconButton
										style={{ cursor: "pointer" }}
										onClick={() =>
											removeSingleFilterCondition(index)
										}
									>
										{closeIcon(20, 20, "#000", "0.6")}
									</IconButton>
								</Stack>

								<Stack
									direction="row"
									style={{
										...styles.data,
										cursor: "pointer",
										borderRadius:
											data.fieldId.value?.name ||
											data.optionId.value?.name
												? "8px 8px 0px 0px"
												: "8px 8px 8px 8px",
										borderBottom:
											(selectedIndex === index &&
											fieldListMenuElement
												? `1px solid ${theme.palette.primary.main}`
												: null) ||
											(data.fieldId.value?.name ||
											data.optionId.value?.name
												? "1px solid rgba(0,0,0,0.1)"
												: null),
									}}
									onClick={(event) =>
										openFieldMenu(event, index)
									}
								>
									{data.fieldId.value?.name ||
									data.optionId.value?.name ? (
										<Typography fontSize={14}>
											{data.fieldId.value?.name
												? data.fieldId.value?.name
												: data.optionId.value?.name}
										</Typography>
									) : (
										<Typography
											sx={{
												fontSize: "14px",
												color: "rgba(0,0,0,0.4)",
											}}
										>
											Select Field
										</Typography>
									)}

									{DropDownIcon(12, 12)}
								</Stack>

								{(data.fieldId.value?.name ||
									data.optionId.value?.name) && (
									<Stack>
										{data.fieldData
											.additionalOptionsDropdown && (
											<Stack
												direction="row"
												style={{
													...styles.data,
													cursor: "pointer",
													borderBottom:
														selectedIndex ===
															index &&
														!!additionalOptionsMenuElement
															? `1px solid ${theme.palette.primary.main}`
															: "1px solid rgba(0,0,0,0.1)",
												}}
												onClick={(event) =>
													openAdditionalOptionsMenu(
														event,
														index
													)
												}
											>
												{data.additionalOption
													.displayName ? (
													<Typography fontSize={14}>
														{
															data
																.additionalOption
																.displayName
														}
													</Typography>
												) : (
													<Typography
														sx={{
															fontSize: "14px",
															color: "rgba(0,0,0,0.4)",
														}}
													>
														Select
													</Typography>
												)}
												{DropDownIcon(12, 12)}
											</Stack>
										)}

										<Stack
											direction="row"
											style={{
												...styles.data,
												cursor: "pointer",
												borderRadius:
													data.comparatorId.value
														.name === "is empty" ||
													data.comparatorId.value
														.name === "is not empty"
														? "0px 0px 8px 8px"
														: null,
												borderBottom:
													selectedIndex === index &&
													comparatorMenuElement
														? `1px solid ${theme.palette.primary.main}`
														: data.comparatorId
																	.value
																	.name ===
																	"is empty" ||
															  data.comparatorId
																	.value
																	.name ===
																	"is not empty"
															? null
															: "1px solid rgba(0,0,0,0.1)",
											}}
											onClick={(event) =>
												openComparatorMenu(event, index)
											}
										>
											{data.comparatorId.value.name ? (
												<Typography fontSize={14}>
													{
														data.comparatorId.value
															.name
													}
												</Typography>
											) : (
												<Typography
													sx={{
														fontSize: "14px",
														color: "rgba(0,0,0,0.4)",
													}}
												>
													Select
												</Typography>
											)}
											{DropDownIcon(12, 12)}
										</Stack>

										{data.comparatorId.value.name ===
											"is empty" ||
										data.comparatorId.value.name ===
											"is not empty" ? null : (
											<>{getField(data, index)}</>
										)}
									</Stack>
								)}
							</Stack>
						</Box>
					) : null}
				</Box>
			))}
		</React.Fragment>
	);
}
