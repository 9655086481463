import React from "react";
import EmailList from "../EmailList";
import { mailListName } from "../../../utils/EmailUtils/mailListName";

export default function Trash() {
	const menuConfig = {
		isDeleteForeverEnabled: true,
		isRestoreEnabled: true,
	};

	return (
		<React.Fragment>
			<EmailList
				listHeader="Trash"
				mailListName={mailListName.TRASHED}
				hasTrashed={true}
				menuConfig={menuConfig}
				HideFavoriteAction={true}
			/>
		</React.Fragment>
	);
}
