import React, { useState } from "react";
import {
	Box,
	Checkbox,
	Divider,
	IconButton,
	MenuItem,
	MenuList,
	Popover,
	Stack,
	useTheme,
} from "@mui/material";
import { default as DealsIcon } from "../../../assets/icons/deals";
import { default as CompanyIcon } from "../../../assets/icons/company";
import { default as contactImage1 } from "../../../assets/images/contact/profilePicture.png";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "../../../styles/twozo";
import { searchFilterConstraints } from "../../../utils/searchUtils";
import { useUpdateSearchedAndViewed } from "../../../hooks/services/search";
import { useUpdateActivityStatus } from "../../../hooks/services/activities";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../utils/notification/notificationMessages";
import DealMenu from "../SearchMenu/DealMenu";
import ContactMenu from "../SearchMenu/ContactMenu";
import CompanyMenu from "../SearchMenu/CompanyMenu";
import Product from "../SearchResults/ResultCard/Product";
import Notes from "../SearchResults/ResultCard/Note";
import Deal from "../SearchResults/ResultCard/Deal";
import Contact from "../SearchResults/ResultCard/Contact";
import Company from "../SearchResults/ResultCard/Company";
import Activity from "../SearchResults/ResultCard/Activity";
import File from "../SearchResults/ResultCard/File";
import { useAuth } from "../../../hooks/auth";
import { PERMISSIONS } from "../../../utils/Auth";
import Can from "../../Auth/Can";

export default function Result(props) {
	const {
		results,
		searchKeyword,
		onSearchedOptionClicked,
		onActivityUpdateSuccess,
	} = props;
	const theme = useTheme();
	const navigate = useNavigate();
	// query call:-
	const { isUserAllowedFor } = useAuth();

	// mutation call
	const recentlySearchedMutation = useUpdateSearchedAndViewed();
	const updateActivityMutation = useUpdateActivityStatus();

	const [dealMenuElement, setDealMenuElement] = useState(null);
	const isDealMenuOpened = Boolean(dealMenuElement);
	const [contactMenuElement, setContactMenuElement] = useState(null);
	const isContactMenuOpened = Boolean(contactMenuElement);
	const [companyMenuElement, setCompanyMenuElement] = useState(null);
	const isCompanyMenuOpened = Boolean(companyMenuElement);

	const [isTooltipOpen, setIsTooltipOpen] = useState(false);
	const [hoveredSearchCardIndex, setHoveredSearchCardIndex] = useState(null);

	const [hoveredIndex, setHoveredIndex] = useState(null);
	const [hoveredSearchDetails, setHoveredSearchDetails] = useState({});

	const onMouseOverDealMenu = (event, result, index, isActionEnabled) => {
		if (!isActionEnabled) {
			return;
		}

		setHoveredIndex(index);
		setHoveredSearchDetails(result);
		setDealMenuElement(event.currentTarget);
	};

	const onMouseOverCompanyMenu = (event, result, index, isActionEnabled) => {
		if (!isActionEnabled) {
			return;
		}

		setHoveredSearchDetails(result);
		setHoveredIndex(index);
		setCompanyMenuElement(event.currentTarget);
	};

	const onMouseOverContactMenu = (event, result, index, isActionEnabled) => {
		if (!isActionEnabled) {
			return;
		}

		setHoveredSearchDetails(result);
		setHoveredIndex(index);
		setContactMenuElement(event.currentTarget);
	};

	const onMouseOverSearchCard = (index) => {
		setIsTooltipOpen(true);
		setHoveredSearchCardIndex(index);
	};

	const onMouseOutSearchCard = () => {
		setIsTooltipOpen(false);
		setHoveredSearchCardIndex(null);
	};

	const handleCloseDealMenu = () => {
		setDealMenuElement(null);
		setHoveredSearchDetails({});
	};

	const handleCloseContactMenu = () => {
		setContactMenuElement(null);
		setHoveredSearchDetails({});
	};

	const handleCloseCompanyMenu = () => {
		setCompanyMenuElement(null);
		setHoveredSearchDetails({});
	};

	const getAllIndicesOfKeyword = (payload, keyword) => {
		let searchStrLen = keyword.length;
		if (searchStrLen === 0) {
			return [];
		}
		let startIndex = 0,
			index,
			indices = [];
		payload = payload.toLowerCase();
		keyword = keyword.toLowerCase();

		while ((index = payload.indexOf(keyword, startIndex)) > -1) {
			indices.push(index);
			startIndex = index + searchStrLen;
		}
		return indices;
	};

	const highlightText = (payload) => {
		if (!searchKeyword || !payload) {
			return payload;
		}

		let searchKeywordLength = searchKeyword.length - 1;
		let matchedIndices = getAllIndicesOfKeyword(payload, searchKeyword);
		let payloadWordCount = 0;
		let newPayload = "";
		matchedIndices.forEach((index) => {
			newPayload += payload.substring(payloadWordCount, index);
			newPayload += `<span style="background-color:#c2ebd9; font-weight: 500; text-decoration: underline;">${payload.substring(
				index,
				index + searchKeywordLength + 1
			)}</span>`;
			payloadWordCount = index + searchKeywordLength + 1;
		});

		newPayload += payload.substring(payloadWordCount);
		return <span dangerouslySetInnerHTML={{ __html: newPayload }}></span>;
	};

	const getFormattedDescription = (data) => {
		return highlightText(data.filter(Boolean).join(" • "));
	};

	const updateRecentlySearched = (result, isActionEnabled) => {
		if (!isActionEnabled) {
			return;
		}

		onSearchedOptionClicked();

		let requestData = getRecentlySearchedRequest(result);
		recentlySearchedMutation.mutate(requestData);

		// Navigate respective places
		let relativePath = getRelativePath(result);
		navigate(relativePath);
	};

	const getRelativePath = (result) => {
		let id = result.matchedResults.id;

		switch (result.index) {
			case searchFilterConstraints.deal:
				return `/deals/${id}`;
			case searchFilterConstraints.contact:
				return `/contacts/${id}`;
			case searchFilterConstraints.company:
				return `/companies/${id}`;
			case searchFilterConstraints.product:
				return `/products/${id}`;
			case searchFilterConstraints.note:
				return getNoteFileRelativePath(result);
			case searchFilterConstraints.file:
				return getNoteFileRelativePath(result);
			case searchFilterConstraints.activity:
				return "/activities";
			default:
				break;
		}
	};

	const getNoteFileRelativePath = ({ matchedResults }) => {
		if (matchedResults.deal) {
			return `/deals/${matchedResults.deal.id}`;
		} else if (matchedResults?.contact) {
			return `/contacts/${matchedResults.contact.id}`;
		} else if (matchedResults?.company) {
			return `/companies/${matchedResults.company.id}`;
		}
	};

	const getRecentlySearchedRequest = (result) => {
		let requestData = {};
		requestData.index = result.index;
		requestData.entryId = result.matchedResults.id;
		requestData.searchText = searchKeyword;

		return requestData;
	};

	const updateActivityStatus = (result) => {
		let requestData = {
			id: result.id,
			isDone: !result.isDone,
		};

		updateActivityMutation.mutate(requestData, {
			onSuccess: () => {
				onActivityUpdateSuccess();
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.activityUpdated,
				});
			},
		});
	};

	const displayLinkedModule = (
		index,
		matchedResults,
		details,
		isActivity
	) => {
		return (
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="flex-end"
				spacing={2}
				pr={1}
				minWidth="180px"
			>
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="center"
					spacing={2}
					width="75%"
				>
					<Box
						width="25%"
						onMouseEnter={(event) =>
							onMouseOverDealMenu(
								event,
								details.deal,
								index,
								true
							)
						}
						onMouseLeave={handleCloseDealMenu}
					>
						<Can permission={PERMISSIONS.deal.view}>
							{!!matchedResults.deal && (
								<IconButton style={{ padding: 0 }}>
									{DealsIcon(
										20,
										20,
										matchedResults.deal.id ===
											hoveredSearchDetails?.id &&
											index === hoveredIndex
											? theme.palette.primary.main
											: "rgba(0, 0, 0, 0.6)"
									)}
								</IconButton>
							)}
						</Can>
					</Box>

					<Box
						display="flex"
						width="25%"
						onMouseEnter={(event) =>
							onMouseOverContactMenu(
								event,
								details.contact,
								index,
								true
							)
						}
						onMouseLeave={handleCloseContactMenu}
					>
						{!!matchedResults.contact && (
							<img
								src={contactImage1}
								width="20px"
								height="20px"
								alt="contact"
							/>
						)}
					</Box>

					<Box
						display="flex"
						width="25%"
						onMouseEnter={(event) =>
							onMouseOverCompanyMenu(
								event,
								details.company,
								index,
								true
							)
						}
						onMouseLeave={handleCloseCompanyMenu}
					>
						{!!matchedResults.company && (
							<IconButton style={{ padding: 0 }}>
								{CompanyIcon(
									20,
									20,
									matchedResults.company.id ===
										hoveredSearchDetails?.id &&
										index === hoveredIndex
										? theme.palette.primary.main
										: "rgba(0, 0, 0, 0.6)"
								)}
							</IconButton>
						)}
					</Box>
				</Stack>

				<Stack width="25%">
					{isActivity && (
						<Stack
							direction="row"
							justifyContent="flex-end"
							alignItems="center"
							spacing={2}
						>
							{(matchedResults.deal ||
								matchedResults.contact ||
								matchedResults.company) && (
								<Divider
									orientation="vertical"
									style={{ height: "20px" }}
								/>
							)}

							<Tooltip
								title={
									matchedResults.isDone
										? "Mark as Not Completed"
										: "Mark as Completed"
								}
								placement="right"
								PopperProps={{
									modifiers: [
										{
											name: "offset",
											options: {
												offset: [0, -10],
											},
										},
									],
								}}
							>
								<Checkbox
									checked={matchedResults.isDone}
									onClick={(event) => event.stopPropagation()}
									disabled={updateActivityMutation.isLoading}
									onChange={() =>
										updateActivityStatus(matchedResults)
									}
								/>
							</Tooltip>
						</Stack>
					)}
				</Stack>
			</Stack>
		);
	};

	const isActionEnabled = (result) => {
		switch (result.index) {
			case searchFilterConstraints.deal:
				return isUserAllowedFor(PERMISSIONS.deal.view);
			case searchFilterConstraints.contact:
				return true;
			case searchFilterConstraints.company:
				return isUserAllowedFor(PERMISSIONS.company.view);
			case searchFilterConstraints.product:
				return true;
			case searchFilterConstraints.note:
				return true;
			case searchFilterConstraints.file:
				return true;
			case searchFilterConstraints.activity:
				return true;
			default:
				return true;
		}
	};

	const openTooltip = (result, index) => {
		return (
			!isActionEnabled(result) &&
			isTooltipOpen &&
			hoveredSearchCardIndex === index
		);
	};

	return (
		<React.Fragment>
			{/* deal menu */}
			<Popover
				id="mouse-over-dealMenu"
				sx={{
					pointerEvents: "none",
				}}
				open={isDealMenuOpened}
				anchorEl={dealMenuElement}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				onClose={handleCloseDealMenu}
				disableRestoreFocus
			>
				<DealMenu dealDetails={hoveredSearchDetails} />
			</Popover>

			{/* Contact Menu */}
			<Popover
				id="mouse-over-contactMenu"
				sx={{
					pointerEvents: "none",
				}}
				open={isContactMenuOpened}
				anchorEl={contactMenuElement}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				onClose={handleCloseContactMenu}
				disableRestoreFocus
			>
				<ContactMenu contactDetails={hoveredSearchDetails} />
			</Popover>

			{/* Company Menu */}
			<Popover
				id="mouse-over-companyMenu"
				sx={{
					pointerEvents: "none",
				}}
				open={isCompanyMenuOpened}
				anchorEl={companyMenuElement}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				onClose={handleCloseCompanyMenu}
				disableRestoreFocus
			>
				<CompanyMenu companyDetails={hoveredSearchDetails} />
			</Popover>

			<MenuList>
				{results?.map((result, index) => (
					<MenuItem
						key={index}
						style={{
							padding: "16px",
							opacity: isActionEnabled(result) ? 1 : 0.6,
						}}
						onClick={() =>
							updateRecentlySearched(
								result,
								isActionEnabled(result)
							)
						}
						onKeyDown={(event) => event.stopPropagation()}
						onMouseOver={() => onMouseOverSearchCard(index)}
						onMouseOut={onMouseOutSearchCard}
					>
						<Tooltip
							open={openTooltip(result, index)}
							title={`You don’t have the permission to view ${result.index}s`}
							placement="right"
						>
							<Stack
								direction="row"
								alignItems="center"
								justifyContent="space-between"
								pl={1}
								width="100%"
							>
								{(function () {
									const matchedResults =
										result.matchedResults;
									const details = result.details;

									switch (result.index) {
										case searchFilterConstraints.deal:
											return (
												<React.Fragment>
													<Deal
														highlightText={
															highlightText
														}
														matchedDeals={
															matchedResults
														}
														getFormattedDescription={
															getFormattedDescription
														}
														onMouseOverDealMenu={(
															event
														) =>
															onMouseOverDealMenu(
																event,
																details,
																index,
																isActionEnabled(
																	result
																)
															)
														}
														handleCloseDealMenu={
															handleCloseDealMenu
														}
													/>
												</React.Fragment>
											);
										case searchFilterConstraints.contact:
											return (
												<Contact
													highlightText={
														highlightText
													}
													matchedContacts={
														matchedResults
													}
													getFormattedDescription={
														getFormattedDescription
													}
													onMouseOverContactMenu={(
														event
													) =>
														onMouseOverContactMenu(
															event,
															details,
															index,
															isActionEnabled(
																result
															)
														)
													}
													handleCloseContactMenu={
														handleCloseContactMenu
													}
												/>
											);
										case searchFilterConstraints.product:
											return (
												<Product
													matchedProducts={
														matchedResults
													}
													highlightText={
														highlightText
													}
												/>
											);
										case searchFilterConstraints.activity:
											return (
												<Activity
													details={details}
													highlightText={
														highlightText
													}
													matchedActivities={
														matchedResults
													}
													displayLinkedModule={() =>
														displayLinkedModule(
															index,
															matchedResults,
															details,
															true
														)
													}
												/>
											);
										case searchFilterConstraints.company:
											return (
												<Company
													highlightText={
														highlightText
													}
													matchedCompanies={
														matchedResults
													}
													getFormattedDescription={
														getFormattedDescription
													}
													onMouseOverCompanyMenu={(
														event
													) =>
														onMouseOverCompanyMenu(
															event,
															details,
															index,
															isActionEnabled(
																result
															)
														)
													}
													handleCloseCompanyMenu={
														handleCloseCompanyMenu
													}
												/>
											);
										case searchFilterConstraints.file:
											return (
												<File
													highlightText={
														highlightText
													}
													matchedFiles={
														matchedResults
													}
													displayLinkedModule={() =>
														displayLinkedModule(
															index,
															matchedResults,
															details
														)
													}
												/>
											);
										case searchFilterConstraints.note:
											return (
												<Notes
													highlightText={
														highlightText
													}
													matchedNotes={
														matchedResults
													}
													displayLinkedModule={() =>
														displayLinkedModule(
															index,
															matchedResults,
															details
														)
													}
												/>
											);
										default:
											return;
									}
								})()}
							</Stack>
						</Tooltip>
					</MenuItem>
				))}
			</MenuList>
		</React.Fragment>
	);
}
