import React, { useEffect, useState } from "react";
import {
	Box,
	Divider,
	MenuItem,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import Menu from "../../Elements/Menu";
import { default as DropdownRightIcon } from "../../../assets/icons/dropDownRight";
import CustomSwipeableDrawer from "../../Elements/CustomSwipeableDrawer";
import { getActionListKey } from "../../../utils/queryKeys/workflow";
import { useQuery } from "@tanstack/react-query";
import { getActionData } from "../../../api/workflow/workflowApi";
import Fields from "./Fields";

export default function Action(props) {
	const {
		styles,
		setAction,
		setFieldMappings,
		index,
		triggerSourceId,
		source,
		event,
		fieldMapping,
	} = props;
	const theme = useTheme();
	const [selectedAction, setSelectedAction] = useState(null);
	const [selectedSubAction, setSelectedSubAction] = useState(null);
	const [hoveredActionMenu, setHoveredActionMenu] = useState(null);
	const [actionMenuElement, setActionMenuElement] = useState(null);
	const isActionMenuOpened = Boolean(actionMenuElement);
	const [subActionMenuElement, setSubActionMenuElement] = useState(null);
	const isSubActionMenuOpened = Boolean(subActionMenuElement);
	const [
		isActionFieldMappingDialogOpened,
		setIsActionFieldMappingDialogOpened,
	] = useState(false);
	const [actions, setActions] = useState([]);

	const actionListKey = getActionListKey(triggerSourceId);
	const { data: actionListData } = useQuery(
		actionListKey,
		() => getActionData(triggerSourceId),
		{ staleTime: 600000 }
	);

	useEffect(() => {
		if (actionListData && actionListData.list) {
			setActions(actionListData.list);
			if (source && source.id) {
				const selectedAction = actionListData.list.find(
					(action) => action.id === source.id
				);
				if (selectedAction) {
					setSelectedAction(selectedAction);
					if (
						event &&
						event.id &&
						selectedAction.event &&
						selectedAction.event.length > 0
					) {
						const selectedSubAction = selectedAction.event.find(
							(subAction) => subAction.id === event.id
						);
						if (selectedSubAction) {
							setSelectedSubAction(selectedSubAction);
						}
					}
				}
			}
		}
	}, [actionListData]);

	const OpenActionMenu = (event) => {
		setActionMenuElement(event.currentTarget);
	};

	const CloseActionMenu = () => {
		CloseSubActionMenu();
		setActionMenuElement(null);
	};

	const OpenSubActionMenu = (event) => {
		setSubActionMenuElement(event.currentTarget);
	};

	const CloseSubActionMenu = () => {
		setSubActionMenuElement(null);
	};

	const changeAction = (event, action) => {
		setSelectedAction(action);
		if (action.event?.length > 0) {
			OpenSubActionMenu(event);
		} else {
			setSelectedSubAction(null);
			setAction(index, action);
			CloseActionMenu();
		}
	};

	const changeSubAction = (action) => {
		if (
			!(
				source?.id &&
				source.id === selectedAction.id &&
				event?.id &&
				event.id === action.id
			)
		) {
			setFieldMappings(index, []);
		}
		setSelectedSubAction(action);
		setAction(index, selectedAction, action);
		CloseActionMenu();
		toggleActionFieldMappingDialog();
	};

	const toggleActionFieldMappingDialog = () => {
		setIsActionFieldMappingDialogOpened(
			(openAddCompanyDialog) => !openAddCompanyDialog
		);
	};

	const handleFieldMappingsChange = (fieldMappings) => {
		toggleActionFieldMappingDialog();
		setFieldMappings(index, fieldMappings);
	};

	return (
		<React.Fragment>
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isActionFieldMappingDialogOpened}
				onOpen={toggleActionFieldMappingDialog}
				onClose={toggleActionFieldMappingDialog}
				ModalProps={{
					keepMounted: false,
				}}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Fields
					triggerSourceId={triggerSourceId}
					actionSource={selectedAction?.id}
					actionEvent={selectedSubAction?.id}
					formName={selectedSubAction?.name}
					fieldMapping={fieldMapping}
					setFieldMappings={handleFieldMappingsChange}
					toggleAddActivityDialog={toggleActionFieldMappingDialog}
				/>
			</CustomSwipeableDrawer>

			<Menu
				anchorEl={actionMenuElement}
				open={isActionMenuOpened}
				onClose={CloseActionMenu}
				anchorOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				minWidth="250px"
				style={{ marginTop: "-8px" }}
			>
				{actions.map((action) => (
					<MenuItem
						key={action.id}
						style={{ minHeight: "40px" }}
						selected={action.id === selectedAction?.id}
						onClick={(event) => changeAction(event, action)}
						onMouseEnter={() => setHoveredActionMenu(action.id)}
						onMouseLeave={() => setHoveredActionMenu(null)}
					>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							width="100%"
						>
							<Typography fontSize={13}>{action.name}</Typography>
							{action.event?.length > 0 &&
								(hoveredActionMenu === action.id ||
									(isSubActionMenuOpened &&
										action.name ===
											selectedAction?.name)) &&
								DropdownRightIcon(16, 16, "#000")}
						</Stack>
					</MenuItem>
				))}
			</Menu>

			<Menu
				anchorEl={subActionMenuElement}
				open={isSubActionMenuOpened}
				onClose={CloseSubActionMenu}
				anchorOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				minWidth="250px"
				style={{ marginLeft: "6px" }}
			>
				{selectedAction?.event?.map((action) => (
					<MenuItem
						key={action.id}
						style={{ minHeight: "40px" }}
						selected={action.id === selectedSubAction?.id}
						onClick={() => changeSubAction(action)}
						disabled={!action.isEnabled}
					>
						<Typography fontSize={13}>{action.name}</Typography>
					</MenuItem>
				))}
			</Menu>

			<Box
				style={{
					border: "1px solid rgba(0, 0, 0, 0.12)",
					borderRadius: "8px",
					minWidth: "500px",
				}}
			>
				<Box py={1.5} px={2}>
					<Typography style={styles.title}>
						Action{" "}
						<span style={{ color: theme.palette.error.main }}>
							*
						</span>
					</Typography>
				</Box>

				<Divider />

				<Stack direction="row" alignItems="center" spacing={2} p={2}>
					<Typography
						style={
							selectedAction
								? styles.textButton
								: styles.selectTextButton
						}
						onClick={OpenActionMenu}
					>
						{selectedAction
							? selectedSubAction
								? selectedSubAction.name
								: selectedAction.name
							: "Select"}
					</Typography>
				</Stack>
			</Box>
		</React.Fragment>
	);
}
