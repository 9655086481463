import {
	Box,
	Checkbox,
	Stack,
	TableCell,
	TableRow,
	useTheme,
	IconButton,
} from "@mui/material";
import { default as MoreIcon } from "../../../../assets/icons/more";
import Emails from "../Fields/emails";
import Phones from "../Fields/phones";
import Company from "../Fields/company";
import Text from "../Fields/text";
import Monetary from "../Fields/monetary";
import Contact from "../Fields/contact";
import Address from "../Fields/address";
import Email from "../Fields/email";
import Phone from "../Fields/phone";
import Dropdown from "../Fields/dropdown";
import MultiSelect from "../Fields/multiSelect";
import DateTimeRange from "../Fields/dateTimeRange";
import ActivityStatus from "../Fields/activityStatus";
import ActivityDeal from "../Fields/activityDeal";
import ActivityTitle from "../Fields/activityTitle";
import Owner from "../Fields/owner";
import ContactsOnBoard from "../Fields/contactsOnBoard";
import DateComponent from "../Fields/date";
import TimeRange from "../Fields/timeRange";
import Note from "../Fields/note";
import Territory from "../Fields/territory";
import Tags from "../Fields/tags";
import DateRange from "../Fields/dateRange";
import TimeComponent from "../Fields/time";
import { useState } from "react";

const getCellData = (data, structure, row) => {
	switch (structure.fieldType) {
		case "EMAILS":
			return <Emails data={data} id={row.id} rowState={row?.rowState} />;
		case "EMAIL":
			return <Email data={data} id={row.id} rowState={row?.rowState} />;
		case "PHONES":
			return <Phones data={data} id={row.id} rowState={row?.rowState} />;
		case "PHONE":
			return <Phone data={data} id={row.id} rowState={row?.rowState} />;
		case "COMPANY_ONBOARD":
			return <Company data={data} id={row.id} rowState={row?.rowState} />;
		case "COMPANY":
			return <Company data={data} id={row.id} rowState={row?.rowState} />;
		case "TAGS":
			return <Tags data={data} />;
		case "TERRITORY":
			return <Territory data={data} />;
		case "MONETARY":
			return (
				<Monetary data={data} id={row.id} rowState={row?.rowState} />
			);
		case "DEAL_VALUE":
			return (
				<Monetary data={data} id={row.id} rowState={row?.rowState} />
			);
		case "CONTACT":
			return <Contact data={data} id={row.id} rowState={row?.rowState} />;
		case "CONTACT_ONBOARD":
			return <Contact data={data} id={row.id} rowState={row?.rowState} />;
		case "DROPDOWN":
			return (
				<Dropdown data={data} id={row.id} rowState={row?.rowState} />
			);
		case "ADDRESS":
			return <Address data={data} id={row.id} rowState={row?.rowState} />;
		case "DATE_RANGE":
			return <DateRange data={data} />;
		case "TIME_RANGE":
			return <TimeRange data={data} />;
		case "TIME":
			return <TimeComponent data={data} />;
		case "DATE":
			return (
				!!data && (
					<DateComponent
						data={data}
						id={row.id}
						rowState={row?.rowState}
					/>
				)
			);

		case "ACTIVITY_STATUS":
			return (
				<ActivityStatus
					data={data}
					id={row.id}
					rowState={row?.rowState}
				/>
			);
		case "DEAL":
			return (
				<ActivityDeal
					data={data}
					id={row.id}
					rowState={row?.rowState}
				/>
			);
		case "ACTIVITY_TITLE":
			return (
				<ActivityTitle
					data={data}
					id={row.id}
					rowState={row?.rowState}
				/>
			);
		case "OWNER":
			return <Owner data={data} id={row.id} rowState={row?.rowState} />;
		case "CONTACTS_ONBOARD":
			return (
				<ContactsOnBoard
					data={data}
					id={row.id}
					rowState={row?.rowState}
				/>
			);
		case "MULTI_SELECT":
			return <MultiSelect data={data} />;
		case "DATE_TIME_RANGE":
			return <DateTimeRange data={data} />;
		case "NOTE":
			return <Note data={data} rowState={row?.rowState} />;
		default:
			try {
				return (
					<Text
						data={data?.toString()}
						id={row.id}
						rowState={row?.rowState}
					/>
				);
			} catch (e) {
				console.log(e);
				return null;
			}
	}
};

export default function Row({
	row,
	selected,
	onRowClick,
	lastRowRef,
	isActionsVisible,
	handleCheckboxClick,
	openTableMenu,
	selectedColumns,
	isMoreMenuOpened,
}) {
	const [hovered, setHovered] = useState(false);

	const onMouseOverTableRow = () => {
		setHovered(true);
	};

	const onMouseOutTableRow = () => {
		setHovered(false);
	};

	const theme = useTheme();

	return (
		<TableRow
			selected={selected}
			onMouseOver={() => onMouseOverTableRow()}
			onMouseOut={() => onMouseOutTableRow()}
			onClick={() => onRowClick(row)}
			ref={lastRowRef}
		>
			<TableCell padding="checkbox">
				<Checkbox
					checked={selected}
					onClick={(e) => handleCheckboxClick(e, row.id)}
				/>
			</TableCell>
			{selectedColumns.map((column, index) => (
				<TableCell
					key={index}
					style={{
						minWidth: "200px",
					}}
				>
					{getCellData(row[column.name], column, row)}
				</TableCell>
			))}

			{isActionsVisible ? (
				<Box
					sx={{
						position: "sticky",
						right: 5,
						zIndex: 2,
					}}
					component="td"
				>
					{isMoreMenuOpened || hovered ? (
						<Box
							sx={{
								position: "absolute",
								right: 0,
								bottom: 0,
								padding: 0.2,
							}}
						>
							<Box
								display="flex"
								p="5px 13px"
								mt={0.2}
								style={{
									border: `1px solid ${theme.palette.primary.main}`,
									borderRadius: "8px",
									backgroundColor: "#fff",
								}}
								onClick={(event) => openTableMenu(event, row)}
							>
								<Stack alignItems="center">
									<IconButton size="small">
										{MoreIcon(
											16,
											16,
											theme.palette.primary.main
										)}
									</IconButton>
								</Stack>
							</Box>
						</Box>
					) : (
						<></>
					)}
				</Box>
			) : (
				<></>
			)}
		</TableRow>
	);
}
