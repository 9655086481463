import React from "react";
import {
	Box,
	Button,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { emailPattern } from "../../../../utils/validation";
import CustomHomeComponent from "../../../Elements/CustomHomeComponent";
import { default as twozoLogo } from "../../../../assets/images/logo/twozo-full.png";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../../../../hooks/auth";

export default function ForgotPassword() {
	const { isUserAuthenticated } = useAuth();

	if (isUserAuthenticated()) {
		return <Navigate to="/deals" />;
	}

	return <ForgotPasswordPage />;
}

function ForgotPasswordPage() {
	const theme = useTheme();
	const navigate = useNavigate();

	const { isUserAuthenticated } = useAuth();

	if (isUserAuthenticated()) {
		return <Navigate to="/deals" />;
	}

	const {
		register,
		formState: { errors },
		handleSubmit,
		clearErrors,
		setValue,
	} = useForm();

	const onSubmit = () => {
		navigate("/check-inbox");
	};

	return (
		<React.Fragment>
			<CustomHomeComponent>
				<Stack spacing={4} p={5} pb={3}>
					<Box>
						<img
							alt="twozo"
							src={twozoLogo}
							width="108px"
							height="34px"
						/>
					</Box>

					<Stack spacing={3}>
						<Stack spacing={0.5}>
							<Typography fontSize={18} fontWeight={600}>
								Forgot Password?
							</Typography>

							<Typography fontSize={13}>
								No worries, we’ll send you the instructions to
								your email.
							</Typography>
						</Stack>

						<Stack spacing={0.5}>
							<Typography
								fontSize={13}
								fontWeight={500}
								color={
									!!errors.email && theme.palette.error.main
								}
							>
								Work Email Id
							</Typography>

							<TextField
								placeholder="Enter your work email id"
								{...register("email", {
									required: "Please enter your email address",
									pattern: {
										value: emailPattern,
										message:
											"Please enter a valid email id",
									},
								})}
								onChange={(event) => {
									setValue("email", event.target.value); //set the value to the react-hook-form, if the field is auto-filled by the browser, react-form does not know it
									clearErrors("email"); // Clear email validation error
								}}
								FormHelperTextProps={{
									sx: {
										marginLeft: 0,
										fontSize: 13,
									},
								}}
								error={!!errors.email}
								helperText={errors.email?.message}
							/>
						</Stack>

						<Box pb={3.5}>
							<Button
								variant="contained"
								disableElevation
								onClick={handleSubmit(onSubmit)}
							>
								Reset Password
							</Button>
						</Box>
					</Stack>
				</Stack>
			</CustomHomeComponent>
		</React.Fragment>
	);
}
