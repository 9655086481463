import React from "react";
import Notes from "../../../Summary/Notes";
import {
	getCompanyNotesKey,
	getCompanyTimelineKey,
} from "../../../../utils/queryKeys";

export default function CompanyNotes({ companyId }) {
	const companyNotesKey = getCompanyNotesKey(companyId);
	const companyTimeLineKey = getCompanyTimelineKey(companyId);

	return (
		<React.Fragment>
			<Notes
				notesKey={companyNotesKey}
				timeLineKey={companyTimeLineKey}
				source="Company"
				entityId={companyId}
			/>
		</React.Fragment>
	);
}
