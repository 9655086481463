import React from "react";
import Notes from "../../../Summary/Notes";
import {
	getDealKeyById,
	getDealNotesKey,
	getDealTimelineKey,
} from "../../../../utils/queryKeys";

export default function DealNotes({ dealId }) {
	const dealNotesKey = getDealNotesKey(dealId);
	const dealTimeLineKey = getDealTimelineKey(dealId);
	const dealSummaryKey = getDealKeyById(dealId);

	return (
		<React.Fragment>
			<Notes
				notesKey={dealNotesKey}
				timeLineKey={dealTimeLineKey}
				summaryKey={dealSummaryKey}
				source="Deal"
				entityId={dealId}
			/>
		</React.Fragment>
	);
}
