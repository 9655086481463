import { Box, Button, Stack, Grid, CircularProgress } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import React, { useState, useEffect } from "react";
import Row from "../AddForm/Row";
import { useFormFieldsData } from "../../../hooks/services/common/field";

const view = "profileEditView";

export default function SummaryProfileEditForm({
	moduleName,
	isSaving,
	onSave,
	onClose,
	values,
}) {
	const [fields, setFields] = useState([]);
	const [subFields, setSubFields] = useState([]);

	const formMethods = useForm({
		values: values,
		reValidateMode: false, // validation triggered only when the form Submit
	});

	const {
		status: formFieldsAPIstatus,
		isLoading: isLoadingFormFields,
		data: formFieldsData,
	} = useFormFieldsData(moduleName, view);

	useEffect(() => {
		if (formFieldsAPIstatus === "success") {
			if (formFieldsData.fields) {
				setFields(formFieldsData.fields);
			}

			if (formFieldsData.subFields) {
				setSubFields(formFieldsData.subFields);
			}
		}
	}, [formFieldsData, formFieldsAPIstatus]);

	const handleSave = (formData) => {
		onSave(formData);
	};

	return (
		<React.Fragment>
			{isLoadingFormFields ? (
				<Stack
					alignItems="center"
					justifyContent="center"
					style={{
						height: "400px",
						width: "400px",
					}}
				>
					<CircularProgress size="30px" />
				</Stack>
			) : (
				<Box
					style={{
						maxHeight: "400px",
						width: "400px",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<FormProvider {...formMethods}>
						<Box
							px={2}
							py={1}
							style={{
								flex: "1 1 auto",
								minHeight: 0,
								overflowY: "auto",
							}}
						>
							<Grid
								container
								direction="row"
								justifyContent="flex-start"
								alignItems="baseline"
								spacing={2}
							>
								{fields.map((field, index) => (
									<Row
										field={field}
										subFields={subFields}
										key={index}
									/>
								))}
							</Grid>
						</Box>
					</FormProvider>

					<Box p={2}>
						<Stack
							direction="row"
							justifyContent="flex-end"
							alignItems="center"
							spacing={2}
							pr={1}
						>
							<Stack direction="row" spacing={2}>
								<Box>
									<Button
										variant="contained"
										color="secondary"
										disabled={isSaving}
										disableElevation
										onClick={onClose}
									>
										Cancel
									</Button>
								</Box>

								<Box>
									<Button
										variant="contained"
										disabled={isSaving}
										disableElevation
										onClick={formMethods.handleSubmit(
											handleSave
										)}
									>
										Save
									</Button>
								</Box>
							</Stack>
						</Stack>
					</Box>
				</Box>
			)}
		</React.Fragment>
	);
}
