import { Navigate } from "react-router-dom";
import { useAuthContext } from "../../providers/AuthContextProvider";
import { PERMISSIONS } from "../../utils/Auth";

export const useAuth = () => {
	const { permissions: allowedPermissions } = useAuthContext();
	const isUserAuthenticated = () => {
		return !allowedPermissions.isUserNotLoggedIn;
	};

	const isUserAllowedFor = (permission) => {
		if (!isUserAuthenticated()) {
			return false;
		}

		if (
			permission === PERMISSIONS.allowlistedEntity.allowlistedPermission
		) {
			return true;
		}

		return !!allowedPermissions?.[permission.entity]?.[permission.action];
	};

	const navigateToHomeBasedOnPermission = () => {
		if (isUserAllowedFor(PERMISSIONS.deal.view)) {
			return <Navigate to="/deals" />;
		} else if (isUserAllowedFor(PERMISSIONS.contact.view)) {
			return <Navigate to="/contacts" />;
		}
		//Minimum Route which user would have permission to
		return <Navigate to="/contacts" />;
	};

	return {
		isUserAuthenticated,
		isUserAllowedFor,
		navigateToHomeBasedOnPermission,
	};
};
