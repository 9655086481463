import React from "react";
import { Typography } from "@mui/material";
import { useFieldPermissionContext } from "../../../../FieldPermissionContext";
import { twozoStyles } from "../../../../../../styles/twozo";
import SalesOwnerFieldValue from "./SalesOwnerFieldValue";

export default function SalesOwnerComponent(props) {
	const { summaryField } = props;

	// permission
	const fieldPermission = useFieldPermissionContext();
	const isOwnerFieldEnabled = fieldPermission?.owner?.enabled;

	const classes = twozoStyles();

	return (
		<React.Fragment>
			<td>
				<Typography fontSize={14} className={classes.summaryHead}>
					{summaryField.fieldName} :
				</Typography>
			</td>

			<td
				style={{
					padding: 0,
					cursor: "pointer",
					pointerEvents: isOwnerFieldEnabled ? "auto" : "none",
					opacity: isOwnerFieldEnabled ? "1" : "0.6",
				}}
			>
				<SalesOwnerFieldValue summaryField={summaryField} />
			</td>
		</React.Fragment>
	);
}
