import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
	createActivity,
	deleteMultipleActivities,
	getActivitiesCalendarViewData,
	getCollaboratorList,
	getDueDateList,
	getSummaryData,
	partialBulkUpdateActivity,
	partialUpdateActivity,
} from "../../../api/activity/activityApi";
import {
	calendarViewKey,
	getActivitiesCalendarViewDataKey,
	getActivityDuedateKey,
	getActivitySummaryKey,
	getActivityTableKey,
	getCalendarViewWeekKey,
	getCollaboratorListKey,
} from "../../../utils/queryKeys/activity";
import { useInvalidateSearchedAndViewed } from "../search";
import { useInvalidateDealKanbanView } from "../deal";

const useActivityDueDateList = (sourceId) => {
	return useQuery(
		getActivityDuedateKey(sourceId),
		() => getDueDateList(sourceId),
		{
			enabled: !!sourceId,
			staleTime: Infinity,
		}
	);
};

const useActivitySummary = (id) => {
	return useQuery(getActivitySummaryKey(id), () => getSummaryData(id), {
		select: (data) => data,
		enabled: !!id,
	});
};

const useCollaboratorList = (activityId) => {
	return useQuery(
		getCollaboratorListKey(activityId),
		() => getCollaboratorList(activityId),
		{
			select: (data) => data.collaborators,
		}
	);
};

const formattedActivities = (activities) => {
	return activities?.map((activity) => {
		return {
			...activity,
			startTime: new Date(activity.startTime),
			endTime: new Date(activity.endTime),
		};
	});
};

const useActivitiesCalendarViewData = (weekCount, isWeekEnd, criteria) => {
	return useQuery(
		getActivitiesCalendarViewDataKey(weekCount, isWeekEnd, criteria),
		() => getActivitiesCalendarViewData(weekCount, isWeekEnd, criteria),
		{
			select: (data) => formattedActivities(data.activities),
		}
	);
};

//Invalidate Calendar View Data
const useInvalidateCalendarViewWeek = (weekCount) => {
	let queryClient = useQueryClient();
	let calendarViewWeekKey = getCalendarViewWeekKey(weekCount);
	return () => {
		queryClient.invalidateQueries(calendarViewWeekKey);
	};
};

const useInvalidateCalendarView = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(calendarViewKey);
	};
};

//Invalidate Activity Summary
const useInvalidateActivitySummary = (id) => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(getActivitySummaryKey(id));
	};
};

//Invalidate Activity List
const useInvalidateActivityList = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries({ queryKey: getActivityTableKey() });
	};
};

// Update Activity Status from Calendar view
const useUpdateActivityStatusFromCalendar = (weekCount) => {
	let invalidateCalendarViewWeek = useInvalidateCalendarViewWeek(weekCount);
	return useMutation(partialUpdateActivity, {
		onSuccess: () => {
			invalidateCalendarViewWeek();
		},
	});
};

//Update Activity Status
const useUpdateActivityStatus = (id) => {
	let invalidateActivitySummary = useInvalidateActivitySummary(id);
	let invalidateActivityList = useInvalidateActivityList();
	let invalidateSearchedAndViewed = useInvalidateSearchedAndViewed();
	let invalidateCalendarViewData = useInvalidateCalendarView();
	return useMutation(partialUpdateActivity, {
		onSuccess: () => {
			invalidateActivitySummary();
			invalidateActivityList();
			invalidateSearchedAndViewed();
			invalidateCalendarViewData();
		},
	});
};

//Update Activity Status from list
const useUpdateActivityStatusFromList = () => {
	let invalidateActivityList = useInvalidateActivityList();
	return useMutation(partialBulkUpdateActivity, {
		onSuccess: () => {
			invalidateActivityList();
		},
	});
};

//Delete Activites
const useDeleteActivities = () => {
	let invalidateActivityList = useInvalidateActivityList();
	return useMutation(deleteMultipleActivities, {
		onSuccess: () => {
			invalidateActivityList();
		},
	});
};

//Create Activity
const useCreateActivity = () => {
	let invalidateActivityList = useInvalidateActivityList();
	let invalidateCalendarViewData = useInvalidateCalendarView();
	let invalidateDealKanbanView = useInvalidateDealKanbanView();
	return useMutation(createActivity, {
		onSuccess: () => {
			invalidateActivityList();
			invalidateCalendarViewData();
			invalidateDealKanbanView();
		},
	});
};

export {
	useActivityDueDateList,
	useActivitySummary,
	useUpdateActivityStatus,
	useUpdateActivityStatusFromList,
	useDeleteActivities,
	useCreateActivity,
	useCollaboratorList,
	useInvalidateActivityList,
	useActivitiesCalendarViewData,
	useUpdateActivityStatusFromCalendar,
};
