import { getWorkFlowUrl } from "../apiConfig";
import { apiClient } from "../index";
import { assertError } from "../apiHelper";

export const getTemplateData = async (templateId) => {
	let requestData = getTemplateRequestData(templateId);
	return await postWorkflowTemplateManagementApi(requestData);
};

export const getAllTemplateData = async () => {
	let requestData = getAllTemplateRequestData();
	return await postWorkflowTemplateManagementApi(requestData);
};

export const createWorkflow = async (workflowData) => {
	let requestData = getCreateWorkflowRequestData(workflowData);
	return await postWorkflowManagementApi(requestData);
};

export const getCriteriaFieldsData = async (triggerSource, triggerEvent) => {
	let requestData = getCriteriaFieldsRequestData(triggerSource, triggerEvent);
	return await postWorkflowManagementApi(requestData);
};

export const getActionData = async (triggerSource) => {
	let requestData = getActionRequestData(triggerSource);
	return await postWorkflowActionManagementApi(requestData);
};

export const getWorkflowList = async () => {
	let requestData = getWorkflowListRequestData();
	return await postWorkflowListApi(requestData);
};

export const getActionFieldsData = async (
	triggerSource,
	actionSource,
	actionEvent
) => {
	let requestData = getActionFieldsRequestData(
		triggerSource,
		actionSource,
		actionEvent
	);
	return await postWorkflowActionManagementApi(requestData);
};

export const getActionStepOptionData = async (
	triggerSource,
	fieldId,
	actions
) => {
	let requestData = getActionStepOptionRequestData(
		triggerSource,
		fieldId,
		actions
	);
	return await postWorkflowActionManagementApi(requestData);
};

const getCreateWorkflowRequestData = (workflowData) => {
	return {
		type: "create",
		data: workflowData,
	};
};

const getCriteriaFieldsRequestData = (triggerSource, triggerEvent) => {
	return {
		type: "criteriaFieldList",
		data: {
			trigger: {
				source: triggerSource,
				event: triggerEvent,
			},
		},
	};
};

const getTemplateRequestData = (templateId) => {
	return {
		type: "get",
		data: {
			id: templateId,
		},
	};
};

const getAllTemplateRequestData = () => {
	return {
		type: "getAll",
		data: {},
	};
};

const getActionRequestData = (triggerSource) => {
	return {
		type: "getActions",
		data: {
			triggerSource: triggerSource,
		},
	};
};

const getActionFieldsRequestData = (
	triggerSource,
	actionSource,
	actionEvent
) => {
	return {
		type: "getActionFields",
		data: {
			triggerSource: triggerSource,
			action: {
				source: actionSource,
				event: actionEvent,
			},
		},
	};
};

const getActionStepOptionRequestData = (triggerSource, fieldId, actions) => {
	return {
		type: "getStepOptions",
		data: {
			triggerSource: triggerSource,
			fieldId: fieldId,
			actions: actions,
		},
	};
};

const getWorkflowListRequestData = () => {
	return {
		type: "list",
		data: {},
	};
};

const postWorkflowTemplateManagementApi = async (requestData) => {
	let workflowTemplateManagementUrl = getWorkflowTemplateManagementUrl();
	return postWorkflowAPI(workflowTemplateManagementUrl, requestData);
};

const postWorkflowActionManagementApi = async (requestData) => {
	let workflowActionManagementUrl = getWorkflowActionManagementUrl();
	return postWorkflowAPI(workflowActionManagementUrl, requestData);
};

const postWorkflowManagementApi = async (requestData) => {
	let workflowManagementUrl = getWorkflowManagementUrl();
	return postWorkflowAPI(workflowManagementUrl, requestData);
};

const postWorkflowListApi = async (requestData) => {
	let workflowListUrl = getWorkflowListUrl();
	return postWorkflowAPI(workflowListUrl, requestData);
};

const postWorkflowAPI = async (requestUrl, requestData) => {
	let response = await apiClient.post(requestUrl, requestData);
	assertError(response, requestUrl);

	return response.data.data;
};

const getWorkflowTemplateManagementUrl = () => {
	return getWorkFlowUrl() + "/template/mgmt";
};

const getWorkflowActionManagementUrl = () => {
	return getWorkFlowUrl() + "/action/mgmt";
};

const getWorkflowManagementUrl = () => {
	return getWorkFlowUrl() + "/mgmt";
};

const getWorkflowListUrl = () => {
	return getWorkFlowUrl() + "/list";
};
