export const filterFieldNames = {
	sourceId: "sourceId",
	connectorId: "connectorId",
	comparatorId: "comparatorId",
	fieldId: "fieldId",
	optionId: "optionId",
	additionalOption: "additionalOption",
	valueEntityId: "valueEntityId",
	value: "value",
};

export const filterConnectorsList = {
	AND: "AND",
	OR: "OR",
};

export const fieldType = {
	time: "TIME",
	timeRange: "TIME_RANGE",
	monetary: "MONETARY",
	premiumProduct: "PREMIUM_PRODUCT",
	multiSelect: "MULTI_SELECT",
	singleNumber: "SINGLE_NUMBER",
	owner: "OWNER",
	date: "DATE",
	dropdown: "DROPDOWN",
	companyOnboard: "COMPANY_ONBOARD",
	company: "COMPANY",
	tags: "TAGS",
	territory: "TERRITORY",
	contactsOnboard: "CONTACTS_ONBOARD",
	contact: "CONTACT",
	contactOnboard: "CONTACT_ONBOARD",
};

export const filterVisibility = {
	justMe: 1,
	everyone: 2,
	selected: 3,
};
