import { Box, Skeleton, Stack } from "@mui/material";
import React from "react";

const DealCard = () => {
	const styles = {
		dealCard: {
			width: "304px",
			height: "108px",
			backgroundColor: "#FFF",
			borderRadius: "8px",
			padding: "16px",
		},
	};

	return (
		<Box style={styles.dealCard}>
			<Stack spacing={1}>
				<Skeleton width="118px" height="18px" />
				<Skeleton width="188px" height="18px" />

				<Stack direction="row" spacing={1}>
					<Skeleton variant="circular" width="26px" height="26px" />
					<Skeleton variant="circular" width="26px" height="26px" />
				</Stack>
			</Stack>
		</Box>
	);
};

const DealStage = () => {
	const styles = {
		tableHead: {
			width: "320px",
			height: "70px",
			border: "1px solid rgba(0, 0, 0, 0.1)",
			paddingLeft: "16px",
		},
	};

	return (
		<tr>
			<th style={styles.tableHead}>
				<Stack spacing={1}>
					<Skeleton width="60px" height="18px" />
					<Skeleton width="118px" height="18px" />
				</Stack>
			</th>
		</tr>
	);
};

export default function KanbanViewStageLoader(props) {
	const { menuBarEndingPosition } = props;
	const dealStageCount = 3;

	const styles = {
		table: {
			borderCollapse: "collapse",
			width: "max-content",
			height: `calc(100vh - ${menuBarEndingPosition}px)`,
		},

		tableCell: {
			width: "320px",
			border: "1px solid rgba(0, 0, 0, 0.1)",
		},
	};

	return (
		<React.Fragment>
			{Array.from({ length: dealStageCount }, (_, index) => (
				<Stack direction="row" key={index}>
					<Box>
						<table style={styles.table}>
							<thead>
								<DealStage />
							</thead>
							<tbody style={styles.tableCell}>
								<tr>
									<td style={styles.tableCell}>
										<Stack height="100%" p={1} spacing={1}>
											<DealCard />
											<DealCard />
										</Stack>
									</td>
								</tr>
							</tbody>
						</table>
					</Box>

					<Box>
						<table style={styles.table}>
							<thead>
								<DealStage />
							</thead>
							<tbody>
								<tr>
									<td style={styles.tableCell}></td>
								</tr>
							</tbody>
						</table>
					</Box>

					<Box>
						<table style={styles.table}>
							<thead>
								<DealStage />
							</thead>
							<tbody>
								<tr>
									<td style={styles.tableCell}>
										<Stack height="100%" p={1}>
											<DealCard />
										</Stack>
									</td>
								</tr>
							</tbody>
						</table>
					</Box>

					<Box>
						<table style={styles.table}>
							<thead>
								<DealStage />
							</thead>
							<tbody>
								<tr>
									<td style={styles.tableCell}></td>
								</tr>
							</tbody>
						</table>
					</Box>

					<Box>
						<table style={styles.table}>
							<thead>
								<DealStage />
							</thead>
							<tbody>
								<tr>
									<td style={styles.tableCell}>
										<Stack height="100%" p={1}>
											<DealCard />
										</Stack>
									</td>
								</tr>
							</tbody>
						</table>
					</Box>
				</Stack>
			))}
		</React.Fragment>
	);
}
