import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import {
	SummaryFieldName,
	Tooltip,
	twozoStyles,
} from "../../../../../../../styles/twozo";
import { getDateMonthAndYearFormat } from "../../../../../../../utils/DateUtils";
import { useSummaryContext } from "../../../../../../Summary/SummaryContext";
import DateFieldEditForm from "../../../../../FieldEditFormComponents/DateFieldEditForm";
import FieldEdit from "../../../../../FieldEdit";

const fieldEditStyles = {
	fieldEditContainer: {
		width: "371px",
	},
	fieldEditForm: {
		width: "300px",
	},
};

export default function DateFieldValue(props) {
	const { summaryField } = props;

	// summary context
	const { updateSummaryField, entityId } = useSummaryContext();

	const theme = useTheme();
	const classes = twozoStyles();

	const [openToolTip, setOpenToolTip] = useState(false);

	const handleOpenTooltip = (event) => {
		setOpenToolTip(event.target.scrollWidth > event.target.clientWidth);
	};

	const getDateRequestData = (dateFieldValue) => {
		return {
			...dateFieldValue,
			id: entityId,
		};
	};

	const hasValueOrIsChanged = (dateFieldValue) => {
		return (
			dateFieldValue[summaryField?.fieldId] !== summaryField?.value &&
			dateFieldValue[summaryField?.fieldId]
		);
	};

	const handleSave = (dateFieldValue, onClose) => {
		if (hasValueOrIsChanged(dateFieldValue)) {
			let requestData = getDateRequestData(dateFieldValue);
			updateSummaryField(requestData, onClose);
		} else {
			onClose();
		}
	};

	const styles = {
		inputContent: {
			width: "150px",
			"&:hover": { borderBottom: "1px solid rgba(0, 0, 0, 0.2)" },
			fontSize: "14px",
			fontWeight: 500,
			color: theme.palette.secondary.contrastText,
			cursor: "pointer",
			opacity: "0.4",
		},
	};

	const getDateFieldLabel = (onFieldClicked) => {
		return (
			<Box onClick={onFieldClicked}>
				{summaryField.value?.toString() ? (
					<Tooltip
						open={openToolTip}
						placement="bottom"
						title={
							getDateMonthAndYearFormat(summaryField.value) || ""
						}
					>
						<SummaryFieldName
							className={classes.summaryData}
							onMouseOver={handleOpenTooltip}
							onMouseLeave={() => setOpenToolTip(false)}
						>
							{getDateMonthAndYearFormat(summaryField.value)}
						</SummaryFieldName>
					</Tooltip>
				) : summaryField.config.readOnly ? (
					<SummaryFieldName style={{ opacity: 0.6 }}>
						-
					</SummaryFieldName>
				) : (
					<Typography sx={styles.inputContent}>
						Click to Add
					</Typography>
				)}
			</Box>
		);
	};

	return (
		<React.Fragment>
			<FieldEdit
				field={summaryField}
				fieldLabel={getDateFieldLabel}
				styles={fieldEditStyles}
			>
				<DateFieldEditForm
					onSave={handleSave}
					value={summaryField?.value}
					field={summaryField}
				/>
			</FieldEdit>
		</React.Fragment>
	);
}
