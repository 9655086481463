import React from "react";
import BulkEmailList from "../../BulkEmailList";
import { Box, Stack, Typography } from "@mui/material";
import { default as MailDraftIcon } from "../../../../assets/icons/mailDraft";
import { mailListName } from "../../../../utils/EmailUtils/mailListName";
import { getFormattedDateMonthYearAndTime } from "../../../../utils/DateUtils";

const styles = {
	text: {
		fontSize: "14px",
	},
};

export default function Draft() {
	const Header = (props) => (
		<Stack direction="row" alignItems="center" spacing={2}>
			<Box display="flex" pt={0.25}>
				{MailDraftIcon(20, 20, "rgba(0, 0, 0, 0.2)")}
			</Box>

			<Typography style={styles.text}>
				{props.totalRecipients} Contacts
			</Typography>
		</Stack>
	);

	const Body = (props) => (
		<Stack direction="row" spacing={1}>
			<Typography style={styles.text} noWrap>
				{props.subject}
			</Typography>

			{!!props.message && (
				<Typography style={styles.text} color="rgba(0, 0, 0, 0.6)">
					-
				</Typography>
			)}

			<Typography style={styles.text} color="rgba(0, 0, 0, 0.6)" noWrap>
				{props.message}
			</Typography>
		</Stack>
	);

	const Footer = (props) => (
		<Typography style={styles.text} color="rgba(0, 0, 0, 0.6)">
			{getFormattedDateMonthYearAndTime(props.updatedTime)}
		</Typography>
	);

	return (
		<React.Fragment>
			<BulkEmailList
				listHeader="Bulk Emails Drafts"
				header={Header}
				body={Body}
				footer={Footer}
				mailListName={mailListName.BULK_DRAFT}
			/>
		</React.Fragment>
	);
}
