import React from "react";
import RelatedContacts from "../../../Summary/RelatedContacts";
import {
	useAddParticipantMutation,
	useDealParticipants,
	useDeleteParticipantMutation,
	useParticipantsDropdownList,
} from "../../../../hooks/services/deal/dealParticipants";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import { getDealKeyById } from "../../../../utils/queryKeys";
import { useQueryClient } from "@tanstack/react-query";

export default function DealRelatedContacts(props) {
	const { dealId } = props;
	const queryClient = useQueryClient();
	const dealSummaryKey = getDealKeyById(dealId);

	const { data: participantsDropdownList } =
		useParticipantsDropdownList(dealId);

	const { data: dealParticipantsData, isLoading } =
		useDealParticipants(dealId);

	const addParticipantMutation = useAddParticipantMutation(dealId);

	const deleteParticipantMutation = useDeleteParticipantMutation(dealId);

	const onChangeParticipantsMenu = (participant) => {
		let addParticipantRequestData = {
			dealId: dealId,
			contactId: participant?.value,
		};

		addParticipantMutation.mutate(addParticipantRequestData, {
			onSuccess: () => {
				queryClient.invalidateQueries(dealSummaryKey);
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.dealUpdated,
				});
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants?.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	const handleDeleteParticipant = (participantId) => {
		deleteParticipantMutation.mutate(participantId, {
			onSuccess: () => {
				queryClient.invalidateQueries(dealSummaryKey);
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.dealUpdated,
				});
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	return (
		<React.Fragment>
			<RelatedContacts
				isLoading={isLoading}
				dealParticipantsData={dealParticipantsData}
				participantsDropdownList={participantsDropdownList}
				onChangeParticipantsMenu={onChangeParticipantsMenu}
				handleDeleteParticipant={handleDeleteParticipant}
				isAddParticipantsVisible={true}
			/>
		</React.Fragment>
	);
}
