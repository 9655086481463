import React, { useState } from "react";
import {
	Button,
	IconButton,
	MenuItem,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { default as DropDownIcon } from "../../../../assets/icons/dropDownCentered";
import { default as CloseIcon } from "../../../../assets/icons/close";
import { default as DropDownRight } from "../../../../assets/icons/dropDownRight";
import DateRange from "../../../Activities/ActivityFilters/ActivityDueDate/DateRange";
import Menu from "../../../Elements/Menu";
import { getDateMonthAndYearFormat } from "../../../../utils/DateUtils";

export default function HistoryMenu() {
	const theme = useTheme();
	const [dueDateMenuElement, setDueDateMenuElement] = useState(null);
	const isDueDateMenuOpened = Boolean(dueDateMenuElement);
	const [selectedDueDate, setSelectedDueDate] = useState({});
	const [dateRange, setDateRange] = useState("");
	const [dueDateList, setDueDateList] = useState([
		{
			id: "1",
			name: "All",
			isRange: false,
		},
		{
			id: "2",
			name: "Today",
			isRange: false,
		},
		{
			id: "3",
			name: "Yesterday",
			isRange: false,
		},
		{
			id: "4",
			name: "This Week",
			isRange: false,
		},
		{
			id: "5",
			name: "Last Week",
			isRange: false,
		},
		{
			id: "6",
			name: "Between",
			isRange: true,
		},
	]);
	const [selectedDate, setSelectedDate] = useState({});
	const [statusMenuElement, setStatusMenuElement] = useState(null);
	const isStatusMenuOpened = Boolean(statusMenuElement);
	const [selectedStatus, setSelectedStatus] = useState(null);
	const statuses = [
		{
			id: "1",
			name: "All",
		},
		{
			id: "2",
			name: "Success",
		},
		{
			id: "3",
			name: "Failure",
		},
	];

	const openStatusMenu = (event) => {
		setStatusMenuElement(event.currentTarget);
	};

	const closeStatusMenu = () => {
		setStatusMenuElement(null);
	};

	const openDueDateMenu = (event) => {
		setDueDateMenuElement(event.currentTarget);
	};

	const closeDueDateMenu = () => {
		setDueDateMenuElement(null);
	};

	const handleMoveBackToPrevious = () => {
		setSelectedDueDate({});
		setDateRange("");
	};

	const handleDateRangeValueChange = (newDateRangeValue) => {
		setSelectedDate(newDateRangeValue);
	};

	const resetDueDateList = (dateRange) => {
		if (dateRange) {
			//Remove the selected date and replace it with between
			const modifiedDueDateList = dueDateList.map((dueDate) => {
				if (dueDate.isRange) {
					return { ...dueDate, name: "Between" };
				} else {
					return dueDate;
				}
			});

			setDueDateList(modifiedDueDateList);
			setDateRange("");
			setSelectedDate({});
		}
	};

	const handleSelectDueDate = (dueDate) => {
		setSelectedDueDate(dueDate);
		resetDueDateList(dateRange);

		if (!dueDate.isRange) {
			setDueDateMenuElement(null);
		}
	};

	const handleRemoveDueDate = (event) => {
		event.stopPropagation();
		resetDueDateList(dateRange);
		setSelectedDueDate({});
		closeDueDateMenu();
	};

	const onDueDateSelect = (selectedDueDate, dueDate, dateRange) => {
		if (
			!(
				selectedDueDate.id === dueDate.id ||
				(dueDate.isRange && dateRange)
			)
		) {
			return () => handleSelectDueDate(dueDate);
		}
	};

	const handleSave = () => {
		const startDate = getDateMonthAndYearFormat(selectedDate.from);
		const endDate = getDateMonthAndYearFormat(selectedDate.to);
		setDateRange(`${startDate} - ${endDate}`);
		setDueDateMenuElement(null);
		setSelectedDueDate({});

		//Update the selected date instead of between in the menu
		const modifiedDueDateList = dueDateList.map((dueDate) => {
			if (dueDate.isRange) {
				return { ...dueDate, name: `${startDate} - ${endDate}` };
			} else {
				return dueDate;
			}
		});
		setDueDateList(modifiedDueDateList);
	};

	const changeStatus = (status) => {
		setSelectedStatus(status);
		closeStatusMenu();
	};

	return (
		<React.Fragment>
			<Menu
				minWidth="200px"
				anchorEl={dueDateMenuElement}
				open={isDueDateMenuOpened}
				onClose={closeDueDateMenu}
				style={{ marginTop: "8px" }}
			>
				{selectedDueDate?.isRange ? (
					<DateRange
						handleDateRangeValueChange={handleDateRangeValueChange}
						handleSave={handleSave}
						handleMoveBackToPrevious={handleMoveBackToPrevious}
					/>
				) : (
					dueDateList.map((dueDate) => (
						<MenuItem
							key={dueDate.id}
							style={{
								minHeight: "40px",
							}}
							selected={
								selectedDueDate.id === dueDate.id ||
								!!(dueDate.isRange && dateRange)
							}
							onClick={onDueDateSelect(
								selectedDueDate,
								dueDate,
								dateRange
							)}
						>
							<Stack
								minWidth="202px"
								direction="row"
								alignItems="center"
								justifyContent="space-between"
							>
								<Typography fontSize={13}>
									{dueDate.name}
								</Typography>

								{(selectedDueDate.id === dueDate.id ||
									(dueDate.isRange && dateRange)) && (
									<IconButton
										disableRipple
										style={{ padding: 0 }}
										onClick={(event) =>
											handleRemoveDueDate(event)
										}
									>
										{CloseIcon(20, 20, "rgba(0,0,0,0.6)")}
									</IconButton>
								)}

								{dueDate.isRange && !dateRange && (
									<IconButton
										disableRipple
										style={{ padding: 0 }}
									>
										{DropDownRight(
											16,
											16,
											"rgba(0,0,0,0.6)"
										)}
									</IconButton>
								)}
							</Stack>
						</MenuItem>
					))
				)}
			</Menu>

			<Menu
				minWidth="200px"
				anchorEl={statusMenuElement}
				open={isStatusMenuOpened}
				onClose={closeStatusMenu}
				style={{ marginTop: "8px" }}
			>
				{statuses.map((status) => (
					<MenuItem
						key={status.id}
						style={{
							minHeight: "40px",
						}}
						selected={selectedStatus === status.name}
						onClick={() => changeStatus(status.name)}
					>
						<Typography fontSize={13}>{status.name}</Typography>
					</MenuItem>
				))}
			</Menu>

			<Stack direction="row" spacing={2}>
				<Button
					variant="contained"
					endIcon={DropDownIcon(
						16,
						16,
						theme.palette.secondary.contrastText
					)}
					color="secondary"
					disableElevation
					onClick={openDueDateMenu}
				>
					{selectedDueDate.id && !selectedDueDate.isRange
						? selectedDueDate.name
						: dateRange
							? dateRange
							: "Due Date"}
				</Button>

				<Button
					variant="contained"
					endIcon={DropDownIcon(
						16,
						16,
						theme.palette.secondary.contrastText
					)}
					color="secondary"
					disableElevation
					onClick={openStatusMenu}
				>
					{selectedStatus ? selectedStatus : "Status"}
				</Button>
			</Stack>
		</React.Fragment>
	);
}
