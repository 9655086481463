import React, { useEffect, useRef, useState } from "react";
import EmailView from "../EmailView";
import {
	Box,
	Divider,
	IconButton,
	MenuItem,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import contactImage from "../../../../assets/images/contact/unknownContact.png";
import ReplyAllIcon from "../../../../assets/icons/replyAll";
import ReplyIcon from "../../../../assets/icons/reply";
import MoreIcon from "../../../../assets/icons/more";
import {
	getDateMonthAndYearFormat,
	getFormattedDateMonthYearAndTime,
	getHourAndMinuteFormat,
} from "../../../../utils/DateUtils";
import Menu from "../../../Elements/Menu";
import EmailComposer from "../../EmailComposer";
import ConfirmDialog from "../../../Elements/ConfirmDialog";
import { useDeleteMail } from "../../../../hooks/services/mail";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";

export default function ThreadEmailView(props) {
	const {
		mails,
		handleMailSubject,
		mailListName,
		threadId,
		disableViewActions,
		viewerMode,
		isMailViewerFirstRender,
	} = props;
	const theme = useTheme();
	const composerRef = useRef();

	const [defaultComposerValues, setDefaultComposerValues] = useState({});
	const [expandedThreadId, setExpandedThreadId] = useState(
		mails?.[mails?.length - 1]?.id
	);
	const [mailViewerMenuElement, setMailViewerMenuElement] = useState(null);
	const isMailViewerMenuOpened = Boolean(mailViewerMenuElement);
	const [selectedConversationId, setSelectedConversationId] = useState("");
	const [
		replyMailIdForDuplicateComposer,
		setReplyMailIdForDuplicateComposer,
	] = useState("");

	const [showDiscardDialog, setShowDiscardDialog] = useState(false);
	const [composerMode, setComposerMode] = useState({});

	const scheduled = "SCHEDULED";
	const successNotificationTitle = "Success!";
	const replyMode = composerMode.reply || composerMode.replyAll;

	const deleteMailMutation = useDeleteMail(mailListName, threadId);

	const handleMailThreadOpen = (thread) => (_, newThread) => {
		setExpandedThreadId(newThread ? thread : false);
	};

	useEffect(() => {
		const lastConversationData = mails?.[mails?.length - 1];
		if (lastConversationData && isMailViewerFirstRender) {
			if (viewerMode?.reply) {
				handleReply(lastConversationData);
			} else if (viewerMode?.forward) {
				handleForwardMail(lastConversationData);
			}
		}
	}, [viewerMode]);

	useEffect(() => {
		const selectedMail = mails?.find(
			(mail) => mail?.id === expandedThreadId
		);
		if (selectedMail) {
			handleMailSubject(selectedMail?.subject);
		}
	}, [expandedThreadId]);

	const isReplyAllActionEnabled = (mailData) => {
		const toAddressData = mailData?.recipients?.to || [];
		const ccAddressData = mailData?.recipients?.cc || [];
		const bccAddressData = mailData?.recipients?.bcc || [];
		const isMultipleRecipients =
			[...toAddressData, ...ccAddressData, ...bccAddressData].length > 1;
		return isMultipleRecipients;
	};

	const openMailViewerMenu = (event) => {
		event.stopPropagation();
		if (!disableViewActions) {
			setMailViewerMenuElement(event.currentTarget);
		}
	};

	const closeMailViewerMenu = (event) => {
		event.stopPropagation();
		setMailViewerMenuElement(null);
	};

	useEffect(() => {
		if (composerRef.current) {
			if (!composerRef.current) return;

			const resizeObserver = new ResizeObserver(() => {
				composerRef.current.scrollIntoView({ behavior: "smooth" });
			});

			resizeObserver.observe(composerRef.current);
			return () => resizeObserver.disconnect(); // clean up
		}
	}, [selectedConversationId, showDiscardDialog, composerRef]);

	const getReplySubject = (subject) => {
		return subject?.startsWith("Re:") ? subject : `Re: ${subject}`;
	};

	const handleReply = (mail, event) => {
		event?.stopPropagation();
		const toAddressData = mail?.recipients?.to || [];
		const subject = mail?.subject;

		if (mail?.messageCategory === "INBOUND") {
			setDefaultComposerValues({
				toAddress: mail?.from?.contactId
					? [mail?.from?.contactId]
					: [{ email: mail?.from.email }],
				subject: getReplySubject(subject),
				body: mail.message,
			});
		} else {
			setDefaultComposerValues({
				toAddress: toAddressData?.map(
					(toAddress) => toAddress.id || { email: toAddress.mailId }
				),
				subject: getReplySubject(subject),
				body: mail.message,
			});
		}
		setComposerMode({ reply: true });
		openEmailComposer(mail);
	};

	const getReplyAllToAddress = (mail, toAddressData) => {
		let toAddress = toAddressData?.map(
			(toAddress) => toAddress.id || { email: toAddress.mailId }
		);
		let fromAddress = mail?.from?.contactId
			? mail?.from?.contactId
			: { email: mail?.from.email };
		return [fromAddress, ...toAddress];
	};

	const handleReplyAll = (event, mail) => {
		event.stopPropagation();
		const toAddressData = mail?.recipients?.to || [];
		const ccAddressData = mail?.recipients?.cc || [];
		const subject = mail?.subject;
		if (mail?.messageCategory === "INBOUND") {
			setDefaultComposerValues({
				toAddress: getReplyAllToAddress(mail, toAddressData),
				ccAddress: ccAddressData?.map(
					(ccAddress) => ccAddress.id || { email: ccAddress.mailId }
				),
				subject: getReplySubject(subject),
				body: mail.message,
			});
		} else {
			setDefaultComposerValues({
				toAddress: toAddressData?.map(
					(toAddress) => toAddress.id || { email: toAddress.mailId }
				),
				ccAddress: ccAddressData?.map(
					(ccAddress) => ccAddress.id || { email: ccAddress.mailId }
				),
				subject: getReplySubject(subject),
				body: mail.message,
			});
		}
		setComposerMode({ replyAll: true });
		openEmailComposer(mail);
	};

	const openEmailComposer = (mail) => {
		if (!selectedConversationId) {
			setSelectedConversationId(mail.conversationId);
		} else if (selectedConversationId !== mail.conversationId) {
			setShowDiscardDialog(true);
			setReplyMailIdForDuplicateComposer(mail.conversationId);
		}
	};

	const closeEmailComposer = () => {
		setSelectedConversationId("");
		setReplyMailIdForDuplicateComposer("");
		setComposerMode({});
		setDefaultComposerValues({});
	};

	const handleConfirm = () => {
		setSelectedConversationId(replyMailIdForDuplicateComposer);
		setShowDiscardDialog(false);
	};

	const handleDeleteMail = (conversationId) => {
		setMailViewerMenuElement(null);
		deleteMailMutation.mutate(conversationId, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.mailUnactive,
					title: successNotificationTitle,
				});
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	const getForwardSubject = (subject) => {
		return subject.startsWith("Fwd:") ? subject : `Fwd: ${subject}`;
	};

	const handleForwardMail = (mail, event) => {
		event?.stopPropagation();
		setComposerMode({
			forward: true,
		});
		setMailViewerMenuElement(null);
		setSelectedConversationId(mail?.conversationId);
		setDefaultComposerValues({
			subject: getForwardSubject(mail?.subject),
			body: mail.message,
		});
	};

	return (
		<React.Fragment>
			<ConfirmDialog
				open={showDiscardDialog}
				title="Are you sure you want to discard it and create a new one ?"
				subtitle="This action will discard the existing draft and open a new composer."
				onCancel={() => setShowDiscardDialog(false)}
				onConfirm={() => handleConfirm()}
				confirmButtonColor="primary"
			/>

			<Box sx={{ borderTop: `1px solid ${theme.palette.divider}` }}>
				{mails?.map((mail) => (
					<Box key={mail.id}>
						<MuiAccordion
							key={mail.id}
							expanded={expandedThreadId === mail.id}
							onChange={handleMailThreadOpen(mail.id)}
							elevation={0}
							sx={{
								borderBottom: `1px solid ${theme.palette.divider}`,
								borderLeft:
									expandedThreadId === mail.id
										? `2px solid ${theme.palette.primary.main}`
										: "2px solid #fff",
							}}
							square
							disableGutters
						>
							<MuiAccordionSummary
								sx={{
									backgroundColor:
										expandedThreadId === mail.id
											? "#fff"
											: "#FAFAFA",
								}}
							>
								{expandedThreadId === mail?.id ? (
									<Menu
										minWidth="120px"
										anchorEl={mailViewerMenuElement}
										open={isMailViewerMenuOpened}
										onClose={closeMailViewerMenu}
									>
										{mail?.messageCategory !== scheduled ? (
											<MenuItem
												style={{ height: "40px" }}
												onClick={(event) =>
													handleForwardMail(
														mail,
														event
													)
												}
											>
												<Typography
													fontSize={13}
													fontWeight={500}
													color={
														theme.palette.primary
															.main
													}
												>
													Forward
												</Typography>
											</MenuItem>
										) : null}
										<MenuItem
											style={{ height: "40px" }}
											onClick={() =>
												handleDeleteMail(
													mail.conversationId
												)
											}
										>
											<Typography
												fontSize={13}
												fontWeight={500}
												color={
													theme.palette.primary.main
												}
											>
												Delete
											</Typography>
										</MenuItem>
									</Menu>
								) : null}

								<Box width="42vw">
									<Stack
										direction="row"
										alignItems="center"
										justifyContent="space-between"
										flexWrap="wrap"
										spacing={2}
										pb={2}
									>
										<Stack
											direction="row"
											alignItems="center"
											spacing={2}
										>
											<img
												src={contactImage}
												alt="contact_image"
												height="50px"
												width="50px"
											/>

											<Box>
												<Stack
													direction="row"
													spacing={1}
												>
													<Typography
														fontWeight={500}
														style={{
															overflowWrap:
																"anywhere",
														}}
													>
														{mail?.from?.contactName
															? `${mail.from.contactName} <${mail.from?.email}>`
															: `${mail?.from?.name} <${mail?.from?.email}>`}
													</Typography>
													{mail.isBounced ? (
														<Box
															sx={{
																p: 0.5,
																color: theme
																	.palette
																	.secondary
																	.contrastText,
																backgroundColor:
																	theme
																		.palette
																		.secondary
																		.main,
																borderRadius:
																	"6px",
															}}
														>
															<Typography
																fontSize={12}
																fontWeight={500}
															>
																Bounced
															</Typography>
														</Box>
													) : null}
												</Stack>

												<Typography
													fontSize={13}
													color={"rgba(0, 0, 0, 0.6)"}
												>
													{mail?.messageCategory ===
													scheduled
														? "Scheduled for "
														: ""}
													{getDateMonthAndYearFormat(
														mail?.timeStamp
													)}{" "}
													{getHourAndMinuteFormat(
														mail?.timeStamp
													)}
													{expandedThreadId ===
														mail.id && (
														<>
															&nbsp;&nbsp;•&nbsp;&nbsp;
															<span
																style={{
																	color: "#000",
																}}
															>
																Opened(1)
															</span>
															&nbsp;&nbsp;•&nbsp;&nbsp;Clicked
														</>
													)}
												</Typography>
											</Box>
										</Stack>

										{expandedThreadId === mail?.id ? (
											<Stack
												direction="row"
												alignItems="center"
											>
												{mail.messageCategory !==
												scheduled ? (
													<Box display="flex">
														{isReplyAllActionEnabled(
															mail
														) ? (
															<IconButton
																onClick={(
																	event
																) =>
																	handleReplyAll(
																		event,
																		mail
																	)
																}
															>
																{ReplyAllIcon(
																	20,
																	20,
																	theme
																		.palette
																		.primary
																		.main
																)}
															</IconButton>
														) : null}

														<IconButton
															onClick={(event) =>
																handleReply(
																	mail,
																	event
																)
															}
														>
															{ReplyIcon(
																20,
																20,
																theme.palette
																	.primary
																	.main
															)}
														</IconButton>
													</Box>
												) : null}
												<IconButton
													onClick={(event) =>
														openMailViewerMenu(
															event
														)
													}
												>
													{MoreIcon(
														20,
														20,
														theme.palette.primary
															.main
													)}
												</IconButton>
											</Stack>
										) : null}
									</Stack>

									{expandedThreadId === mail.id ? (
										<Box>
											{mail?.recipients?.to?.length >
											0 ? (
												<Stack
													direction="row"
													alignItems="center"
													spacing={2.5}
												>
													<Typography
														fontSize={13}
														color="rgba(0, 0, 0, 0.6)"
														pl={4}
													>
														To
													</Typography>

													<Typography fontSize={13}>
														{mail?.recipients?.to?.map(
															(
																recipient,
																index
															) =>
																`${
																	recipient?.name
																} <${
																	recipient?.mailId
																}>${
																	index !==
																	mail
																		?.recipients
																		?.to
																		?.length -
																		1
																		? ", "
																		: ""
																}`
														)}
													</Typography>
												</Stack>
											) : null}

											{mail?.recipients?.cc?.length >
											0 ? (
												<Stack
													direction="row"
													alignItems="center"
													spacing={2.5}
													pt={1}
												>
													<Typography
														fontSize={13}
														color="rgba(0, 0, 0, 0.6)"
														pl={4}
													>
														CC
													</Typography>

													<Typography fontSize={13}>
														{mail?.recipients?.cc?.map(
															(
																recipient,
																index
															) =>
																`${
																	recipient?.name
																} <${
																	recipient?.mailId
																}>${
																	index !==
																	mail
																		?.recipients
																		?.cc
																		?.length -
																		1
																		? ", "
																		: ""
																}`
														)}
													</Typography>
												</Stack>
											) : null}

											{mail?.recipients?.bcc?.length >
											0 ? (
												<Stack
													direction="row"
													alignItems="center"
													spacing={2.5}
													pt={1}
												>
													<Typography
														fontSize={13}
														color="rgba(0, 0, 0, 0.6)"
														pl={4}
													>
														BCC
													</Typography>

													<Typography fontSize={13}>
														{mail?.recipients?.bcc?.map(
															(
																recipient,
																index
															) =>
																`${
																	recipient?.name
																} <${
																	recipient?.mailId
																}>${
																	index !==
																	mail
																		?.recipients
																		?.bcc
																		?.length -
																		1
																		? ", "
																		: ""
																}`
														)}
													</Typography>
												</Stack>
											) : null}
										</Box>
									) : null}

									{expandedThreadId !== mail.id && (
										<Typography fontSize={14} pr={2} noWrap>
											{mail?.subject
												? mail?.subject
												: "(no subject)"}{" "}
											<span
												style={{
													color: "rgba(0, 0, 0, 0.6)",
												}}
											>
												- {mail?.messagePreview}
											</span>
										</Typography>
									)}
								</Box>
							</MuiAccordionSummary>
							<MuiAccordionDetails>
								<Divider />
								<EmailView
									collapsable
									hideHeader
									mailData={mail}
								/>
							</MuiAccordionDetails>
						</MuiAccordion>

						<Box
							ref={
								expandedThreadId === mail.id
									? composerRef
									: null
							}
						>
							<Box
								hidden={
									selectedConversationId !==
									mail.conversationId
								}
							>
								{replyMode ? (
									<EmailComposer
										composerValues={defaultComposerValues}
										onClose={closeEmailComposer}
										composerMode={composerMode}
										conversationId={mail?.conversationId}
										emailContext={{
											threadId: threadId,
											mailListName: mailListName,
										}}
										trimmedContentContext={
											expandedThreadId === mail.id
												? {
														sendTime:
															getFormattedDateMonthYearAndTime(
																mail?.timeStamp
															),
														fromAddress: mail?.from,
													}
												: {}
										}
									/>
								) : null}

								{composerMode?.forward ? (
									<EmailComposer
										onClose={closeEmailComposer}
										emailContext={{
											threadId: threadId,
											mailListName: mailListName,
										}}
										composerValues={defaultComposerValues}
										composerMode={composerMode}
										forwardMailContext={{
											sendTime:
												getFormattedDateMonthYearAndTime(
													mail?.timeStamp
												),
											fromAddress: mail?.from,
											toAddress: mail?.recipients?.to,
										}}
									/>
								) : null}
							</Box>
						</Box>
					</Box>
				))}
			</Box>
		</React.Fragment>
	);
}
