import { apiClient } from "..";
import { getMailUrl, getNotificationsUrl } from "../apiConfig";
import { assertError } from "../apiHelper";
import postFieldsApi from "../fields/postFieldsApi";

export const getMailList = async (mailListName, filter) => {
	let mailListUrl = getMailListUrl(mailListName);
	let requestData = getMailListRequest(filter);
	return await postMailListApi(mailListUrl, requestData);
};

export const getFilterData = async (mailListName) => {
	let mailListUrl = getMailListUrl(mailListName);
	let requestData = getFilterDataRequest();
	return await postMailListApi(mailListUrl, requestData);
};

export const getMailData = async (mailId, isTrash) => {
	let requestData = getMailRequest(mailId, isTrash);
	return await postMailManagementApi(requestData);
};

export const getSingleConversationData = async (conversationId) => {
	let requestData = getSingleConversationRequest(conversationId);
	return await postMailManagementApi(requestData);
};

export const updateMailState = async (updatedMailState) => {
	let requestData = getMailStateUpdateRequest(updatedMailState);
	return await postMailManagementApi(requestData);
};

export const deleteForever = async (mailIds) => {
	let requestData = getDeleteForeverRequest(mailIds);
	return await postMailManagementApi(requestData);
};

export const updateScheduledMail = async ({
	isCancelled,
	isDeleted,
	mailIds,
}) => {
	let requestData = getScheduledMailUpdateRequest(
		isCancelled,
		isDeleted,
		mailIds
	);
	return await postScheduledMailManagementApi(requestData);
};

export const getFromAddressList = async () => {
	let requestData = getFromAddressRequestData();
	return await postFieldsApi(requestData);
};

export const getMailRecipientsList = async () => {
	let requestData = getMailRecipientsListRequestData();
	return await postFieldsApi(requestData);
};

export const sendMail = async (sendMailRequestData) => {
	let requestData = getSendMailRequestData(sendMailRequestData);
	return await postMailManagementApi(requestData);
};

export const forwardMail = async (forwardMailRequestData) => {
	let requestData = getForwardMailRequestData(forwardMailRequestData);
	return await postMailManagementApi(requestData);
};

export const saveDraftMail = async (draftMailRequestData) => {
	let requestData = getDraftMailRequestData(draftMailRequestData);
	return await postMailManagementApi(requestData);
};

export const deleteSingleMail = async (mailId) => {
	let requestData = getDeleteMailRequestData(mailId);
	return await postMailManagementApi(requestData);
};

export const getMetrics = async (metricId) => {
	let requestData = getMetricsRequestData(metricId);
	return await postBulkMailManagementApi(requestData);
};

export const getMetricsInsights = async (metricId, metricType) => {
	let requestData = getMetricsInsightsRequestData(metricId, metricType);
	return await postBulkMailManagementApi(requestData);
};

export const markAsDone = async (mailIds) => {
	let requestData = getMarkAsDoneRequestData(mailIds);
	return await postMailManagementApi(requestData);
};

export const sendBulkMail = async (sendBulkMailRequestData) => {
	let requestData = getSendBulkMailRequestData(sendBulkMailRequestData);
	return await postBulkMailManagementApi(requestData);
};

export const discardDrafts = async (mailIds) => {
	let requestData = getDiscardDraftRequestData(mailIds);
	return await postMailManagementApi(requestData);
};

const getMailListRequest = (filter) => {
	if (filter?.criteria) {
		return {
			data: {
				...filter,
			},
		};
	} else {
		return {
			data: {},
		};
	}
};

export const replyMail = async (replyMailRequestData) => {
	let requestData = getReplyMailRequestData(replyMailRequestData);
	return await postMailManagementApi(requestData);
};

export const uploadFiles = async (files) => {
	let requestData = getUploadFilesRequestData(files);
	return await postFileUploadManagementApi(requestData);
};

const getFilterDataRequest = () => {
	return {
		type: "getFilter",
		data: {},
	};
};

const getMailRequest = (mailId, isTrash) => {
	return {
		type: "getMails",
		data: {
			id: mailId,
			isTrash: isTrash,
		},
	};
};

const getSingleConversationRequest = (conversationId) => {
	return {
		type: "getSingleConversation",
		data: {
			id: conversationId,
		},
	};
};

const getMailStateUpdateRequest = (updatedMailState) => {
	return {
		type: "updateState",
		data: {
			...updatedMailState,
		},
	};
};

const getScheduledMailUpdateRequest = (isCancelled, isDeleted, mailIds) => {
	return {
		type: "updateScheduledMail",
		data: {
			ids: mailIds,
			isCancelled: isCancelled,
			isDeleted: isDeleted,
			scheduledType: 1,
		},
	};
};

const getSendMailRequestData = (sendMailRequestData) => {
	return {
		type: "send",
		data: sendMailRequestData,
	};
};

const getDraftMailRequestData = (draftMailRequestData) => {
	return {
		type: "draft",
		data: draftMailRequestData,
	};
};

const getDeleteMailRequestData = (mailId) => {
	return {
		type: "delete",
		data: {
			id: mailId,
			isActive: false,
		},
	};
};

const getSendBulkMailRequestData = (sendBulkMailRequestData) => {
	return {
		type: "bulkSend",
		data: sendBulkMailRequestData,
	};
};

const getReplyMailRequestData = (replyMailRequestData) => {
	return {
		type: "reply",
		data: replyMailRequestData,
	};
};

const getForwardMailRequestData = (forwardMailRequestData) => {
	return {
		type: "forward",
		data: forwardMailRequestData,
	};
};

const getFromAddressRequestData = () => {
	return {
		type: "dropdownData",
		data: {
			name: "fromAddressList",
		},
	};
};

const getMailRecipientsListRequestData = () => {
	return {
		type: "dropdownData",
		data: {
			name: "contactList",
			includeEmails: true,
		},
	};
};

const getMetricsRequestData = (metricId) => {
	return {
		type: "getMetrics",
		data: {
			id: metricId,
		},
	};
};

const getDeleteForeverRequest = (mailIds) => {
	return {
		type: "deleteForever",
		data: {
			ids: mailIds,
		},
	};
};

const getUploadFilesRequestData = (files) => {
	return {
		type: "upload",
		data: {
			attachments: files,
		},
	};
};

const getMarkAsDoneRequestData = (mailIds) => {
	return {
		type: "markAsDone",
		data: {
			ids: mailIds,
		},
	};
};

const getMetricsInsightsRequestData = (metricId, metricType) => {
	return {
		type: "metricsInsights",
		data: {
			id: metricId,
			type: metricType,
		},
	};
};

const getDiscardDraftRequestData = (mailIds) => {
	return {
		type: "discardDrafts",
		data: {
			ids: mailIds,
		},
	};
};

const postMailListApi = async (mailListUrl, requestData) => {
	let response = await apiClient.post(mailListUrl, requestData);
	assertError(response);
	return response.data.data;
};

const postMailManagementApi = async (requestData) => {
	let mailNotificationUrl = getMailNotificationUrl();
	let response = await apiClient.post(mailNotificationUrl, requestData);
	assertError(response);
	return response.data.data;
};

const postBulkMailManagementApi = async (requestData) => {
	let bulkMailNotificationUrl = getBulkMailNotificationUrl();
	let response = await apiClient.post(bulkMailNotificationUrl, requestData);
	assertError(response);
	return response.data.data;
};

const postScheduledMailManagementApi = async (requestData) => {
	let scheduledMailNotificationUrl = getScheduledMailNotificationUrl();
	let response = await apiClient.post(
		scheduledMailNotificationUrl,
		requestData
	);
	assertError(response);
	return response.data.data;
};

const postFileUploadManagementApi = async (requestData) => {
	let mailNotificationUrl = getMailNotificationUrl();
	let response = await apiClient.post(mailNotificationUrl, requestData, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
	assertError(response);
	return response.data.data;
};

const getMailListUrl = (mailListName) => {
	return getMailUrl() + `/${mailListName}/list`;
};

const getMailNotificationUrl = () => {
	return getNotificationsUrl() + "/email";
};

const getBulkMailNotificationUrl = () => {
	return getNotificationsUrl() + "/bulk/email";
};

const getScheduledMailNotificationUrl = () => {
	return getNotificationsUrl() + "/scheduled/email";
};
