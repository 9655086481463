import React, { useEffect, useState } from "react";
import TagsList from "../TagsList";
import {
	useCreateTagMutation,
	useDeleteTagMutation,
	useTagsList,
	useUpdateTagMutation,
} from "../../../../hooks/services/tags";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import { tagOperations } from "../../../../utils/tagOperations";
import { tagActionTypes } from "../../../../utils/tagActionTypes";
import { useTagContext } from "../TagsContext";

export default function RecordTags() {
	const { tagState, tagStateDispatcher } = useTagContext();

	// tags mutations
	const createTagMutation = useCreateTagMutation();
	const updateTagMutation = useUpdateTagMutation();
	const deleteTagMutation = useDeleteTagMutation();

	const [tags, setTags] = useState([]);
	const [editedTagData, setEditedTagData] = useState({});
	const deleteDialogSubTitle =
		"This will also removed from all the contacts, companies and deals if used.";

	const {
		data: tagsList,
		status: tagsListStatus,
		isLoading: isLoadingRecordTagList,
	} = useTagsList();

	useEffect(() => {
		if (tagsListStatus === "success") {
			if (tagsList) {
				setTags(tagsList);
			}
		}
	}, [tagsListStatus, tagsList]);

	const handleCreateRecordTag = (tagName) => {
		// create new tag mutation
		createTagMutation.mutate(tagName, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.tagCreated,
				});
				tagStateDispatcher({ type: tagActionTypes.resetCreateActions });
			},
			onError: (error) => {
				let errorMessage = error?.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage
						? errorMessage
						: notificationMessage.errorMessage,
				});
			},
		});
	};

	const handleUpdateRecordTag = (tagName, tagId) => {
		// update tag mutation
		updateTagMutation.mutate(
			{ tagName, tagId },
			{
				onSuccess: () => {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.tagUpdated,
					});
					tagStateDispatcher({
						type: tagActionTypes.resetUpdateActions,
					});
					setEditedTagData({});
				},
				onError: (error) => {
					let errorMessage = error?.message;
					enqueueSnackbar({
						variant: notificationVariants.error,
						message: errorMessage
							? errorMessage
							: notificationMessage.errorMessage,
					});
				},
			}
		);
	};

	const handleDeleteRecordTag = (tag) => {
		// delete mutation
		deleteTagMutation.mutate(tag.id, {
			onSuccess: () => {
				enqueueSnackbar({
					title: "Success!",
					variant: notificationVariants.error,
					message: notificationMessage.tagDeleted,
				});
			},
			onError: (error) => {
				let errorMessage = error?.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage
						? errorMessage
						: notificationMessage.errorMessage,
				});
			},
		});

		// filter the deleted tag
		let filteredTags = tags.filter((tags) => tags.id !== tag.id);
		setTags(filteredTags);
	};

	const updateEditedTagData = (editedTagData) => {
		setEditedTagData(editedTagData);
	};

	const updateTags = (updatedTag, index) => {
		const updatedTags = tags.map((tag, tagIndex) => {
			if (index === tagIndex) {
				return { ...tag, ...updatedTag };
			}

			return tag;
		});
		setTags(updatedTags);
	};

	const performCreateUpdateDelete = (action, tagName, tagId) => {
		switch (action) {
			case tagOperations.create:
				return handleCreateRecordTag(tagName);
			case tagOperations.update:
				return handleUpdateRecordTag(tagName, tagId);
			case tagOperations.delete:
				return handleDeleteRecordTag(tagName);
			default:
				break;
		}
	};

	return (
		<React.Fragment>
			{isLoadingRecordTagList ? (
				<Stack
					justifyContent="center"
					alignItems="center"
					height="30vh"
				>
					<CircularProgress size="35px" />
				</Stack>
			) : (!!tags && tags.length > 0) || tagState.isCreateMode ? (
				<TagsList
					tags={tags}
					updateTags={updateTags}
					editedTagData={editedTagData}
					updateEditedTagData={updateEditedTagData}
					deleteDialogSubTitle={deleteDialogSubTitle}
					performCreateUpdateDelete={performCreateUpdateDelete}
				/>
			) : (
				<Stack
					p={4}
					justifyContent="center"
					alignItems="center"
					width="100%"
				>
					<Typography fontWeight={400} width="75%">
						You can efficiently organize your records by creating
						tags and assigning them to deals, contacts, and
						companies. This feature enhances your organization and
						simplifies the process of sorting and retrieving
						information.
					</Typography>
				</Stack>
			)}
		</React.Fragment>
	);
}
