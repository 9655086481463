import React, { useState } from "react";
import {
	Typography,
	Box,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TableContainer,
	Stack,
	Button,
} from "@mui/material";
import { TableHeaderLabel, twozoStyles } from "../../../../styles/twozo";
import companyProfile from "../../../../assets/images/company/companyProfile.png";
import ReviewDuplicates from "./ReviewDuplicates";
import CustomSwipeableDrawer from "../../../Elements/CustomSwipeableDrawer";
import {
	useCompanyDuplicateList,
	useDismissDuplicateCompany,
} from "../../../../hooks/services/company/companyDuplicates";
import ConfirmDialog from "../../ConfirmDialog";
import { enqueueSnackbar } from "notistack";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import Can from "../../../Auth/Can";
import CanNot from "../../../Auth/CanNot";
import UnAuthorized from "../../../Auth/UnAuthorized";
import { PERMISSIONS } from "../../../../utils/Auth";

export default function DuplicateCompany() {
	const classes = twozoStyles();
	const [isReviewDialogOpened, setIsReviewDialogOpened] = useState(false);
	const [primaryCompanyId, setPrimaryCompanyId] = useState(null);
	const [isDismissDialogOpened, setIsDismissDialogOpened] = useState(false);

	const notificationTitle = "Success";

	const { data: companyDuplicateList } = useCompanyDuplicateList();

	const dismissDuplicateCompanyMutation = useDismissDuplicateCompany();

	const toggleReviewDialogBox = (id) => {
		setIsReviewDialogOpened(
			(isReviewDialogOpened) => !isReviewDialogOpened
		);
		setPrimaryCompanyId(id);
	};

	const openDismissDialog = (id) => {
		setPrimaryCompanyId(id);
		setIsDismissDialogOpened(true);
	};

	const closeDismissDialog = () => {
		setIsDismissDialogOpened(false);
	};

	const dismissDuplicateCompany = () => {
		dismissDuplicateCompanyMutation.mutate(primaryCompanyId, {
			onSuccess: () => {
				closeDismissDialog();
				enqueueSnackbar({
					title: notificationTitle,
					variant: notificationVariants.error,
					message: notificationMessage.companyDismissMessage,
				});
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.errorMessage,
				});
			},
		});
	};

	return (
		<React.Fragment>
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: {
						boxShadow: "-3px 0px 14px rgba(0, 0, 0, 0.1)",
						borderRadius: "8px",
					},
				}}
				open={isReviewDialogOpened}
				onOpen={toggleReviewDialogBox}
				onClose={toggleReviewDialogBox}
				BackdropProps={{ style: { backgroundColor: "transparent" } }}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box
					className={classes.addDialogContainer}
					style={{
						overflowY: "scroll",
						boxShadow: "-3px 0px 14px rgba(0, 0, 0, 0.1)",
					}}
				>
					<ReviewDuplicates
						primaryCompanyId={primaryCompanyId}
						onClose={toggleReviewDialogBox}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<ConfirmDialog
				open={isDismissDialogOpened}
				onCancel={closeDismissDialog}
				onConfirm={dismissDuplicateCompany}
				title="Are you sure you want to dismiss these companies?"
				subtitle="The duplicates found will no longer be suggested for merge."
				confirmButtonText="Dismiss"
			/>

			<Can permission={PERMISSIONS.company.mergeRecords}>
				<Box px={3}>
					<Typography fontSize={16} fontWeight={400}>
						Companies with the same name are considered duplicates.
					</Typography>
				</Box>

				<Box pt={1}>
					<TableContainer>
						<Table sx={{ minWidth: 650 }} size="small">
							<TableHead>
								<TableRow>
									<TableCell>
										<TableHeaderLabel ml={1}>
											Primary Company
										</TableHeaderLabel>
									</TableCell>
									<TableCell>
										<TableHeaderLabel ml={1}>
											Secondary Company
										</TableHeaderLabel>
									</TableCell>
									<TableCell>
										<TableHeaderLabel ml={1}>
											Actions
										</TableHeaderLabel>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{companyDuplicateList?.length > 0 ? (
									companyDuplicateList?.map(
										(duplicateCompany, index) => (
											<TableRow key={index}>
												<TableCell>
													<Box p={1}>
														<Stack
															alignItems="center"
															direction="row"
															spacing={2}
														>
															<img
																style={{
																	borderRadius:
																		"100%",
																}}
																alt="companyImage"
																src={
																	companyProfile
																}
																width="48px"
																height="48px"
															/>
															<Stack>
																<Typography
																	fontSize={
																		14
																	}
																>
																	{
																		duplicateCompany
																			.primary
																			.name
																	}
																</Typography>
															</Stack>
														</Stack>
													</Box>
												</TableCell>
												<TableCell>
													<Box p={1}>
														<Stack
															alignItems="center"
															direction="row"
															spacing={2}
														>
															<img
																style={{
																	borderRadius:
																		"100%",
																}}
																alt="companyImage"
																src={
																	companyProfile
																}
																width="48px"
																height="48px"
															/>
															<Stack>
																<Typography
																	fontSize={
																		14
																	}
																>
																	{
																		duplicateCompany
																			.secondary
																			.name
																	}
																</Typography>
															</Stack>
														</Stack>
													</Box>
												</TableCell>
												<TableCell>
													<Stack
														p={1}
														direction="row"
														spacing={2}
														alignItems="center"
													>
														<Button
															variant="contained"
															onClick={() =>
																toggleReviewDialogBox(
																	duplicateCompany
																		?.primary
																		?.id
																)
															}
															disableElevation
														>
															Review
														</Button>
														<Button
															variant="contained"
															color="secondary"
															disableElevation
															onClick={() =>
																openDismissDialog(
																	duplicateCompany
																		.primary
																		.id
																)
															}
														>
															Dismiss
														</Button>
													</Stack>
												</TableCell>
											</TableRow>
										)
									)
								) : (
									<TableRow>
										<TableCell
											colSpan={7}
											sx={{
												border: "none",
											}}
										>
											<Stack
												alignItems="center"
												justifyContent="center"
												style={{
													height: "400px",
													width: "100%",
												}}
											>
												<Typography
													fontSize={14}
													fontWeight={400}
													style={{
														opacity: 0.6,
													}}
												>
													No Duplicates Found
												</Typography>
											</Stack>
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
			</Can>

			<CanNot permission={PERMISSIONS.company.mergeRecords}>
				<Box height="50vh">
					<UnAuthorized />
				</Box>
			</CanNot>
		</React.Fragment>
	);
}
