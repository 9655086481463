import React, { useState } from "react";
import {
	Box,
	Button,
	CircularProgress,
	Divider,
	MenuItem,
	Select,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { default as SendIcon } from "../../../../../assets/icons/send";
import { default as TrackIcon } from "../../../../../assets/icons/track";
import { default as ContactIcon } from "../../../../../assets/icons/contact";
import { default as WorkflowIcon } from "../../../../../assets/icons/workflow";
import { default as AddIcon } from "../../../../../assets/icons/add";
import Dialog from "../../../../Elements/Dialog";
import { FormFieldName } from "../../../../../styles/twozo";
import EmailSyncSettings from "../EmailSyncSettings";
import { useQuery } from "@tanstack/react-query";
import { getConnectedEmailData } from "../../../../../api/emailSync";
import { getDropdownDataByName } from "../../../../../utils/dropdownData";
import { connectedEmailKey } from "../../../../../utils/queryKeys";

export default function Sync() {
	const theme = useTheme();
	const [openConnectEmailAccountDialog, setOpenConnectEmailAccountDialog] =
		useState(false);
	const handleOpenConnectEmailAccountDialog = () => {
		setOpenConnectEmailAccountDialog(true);
	};

	const handleCloseConnectEmailAccountDialog = () => {
		setOpenConnectEmailAccountDialog(false);
	};

	const FeatureText = (props) => (
		<Stack
			direction="row"
			justifyContent="center"
			spacing={2}
			py={2}
			pl={3}
		>
			{props.icon(20, 20, theme.palette.primary.main)}

			<Typography fontSize={14}>{props.text}</Typography>
		</Stack>
	);

	//Select provider
	const providerList = getDropdownDataByName("PROVIDER_LIST");
	const [selectedProvider, setSelectedProvider] = useState({});

	const selectProvider = (provider) => {
		setSelectedProvider(provider);
	};

	const connectEmail = () => {
		// eslint-disable-next-line no-undef
		if (process.env.NODE_ENV === "development") {
			window.location.href = `/api/oauth/user/consent/${selectedProvider.name}?localRedirectionRequired=true`;
		} else {
			window.location.href = `/api/oauth/user/consent/${selectedProvider.name}`;
		}
	};

	// Get email settings
	const { data: connectedEmailDetails, isLoading } = useQuery(
		connectedEmailKey,
		() => getConnectedEmailData(),
		{
			staleTime: 30000,
			select: (connectedEmailData) => connectedEmailData?.settings,
		}
	);

	return (
		<React.Fragment>
			<Dialog
				open={openConnectEmailAccountDialog}
				onClose={handleCloseConnectEmailAccountDialog}
				title="Connect Email Account"
			>
				<Stack spacing={2} p={2} pb={3}>
					<Stack spacing={0.5}>
						<FormFieldName>Select Email Provider</FormFieldName>

						<Select value={selectedProvider.value || ""} fullWidth>
							{providerList.map((data) => (
								<MenuItem
									value={data.value}
									key={data.value}
									onClick={() => selectProvider(data)}
								>
									<Typography fontSize={14}>
										{data.displayName}
									</Typography>
								</MenuItem>
							))}
						</Select>
					</Stack>

					<Stack alignItems="flex-end" pt={1}>
						<Button
							variant="contained"
							onClick={() => {
								handleCloseConnectEmailAccountDialog();
								connectEmail();
							}}
							disableElevation
						>
							Connect
						</Button>
					</Stack>
				</Stack>
			</Dialog>

			{isLoading ? (
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="center"
					height="60vh"
					spacing={1}
				>
					<CircularProgress />
				</Stack>
			) : (
				<Box>
					{connectedEmailDetails?.id ? (
						<EmailSyncSettings
							connectedEmailDetails={connectedEmailDetails}
							connectedEmailKey={connectedEmailKey}
						/>
					) : (
						<Stack alignItems="center" py={3}>
							<Stack alignItems="flex-start">
								<FeatureText
									icon={SendIcon}
									text="Send and schedule emails from CRM"
								/>

								<Divider style={{ width: "130%" }} />

								<FeatureText
									icon={TrackIcon}
									text="Track email engagement"
								/>

								<Divider style={{ width: "130%" }} />

								<FeatureText
									icon={ContactIcon}
									text="Link emails to contacts and deals automatically"
								/>

								<Divider style={{ width: "130%" }} />

								<FeatureText
									icon={WorkflowIcon}
									text="Setup email workflows"
								/>
							</Stack>

							<Box pt={5}>
								<Button
									variant="contained"
									startIcon={AddIcon(20, 20, "#fff")}
									onClick={
										handleOpenConnectEmailAccountDialog
									}
									disableElevation
								>
									Connect Email Account
								</Button>
							</Box>
						</Stack>
					)}
				</Box>
			)}
		</React.Fragment>
	);
}
