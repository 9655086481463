import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
	getNotificationAlertStateKey,
	getNotificationsDataKey,
	getPendingNotificationKey,
	getReminderNotificationKey,
	getTopNotificationsKey,
} from "../../../../utils/queryKeys/appNotification";
import {
	getNotificationAlertState,
	getNotificationsData,
	getPendingNotification,
	getReminderNotificationData,
	getTopNotifications,
	updateMarkAllAsRead,
	updateMarkAsRead,
	updateNotificationAlertState,
	updatePendingNotification,
} from "../../../../api/notification/appNotification/appNotificationApi";

const useNotificationsData = () => {
	return useQuery(getNotificationsDataKey(), () => getNotificationsData(), {
		select: (data) => data.notifications,
	});
};

const usePendingNotification = () => {
	return useQuery(
		getPendingNotificationKey(),
		() => getPendingNotification(),
		{
			select: (data) => data.pendingNotification,
			refetchInterval: (data) => pendingNotificationRefetchInterval(data),
		}
	);
};

const pendingNotificationRefetchInterval = (pendingNotification) => {
	if (!pendingNotification) {
		return 10000;
	}
};

const useTopNotifications = () => {
	return useQuery(getTopNotificationsKey(), () => getTopNotifications(), {
		refetchOnWindowFocus: false,
	});
};

const useNotificationAlertState = () => {
	return useQuery(
		getNotificationAlertStateKey(),
		() => getNotificationAlertState(),
		{
			select: (data) => data.notificationState,
		}
	);
};

const useReminderNotification = () => {
	return useQuery(
		getReminderNotificationKey(),
		() => getReminderNotificationData(),
		{
			refetchInterval: () => reminderNotificationRefetchInterval(),
		}
	);
};

const reminderNotificationRefetchInterval = () => {
	return 10000;
};

const useInvalidateNotificationsData = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(getNotificationsDataKey());
	};
};

const useInvalidateNotificationAlertState = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(getNotificationAlertStateKey());
	};
};

const useInvalidatePendingNotification = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(getPendingNotificationKey());
	};
};

const useUpdateNotificationAlertStateMutation = () => {
	let invalidateNotificationAlertState =
		useInvalidateNotificationAlertState();
	return useMutation(updateNotificationAlertState, {
		onSuccess: () => {
			invalidateNotificationAlertState();
		},
	});
};

const useMarkAllAsReadMutation = () => {
	let invalidateNotificationsData = useInvalidateNotificationsData();
	return useMutation(updateMarkAllAsRead, {
		onSuccess: () => {
			invalidateNotificationsData();
		},
	});
};

const useMarkAsReadMutation = () => {
	let invalidateNotificationsData = useInvalidateNotificationsData();
	return useMutation(updateMarkAsRead, {
		onSuccess: () => {
			invalidateNotificationsData();
		},
	});
};

const useUpdatePendingNotification = () => {
	let invalidatePendingNotification = useInvalidatePendingNotification();
	return useMutation(updatePendingNotification, {
		onSuccess: () => {
			invalidatePendingNotification();
		},
	});
};

export {
	useNotificationsData,
	useNotificationAlertState,
	useUpdateNotificationAlertStateMutation,
	useMarkAllAsReadMutation,
	useMarkAsReadMutation,
	usePendingNotification,
	useTopNotifications,
	useUpdatePendingNotification,
	useReminderNotification,
};
