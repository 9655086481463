import React, { useState } from "react";
import {
	Box,
	Button,
	Divider,
	Stack,
	ToggleButton,
	Typography,
	useTheme,
} from "@mui/material";
import { twozoStyles } from "../../../styles/twozo";
import { useNavigate, useSearchParams } from "react-router-dom";
import { default as DropDownRight } from "../../../assets/icons/dropDownRight";
import { default as AddIcon } from "../../../assets/icons/add";
import ToggleButtonGroup from "../../Elements/ToggleButtonGroup";
import Templates from "./Templates";
import Automation from "./Automation";
import History from "./History";
import HistoryMenu from "./History/menu";

const types = {
	TEMPLATE: "template",
	AUTOMATION: "automation",
	HISTORY: "history",
};

export default function WorkflowAutomation() {
	const theme = useTheme();
	const navigate = useNavigate();
	const classes = twozoStyles();
	const [searchParams, setSearchParams] = useSearchParams();
	const [type, setType] = useState(searchParams.get("type") || "template");

	const handleToggleButtonGroup = (_, type) => {
		if (type) {
			setType(type);
			setSearchParams({ type: type });
		}
	};

	return (
		<React.Fragment>
			<Box
				style={{
					height: "calc(100% - 64px)", //TODO: The top bar height is handle as static value, In future It will be handled as dynamically
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box className={classes.menuBar}>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={2}
						height="100%"
					>
						<Stack direction="row" alignItems="center" spacing={1}>
							<Box
								pl={1}
								style={{ cursor: "pointer" }}
								onClick={() => navigate("/settings")}
							>
								<Typography
									fontWeight={500}
									fontSize={16}
									color={theme.palette.secondary.contrastText}
								>
									Settings
								</Typography>
							</Box>

							{DropDownRight(16, 16)}

							<Typography fontWeight={500} fontSize={16}>
								Workflow Management
							</Typography>
						</Stack>

						<Button
							variant="contained"
							startIcon={AddIcon(20, 20, "#fff")}
							color="primary"
							disableElevation
							onClick={() => navigate("/settings/workflow/-1")}
						>
							Automation
						</Button>
					</Stack>
				</Box>

				<Divider />

				<Stack direction="row" alignItems="center" spacing={3} p={2}>
					<ToggleButtonGroup
						value={type}
						onChange={handleToggleButtonGroup}
					>
						<ToggleButton value={types.TEMPLATE}>
							Templates
						</ToggleButton>
						<ToggleButton value={types.AUTOMATION}>
							Automation
						</ToggleButton>
						<ToggleButton value={types.HISTORY}>
							History
						</ToggleButton>
					</ToggleButtonGroup>

					<Box>{type === types.HISTORY && <HistoryMenu />}</Box>
				</Stack>

				<Box
					style={{
						flexGrow: 1,
						overflowY: "auto",
						height: "0px", // Need height for the overflowY to work. Refer - https://stackoverflow.com/a/14964944/8653179
					}}
				>
					{(function () {
						switch (type) {
							case types.TEMPLATE:
								return <Templates />;
							case types.AUTOMATION:
								return <Automation />;
							case types.HISTORY:
								return <History />;
						}
					})()}
				</Box>
			</Box>
		</React.Fragment>
	);
}
