import React, { useEffect, useState } from "react";
import DealAutocomplete from "../../../../../DealAutoComplete";
import { Stack } from "@mui/material";
import AddNewContact from "../../../../../../Deals/AddNewDeals/AddNewContact";
import { default as ContactIcon } from "../../../../../../../assets/icons/contact";
import { removeFieldsWithEmptyValues } from "../../../../../../../utils/common";
import { useContactList } from "../../../../../../../hooks/services/contact";
import { useAuth } from "../../../../../../../hooks/auth";
import { PERMISSIONS } from "../../../../../../../utils/Auth";

export function ContactOnBoardBaseComponent(props) {
	const {
		field,
		onContactInputChange,
		inputRef,
		value,
		error,
		clearErrors,
		formValues,
	} = props;

	const [isAddNewContact, setIsAddNewContact] = useState(false);
	const [selectedContact, setSelectedContact] = useState({ name: "" });
	const [contactList, setContactList] = useState([]);

	const relatedFieldId = field?.relatedField?.id;
	const relatedContactIds = formValues?.[field?.config?.associatedWith];
	const relatedContactIdsSet = new Set(relatedContactIds);

	const contactFormData = {
		[relatedFieldId]: selectedContact?.name,
	};

	const { isUserAllowedFor } = useAuth();
	const isContactListEnabled = isUserAllowedFor(PERMISSIONS.contact.view);

	const { data: contacts, status: contactsStatus } =
		useContactList(isContactListEnabled);

	useEffect(() => {
		if (contactsStatus === "success" && contacts?.length > 0) {
			setContactList(
				contacts.filter(
					(contact) => !relatedContactIdsSet.has(contact?.value)
				)
			);
		}
	}, [contacts, contactsStatus, relatedContactIds]);

	useEffect(() => {
		const getSelectedContact = () => {
			const selectedContact = contacts?.find(
				(contact) => contact.value === value
			);
			if (selectedContact) {
				return selectedContact;
			}
		};
		const contactValue = getSelectedContact();
		if (contactValue && isUserAllowedFor(PERMISSIONS.contact.view)) {
			setSelectedContact(contactValue);
		}
	}, [value, contacts]);

	const onClearErrors = () => {
		clearErrors(field.id);
	};

	const handleSelectedContacts = (value) => {
		if (!value) {
			return;
		}
		let selectedContact = { ...value };

		if (selectedContact?.isNewOption) {
			delete selectedContact?.isNewOption;
			setSelectedContact(selectedContact);
			setIsAddNewContact(true);
		} else {
			setSelectedContact(selectedContact);
			onContactInputChange(selectedContact.value);
		}
		onClearErrors();
	};

	const getInputAdornmentIcon = () => {
		return ContactIcon;
	};

	const handleRemoveContactInput = () => {
		setSelectedContact({ name: "" });
		onContactInputChange("");
	};

	const onAddNewContact = (formSubmitData) => {
		removeFieldsWithEmptyValues(formSubmitData);
		onContactInputChange(formSubmitData);

		const contactValue = {
			...selectedContact,
			name: formSubmitData[relatedFieldId],
		};
		setSelectedContact(contactValue);
		setIsAddNewContact(false);
		onClearErrors();
	};

	const onCloseAddNewContactForm = () => {
		setIsAddNewContact(false);
		setSelectedContact({ name: "" });
	};

	return (
		<React.Fragment>
			<Stack spacing={1}>
				{isAddNewContact ? (
					<AddNewContact
						onSubmit={onAddNewContact}
						onClose={onCloseAddNewContactForm}
						contactFormData={contactFormData}
					></AddNewContact>
				) : (
					<DealAutocomplete
						inputRef={inputRef}
						error={error}
						onClearErrors={onClearErrors}
						options={contactList || []}
						value={selectedContact}
						onChange={(_, value) => handleSelectedContacts(value)}
						getInputAdornmentIcon={getInputAdornmentIcon}
						handleRemoveInput={handleRemoveContactInput}
						placeHolder={field?.placeHolder}
						noOptionsText="No Results Found"
						isAllowNewOption={isUserAllowedFor(
							PERMISSIONS.contact.create
						)}
					></DealAutocomplete>
				)}
			</Stack>
		</React.Fragment>
	);
}
