import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
	getDealParticipantsDataKey,
	getDealParticipantsDropdownKey,
} from "../../../utils/queryKeys";
import {
	addParticipant,
	deleteParticipant,
	getDealParticipantDropdownList,
	getDealParticipantsData,
} from "../../../api/deal/dealApi";

const useDealParticipants = (dealId) => {
	const dealParticipantsDataKey = getDealParticipantsDataKey(dealId);
	return useQuery(
		dealParticipantsDataKey,
		() => getDealParticipantsData(dealId),
		{
			enabled: !!dealId,
			select: (data) => data?.list,
		}
	);
};

const useInvalidateDealParticipantsData = (dealId) => {
	let queryClient = useQueryClient();
	const dealParticipantsDataKey = getDealParticipantsDataKey(dealId);
	return () => {
		queryClient.invalidateQueries(dealParticipantsDataKey);
	};
};

//Add Participant
const useAddParticipantMutation = (dealId) => {
	let invalidateParticipantsData = useInvalidateDealParticipantsData(dealId);
	let invalidateParticipantsDropdownList =
		useInvalidateParticipantsDropdownList(dealId);
	return useMutation(addParticipant, {
		onSuccess: () => {
			invalidateParticipantsData();
			invalidateParticipantsDropdownList();
		},
	});
};

//Delete Participant
const useDeleteParticipantMutation = (dealId) => {
	let invalidateParticipantsData = useInvalidateDealParticipantsData(dealId);
	let invalidateParticipantsDropdownList =
		useInvalidateParticipantsDropdownList(dealId);
	return useMutation(deleteParticipant, {
		onSuccess: () => {
			invalidateParticipantsData();
			invalidateParticipantsDropdownList();
		},
	});
};

// participants dropdown list
const useParticipantsDropdownList = (dealId) => {
	const participantsDropdownKey = getDealParticipantsDropdownKey(dealId);
	return useQuery(
		participantsDropdownKey,
		() => getDealParticipantDropdownList(dealId),
		{
			enabled: !!dealId,
			select: (data) => data?.options,
		}
	);
};

const useInvalidateParticipantsDropdownList = (dealId) => {
	let queryClient = useQueryClient();
	const participantsDropdownKey = getDealParticipantsDropdownKey(dealId);
	return () => {
		queryClient.invalidateQueries(participantsDropdownKey);
	};
};

export {
	useDealParticipants,
	useInvalidateDealParticipantsData,
	useDeleteParticipantMutation,
	useAddParticipantMutation,
	useParticipantsDropdownList,
	useInvalidateParticipantsDropdownList,
};
