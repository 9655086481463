import React, { useState } from "react";
import { Box, MenuItem, Stack, Typography, useTheme } from "@mui/material";
import Menu from "../../Elements/Menu";
import MenuSearchBar from "../../Elements/MenuSearchBar";

export default function CurrencyMenu(props) {
	const {
		selectedCurrency,
		currencyOptions,
		onCurrencyInputChange,
		isProductDetailTable,
		index,
		error,
	} = props;
	const theme = useTheme();

	const [currencyMenu, setCurrencyMenu] = useState(null);
	const openCurrencyMenu = Boolean(currencyMenu);
	const [searchValue, setSearchValue] = useState("");

	const CloseCurrencyMenu = () => {
		setCurrencyMenu(null);
	};

	const handleCurrencyMenu = (event) => {
		setCurrencyMenu(event.currentTarget);
	};

	const handleCurrencyChange = (currency) => {
		onCurrencyInputChange(currency, index);
		setCurrencyMenu(null);
	};

	const handleSearch = (event) => {
		const { value } = event.target;
		setSearchValue(value);
	};

	const getCurrencyCode = (currencyId) => {
		let currencyObj = currencyOptions.find(
			(currency) => currency.id === currencyId
		);
		return currencyObj ? currencyObj.code : "";
	};

	return (
		<React.Fragment>
			<Menu
				minWidth="300px"
				anchorEl={currencyMenu}
				open={openCurrencyMenu}
				onClose={CloseCurrencyMenu}
				sx={{
					"& .MuiMenu-list": { paddingTop: 0 },
					marginTop: "15px",
					height: "330px",
				}}
			>
				<Box
					p={0.5}
					sx={{
						position: "sticky",
						top: 0,
						zIndex: 1,
						backgroundColor: "#fff",
					}}
				>
					<MenuSearchBar
						value={searchValue}
						onChange={handleSearch}
					/>
				</Box>
				<Box sx={{ maxHeight: "240px", overflowY: "auto" }}>
					{currencyOptions
						?.filter(
							(currency) =>
								//filter for search
								currency.name
									.toLowerCase()
									.includes(searchValue.toLowerCase()) ||
								currency.code
									.toLowerCase()
									.includes(searchValue.toLowerCase())
						)
						.map((currency) => (
							<MenuItem
								key={currency.id}
								onClick={() => handleCurrencyChange(currency)}
								sx={{
									height: "40px",
									backgroundColor:
										selectedCurrency === currency.id &&
										"rgba(51, 188, 126, 0.12)",
								}}
								value={currency.id}
								onError={error}
							>
								<Stack
									direction="row"
									alignItems="center"
									justifyContent="space-between"
									width="100%"
								>
									<Typography fontSize="13px">
										{currency.name}
									</Typography>

									<Typography fontSize="13px">
										{currency.code}
									</Typography>
								</Stack>
							</MenuItem>
						))}
				</Box>
			</Menu>

			<Stack
				sx={{ justifyContent: "center", paddingLeft: "16px" }}
				onClick={(event) => handleCurrencyMenu(event)}
			>
				{selectedCurrency ? (
					<Typography
						sx={{
							fontSize: "14px",
							fontWeight: isProductDetailTable ? 400 : 500,
							color: isProductDetailTable
								? "#000"
								: `${theme.palette.secondary.contrastText}`,
						}}
					>
						{getCurrencyCode(selectedCurrency)}
					</Typography>
				) : (
					<Typography
						sx={{
							fontSize: "14px",
							opacity: 0.6,
							color: isProductDetailTable
								? "#000"
								: `${theme.palette.secondary.contrastText}`,
						}}
					>
						Select
					</Typography>
				)}
			</Stack>
		</React.Fragment>
	);
}
