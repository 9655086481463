import React, { useEffect, useRef, useState } from "react";
import {
	Box,
	Button,
	ButtonGroup,
	Checkbox,
	Divider,
	Grid,
	IconButton,
	MenuItem,
	Stack,
	ToggleButton,
	Typography,
	useTheme,
} from "@mui/material";
import SummaryMenu from "./Menu";
import { useNavigate, useParams } from "react-router-dom";
import { Tooltip, twozoStyles } from "../../../styles/twozo";
import UnKnownImage from "../../../assets/images/contact/unknownContact.png";
import LinkedinLogo from "../../../assets/images/socialMedia/linkedin.png";
import FacebookLogo from "../../../assets/images/socialMedia/facebook.png";
import { default as DropDownIcon } from "../../../assets/icons/dropDownCentered";
import { default as AddIcon } from "../../../assets/icons/add";
import { default as CloseIcon } from "../../../assets/icons/close";
import { default as EyeIcon } from "../../../assets/icons/eye";
import { default as EyeOffIcon } from "../../../assets/icons/eyeOff";
import { default as EditIcon } from "../../../assets/icons/edit";
import ToggleButtonGroup from "../../Elements/ToggleButtonGroup";
import ContactTimeline from "./Timeline";
import ContactFiles from "./Files";
import ContactNotes from "./Notes";
import ContactConversations from "./Conversations";
import ContactActivities from "./Activities";
import AddActivity from "../../Summary/Activities/AddActivity";
import Menu from "../../Elements/Menu";
import { default as CompanyIcon } from "../../../assets/icons/company";
import SummaryCardView from "../../Elements/SummaryCardView";
import EditContact from "../EditContact";
import SummaryLoader from "../../Elements/Loader/SummaryLoader";
import {
	getContactCompletedActivityKey,
	getContactToDoAcitivityKey,
} from "../../../utils/queryKeys";
import CustomSwipeableDrawer from "../../Elements/CustomSwipeableDrawer";
import AddNewDeals from "../../Deals/AddNewDeals";
import { useModuleName } from "../../../hooks/modules";
import { modules } from "../../../utils/common/ModulesName";
import { useActivityTypeList } from "../../../hooks/services/activityType";
import {
	useContactSummaryData,
	usePartialUpdateContact,
} from "../../../hooks/services/contact";
import Can from "../../Auth/Can";
import { PERMISSIONS } from "../../../utils/Auth";
import { useContactFieldPermissions } from "../../../hooks/modules/auth/contact";
import FieldPermissionContextProvider from "../../Elements/FieldPermissionContext/FieldPermissionContextProvider";
import ContactSummaryProfileEdit from "./ContactSummaryProfileEdit";
import { SummaryContextProvider } from "../../Summary/SummaryContext/SummaryContextProvider";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../utils/notification/notificationMessages";
import { useAuth } from "../../../hooks/auth";
import TooltipComponent from "../../Elements/TooltipComponent";

const styles = {
	socialMediaLogoContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: "50px",
		width: 19,
		height: 19,
		cursor: "pointer",
	},
	profileContent: {
		fontSize: "14px",
		fontWeight: 500,
		cursor: "pointer",
	},
	profileNameContainer: {
		fontSize: "18px",
		fontWeight: 600,
		cursor: "pointer",
	},
};

export default function ContactsSummary() {
	let { contactId } = useParams();
	const parsedContactId = parseInt(contactId);
	const theme = useTheme();
	const navigate = useNavigate();
	const classes = twozoStyles();
	const { getModuleName } = useModuleName();
	const { isUserAllowedFor } = useAuth();

	// permission
	const hasEditPermission = isUserAllowedFor(PERMISSIONS.contact.edit);

	const detailsCardContainerRef = useRef(null);
	const profileContainerRef = useRef(null);

	const updateMutation = usePartialUpdateContact(parsedContactId);

	const [view, setView] = useState("timeline");
	const [openAddActivityDialog, setOpenAddActivityDialog] = useState(false);
	const [
		hideDetailsCardContainerLeftScrollButton,
		setHideDetailsCardContainerLeftScrollButton,
	] = useState(true);
	const [
		hideDetailsCardContainerRightScrollButton,
		setHideDetailsCardContainerRightScrollButton,
	] = useState(false);
	const [filterTimelineMenuElement, setFilterTimelineMenuElement] =
		useState(null);
	const isTimelineFilterMenuOpened = Boolean(filterTimelineMenuElement);
	const [filterActivitiesMenuElement, setFilterActivitiesMenuElement] =
		useState(null);
	const isActivitiesFilterMenuOpened = Boolean(filterActivitiesMenuElement);
	const [isEditFormDialogOpen, setIsEditFormDialogOpen] = useState(false);
	const [summaryFields, setSummaryFields] = useState([]);
	const [summarySubFields, setSummarySubFields] = useState([]);
	const [profile, setProfile] = useState({});
	const contactToDoActivityKey = getContactToDoAcitivityKey(parsedContactId);
	const contactCompletedActivityKey =
		getContactCompletedActivityKey(parsedContactId);
	const [timelineIdsToFilter, setSelectedTimelineFilterIds] = useState([]);
	const [activityTypeIdsToFilter, setActivityTypeIdsToFilter] = useState([]);
	const [isAddDealDialogOpened, setIsAddDealDialogOpened] = useState(false);
	const [hideEmptyFields, setHideEmptyFields] = useState(false);

	const [profileMenuElement, setProfileMenuElement] = useState(null);
	const isProfileEditMenuOpened = Boolean(profileMenuElement);

	const [isProfileEditIconVisible, setIsProfileEditIconVisible] =
		useState(false);

	const onMouseOverProfile = () => {
		setIsProfileEditIconVisible(true);
	};

	const onMouseOutProfile = () => {
		setIsProfileEditIconVisible(false);
	};

	const timelineFilterList = [
		{
			id: "calls",
			name: "Calls",
		},
		{
			id: "emails",
			name: "Emails",
		},
		{
			id: "notes",
			name: "Notes",
		},
		{
			id: "activity",
			name: "Activities",
		},
		{
			id: "file",
			name: "Files",
		},
		{
			id: "updates",
			name: "Updates",
		},
	];
	const viewName = getModuleName(modules.CONTACT);
	const contactFullName = `${profile?.firstName?.value}${" "}${profile?.lastName?.value}`;
	const contactFieldPermissions = useContactFieldPermissions();

	const selectedTimelineFilterCount =
		timelineIdsToFilter.length > 0 ? timelineIdsToFilter.length : "";
	const selectedActivityFilterCount =
		activityTypeIdsToFilter.length > 0
			? activityTypeIdsToFilter.length
			: "";
	const companyId = profile?.company?.value?.id;

	const isActiveActivityType = true;
	const { data: activityTypeList } =
		useActivityTypeList(isActiveActivityType);

	const {
		data: summaryData,
		status: summaryStatus,
		isLoading: isContactSummaryDetailsLoading,
	} = useContactSummaryData(contactId, hideEmptyFields);

	const toggleFilterTimelineMenu = (timelineFilterId) => {
		if (timelineIdsToFilter.includes(timelineFilterId)) {
			setSelectedTimelineFilterIds((timelineIdsToFilter) =>
				timelineIdsToFilter.filter((filterId) => {
					return filterId !== timelineFilterId;
				})
			);
		} else {
			setSelectedTimelineFilterIds([
				...timelineIdsToFilter,
				timelineFilterId,
			]);
		}
	};

	const toggleFilterActivitiesMenu = (activityTypeId) => {
		if (activityTypeIdsToFilter.includes(activityTypeId)) {
			setActivityTypeIdsToFilter((activityTypeIdsToFilter) =>
				activityTypeIdsToFilter.filter((id) => {
					return id !== activityTypeId;
				})
			);
		} else {
			setActivityTypeIdsToFilter([
				...activityTypeIdsToFilter,
				activityTypeId,
			]);
		}
	};

	const toggleAddActivityDialog = () => {
		setOpenAddActivityDialog(
			(openAddCompanyDialog) => !openAddCompanyDialog
		);
	};

	const handleViewToggleButtonGroup = (e, type) => {
		if (type) {
			setView(type);
		}
	};

	const openFilterTimelineMenu = (event) => {
		setFilterTimelineMenuElement(event.currentTarget);
	};

	const closeFilterTimelineMenu = () => {
		setFilterTimelineMenuElement(null);
	};

	const openFilterActivitiesMenu = (event) => {
		setFilterActivitiesMenuElement(event.currentTarget);
	};

	const closeFilterActivitiesMenu = () => {
		setFilterActivitiesMenuElement(null);
	};

	const scroll = (scrollOffset) => {
		const { scrollLeft, scrollWidth, clientWidth } =
			detailsCardContainerRef.current;
		if (scrollLeft + clientWidth + scrollOffset >= scrollWidth) {
			setHideDetailsCardContainerRightScrollButton(true);
		} else if (scrollLeft + clientWidth + scrollOffset <= clientWidth) {
			setHideDetailsCardContainerLeftScrollButton(true);
		} else {
			setHideDetailsCardContainerRightScrollButton(false);
			setHideDetailsCardContainerLeftScrollButton(false);
		}

		detailsCardContainerRef.current.scrollLeft += scrollOffset;
	};

	const handleScrollUpdate = () => {
		if (detailsCardContainerRef.current) {
			const { scrollLeft, scrollWidth, clientWidth } =
				detailsCardContainerRef.current;

			if (scrollLeft <= 0) {
				setHideDetailsCardContainerLeftScrollButton(false);
			}
			if (clientWidth <= scrollWidth) {
				setHideDetailsCardContainerRightScrollButton(false);
			}
		}
	};

	const toggleEditFormDialog = () => {
		setIsEditFormDialogOpen((openEditFormDialog) => !openEditFormDialog);
	};

	useEffect(() => {
		if (summaryStatus === "success") {
			if (summaryData.profile) {
				setProfile(summaryData.profile);
			}
			if (summaryData.subFields) {
				setSummarySubFields(summaryData.subFields);
			}
			if (summaryData.summary) {
				setSummaryFields(summaryData.summary);
			}
		}
	}, [summaryStatus, summaryData]);

	const handleClearTimelineFilter = () => {
		setSelectedTimelineFilterIds([]);
		closeFilterTimelineMenu();
	};

	const handleClearActivityFilter = () => {
		setActivityTypeIdsToFilter([]);
		closeFilterActivitiesMenu();
	};

	const toggleAddDealDialog = () => {
		setIsAddDealDialogOpened(
			(isAddDealDialogOpened) => !isAddDealDialogOpened
		);
	};

	const handleRedirect = (value) => {
		if (value) {
			window.open(value);
		}
	};

	const toggleFieldVisibility = () => {
		setHideEmptyFields((hideEmptyFields) => !hideEmptyFields);
		handleScrollUpdate();
	};

	const getHoverredEyeIconContent = () => {
		return hideEmptyFields ? "Hide Empty Fields" : "Show Empty Fields";
	};

	const openProfileEditMenu = () => {
		setProfileMenuElement(profileContainerRef.current);
	};

	const closeProfileEditMenu = () => {
		setProfileMenuElement(null);
	};

	const updateSummaryField = (summaryData, onClose) => {
		updateMutation.mutate(summaryData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.contactUpdated,
				});
				onClose();
			},
			onError: (error) => {
				let errorMessage = error?.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage ?? notificationMessage.errorMessage,
				});
			},
		});
	};

	return (
		<React.Fragment>
			{/* profile edit menu */}
			<Menu
				open={isProfileEditMenuOpened}
				onClose={closeProfileEditMenu}
				anchorEl={profileMenuElement}
			>
				<ContactSummaryProfileEdit
					profile={profile}
					contactId={parsedContactId}
					onClose={closeProfileEditMenu}
				/>
			</Menu>

			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isAddDealDialogOpened}
				onOpen={toggleAddDealDialog}
				onClose={toggleAddDealDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<AddNewDeals
						onClose={toggleAddDealDialog}
						formData={{
							contactId: parsedContactId,
							companyId: companyId,
						}}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isEditFormDialogOpen}
				onOpen={toggleEditFormDialog}
				onClose={toggleEditFormDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<EditContact
						contactId={contactId}
						onClose={toggleEditFormDialog}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={openAddActivityDialog}
				onOpen={toggleAddActivityDialog}
				onClose={toggleAddActivityDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<AddActivity
						onClose={toggleAddActivityDialog}
						contactIds={[parsedContactId]}
						companyId={companyId}
						toDoActivityKey={contactToDoActivityKey}
						completedActivityKey={contactCompletedActivityKey}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<Menu
				minWidth="200px"
				anchorEl={filterTimelineMenuElement}
				open={isTimelineFilterMenuOpened}
				onClose={closeFilterTimelineMenu}
				style={{
					marginTop: "4px",
				}}
			>
				{timelineFilterList.map((timelineFilterData) => (
					<MenuItem
						key={timelineFilterData.id}
						onClick={() =>
							toggleFilterTimelineMenu(timelineFilterData.id)
						}
						style={{ height: "40px" }}
					>
						<Stack
							direction="row"
							spacing={1.5}
							alignItems="center"
						>
							<Checkbox
								checked={timelineIdsToFilter.includes(
									timelineFilterData.id
								)}
							/>

							<Typography fontSize={13}>
								{timelineFilterData.name}
							</Typography>
						</Stack>
					</MenuItem>
				))}
			</Menu>

			<Menu
				minWidth="200px"
				anchorEl={filterActivitiesMenuElement}
				open={isActivitiesFilterMenuOpened}
				onClose={closeFilterActivitiesMenu}
				style={{
					marginTop: "4px",
				}}
			>
				{activityTypeList?.map((activityType) => (
					<MenuItem
						key={activityType.id}
						onClick={() =>
							toggleFilterActivitiesMenu(activityType.id)
						}
						style={{ height: "40px" }}
					>
						<Stack
							direction="row"
							spacing={1.5}
							alignItems="center"
						>
							<Checkbox
								checked={activityTypeIdsToFilter.includes(
									activityType?.id
								)}
							/>

							<Typography fontSize={13}>
								{activityType.name}
							</Typography>
						</Stack>
					</MenuItem>
				))}
			</Menu>

			{isContactSummaryDetailsLoading ? (
				<SummaryLoader moduleName={`All ${viewName}`} />
			) : (
				<Box>
					<Box className={classes.menuBar}>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							height="100%"
						>
							<Stack
								direction="row"
								spacing={1.5}
								alignItems="center"
							>
								<Box
									style={{ cursor: "pointer" }}
									onClick={() => navigate("/contacts")}
								>
									<Typography
										fontWeight={600}
										color={
											theme.palette.secondary.contrastText
										}
									>
										{`All ${viewName}`}
									</Typography>
								</Box>

								<Box
									display="flex"
									style={{
										transform: "rotate(-90deg)",
									}}
								>
									{DropDownIcon(16, 16)}
								</Box>

								<TooltipComponent
									title={contactFullName}
									placement="top"
									verticalDisplacement={-3}
									width="600px"
								>
									<Typography
										fontWeight={500}
										fontSize={16}
										maxWidth="750px"
										noWrap
										style={{ cursor: "pointer" }}
									>
										{contactFullName}
									</Typography>
								</TooltipComponent>
							</Stack>

							<SummaryMenu
								contactId={parsedContactId}
								toggleEditFormDialog={toggleEditFormDialog}
								onAddDealClick={toggleAddDealDialog}
							/>
						</Stack>
					</Box>

					<Box
						px={3}
						onMouseOver={onMouseOverProfile}
						onMouseLeave={onMouseOutProfile}
					>
						<Stack direction="row" alignItems="center" spacing={3}>
							<img
								src={UnKnownImage}
								alt="profile"
								width="64px"
								height="64px"
								style={{ borderRadius: "100%" }}
							/>

							<Box>
								<Stack
									direction="row"
									alignItems="center"
									spacing={1}
								>
									<TooltipComponent
										title={contactFullName}
										placement="top"
										verticalDisplacement={-3}
										width="600px"
									>
										<Typography
											sx={styles.profileNameContainer}
											maxWidth="1000px"
											noWrap
										>
											{contactFullName}
										</Typography>
									</TooltipComponent>

									<Box
										style={{
											...styles.socialMediaLogoContainer,
											backgroundColor: "#225982",
											opacity: profile?.linkedIn?.value
												? 1
												: 0.6,
										}}
										onClick={() =>
											handleRedirect(
												profile.linkedIn?.value
											)
										}
									>
										<img
											src={LinkedinLogo}
											alt={"linkedin"}
											width="11px"
											height="11px"
										/>
									</Box>

									<Box
										style={{
											...styles.socialMediaLogoContainer,
											backgroundColor: "#4267B2",
											opacity: profile?.facebook?.value
												? 1
												: 0.6,
										}}
										onClick={() =>
											handleRedirect(
												profile.facebook?.value
											)
										}
									>
										<img
											src={FacebookLogo}
											alt={"facebook"}
											width="11px"
											height="11px"
										/>
									</Box>

									<Box display="flex" width="22px">
										{isProfileEditIconVisible &&
										hasEditPermission ? (
											<IconButton
												style={{ padding: 0 }}
												onClick={openProfileEditMenu}
											>
												{EditIcon(
													19,
													19,
													theme.palette.primary.main
												)}
											</IconButton>
										) : null}
									</Box>
								</Stack>

								<Stack
									direction="row"
									spacing={1}
									ref={profileContainerRef}
								>
									<Box display="flex">
										{profile?.designation?.value ? (
											<TooltipComponent
												title={
													summaryData?.profile
														.designation?.value
												}
												placement="top"
												verticalDisplacement={-5}
												width="600px"
											>
												<Typography
													fontWeight={400}
													fontSize={14}
													style={{
														cursor: "pointer",
													}}
													noWrap
													maxWidth="500px"
												>
													{
														summaryData?.profile
															.designation?.value
													}
												</Typography>
											</TooltipComponent>
										) : (
											<Typography
												onClick={openProfileEditMenu}
												sx={{
													...styles.profileContent,
												}}
												color={
													theme.palette.primary.main
												}
											>
												Add Designation
											</Typography>
										)}
									</Box>

									<Can permission={PERMISSIONS.company.view}>
										<Stack
											direction="row"
											alignItems="center"
											spacing={0.5}
										>
											{CompanyIcon(
												16,
												16,
												theme.palette.primary.main
											)}
											{profile?.company?.value?.name ? (
												<TooltipComponent
													title={
														profile?.company?.value
															?.name
													}
													placement="top"
													verticalDisplacement={-3}
													width="600px"
												>
													<Typography
														noWrap
														maxWidth="500px"
														sx={
															styles.profileContent
														}
														color={
															theme.palette
																.secondary
																.contrastText
														}
														onClick={() =>
															navigate(
																`/companies/${profile?.company?.value.id}`
															)
														}
													>
														{
															profile?.company
																?.value?.name
														}
													</Typography>
												</TooltipComponent>
											) : (
												<Typography
													onClick={
														openProfileEditMenu
													}
													sx={{
														...styles.profileContent,
													}}
													color={
														theme.palette.secondary
															.contrastText
													}
												>
													Add Company
												</Typography>
											)}
										</Stack>
									</Can>
								</Stack>
							</Box>
						</Stack>
					</Box>

					<Box
						style={{
							display: "flex",
							flexDirection: "row",
						}}
					>
						{!hideDetailsCardContainerLeftScrollButton && (
							<Stack alignItems="center" justifyContent="center">
								<IconButton
									onClick={() => scroll(-280)}
									style={{
										padding: "4px",
									}}
								>
									<Box style={{ transform: "rotate(90deg)" }}>
										{DropDownIcon(16, 16, "#666")}
									</Box>
								</IconButton>
							</Stack>
						)}

						<Box
							style={{
								flex: "1 1 auto",
								minWidth: 0,
								overflowX: "hidden",
								scrollBehavior: "smooth",
							}}
							ref={detailsCardContainerRef}
						>
							<Stack direction="row" spacing={2} p={2}>
								<FieldPermissionContextProvider
									value={contactFieldPermissions}
								>
									<SummaryContextProvider
										hasEditPermission={hasEditPermission}
										source={modules.CONTACT}
										entityId={parsedContactId}
										updateSummaryField={updateSummaryField}
										isSaving={updateMutation.isLoading}
									>
										<SummaryCardView
											summaryFields={summaryFields}
											summarySubFields={summarySubFields}
										/>
									</SummaryContextProvider>
								</FieldPermissionContextProvider>
							</Stack>
						</Box>

						<Stack
							direction="row"
							alignItems="center"
							justifyContent="center"
							spacing={1}
							px={1}
						>
							<Tooltip
								title={getHoverredEyeIconContent()}
								placement="top"
							>
								<IconButton
									style={{ padding: "4px" }}
									onClick={toggleFieldVisibility}
								>
									{hideEmptyFields
										? EyeOffIcon(16, 16, "#666")
										: EyeIcon(16, 16, "#666")}
								</IconButton>
							</Tooltip>

							{!hideDetailsCardContainerRightScrollButton && (
								<Stack
									direction="row"
									alignItems="center"
									justifyContent="center"
									spacing={1}
								>
									<Divider
										orientation="vertical"
										style={{ height: "28px" }}
									/>

									<IconButton
										onClick={() => scroll(280)}
										style={{ padding: "4px" }}
									>
										<Box
											style={{
												transform: "rotate(-90deg)",
											}}
										>
											{DropDownIcon(16, 16, "#666")}
										</Box>
									</IconButton>
								</Stack>
							)}
						</Stack>
					</Box>

					<Box px={2}>
						<Divider />
					</Box>

					<Box p={1}>
						<Grid
							container
							direction="row"
							justifyContent="flex-start"
							alignItems="stretch"
							spacing={1}
						>
							<Grid item xs={12} md={9}>
								<Box className={classes.detailsContainer}>
									<Stack direction="row" spacing={3}>
										<ToggleButtonGroup
											value={view}
											onChange={
												handleViewToggleButtonGroup
											}
										>
											<ToggleButton value="timeline">
												Timeline
											</ToggleButton>
											<ToggleButton value="conversations">
												Conversations
											</ToggleButton>
											<ToggleButton value="activities">
												Activities
											</ToggleButton>
										</ToggleButtonGroup>

										{view === "timeline" && (
											<ButtonGroup
												variant="contained"
												disableElevation
												color="secondary"
												sx={{
													"& .MuiButtonGroup-grouped:not(:last-child)":
														{
															borderRight: "none",
															borderRadius:
																selectedTimelineFilterCount >
																0
																	? "8px 0px 0px 8px"
																	: "8px",
														},
												}}
											>
												<Button
													onClick={
														openFilterTimelineMenu
													}
													endIcon={DropDownIcon(
														16,
														16,
														theme.palette.secondary
															.contrastText
													)}
												>
													Filter Timeline{" "}
													{selectedTimelineFilterCount >
													0
														? `(${selectedTimelineFilterCount})`
														: ""}
												</Button>

												<Divider
													orientation="vertical"
													sx={{
														display:
															!selectedTimelineFilterCount >
																0 && "none",
														height: "95%",
													}}
												/>

												<Button
													onClick={
														handleClearTimelineFilter
													}
													sx={{
														display:
															!selectedTimelineFilterCount >
																0 && "none",
														padding: 0,
													}}
												>
													{CloseIcon(
														19,
														19,
														theme.palette.secondary
															.contrastText
													)}
												</Button>
											</ButtonGroup>
										)}

										{view === "activities" && (
											<Stack direction="row" spacing={2}>
												<Button
													variant="contained"
													startIcon={AddIcon(
														16,
														16,
														theme.palette.secondary
															.contrastText
													)}
													color="secondary"
													onClick={
														toggleAddActivityDialog
													}
													disableElevation
												>
													Activity
												</Button>

												<ButtonGroup
													variant="contained"
													disableElevation
													color="secondary"
													sx={{
														"& .MuiButtonGroup-grouped:not(:last-child)":
															{
																borderRight:
																	"none",
																borderRadius:
																	selectedActivityFilterCount >
																	0
																		? "8px 0px 0px 8px"
																		: "8px",
															},
													}}
												>
													<Button
														onClick={
															openFilterActivitiesMenu
														}
														endIcon={DropDownIcon(
															16,
															16,
															theme.palette
																.secondary
																.contrastText
														)}
													>
														Filter Activities{" "}
														{selectedActivityFilterCount >
														0
															? `(${selectedActivityFilterCount})`
															: ""}
													</Button>

													<Divider
														orientation="vertical"
														sx={{
															display:
																!selectedActivityFilterCount >
																	0 && "none",
															height: "95%",
														}}
													/>

													<Button
														onClick={
															handleClearActivityFilter
														}
														sx={{
															display:
																!selectedActivityFilterCount >
																	0 && "none",
															padding: 0,
														}}
													>
														{CloseIcon(
															19,
															19,
															theme.palette
																.secondary
																.contrastText
														)}
													</Button>
												</ButtonGroup>
											</Stack>
										)}
									</Stack>

									<Box pt={1}>
										{(function () {
											switch (view) {
												case "timeline":
													return (
														<ContactTimeline
															contactId={
																parsedContactId
															}
															timelineIdsToFilter={
																timelineIdsToFilter
															}
														/>
													);
												case "conversations":
													return (
														<ContactConversations
															contactId={
																parsedContactId
															}
														/>
													);
												case "activities":
													return (
														<ContactActivities
															contactId={
																parsedContactId
															}
															activityTypeIdsToFilter={
																activityTypeIdsToFilter
															}
														/>
													);
												default:
													return null;
											}
										})()}
									</Box>
								</Box>
							</Grid>

							<Grid item xs={12} md={3}>
								<Stack spacing={1}>
									<Box className={classes.detailsContainer}>
										<ContactNotes
											contactId={parsedContactId}
										/>
									</Box>

									<Box className={classes.detailsContainer}>
										<ContactFiles
											contactId={parsedContactId}
										/>
									</Box>
								</Stack>
							</Grid>
						</Grid>
					</Box>
				</Box>
			)}
		</React.Fragment>
	);
}
