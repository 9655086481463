import React from "react";
import { TableCellText } from "../../../../styles/twozo";
import { Stack, useTheme } from "@mui/material";
import { getIconByName } from "../../../../utils";
import { getDealIconOrDealNameColor } from "../../../../utils/ActivitiesUtils";
import { useAuth } from "../../../../hooks/auth";
import { PERMISSIONS } from "../../../../utils/Auth";

export default function ActivityDeal(props) {
	const { data, rowState } = props;
	const theme = useTheme();
	const { isUserAllowedFor } = useAuth();

	if (!data || !isUserAllowedFor(PERMISSIONS.deal.view)) {
		return "-";
	}

	return (
		<React.Fragment>
			<Stack direction="row" spacing={1} alignItems="center">
				{getIconByName("deal")(
					18,
					18,
					getDealIconOrDealNameColor(rowState, theme).color
				)}

				<TableCellText
					color={getDealIconOrDealNameColor(rowState, theme).color}
					noWrap
				>
					{data.title}
				</TableCellText>
			</Stack>
		</React.Fragment>
	);
}
