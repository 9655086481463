import {
	Box,
	Button,
	Divider,
	IconButton,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { default as BackIcon } from "../../../assets/icons/leftArrow";
import InfoIcon from "../../../assets/icons/info";
import DuplicateNotFound from "./DuplicateNotFound";
import DuplicatesFound from "./DuplicatesFound";
import { notificationVariants } from "../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../utils/notification/notificationMessages";
import { enqueueSnackbar } from "notistack";
import {
	useContactDuplicateList,
	useMergeDuplicateContactMutation,
} from "../../../hooks/services/contact";

const styles = {
	contentName: {
		fontSize: 14,
		fontWeight: 500,
	},
	text: {
		fontSize: 14,
	},
};

function CheckDuplicates(props) {
	const { contactDetails, onDuplicateMergeSuccess, onClose } = props;
	const theme = useTheme();
	const [selectedContactId, setSelectedContactId] = useState("");
	const updateMutation = useMergeDuplicateContactMutation(contactDetails);

	const { data: duplicateList } = useContactDuplicateList(contactDetails);
	const isDuplicateFound = duplicateList?.duplicates.length >= 1;

	const handleSelectContact = (contactId) => {
		setSelectedContactId(contactId);
	};

	const getMergeRequestData = () => {
		let mergeRequestData = {
			primaryContactId: selectedContactId,
			contact: contactDetails,
		};

		return mergeRequestData;
	};

	const mergeDuplicateContact = () => {
		let mergeRequestData = getMergeRequestData();
		updateMutation.mutate(mergeRequestData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.fieldValuesMerged,
				});
				onDuplicateMergeSuccess();
			},
			onError: (error) => {
				let errorMessage = error.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage
						? errorMessage
						: notificationMessage.genericErrorMessage,
				});
			},
		});
	};

	return (
		<React.Fragment>
			<Box
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box p={2}>
					<Stack direction="row" alignItems="center" spacing={2}>
						<IconButton
							onClick={onClose}
							size="small"
							style={{ padding: 0 }}
						>
							{BackIcon(20, 20)}
						</IconButton>
						<Typography fontWeight={600}>Duplicates</Typography>
					</Stack>
				</Box>

				<Divider />

				<Box
					style={{
						flex: "1 1 auto",
						minHeight: 0,
						overflowY: "auto",
					}}
				>
					<Box
						m={2}
						p={2}
						style={{
							backgroundColor: "#E7F7F0",
							borderRadius: "8px",
						}}
					>
						<Stack direction="row" alignItems="center" spacing={2}>
							<IconButton>
								{InfoIcon(20, 20, theme.palette.primary.main)}
							</IconButton>
							<Stack>
								<Typography style={styles.text}>
									We check for exact matches in these fields:
								</Typography>
								<Typography style={styles.text}>
									Emails, Work email, Work, Mobile, Other
									phone numbers
								</Typography>
							</Stack>
						</Stack>
					</Box>

					<Box>
						{isDuplicateFound ? (
							<DuplicatesFound
								duplicateList={duplicateList}
								handleSelectContact={handleSelectContact}
								contactId={selectedContactId}
							/>
						) : (
							<DuplicateNotFound />
						)}
					</Box>
				</Box>

				{isDuplicateFound && (
					<>
						<Divider />

						<Box>
							<Stack direction="row" p={2}>
								<Button
									variant="contained"
									disableElevation
									sx={{
										"&.Mui-disabled": {
											backgroundColor:
												theme.palette.primary.main,
											color: "rgba(255,255,255, 0.6)",
										},
									}}
									onClick={mergeDuplicateContact}
									disabled={!selectedContactId}
								>
									Merge
								</Button>
							</Stack>
						</Box>
					</>
				)}
			</Box>
		</React.Fragment>
	);
}

export default CheckDuplicates;
