import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
	createFieldRequestData,
	deleteField,
	getFieldDropdownData,
	getFieldTypeData,
	getFieldsListData,
	postBulkFieldUpdate,
	postFieldUpdate,
	updateFieldListOrder,
} from "../../../api/fields/config/configApi";
import {
	fieldTypeKey,
	getAllFieldListSettingsTypeKey,
	getDropdownFieldKey,
	getFieldListSettingTypeKey,
} from "../../../utils/queryKeys/dataFields";

const useFieldList = (moduleName, needGroupView) => {
	let fieldListSettingTypeKey = getFieldListSettingTypeKey(
		moduleName,
		needGroupView
	);
	return useQuery(
		fieldListSettingTypeKey,
		() => getFieldsListData(moduleName, needGroupView),
		{
			enabled: !!moduleName,
			select: (data) => data.fields,
		}
	);
};

const useFieldDropdown = (fieldId, isDropdownFieldTypeEnabled) => {
	let dropdownFieldTypeKey = getDropdownFieldKey(fieldId);
	return useQuery(
		dropdownFieldTypeKey,
		() => getFieldDropdownData({ id: fieldId }),
		{
			enabled: !!isDropdownFieldTypeEnabled,
			select: (data) => data.options,
		}
	);
};

const useFieldType = () => {
	return useQuery(fieldTypeKey, () => getFieldTypeData(), {
		select: (data) => data.list,
	});
};

const useCreateField = (moduleName) => {
	let invalidateAllModulesFieldList =
		useInvalidateAllModulesFieldList(moduleName);
	return useMutation(createFieldRequestData, {
		onSuccess: () => {
			invalidateAllModulesFieldList();
		},
	});
};

const useUpdateField = (moduleName) => {
	let invalidateAllModulesFieldList =
		useInvalidateAllModulesFieldList(moduleName);
	return useMutation(postFieldUpdate, {
		onSuccess: () => {
			invalidateAllModulesFieldList();
		},
	});
};

const useUpdateDropdownField = (fieldId) => {
	let invalidateDropdownField = useInvalidateDropdownFieldList(fieldId);
	return useMutation(postFieldUpdate, {
		onSuccess: () => {
			invalidateDropdownField();
		},
	});
};

const useDeleteField = (moduleName) => {
	let invalidateAllModulesFieldList =
		useInvalidateAllModulesFieldList(moduleName);
	return useMutation(deleteField, {
		onSuccess: () => {
			invalidateAllModulesFieldList();
		},
	});
};

const useBulkUpdate = (moduleName) => {
	let invalidateAllModulesFieldList =
		useInvalidateAllModulesFieldList(moduleName);
	return useMutation(postBulkFieldUpdate, {
		onSuccess: () => {
			invalidateAllModulesFieldList();
		},
	});
};

const useUpdateFieldListOrder = (moduleName) => {
	let invalidateAllModulesFieldList =
		useInvalidateAllModulesFieldList(moduleName);
	return useMutation(updateFieldListOrder, {
		onSuccess: () => {
			invalidateAllModulesFieldList();
		},
	});
};

const useInvalidateAllModulesFieldList = (moduleName) => {
	let queryClient = useQueryClient();
	let allFieldListSettingTypeKey = getAllFieldListSettingsTypeKey(moduleName);
	return () => {
		queryClient.invalidateQueries(allFieldListSettingTypeKey);
	};
};

const useInvalidateDropdownFieldList = (fieldId) => {
	let queryClient = useQueryClient();
	let fieldListSettingTypeKey = getDropdownFieldKey(fieldId);
	return () => {
		queryClient.invalidateQueries(fieldListSettingTypeKey);
	};
};

export {
	useCreateField,
	useFieldList,
	useBulkUpdate,
	useFieldType,
	useUpdateField,
	useDeleteField,
	useFieldDropdown,
	useUpdateDropdownField,
	useUpdateFieldListOrder,
};
