import { Box, Button, Stack, useTheme } from "@mui/material";
import React from "react";
import { default as CloseIcon } from "../../../../../../../assets/icons/close";
import { default as TickIcon } from "../../../../../../../assets/icons/tick";
import { useUpdateField } from "../../../../../../../hooks/services/dataFields";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../../../utils/notification/notificationMessages";
import { useFieldContext } from "../../../FieldContext";
import { useFieldListContext } from "../../../../FieldListContext";

export default function UpdateButtons(props) {
	const { moduleName, field, onCancelField } = props;
	const theme = useTheme();

	// mutation call
	const updateFieldMutation = useUpdateField(moduleName);

	// field and field list context
	const { resetUpdateMode } = useFieldListContext();

	const {
		fieldState,
		resetFieldNameInEditMode,
		onFieldValueChange,
		resetFieldNameError,
		hasSelectChoiceChanged,
	} = useFieldContext(field);

	const handleUpdateField = () => {
		let requestData = getUpdateFieldRequestData();
		updateField(requestData);
	};

	const handleUpdateClick = () => {
		if (isFieldUpdateEnabled()) {
			handleUpdateField();
		}
	};

	const isFieldUpdateEnabled = () => {
		return (
			(!!fieldState.unSavedFieldData.fieldName &&
				fieldState.unSavedFieldData.fieldName === "") ||
			fieldState.isFieldValueChanged ||
			fieldState.isSelectChoiceChanged
		);
	};

	const updateField = (requestData) => {
		updateFieldMutation.mutate(requestData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.fieldUpdated,
				});
				onFieldUpdateSuccess();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.genericErrorMessage,
				});
			},
		});
	};

	const getUpdateFieldRequestData = () => {
		let requestData = { ...field };
		requestData.fieldName = fieldState.unSavedFieldData.fieldName;
		requestData.config.required = fieldState.unSavedFieldData.isRequired;
		requestData.config.addView = fieldState.unSavedFieldData.isAddView;
		requestData.config.options = getFilteredSelectOptions();
		return requestData;
	};

	const getFilteredSelectOptions = () => {
		if (Array.isArray(fieldState.unSavedFieldData.selectChoices)) {
			const filteredSelectChoices =
				fieldState.unSavedFieldData.selectChoices.map((choice) => {
					if (choice.isNew) {
						let updatedChoice = { ...choice };
						delete updatedChoice.isNew;
						return updatedChoice;
					}
					return choice;
				});
			return filteredSelectChoices;
		}
	};

	const onFieldUpdateSuccess = () => {
		resetUpdateMode();
		resetFieldNameInEditMode();
		resetFieldNameError();
		onFieldValueChange(false);
		hasSelectChoiceChanged(false);
	};

	const handleCancelClick = () => {
		onCancelField();
		resetUpdateMode();
		resetFieldNameError();
		onFieldValueChange(false);
		resetFieldNameInEditMode();
	};

	return (
		<React.Fragment>
			<Box>
				<Stack direction="row" spacing={2}>
					<Button
						variant="outlined"
						startIcon={CloseIcon(
							20,
							20,
							theme.palette.secondary.contrastText
						)}
						disableElevation
						onClick={handleCancelClick}
						style={{
							borderColor: theme.palette.secondary.main,
						}}
					>
						Cancel
					</Button>

					<Button
						variant="contained"
						color="secondary"
						startIcon={TickIcon(
							20,
							20,
							theme.palette.secondary.contrastText
						)}
						style={{
							opacity: !isFieldUpdateEnabled() ? 0.6 : 1,
						}}
						disableElevation
						disabled={updateFieldMutation.isLoading}
						onClick={handleUpdateClick}
					>
						Update
					</Button>
				</Stack>
			</Box>
		</React.Fragment>
	);
}
