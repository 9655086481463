import React, { useEffect, useRef, useState } from "react";
import {
	Box,
	Checkbox,
	CircularProgress,
	Stack,
	Typography,
} from "@mui/material";
import MenuSearchBar from "../../../Elements/MenuSearchBar";
import { useMailList } from "../../../../hooks/services/mail";
import { mailListName } from "../../../../utils/EmailUtils/mailListName";
import MetricsListRow from "./MetricsListRow";

const styles = {
	headerText: {
		fontSize: "13px",
		fontWeight: 500,
		color: "rgba(0, 0, 0, 0.6)",
	},
	text: {
		fontSize: "14px",
	},
};

export default function Metrics() {
	const mailListRef = useRef();
	let bulkMetrics = mailListName.BULK_METRICS;
	const { data: mails, isLoading } = useMailList(bulkMetrics);

	const [topBarHeight, setTopBarHeight] = useState(0);

	useEffect(() => {
		if (mailListRef) {
			setTopBarHeight(mailListRef.current.getBoundingClientRect().top);
		}
	}, [mailListRef]);

	return (
		<React.Fragment>
			<Box>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					py={1.25}
				>
					<Stack
						direction="row"
						alignItems="center"
						spacing={1.5}
						px={2}
					>
						<Box>
							<Checkbox disabled />
						</Box>

						<Typography fontWeight={500}>
							Bulk Email - Metrics
						</Typography>
					</Stack>

					<Stack
						direction="row"
						alignItems="center"
						spacing={3}
						pr={3}
					>
						<MenuSearchBar style={{ width: "300px" }} />
					</Stack>
				</Stack>

				<Stack
					direction="row"
					justifyContent="flex-start"
					alignItems="center"
					style={{
						borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
					}}
					px={3}
					py={1}
				>
					<Box width="25%" />

					<Stack direction="row" width="60%">
						<Box width="20%">
							<Typography style={styles.headerText}>
								Sent
							</Typography>
						</Box>

						<Box width="20%">
							<Typography style={styles.headerText}>
								Opened
							</Typography>
						</Box>

						<Box width="20%">
							<Typography style={styles.headerText}>
								Clicked
							</Typography>
						</Box>

						<Box width="20%">
							<Typography style={styles.headerText}>
								Replied
							</Typography>
						</Box>

						<Box width="20%">
							<Typography style={styles.headerText}>
								Bounced
							</Typography>
						</Box>

						<Box width="20%">
							<Typography style={styles.headerText}>
								Unsubscribed
							</Typography>
						</Box>
					</Stack>

					<Box width="15%"></Box>
				</Stack>

				<Box
					ref={mailListRef}
					sx={{
						height: `calc(100vh - ${topBarHeight + 8}px)`,
						overflowY: "auto",
					}}
				>
					{isLoading ? (
						<Stack
							alignItems="center"
							justifyContent="center"
							height="80vh"
						>
							<CircularProgress />
						</Stack>
					) : mails?.length > 0 ? (
						mails.map((metricData) => (
							<MetricsListRow
								key={metricData.id}
								metricData={metricData}
							/>
						))
					) : (
						<Stack
							alignItems="center"
							justifyContent="center"
							height="70vh"
						>
							<Typography
								fontSize={18}
								fontWeight={500}
								color="rgba(0, 0, 0, 0.6)"
							>
								No Conversations Found
							</Typography>
						</Stack>
					)}
				</Box>
			</Box>
		</React.Fragment>
	);
}
