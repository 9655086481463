import React from "react";
import {
	Box,
	CircularProgress,
	Divider,
	IconButton,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { default as EditIcon } from "../../../../../../assets/icons/edit";
import { default as CloseIcon } from "../../../../../../assets/icons/close";
import { useNavigate } from "react-router-dom";
import FieldComponent from "../../../WebFormBuilder/WebFormPreview/FormComponent";
import { default as CopyIcon } from "../../../../../../assets/icons/copy";
import { twozoStyles } from "../../../../../../styles/twozo";
import { useWebFormDeatils } from "../../../../../../hooks/services/webform";

export default function FormInfo(props) {
	const { onClose, webFormId } = props;
	const theme = useTheme();
	const classes = twozoStyles();
	const navigate = useNavigate();

	const { data: webFormDetails, isLoading: isWebFormDetailsLoading } =
		useWebFormDeatils(webFormId);

	return (
		<React.Fragment>
			<Box
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box p={2}>
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
					>
						<Typography fontWeight={600}>Preview</Typography>
						<Stack direction="row" alignItems="center" spacing={2}>
							<IconButton
								size="small"
								style={{ padding: 0 }}
								onClick={() =>
									navigate(webFormDetails?.id?.toString())
								}
							>
								{EditIcon(20, 20, theme.palette.primary.main)}
							</IconButton>

							<IconButton
								size="small"
								style={{ padding: 0 }}
								onClick={onClose}
							>
								{CloseIcon(24, 24, theme.palette.primary.main)}
							</IconButton>
						</Stack>
					</Stack>
				</Box>

				<Divider />

				<Box
					style={{
						flex: "1 1 auto",
						minHeight: 0,
						overflow: "auto",
					}}
				>
					{isWebFormDetailsLoading ? (
						<Stack
							alignItems="center"
							justifyContent="center"
							p={1}
							height="100%"
						>
							<CircularProgress size="30px" />
						</Stack>
					) : (
						<FieldComponent formToPreview={webFormDetails} />
					)}

					<Divider />

					<Stack p={3} spacing={2}>
						<Stack spacing={0.5}>
							<Typography pl={1} fontSize={13} fontWeight={500}>
								Share as Link
							</Typography>
							<Stack
								p={2}
								direction="row"
								justifyContent="space-between"
								alignItems="center"
								className={classes.detailsContainer}
							>
								<Typography fontSize={14}>
									{webFormDetails?.url}
								</Typography>
								<IconButton>
									{CopyIcon(
										20,
										20,
										theme.palette.primary.main
									)}
								</IconButton>
							</Stack>
						</Stack>

						<Stack spacing={0.5}>
							<Typography pl={1} fontSize={13} fontWeight={500}>
								Embed the form on your website
							</Typography>
							<Stack
								p={2}
								direction="row"
								justifyContent="space-between"
								alignItems="center"
								className={classes.detailsContainer}
							>
								<Typography
									fontSize={14}
								>{`<iframe width="560" height="315" src=${webFormDetails?.url} frameborder="0"></iframe>`}</Typography>
								<IconButton>
									{CopyIcon(
										20,
										20,
										theme.palette.primary.main
									)}
								</IconButton>
							</Stack>
						</Stack>
					</Stack>
				</Box>
			</Box>
		</React.Fragment>
	);
}
