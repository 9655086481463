import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { handleFieldValidation } from "../../../../../../utils/fieldValidation";
import { CompanyBaseComponent } from "./CompanyBaseComponent";

export default function CompanyComponent({
	field,
	inputRef,
	fieldSpecificProps: { isIconNeeded = true } = {},
}) {
	const { control, clearErrors } = useFormContext();

	return (
		<React.Fragment>
			<Controller
				name={field.id + ""}
				control={control}
				defaultValue=""
				rules={handleFieldValidation(field)}
				render={({
					field: { value, onChange },
					fieldState: { error },
				}) => (
					<CompanyBaseComponent
						onChange={onChange}
						value={value}
						field={field}
						inputRef={inputRef}
						error={error}
						clearErrors={clearErrors}
						isIconNeeded={isIconNeeded}
					/>
				)}
			></Controller>
		</React.Fragment>
	);
}
