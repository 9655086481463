import React from "react";
import {
	Box,
	Checkbox,
	CircularProgress,
	Stack,
	Typography,
} from "@mui/material";
import MenuSearchBar from "../../Elements/MenuSearchBar";
import { useMailList } from "../../../hooks/services/mail";

export default function BulkEmailList(props) {
	const { listHeader, header, body, footer, mailListName } = props;
	const { data: mails, isLoading } = useMailList(mailListName);

	return (
		<React.Fragment>
			<Box>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					py={1.25}
					style={{
						borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
					}}
				>
					<Stack direction="row" alignItems="center">
						<Box px={2}>
							<Checkbox disabled />
						</Box>

						<Typography fontWeight={500}>{listHeader}</Typography>
					</Stack>

					<Stack
						direction="row"
						alignItems="center"
						spacing={3}
						pr={3}
					>
						<MenuSearchBar style={{ width: "300px" }} />
					</Stack>
				</Stack>

				<Box>
					{isLoading ? (
						<Stack
							alignItems="center"
							justifyContent="center"
							height="80vh"
						>
							<CircularProgress />
						</Stack>
					) : mails.length > 0 ? (
						<Box>
							{mails?.map((mail, index) => (
								<Stack
									key={index}
									direction="row"
									justifyContent="flex-start"
									alignItems="center"
									style={{
										borderBottom:
											"1px solid rgba(0, 0, 0, 0.1)",
									}}
									px={2}
									py={1}
								>
									<Stack
										direction="row"
										alignItems="center"
										spacing={2}
										width="25%"
									>
										<Box>
											<Checkbox />
										</Box>

										{!!header && <props.header {...mail} />}
									</Stack>

									<Box width="60%">
										{!!body && <props.body {...mail} />}
									</Box>

									<Box
										style={{
											width: "15%",
											textAlign: "right",
										}}
									>
										{!!footer && <props.footer {...mail} />}
									</Box>
								</Stack>
							))}
						</Box>
					) : (
						<Stack
							alignItems="center"
							justifyContent="center"
							height="70vh"
						>
							<Typography
								fontSize={18}
								fontWeight={500}
								color="rgba(0, 0, 0, 0.6)"
							>
								No Conversations Found
							</Typography>
						</Stack>
					)}
				</Box>
			</Box>
		</React.Fragment>
	);
}
