import React, { useState } from "react";
import Dialog from "../../Elements/Dialog";
import {
	Box,
	Button,
	FormControlLabel,
	Paper,
	Radio,
	RadioGroup,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import Autocomplete from "../../Elements/Autocomplete";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../utils/notification/notificationMessages";
import {
	useRemoveTags,
	useTagDropdownList,
} from "../../../hooks/services/tags";

const CustomPaper = function (props) {
	return (
		<Paper
			{...props}
			style={{
				...props.style,
				borderRadius: "8px",
				width: "260px",
				boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.08)",
			}}
		/>
	);
};

export default function RemoveTags(props) {
	const {
		isRemoveTagsDialogOpened,
		closeRemoveTagsDialog,
		selectedIds,
		onTagsRemoved,
		sourceName,
	} = props;
	const theme = useTheme();
	const removedTagsType = {
		allTags: "allTags",
		selectedTags: "selectedTags",
	};

	const [type, setType] = useState(removedTagsType.allTags);
	const [selectedTags, setSelectedTags] = useState([]);
	const [tagInputValue, setTagInputValue] = useState("");

	const successNotificationTitle = "Success!";

	const { data: tagsList } = useTagDropdownList();
	const removeTagsMutation = useRemoveTags();

	const isAllTagsRemoved = () => {
		return type === removedTagsType.allTags;
	};

	const handleSelectTags = (_, values) => {
		setSelectedTags(values);
		setTagInputValue("");
	};

	const handleTagInputChange = (event) => {
		const { value } = event.target;

		if (value.trim()) {
			setTagInputValue(value);
		} else {
			setTagInputValue("");
		}
	};

	const performCancel = () => {
		setType(removedTagsType.allTags);
		setSelectedTags([]);
		closeRemoveTagsDialog();
	};

	const handleRemoveTags = () => {
		let removeTagsRequestData = getRemoveTagsRequestData();
		removeTagsMutation.mutate(removeTagsRequestData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					title: successNotificationTitle,
					message: notificationMessage.tagsRemoved,
				});
				setSelectedTags([]);
				setType(removedTagsType.allTags);
				onTagsRemoved();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	const getRemoveTagsRequestData = () => {
		if (isAllTagsRemoved()) {
			return {
				source: sourceName,
				entityIds: selectedIds,
			};
		} else {
			return {
				source: sourceName,
				tagIds: selectedTags?.map((tag) => tag?.value),
				entityIds: selectedIds,
			};
		}
	};

	const toggleRemoveTagsType = (event) => {
		const { value } = event.target;
		setType(value);
	};

	const isSaveButtonDisable = () => {
		if (isAllTagsRemoved()) {
			return false;
		} else if (selectedTags.length === 0) {
			return true;
		} else {
			return false;
		}
	};

	const onBlurTagsInput = () => {
		setTagInputValue("");
	};

	const isOpenDropdownMenu = () => {
		return tagInputValue.trim().length > 0;
	};

	return (
		<React.Fragment>
			<Dialog title="Remove Tags" open={isRemoveTagsDialogOpened}>
				<Box pt={2} px={3} pb={3}>
					<Stack spacing={1}>
						<RadioGroup
							row
							onChange={toggleRemoveTagsType}
							value={type}
						>
							<FormControlLabel
								value={removedTagsType.allTags}
								control={<Radio size="large" />}
								label={
									<Typography fontSize={14}>
										All Tags
									</Typography>
								}
							/>

							<FormControlLabel
								value={removedTagsType.selectedTags}
								style={{ margin: 0 }}
								control={<Radio size="large" />}
								label={
									<Typography fontSize={14}>
										Selected Tags
									</Typography>
								}
							/>
						</RadioGroup>

						{isAllTagsRemoved() ? (
							<Typography fontSize={14}>
								All the tags added in the selected contacts will
								be removed.
							</Typography>
						) : (
							<Stack spacing={1}>
								<Typography fontSize={13} fontWeight={500}>
									These are the common tags used in the
									selected contacts.
								</Typography>

								<Autocomplete
									freeSolo={false}
									open={isOpenDropdownMenu()}
									filterSelectedOptions
									noOptionsText="no results found"
									paperComponent={CustomPaper}
									options={tagsList || []}
									onChange={handleSelectTags}
									value={selectedTags}
									renderInput={(params) => (
										<TextField
											{...params}
											onChange={handleTagInputChange}
											value={tagInputValue}
											placeholder="Enter tag name"
											onBlur={onBlurTagsInput}
											inputProps={{
												...params.inputProps,
												style: {
													fontSize: "14px",
												},
											}}
										/>
									)}
								/>
							</Stack>
						)}
					</Stack>

					<Stack
						spacing={2}
						pt={3}
						justifyContent="right"
						direction="row"
					>
						<Button
							variant="contained"
							color="secondary"
							disableElevation
							onClick={performCancel}
							disabled={removeTagsMutation.isLoading}
						>
							Cancel
						</Button>

						<Button
							variant="contained"
							color="error"
							disableElevation
							onClick={handleRemoveTags}
							disabled={
								removeTagsMutation.isLoading ||
								isSaveButtonDisable()
							}
							sx={{
								"&.Mui-disabled": {
									backgroundColor: theme.palette.error.main,
									color: "rgba(255, 255, 255, 0.6)",
								},
							}}
						>
							Remove
						</Button>
					</Stack>
				</Box>
			</Dialog>
		</React.Fragment>
	);
}
