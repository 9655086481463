import React, { useEffect, useState } from "react";
import {
	Box,
	IconButton,
	MenuItem,
	Select,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { default as CloseIcon } from "../../../../assets/icons/close";
import MenuSearchBar from "../../../Elements/MenuSearchBar";
import Field from "../../Field";

export default function SingleCondition(props) {
	const theme = useTheme();
	const { deleteCondition, index, condition } = props;
	const fields = props.fields || [];
	const comparatorsData = props.comparatorsData || {};
	const [fieldValue, setFieldValue] = useState(null);
	const [comparators, setComparators] = useState([]);
	const [selectedField, setSelectedField] = useState("");
	const [selectedComparator, setSelectedComparator] = useState("");

	useEffect(() => {
		let selectedField = fields.find(
			(field) => field.fieldId === condition.field.id
		);
		if (selectedField) {
			handleFieldValueChange(
				selectedField,
				condition.comparator.id,
				condition.values
			);
		}
	}, [condition, fields]);

	const selectMenuProps = {
		autoFocus: false,
		PaperProps: {
			style: {
				width: "300px",
				borderRadius: "8px",
			},
		},
		anchorOrigin: {
			vertical: "bottom",
			horizontal: "left",
		},
		transformOrigin: {
			vertical: "top",
			horizontal: "left",
		},
	};

	const handleFieldValueChange = (
		field,
		comparatorId = null,
		fieldValue = null
	) => {
		setSelectedField(field);
		condition["field"]["id"] = field.fieldId;

		let comparators = field.comparators?.map((comparator) => ({
			...comparatorsData[comparator],
			id: comparator,
		}));

		setComparators(comparators);

		if (comparatorId) {
			const comparator = comparators.find(
				(comparator) => comparator.id === comparatorId
			);
			if (comparator) {
				condition["comparator"]["id"] = comparator.id;
				setSelectedComparator(comparator);
				if (comparator.hasValue && fieldValue) {
					setFieldValue(fieldValue);
					condition["comparator"]["value"] = fieldValue;
				}
			}
		} else {
			setSelectedComparator("");
		}
	};

	return (
		<React.Fragment>
			<Stack direction="row" alignItems="center" width="100%">
				<Select
					displayEmpty
					value={selectedField}
					sx={{
						width: "50%",
						backgroundColor: theme.palette.secondary.main,
						borderRadius: "8px 0px 0px 8px",
						"& .MuiOutlinedInput-notchedOutline": {
							border: "none",
							borderRadius: "8px 0px 0px 8px",
						},
					}}
					onChange={(event) => {
						handleFieldValueChange(event.target.value);
					}}
					renderValue={
						selectedField
							? undefined
							: () => (
									<Typography
										fontSize={14}
										color="rgba(0, 0, 0, 0.6)"
									>
										Select
									</Typography>
								)
					}
					MenuProps={selectMenuProps}
				>
					<Box
						p={0.5}
						marginTop="-8px"
						sx={{
							position: "sticky",
							top: 0,
							zIndex: 1,
							backgroundColor: "#fff",
						}}
					>
						<MenuSearchBar />
					</Box>

					{fields.map((field) => (
						<MenuItem
							key={field.fieldId}
							value={field}
							style={{ minHeight: "40px" }}
						>
							<Typography fontSize={13}>{field.name}</Typography>
						</MenuItem>
					))}
				</Select>

				{!!selectedField && (
					<>
						<Select
							displayEmpty
							sx={{
								minWidth: "20%",
								backgroundColor: theme.palette.secondary.main,
								borderRadius:
									!!selectedComparator &&
									selectedComparator.hasValue
										? "0px"
										: "0px 8px 8px 0px",
								"& .MuiOutlinedInput-notchedOutline": {
									border: "none",
									borderLeft: "1px solid rgba(0, 0, 0, 0.10)",
									borderRight:
										!!selectedComparator &&
										selectedComparator.hasValue
											? "1px solid rgba(0, 0, 0, 0.10)"
											: "none",
									borderRadius:
										!!selectedComparator &&
										selectedComparator.hasValue
											? "0px"
											: "0px 8px 8px 0px",
								},
							}}
							value={selectedComparator}
							onChange={(event) => {
								const comparator = event.target.value;
								setSelectedComparator(comparator);
								condition["comparator"]["id"] = comparator.id;
							}}
							renderValue={
								selectedComparator
									? undefined
									: () => (
											<Typography
												fontSize={14}
												color="rgba(0, 0, 0, 0.6)"
											>
												Select
											</Typography>
										)
							}
							MenuProps={selectMenuProps}
						>
							{comparators.map((comparator) => (
								<MenuItem
									key={comparator.id}
									value={comparator}
									style={{ minHeight: "40px" }}
								>
									<Typography fontSize={13}>
										{comparator.name}
									</Typography>
								</MenuItem>
							))}
						</Select>

						{!!selectedComparator &&
							selectedComparator.hasValue && (
								<Field
									field={
										fieldValue && fieldValue.length > 0
											? {
													...selectedField,
													values: fieldValue,
												}
											: selectedField
									}
									onChange={(value) => {
										condition["values"] = value;
									}}
									metadata={{}}
									style={{
										backgroundColor:
											theme.palette.secondary.main,
										borderRadius: "0px 8px 8px 0px",
										"& .MuiOutlinedInput-notchedOutline": {
											border: "none",
											borderRadius: "0px 8px 8px 0px",
										},
										"& .MuiOutlinedInput-root": {
											"& fieldset": {
												border: "none",
											},
										},
									}}
								/>
							)}
					</>
				)}

				<Box pl={1}>
					<IconButton
						size="small"
						onClick={() => deleteCondition(index)}
					>
						{CloseIcon(20, 20, "#000")}
					</IconButton>
				</Box>
			</Stack>
		</React.Fragment>
	);
}
