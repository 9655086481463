import React, { useEffect } from "react";
import Activities from "../../../Summary/Activities";
import {
	getCompanyCompletedActivityKey,
	getCompanyToDoAcitivityKey,
} from "../../../../utils/queryKeys";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import {
	useCompanyCompletedActivities,
	useCompanyToDoActivities,
} from "../../../../hooks/services/company/summary/activities";

export default function CompanyActivities(props) {
	const { companyId, activityTypeIdsToFilter } = props;
	const toDoActivitiesKey = getCompanyToDoAcitivityKey(
		companyId,
		activityTypeIdsToFilter
	);
	const completedActivitiesKey = getCompanyCompletedActivityKey(
		companyId,
		activityTypeIdsToFilter
	);

	// Company todo Activities get API
	const {
		isLoading: isLoadingTodoActivities,
		data: todoActivities,
		status: todoActivitiesStatus,
	} = useCompanyToDoActivities(companyId, activityTypeIdsToFilter);

	// Company completed Activities get API
	const {
		isLoading: isLoadingCompletedActivities,
		data: completedActivities,
		status: completedActivitiesStatus,
	} = useCompanyCompletedActivities(companyId, activityTypeIdsToFilter);

	// Handed Error Notification
	useEffect(() => {
		if (
			todoActivitiesStatus === "error" ||
			completedActivitiesStatus === "error"
		) {
			enqueueSnackbar({
				variant: notificationVariants.error,
				message: notificationMessage.somethingWentWrong,
			});
		}
	}, [todoActivitiesStatus, completedActivitiesStatus]);

	return (
		<React.Fragment>
			<Activities
				isLoadingTodoActivities={isLoadingTodoActivities}
				isLoadingCompletedActivities={isLoadingCompletedActivities}
				todoActivities={todoActivities}
				completedActivities={completedActivities}
				toDoActivitiesKey={toDoActivitiesKey}
				completedActivitiesKey={completedActivitiesKey}
			/>
		</React.Fragment>
	);
}
