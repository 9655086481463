import React from "react";
import AddForm from "../../../Elements/AddForm";
import {
	removeFieldsWithEmptyValues,
	removeFieldsWithIdKey,
} from "../../../../utils/common";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import {
	useCreateMutation,
	useCompanyData,
} from "../../../../hooks/services/company";
import { modules } from "../../../../utils/common/ModulesName";
import { useCompanyFieldPermissions } from "../../../../hooks/modules/auth/company";
import FieldPermissionContextProvider from "../../../Elements/FieldPermissionContext/FieldPermissionContextProvider";

export default function CloneCompany(props) {
	const { companyId, onClose } = props;
	const moduleName = modules.COMPANY;
	const cloneMutation = useCreateMutation();

	const { data: companyData } = useCompanyData(companyId);
	const companyFieldPermissions = useCompanyFieldPermissions();

	const cloneCompany = (clonedFormData) => {
		removeFieldsWithEmptyValues(clonedFormData);
		removeFieldsWithIdKey(clonedFormData);
		cloneMutation.mutate(clonedFormData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.companyCloned,
				});
				onClose();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.genericErrorMessage,
				});
			},
		});
	};

	return (
		<FieldPermissionContextProvider value={companyFieldPermissions}>
			<AddForm
				onSave={cloneCompany}
				moduleName={moduleName}
				formData={companyData}
				onClose={onClose}
				isCloneMode={true}
			></AddForm>
		</FieldPermissionContextProvider>
	);
}
