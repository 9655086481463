import React, { useState } from "react";
import Files from "../../../Summary/Files";
import {
	getDealFilesKey,
	getDealKeyById,
	getDealTimelineKey,
} from "../../../../utils/queryKeys";

export default function DealFiles({ dealId }) {
	const [isAddFileDialogOpened, setIsAddFileDialogOpened] = useState(false);
	const dealFileKey = getDealFilesKey(dealId);
	const dealTimelineKey = getDealTimelineKey(dealId);
	const dealSummaryKey = getDealKeyById(dealId);

	return (
		<React.Fragment>
			<Files
				isAddFileDialogOpened={isAddFileDialogOpened}
				setIsAddFileDialogOpened={setIsAddFileDialogOpened}
				fileKey={dealFileKey}
				summaryKey={dealSummaryKey}
				timelineKey={dealTimelineKey}
				entityId={dealId}
				source="Deal"
			/>
		</React.Fragment>
	);
}
