import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getContactTimelineKey } from "../../../../utils/queryKeys";
import { getContactTimelineData } from "../../../../api/timeline/timelineAPI";

const useContactTimelineData = (contactId, timelineIdsToFilter) => {
	const contactTimelineKey = getContactTimelineKey(
		contactId,
		timelineIdsToFilter
	);
	return useQuery(
		contactTimelineKey,
		() => getContactTimelineData(contactId, timelineIdsToFilter),
		{
			select: (data) => data?.timeline,
		}
	);
};

//Invalidate Contact Timeline
const useInvalidateContactTimeline = (contactId) => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(getContactTimelineKey(contactId));
	};
};

export { useContactTimelineData, useInvalidateContactTimeline };
