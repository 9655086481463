import React from "react";
import { TableCellText } from "../../../../styles/twozo";
import { getStatusProperties } from "../../../../utils/ActivitiesUtils";
import { useTheme } from "@mui/material";
import { useAuth } from "../../../../hooks/auth";
import { PERMISSIONS } from "../../../../utils/Auth";

export default function Company(props) {
	const { data, rowState } = props;
	const theme = useTheme();
	// query call:-
	const { isUserAllowedFor } = useAuth();

	if (!data || !isUserAllowedFor(PERMISSIONS.company.view)) {
		return "-";
	}

	return (
		<React.Fragment>
			<TableCellText
				color={getStatusProperties(rowState, theme).textColor}
			>
				{data?.name}
			</TableCellText>
		</React.Fragment>
	);
}
