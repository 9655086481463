import React, { useEffect, useState } from "react";
import {
	Box,
	IconButton,
	Stack,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	useTheme,
} from "@mui/material";
import { TableCellText, TableHeaderLabel } from "../../../../styles/twozo";
import { default as DropDownIcon } from "../../../../assets/icons/dropDownCentered";
import { default as DeleteIcon } from "../../../../assets/icons/delete";
import DeleteDialog from "../../../Elements/DeleteDialog";
import { useQuery } from "@tanstack/react-query";
import { getWorkflowList } from "../../../../api/workflow/workflowApi";
import { getWorkflowListKey } from "../../../../utils/queryKeys/workflow";
import { getDropdownDataByName } from "../../../../utils/dropdownData";
import { getDateDifference } from "../../../../utils/DateUtils";

export default function Automation() {
	const theme = useTheme();
	const [tableRowHovered, setTableRowHovered] = useState(null);
	const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
	const [workflowList, setWorkflowList] = useState([]);
	const sources = getDropdownDataByName("WORKFLOW_TRIGGER_SOURCE_LIST");
	const events = getDropdownDataByName("WORKFLOW_TRIGGER_EVENT_LIST");

	const { data: workflowListData } = useQuery(
		getWorkflowListKey(),
		() => getWorkflowList(),
		{
			select: (data) => data.list,
		}
	);

	useEffect(() => {
		if (workflowListData && workflowListData.length > 0) {
			let sourcesMap = {};
			sources.forEach((source) => {
				sourcesMap[source.value] = source;
			});

			let eventsMap = {};
			events.forEach((event) => {
				eventsMap[event.value] = event;
			});

			setWorkflowList(
				workflowListData.map((workflow) => ({
					...workflow,
					module: sourcesMap[workflow.triggerSource],
					executeOn: eventsMap[workflow.triggerEvent],
				}))
			);
		}
	}, [workflowListData]);

	const onMouseOverTableRow = (id) => setTableRowHovered(id);
	const onMouseOutTableRow = () => setTableRowHovered(null);

	const openDeleteDialog = () => {
		setIsOpenDeleteDialog(true);
	};

	const closeDeleteDialog = () => {
		setIsOpenDeleteDialog(false);
	};

	return (
		<React.Fragment>
			<DeleteDialog
				title="Are you sure you want to delete this automation?"
				subtitle="You can't undo this."
				open={isOpenDeleteDialog}
				onCancel={closeDeleteDialog}
				onDelete={closeDeleteDialog}
			/>

			<Box p={1} pt={0} height="100%">
				<Box
					sx={{
						border: "1px solid rgba(0, 0, 0, 0.1)",
						borderRadius: "8px",
						height: "100%",
						overflow: "auto",
					}}
				>
					<TableContainer>
						<Table sx={{ minWidth: 650 }} size="small">
							<TableHead>
								<TableRow>
									<TableCell>
										<TableHeaderLabel>
											Status
										</TableHeaderLabel>
									</TableCell>

									<TableCell width="30%">
										<Stack
											direction="row"
											alignItems="center"
											spacing={1}
										>
											<TableHeaderLabel>
												Workflow Name
											</TableHeaderLabel>

											{DropDownIcon(12, 12, "grey")}
										</Stack>
									</TableCell>

									<TableCell>
										<Stack
											direction="row"
											alignItems="center"
											spacing={1}
										>
											<TableHeaderLabel>
												Module
											</TableHeaderLabel>

											{DropDownIcon(12, 12, "grey")}
										</Stack>
									</TableCell>

									<TableCell>
										<Stack
											direction="row"
											alignItems="center"
											spacing={1}
										>
											<TableHeaderLabel>
												Execute on
											</TableHeaderLabel>

											{DropDownIcon(12, 12, "grey")}
										</Stack>
									</TableCell>

									<TableCell>
										<Stack
											direction="row"
											alignItems="center"
											spacing={1}
										>
											<TableHeaderLabel>
												Actions
											</TableHeaderLabel>

											{DropDownIcon(12, 12, "grey")}
										</Stack>
									</TableCell>

									<TableCell>
										<Stack
											direction="row"
											alignItems="center"
											spacing={1}
										>
											<TableHeaderLabel>
												Last Updated
											</TableHeaderLabel>

											{DropDownIcon(12, 12, "grey")}
										</Stack>
									</TableCell>

									<TableCell width="5%"></TableCell>
								</TableRow>
							</TableHead>

							<TableBody>
								{workflowList.map((workflow, index) => (
									<TableRow
										key={index}
										onMouseOver={() =>
											onMouseOverTableRow(workflow.id)
										}
										onMouseOut={onMouseOutTableRow}
									>
										<TableCell>
											<Switch
												checked={workflow.isActive}
											/>
										</TableCell>

										<TableCell>
											<Typography
												fontSize={14}
												fontWeight={500}
											>
												{workflow.name}
											</Typography>
										</TableCell>

										<TableCell>
											{/*{(function () {*/}
											{/*	const moduleData =*/}
											{/*		getModuleData(*/}
											{/*			workflow.module*/}
											{/*		);*/}
											{/*	return (*/}
											{/*		<Stack*/}
											{/*			direction="row"*/}
											{/*			alignItems="center"*/}
											{/*			spacing={1}*/}
											{/*		>*/}
											{/*			<Box display="flex">*/}
											{/*				{moduleData.icon(*/}
											{/*					20,*/}
											{/*					20,*/}
											{/*					"rgba(0, 0, 0, 0.6)"*/}
											{/*				)}*/}
											{/*			</Box>*/}

											{/*			<TableCellText>*/}
											{/*				{moduleData.name}*/}
											{/*			</TableCellText>*/}
											{/*		</Stack>*/}
											{/*	);*/}
											{/*})()}*/}
											<Stack
												direction="row"
												alignItems="center"
												spacing={1}
											>
												<Box display="flex">
													{workflow.module?.icon(
														20,
														20,
														"rgba(0, 0, 0, 0.6)"
													)}
												</Box>

												<TableCellText>
													{workflow.module?.name}
												</TableCellText>
											</Stack>
										</TableCell>

										<TableCell>
											{workflow.executeOn?.name}
										</TableCell>

										<TableCell>
											{workflow.actions}
										</TableCell>

										<TableCell>
											{getDateDifference(
												workflow.modifiedTime,
												false,
												false
											)}
										</TableCell>

										<TableCell sx={{ py: 0 }}>
											<Stack alignItems="flex-end">
												<Box
													hidden={
														tableRowHovered !==
														workflow.id
													}
													style={{
														width: "fit-content",
														border: `1px solid ${theme.palette.primary.main}`,
														borderRadius: "8px",
														padding: "2px 8px",
													}}
												>
													<IconButton
														size="small"
														onClick={
															openDeleteDialog
														}
													>
														{DeleteIcon(
															20,
															20,
															theme.palette.error
																.main
														)}
													</IconButton>
												</Box>
											</Stack>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
			</Box>
		</React.Fragment>
	);
}
