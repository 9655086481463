import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getCompanyTimelineKey } from "../../../../utils/queryKeys";
import { getCompanyTimelineData } from "../../../../api/timeline/timelineAPI";

const useCompanyTimelineData = (companyId, timelineIdsToFilter) => {
	const companyTimelineKey = getCompanyTimelineKey(
		companyId,
		timelineIdsToFilter
	);
	return useQuery(
		companyTimelineKey,
		() => getCompanyTimelineData(companyId, timelineIdsToFilter),
		{
			select: (data) => data?.timeline,
		}
	);
};

//Invalidate Company Timeline
const useInvalidateCompanyTimeline = (companyId) => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(getCompanyTimelineKey(companyId));
	};
};

export { useCompanyTimelineData, useInvalidateCompanyTimeline };
