import React from "react";
import {
	Box,
	Button,
	Checkbox,
	Divider,
	Stack,
	TextField,
	Typography,
	alpha,
	useTheme,
} from "@mui/material";
import { default as DropdownIcon } from "../../../../../assets/icons/dropDownCentered";
import { default as CopyIcon } from "../../../../../assets/icons/copy";
import { default as CloseIcon } from "../../../../../assets/icons/close";

export default function EmailSettings() {
	const theme = useTheme();
	const blockedEmailAddress = [
		{
			id: 1,
			value: "thesamsungcompany@sams.com",
		},
		{
			id: 2,
			value: "*@abc.com",
		},
		{
			id: 3,
			value: "*@prom.in",
		},
	];

	return (
		<React.Fragment>
			<Box
				style={{
					border: "1px solid rgba(0, 0, 0, 0.1)",
					borderRadius: "8px",
				}}
				m={1}
				px={2}
			>
				<Stack spacing={1} py={3}>
					<Typography fontWeight={600}>Email Tracking</Typography>

					<Stack direction="row" alignItems="center" spacing={1}>
						<Checkbox />

						<Typography>Track Opens</Typography>
					</Stack>

					<Stack direction="row" alignItems="center" spacing={1}>
						<Checkbox />

						<Typography>Track Clicks</Typography>
					</Stack>

					<Stack direction="row" alignItems="center" spacing={1}>
						<Checkbox />

						<Typography>
							Notification for Tracking Activities
						</Typography>
					</Stack>
				</Stack>

				<Divider />

				<Stack spacing={1} pt={3} pb={2}>
					<Typography fontWeight={600}>Email Linking</Typography>

					<Typography fontSize={13} fontWeight={500} pt={1}>
						Link Emails to
					</Typography>

					<Stack direction="row" alignItems="center" spacing={1}>
						<Checkbox />

						<Typography>Deals</Typography>
					</Stack>

					<Stack direction="row" alignItems="center" spacing={1}>
						<Checkbox />

						<Typography>Contacts</Typography>
					</Stack>

					<Stack direction="row" alignItems="center" spacing={1}>
						<Checkbox />

						<Typography>Auto create contact with stage</Typography>

						<Stack
							direction="row"
							alignItems="center"
							spacing={0.5}
							sx={{ cursor: "pointer" }}
						>
							<Typography
								fontWeight={500}
								color={alpha(theme.palette.primary.main, 0.6)}
							>
								Select
							</Typography>

							{DropdownIcon(16, 16, theme.palette.primary.main)}
						</Stack>

						<Typography>from emails</Typography>
					</Stack>
				</Stack>

				<Divider />

				<Stack spacing={2} py={3}>
					<Typography fontWeight={600}>
						Forward your emails
					</Typography>

					<Stack spacing={1.5}>
						<Typography color="rgba(0, 0, 0, 0.6)">
							Copy and paste the address to the cc or bcc when
							sending emails or forward emails to this address to
							automatically sync them.
						</Typography>

						<Stack direction="row">
							<TextField
								value="thiscrm@crm.com"
								sx={{
									width: "35%",
									"& .MuiOutlinedInput-notchedOutline": {
										borderRadius: "8px 0px 0px 8px",
									},
								}}
							/>
							<Button
								style={{
									border: "1px solid rgba(0, 0, 0, 0.1)",
									borderRadius: "0px 8px 8px 0px",
									height: "42px",
									width: "5%",
									marginLeft: "-1px",
								}}
								variant="contained"
								color="secondary"
								disableElevation
							>
								{CopyIcon(20, 20, theme.palette.primary.main)}
							</Button>
						</Stack>
					</Stack>
				</Stack>

				<Divider />

				<Stack spacing={2} py={3}>
					<Typography fontWeight={600}>Blocked Addresses</Typography>

					<Stack spacing={1.5}>
						<Typography>
							Add email addresses that you don’t want to receive
							emails from. You can also block all addresses with a
							certain domain by using the following format:
							<span style={{ fontWeight: 500 }}>
								{" "}
								*@example.com
							</span>
						</Typography>

						<TextField
							placeholder="Enter email address"
							sx={{
								width: "40%",
								borderRadius: "8px",
							}}
						/>
					</Stack>

					<Stack direction="row" spacing={1}>
						{blockedEmailAddress.map((emailAddress) => (
							<Stack
								direction="row"
								alignItems="center"
								borderRadius={2}
								bgcolor={theme.palette.secondary.main}
								p={0.5}
								spacing={1}
								key={emailAddress.id}
							>
								<Typography>{emailAddress.value}</Typography>

								<Stack sx={{ cursor: "pointer" }}>
									{CloseIcon(16, 16, "#000", 0.6)}
								</Stack>
							</Stack>
						))}
					</Stack>
				</Stack>

				<Divider />

				<Box py={3}>
					<Button
						variant="contained"
						color="secondary"
						disableElevation
					>
						Save
					</Button>
				</Box>
			</Box>
		</React.Fragment>
	);
}
