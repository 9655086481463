import React, { useState } from "react";
import Files from "../../../Summary/Files";
import {
	getCompanyFilesKey,
	getCompanyTimelineKey,
} from "../../../../utils/queryKeys";

export default function CompanyFiles({ companyId }) {
	const [isAddFileDialogOpened, setIsAddFileDialogOpened] = useState(false);
	const companyFileKey = getCompanyFilesKey(companyId);
	const companyTimelineKey = getCompanyTimelineKey(companyId);

	return (
		<React.Fragment>
			<Files
				isAddFileDialogOpened={isAddFileDialogOpened}
				setIsAddFileDialogOpened={setIsAddFileDialogOpened}
				fileKey={companyFileKey}
				timelineKey={companyTimelineKey}
				entityId={companyId}
				source="Company"
			/>
		</React.Fragment>
	);
}
