import React from "react";
import AddressComponent from "./FieldComponents/AddressComponent";
import CompanyComponent from "./FieldComponents/CompanyComponent";
import ContactComponent from "./FieldComponents/ContactComponent";
import DateTimeRangeComponent from "./FieldComponents/DateTimeRangeComponents";
import DropDownComponent from "./FieldComponents/DropdownComponent";
import EmailComponent from "./FieldComponents/EmailComponent";
import MonetaryComponent from "./FieldComponents/MonetaryComponent";
import MultiSelectComponent from "./FieldComponents/MultiSelectComponent";
import PhoneComponent from "./FieldComponents/PhoneComponent";
import TextComponent from "./FieldComponents/TextComponent";
import MultiEmailComponent from "./FieldComponents/MultiEmailComponent";
import MultiPhoneComponent from "./FieldComponents/MultiPhoneComponent";
import DealComponent from "./FieldComponents/DealComponent";
import SalesOwnerComponent from "./FieldComponents/SalesOwnerComponent";
import TagComponent from "./FieldComponents/TagComponent";
import TerritoryComponent from "./FieldComponents/TerritoryComponent";
import ProductComponent from "./FieldComponents/ProductComponent";
import TimeComponent from "./FieldComponents/TimeComponent";
import DateComponent from "./FieldComponents/DateComponent";
import TextAreaComponent from "./FieldComponents/TextAreaComponent";
import NumberFieldComponent from "./FieldComponents/NumberFieldComponent";
import TimeRangeComponent from "./FieldComponents/TimeRangeComponent";
import DateRangeComponent from "./FieldComponents/DateRangeComponent";

const fieldComponents = {
	TEXT: TextAreaComponent,
	SINGLE_STRING: TextComponent,
	ADDRESS: AddressComponent,
	MONETARY: MonetaryComponent,
	EMAIL: EmailComponent,
	EMAILS: MultiEmailComponent,
	SINGLE_NUMBER: NumberFieldComponent,
	PHONES: MultiPhoneComponent,
	PHONE: PhoneComponent,
	TIME: TimeComponent,
	DROPDOWN: DropDownComponent,
	MULTI_SELECT: MultiSelectComponent,
	COMPANY: CompanyComponent,
	DATE: DateComponent,
	TIME_RANGE: TimeRangeComponent,
	DATE_RANGE: DateRangeComponent,
	DATE_TIME_RANGE: DateTimeRangeComponent,
	CONTACT: ContactComponent,
	DEAL: DealComponent,
	OWNER: SalesOwnerComponent,
	TAGS: TagComponent,
	TERRITORY: TerritoryComponent,
	PRODUCT: ProductComponent,
};

export default function Field(props) {
	const { summaryField, subFields } = props;
	const FieldComponent = fieldComponents[summaryField?.fieldType];

	return FieldComponent ? (
		<FieldComponent summaryField={summaryField} subFields={subFields} />
	) : null;
}
