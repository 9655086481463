import { Typography } from "@mui/material";
import React from "react";
import { twozoStyles } from "../../../../../../styles/twozo";
import TerritoryFieldValue from "./TerritoryFieldValue";

export default function TerritoryComponent(props) {
	const { summaryField } = props;
	const classes = twozoStyles();

	return (
		<React.Fragment>
			<td>
				<Typography fontSize={14} className={classes.summaryHead}>
					{summaryField.fieldName} :
				</Typography>
			</td>
			<td
				style={{
					padding: 0,
					display: "flex",
					alignItems: "center",
					cursor: "pointer",
				}}
			>
				<TerritoryFieldValue summaryField={summaryField} />
			</td>
		</React.Fragment>
	);
}
