import React, { useEffect, useMemo, useState } from "react";
import { Box, MenuItem, Select, Stack, Typography } from "@mui/material";
import MenuSearchBar from "../../../../../../MenuSearchBar";
import { default as CloseIcon } from "../../../../../../../../assets/icons/close";
import { default as DropdownIcon } from "../../../../../../../../assets/icons/dropDownCentered";

export default function Dropdown(props) {
	const { options, onChange, value, inputRef, field, clearErrors, error } =
		props;
	const [selectedValue, setSelectedValue] = useState("");
	const [searchValue, setSearchValue] = useState("");

	const hideSearchBar = options?.length === 0;

	useEffect(() => {
		const getDefaultValue = () => {
			const defaultValue = options?.find(
				(option) => option.isDefault
			)?.value;
			if (defaultValue) {
				return defaultValue;
			}
			return "";
		};

		const checkIfTheValueMatches = options?.find(
			(option) => option.value === value
		);

		if (options?.length > 0) {
			let defaultValue =
				value && checkIfTheValueMatches ? value : getDefaultValue();

			setSelectedValue(defaultValue);
			onChange(defaultValue);
		}
	}, [options, onChange, value]);

	const selectedOptionName = useMemo(() => {
		if (!selectedValue) {
			return "";
		}
		const selectedOption = options?.find(
			(option) => option.value === selectedValue
		);
		return selectedOption ? selectedOption?.name : "";
	}, [selectedValue, options]);

	const handleChangeDropdownOption = (event) => {
		let { value } = event.target;
		let modifiedEvent = {
			...event,
			target: {
				...event.target,
				value: value,
			},
		};
		onChange(modifiedEvent);
		setSelectedValue(value);
		clearErrors(field.id);
	};

	const handleSearchValue = (event) => {
		const { value } = event.target;
		setSearchValue(value);
	};

	const getFilteredOptionsList = () => {
		if (searchValue.trim()) {
			return options?.filter((option) =>
				//filter for search
				option.name.toLowerCase().startsWith(searchValue.toLowerCase())
			);
		}
		return options;
	};

	const renderEmptyView = () => {
		return (
			<Stack alignItems="center" justifyContent="center" height="40px">
				<Typography fontSize={13} color="rgba(0, 0, 0, 0.6)">
					{options?.length === 0
						? "No Options Found"
						: "No Results Found"}
				</Typography>
			</Stack>
		);
	};

	const handleRemove = () => {
		setSelectedValue(null);
		onChange(null);
	};

	return (
		<React.Fragment>
			<Select
				size="small"
				value={selectedValue || ""}
				inputRef={inputRef}
				onChange={handleChangeDropdownOption}
				error={!!error}
				displayEmpty
				sx={{ width: "100%" }}
				MenuProps={{
					autoFocus: false,
					PaperProps: {
						style: {
							maxHeight: 300,
							marginTop: "4px",
							borderRadius: "8px",
						},
					},
				}}
				onOpen={() => setSearchValue("")}
				renderValue={() => (
					<Typography
						fontSize={14}
						color={selectedValue ? "#000" : "rgba(0, 0, 0, 0.6)"}
					>
						{selectedValue
							? selectedOptionName
							: field?.placeHolder || "Select"}
					</Typography>
				)}
				IconComponent={(props) => (
					<Box {...props}>{DropdownIcon(14, 14)}</Box>
				)}
			>
				<Box
					hidden={hideSearchBar}
					p={0.5}
					marginTop="-8px"
					sx={{
						position: "sticky",
						top: 0,
						zIndex: 1,
						backgroundColor: "#fff",
					}}
				>
					<MenuSearchBar
						value={searchValue}
						onChange={handleSearchValue}
					/>
				</Box>

				{getFilteredOptionsList()?.length > 0
					? getFilteredOptionsList()?.map((option, index) => (
							<MenuItem value={option.value || ""} key={index}>
								<Stack
									direction="row"
									spacing={1}
									alignItems="center"
									justifyContent="space-between"
									width="100%"
								>
									<Typography fontSize={14}>
										{option.name}
									</Typography>

									{selectedValue === option?.value ? (
										<Box
											display="flex"
											onClick={handleRemove}
										>
											{CloseIcon(20, 20, "#000", 0.6)}
										</Box>
									) : null}
								</Stack>
							</MenuItem>
						))
					: renderEmptyView()}
			</Select>
		</React.Fragment>
	);
}
