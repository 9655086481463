import React from "react";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import SingleCondition from "../SingleCondition";
import { DwarfButton } from "../../../../styles/twozo";

export default function ConditionList(props) {
	const {
		conditionSet,
		addCondition,
		deleteCondition,
		activityFields,
		comparatorsData,
		hideNewConditionSetButton,
		addNewConditionSet,
		triggerEventId,
	} = props;
	const theme = useTheme();

	return (
		<React.Fragment>
			<Box>
				<Stack spacing={1.5}>
					{conditionSet.map((condition, index) => (
						<Stack
							key={"condition-set-" + index}
							direction="row"
							alignItems="center"
							spacing={1.5}
						>
							{index > 0 && (
								<Typography
									fontSize={14}
									fontWeight={500}
									color={theme.palette.secondary.contrastText}
								>
									AND
								</Typography>
							)}

							<Box width="420px">
								<SingleCondition
									index={index}
									fields={activityFields}
									condition={condition}
									comparatorsData={comparatorsData}
									deleteCondition={deleteCondition}
									triggerEventId={triggerEventId}
								/>
							</Box>
						</Stack>
					))}
				</Stack>

				<Stack direction="row" spacing={1} pt={1.5}>
					<DwarfButton onClick={addCondition}>
						+ Add Condition
					</DwarfButton>

					{!hideNewConditionSetButton && (
						<DwarfButton onClick={addNewConditionSet}>
							+ Add New Set
						</DwarfButton>
					)}
				</Stack>
			</Box>
		</React.Fragment>
	);
}
