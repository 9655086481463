import { Box, Typography, useTheme } from "@mui/material";
import {
	SummaryFieldName,
	Tooltip,
	twozoStyles,
} from "../../../../../../../styles/twozo";
import { useMemo, useState } from "react";
import { useSummaryContext } from "../../../../../../Summary/SummaryContext";
import EmailFieldEditForm from "../../../../../FieldEditFormComponents/EmailFieldEditForm";
import FieldEdit from "../../../../../FieldEdit";

const fieldEditStyles = {
	fieldEditContainer: {
		width: "400px",
	},
	fieldEditForm: {
		width: "310px",
	},
};

export default function EmailFieldValue(params) {
	const { summaryField, subFields } = params;

	const theme = useTheme();
	const classes = twozoStyles();

	// Summary Context:-
	const { updateSummaryField, entityId } = useSummaryContext();

	const [openToolTip, setOpenToolTip] = useState(false);

	const handleOpenTooltip = (event) => {
		setOpenToolTip(event.target.scrollWidth > event.target.clientWidth);
	};

	const getSubFieldObject = (subFieldName) => {
		let subFieldObj;
		if (!!subFields && Array.isArray(subFields)) {
			subFields.forEach((field) => {
				if (field.name === subFieldName) {
					subFieldObj = field;
				}
			});
		}

		return subFieldObj;
	};

	const emailValueField = getSubFieldObject("EMAIL_VALUE");

	const modifiedEmailValue = useMemo(() => {
		if (summaryField.value) {
			const { EMAIL_VALUE, EMAIL_TYPE } = summaryField.value;

			return {
				[EMAIL_VALUE.id]: EMAIL_VALUE?.value,
				[EMAIL_TYPE.id]: EMAIL_TYPE?.value,
				id: entityId,
			};
		}
		return {};
	}, [summaryField]);

	const getEmailRequestData = (emailFieldValue) => {
		return {
			[summaryField.fieldId]: {
				...emailFieldValue,
			},
			id: entityId,
		};
	};

	const hasValueOrIsChanged = (emailFieldValue) => {
		if (summaryField.value) {
			const { EMAIL_VALUE, EMAIL_TYPE } = summaryField.value;
			return (
				(emailFieldValue[EMAIL_VALUE?.id] !== EMAIL_VALUE?.value &&
					!!emailFieldValue[EMAIL_VALUE?.id]) ||
				(emailFieldValue[EMAIL_TYPE?.id] !== EMAIL_TYPE?.value &&
					!!emailFieldValue[EMAIL_TYPE?.id])
			);
		} else {
			return !!emailFieldValue[emailValueField?.id];
		}
	};

	const handleSaveEmailField = (emailFieldValue, onClose) => {
		if (hasValueOrIsChanged(emailFieldValue)) {
			let requestData = getEmailRequestData(emailFieldValue);
			updateSummaryField(requestData, onClose);
		} else {
			onClose();
		}
	};

	const getEmailFieldLabel = (onFieldClicked) => {
		return (
			<Box onClick={onFieldClicked}>
				{summaryField?.value ? (
					<Tooltip
						open={openToolTip}
						placement="bottom"
						title={summaryField.value?.EMAIL_VALUE?.value || ""}
					>
						<SummaryFieldName
							className={classes.summaryData}
							onMouseOver={handleOpenTooltip}
							onMouseLeave={() => setOpenToolTip(false)}
						>
							{summaryField.value?.EMAIL_VALUE?.value}
						</SummaryFieldName>
					</Tooltip>
				) : summaryField.config.readOnly ? (
					<SummaryFieldName style={{ opacity: 0.6 }}>
						-
					</SummaryFieldName>
				) : (
					<Typography
						width="150px"
						sx={{
							"&:hover": {
								borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
							},
						}}
						fontSize="14px"
						fontWeight={500}
						style={{
							color: theme.palette.secondary.contrastText,
							opacity: "0.4",
						}}
					>
						Click to Add
					</Typography>
				)}
			</Box>
		);
	};

	return (
		<>
			<FieldEdit
				field={summaryField}
				fieldLabel={getEmailFieldLabel}
				styles={fieldEditStyles}
			>
				<EmailFieldEditForm
					subFields={subFields}
					onSave={handleSaveEmailField}
					value={modifiedEmailValue}
					field={summaryField}
				/>
			</FieldEdit>
		</>
	);
}
