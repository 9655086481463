import React, { useMemo, useState } from "react";
import { default as AddIcon } from "../../../../assets/icons/add";
import { default as CallIcon } from "../../../../assets/icons/call";
import { default as MoreIcon } from "../../../../assets/icons/more";
import { default as MailIcon } from "../../../../assets/icons/mail";
import { default as ThumbsUpIcon } from "../../../../assets/icons/thumbsUp";
import { default as ThumbsDownIcon } from "../../../../assets/icons/thumbsDown";
import { default as ReopenIcon } from "../../../../assets/icons/reopen";
import { default as DeleteIcon } from "../../../../assets/icons/delete";
import { default as CopyIcon } from "../../../../assets/icons/copy";
import { default as EditIcon } from "../../../../assets/icons/edit";
import { default as ExportIcon } from "../../../../assets/icons/export";
import { default as MergeIcon } from "../../../../assets/icons/merge";
import {
	Box,
	Button,
	IconButton,
	MenuItem,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import Menu from "../../../Elements/Menu";
import Dialog from "../../../Elements/Dialog";
import { hexToRgba, twozoStyles } from "../../../../styles/twozo";
import LogCall from "../LogCall";
import ClosedLost from "../ClosedLost";
import ClosedWon from "../ClosedWon";
import MergeDeal from "../MergeDeal";
import CustomSwipeableDrawer from "../../../Elements/CustomSwipeableDrawer";
import { getDropdownDataByName } from "../../../../utils/dropdownData";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import CloneDeal from "../../CloneDeal";
import {
	useDeleteMutation,
	useLinkedProductsToDeal,
	useUpdateDealStateWithoutPipelineId,
} from "../../../../hooks/services/deal";
import DeleteDialog from "../../../Elements/DeleteDialog";
import { modules } from "../../../../utils/common/ModulesName";
import { dealState } from "../../../../utils/dealUtils";
import AddDeal from "../../../Products/AddDeal";
import EmailComposerDrawer from "../../../Mail/EmailComposer/EmailComposerDrawer";
import { useAuth } from "../../../../hooks/auth";
import { PERMISSIONS } from "../../../../utils/Auth";
import Can from "../../../Auth/Can";
import { useModuleName } from "../../../../hooks/modules";
import { useModuleList } from "../../../../hooks/services/common";

export default function SummaryMenu(props) {
	const { dealId, toggleEditFormDialog, contactId, dealStatus } = props;
	const theme = useTheme();
	const classes = twozoStyles();
	const navigate = useNavigate();

	const updateDealStateMutation = useUpdateDealStateWithoutPipelineId(dealId);
	const deleteMutation = useDeleteMutation();
	// query call:-
	const { isUserAllowedFor } = useAuth();

	const [summaryMenuElement, setSummaryMenuElement] = useState(null);
	const openSummaryMenu = Boolean(summaryMenuElement);
	const [openLogCallDialog, setOpenLogCallDialog] = useState(false);
	const [openClosedLostDialog, setClosedLostDialog] = useState(false);
	const [openClosedWonDialog, setClosedWonDialog] = useState(false);
	const [openMergeDealDialog, setOpenMergeDealDialog] = useState(false);
	const [openAddNewDealsDialog, setOpenAddNewDealsDialog] = useState(false);
	const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState(false);
	const [isEmailComposerOpened, setIsEmailComposerOpened] = useState(false);
	const [isAddProductsDrawerOpened, setIsAddProductsDrawerOpened] =
		useState(false);

	const { data: linkedProducts } = useLinkedProductsToDeal(dealId);

	const { getModuleName } = useModuleName();
	const { data: moduleList } = useModuleList();

	const isAddProductButtonEnabled = useMemo(() => {
		if (Array.isArray(moduleList)) {
			let moduleData = moduleList.find(
				(module) => module.sourceName === modules.PRODUCT
			);
			if (moduleData) {
				return (
					moduleData.isEnabled &&
					isUserAllowedFor(PERMISSIONS.product.view)
				);
			}
		}
	}, [moduleList]);

	const OpenSummaryMenu = (event) => {
		setSummaryMenuElement(event.currentTarget);
	};

	const CloseSummaryMenu = () => {
		setSummaryMenuElement(null);
	};

	const handleOpenMergeDealDialog = () => {
		CloseSummaryMenu();
		setOpenMergeDealDialog(true);
	};

	const handleCloseMergeDealDialog = () => {
		setOpenMergeDealDialog(false);
	};

	const handleOpenLogCallDialog = () => {
		setOpenLogCallDialog(true);
	};

	const handleCloseLogCallDialog = () => {
		setOpenLogCallDialog(false);
	};

	const handleOpenClosedLostDialog = () => {
		setClosedLostDialog(true);
	};

	const handleCloseClosedLostDialog = () => {
		setClosedLostDialog(false);
	};

	const handleOpenClosedWonDialog = () => {
		setClosedWonDialog(true);
	};

	const handleCloseClosedWonDialog = () => {
		setClosedWonDialog(false);
	};

	const openDeleteDialog = () => {
		setIsDeleteDialogOpened(true);
		setSummaryMenuElement(null);
	};

	const closeDeleteDialog = () => {
		setIsDeleteDialogOpened(false);
	};

	const handleDeleteDeal = () => {
		deleteMutation.mutate(dealId, {
			onSuccess: () => {
				enqueueSnackbar({
					title: "Success!",
					variant: notificationVariants.error,
					message: notificationMessage.dealDeleted,
				});
				navigate("/deals");
			},
			onError: (error) => {
				let errorMessage = error?.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage
						? errorMessage
						: notificationMessage.errorMessage,
				});
			},
		});
	};

	const handleCloneDeal = () => {
		toggleAddNewDealsDialog();
		CloseSummaryMenu();
	};

	const handleEditDeal = () => {
		toggleEditFormDialog();
		setSummaryMenuElement(null);
	};

	const menuOptions = [
		{
			id: "edit",
			name: "Edit",
			icon: EditIcon,
			onClick: handleEditDeal,
			permission: isUserAllowedFor(PERMISSIONS.deal.edit),
		},
		{
			id: "merge",
			name: "Merge",
			icon: MergeIcon,
			onClick: handleOpenMergeDealDialog,
			permission: isUserAllowedFor(PERMISSIONS.deal.mergeRecords),
		},
		{
			id: "clone",
			name: "Clone",
			icon: CopyIcon,
			onClick: handleCloneDeal,
			permission: isUserAllowedFor(PERMISSIONS.deal.cloneRecords),
		},
		{
			id: "delete",
			name: "Delete",
			icon: DeleteIcon,
			onClick: openDeleteDialog,
			permission: isUserAllowedFor(PERMISSIONS.deal.delete),
		},
		{
			id: "log_call",
			name: "Log a Call",
			icon: CallIcon,
			onClick: handleOpenLogCallDialog,
			permission: isUserAllowedFor(PERMISSIONS.contact.create),
		},
		{
			id: "export",
			name: "Export",
			icon: ExportIcon,
			onClick: () => {},
			permission: isUserAllowedFor(PERMISSIONS.deal.exportRecords),
		},
	];

	const getMenuOptions = () => {
		let filteredMenuOptions = menuOptions.filter(
			(option) => option.permission
		);
		return filteredMenuOptions;
	};

	const handleUpdateDealState = (dealStateData, state) => {
		let requestData = getDealStateRequest(dealStateData, state);
		updateDealStateMutation.mutate(requestData, {
			onSuccess: () => {
				enqueueSnackbar({
					title: "Success!",
					variant: notificationVariants.success,
					message: notificationMessage.dealStateUpdated,
				});
				handleCloseClosedLostDialog();
				handleCloseClosedWonDialog();
			},
			onError: (error) => {
				let errorMessage = error?.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage
						? errorMessage
						: notificationMessage.errorMessage,
				});
			},
		});
	};

	const getDealStateId = (state) => {
		let dealState = getDropdownDataByName("STATE");
		let dealStateId = dealState?.find(
			(dealState) => dealState.name.toLowerCase() === state?.toLowerCase()
		);

		return dealStateId.value;
	};

	const getDealStateRequest = (dealStateData, state) => {
		let dealStateId = getDealStateId(state);
		let requestData = {
			id: dealId,
			state: dealStateId,
		};

		if (state?.toLowerCase() === dealState.won) {
			requestData.wonReason = dealStateData.wonReason;
			requestData.dealClosedOn = dealStateData.dealClosedOn;
		} else if (state?.toLowerCase() === dealState.lost) {
			requestData.lostReason = dealStateData.lostReason;
			requestData.dealClosedOn = dealStateData.dealClosedOn;
		}

		return requestData;
	};

	const toggleAddNewDealsDialog = () => {
		setOpenAddNewDealsDialog(
			(openAddNewDealsDialog) => !openAddNewDealsDialog
		);
	};

	const openEmailComposer = () => {
		setIsEmailComposerOpened(true);
	};

	const closeEmailComposer = () => {
		setIsEmailComposerOpened(false);
	};

	const handleReopenDeal = (_) => {
		handleUpdateDealState(_, dealState.open);
	};

	const openAddProductsDrawer = () => {
		setIsAddProductsDrawerOpened(true);
	};

	const closeAddProductsDrawer = () => {
		setIsAddProductsDrawerOpened(false);
	};

	const onAddProductsToDeal = () => {
		closeAddProductsDrawer();
	};

	const getSummaryMenuOptions = () => {
		return menuOptions.filter((menuOption) => menuOption.permission);
	};

	let moduleName = getModuleName(modules.DEAL).toLowerCase();

	return (
		<React.Fragment>
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isAddProductsDrawerOpened}
				onOpen={openAddProductsDrawer}
				onClose={closeAddProductsDrawer}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box
					className={classes.addDialogContainer}
					style={{ width: "55vw" }}
				>
					<AddDeal
						onClose={closeAddProductsDrawer}
						defaulValues={linkedProducts}
						onAddProductsToDeal={onAddProductsToDeal}
						dealId={dealId}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={openAddNewDealsDialog}
				onOpen={toggleAddNewDealsDialog}
				onClose={toggleAddNewDealsDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<CloneDeal
						onClose={toggleAddNewDealsDialog}
						dealId={dealId}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<Dialog
				open={openMergeDealDialog}
				onClose={handleCloseMergeDealDialog}
				fullWidth={true}
				maxWidth={"lg"}
				style={{
					height: "100%",
				}}
				sx={{
					"& .MuiPaper-root": {
						width: "100%",
						top: 0,
						bottom: 0,
						height: "100%",
					},
				}}
			>
				<MergeDeal
					dealId={dealId}
					onClose={handleCloseMergeDealDialog}
				/>
			</Dialog>

			<Dialog
				open={openLogCallDialog}
				onClose={handleCloseLogCallDialog}
				title="Log a call"
			>
				<LogCall />
			</Dialog>

			<Dialog open={openClosedLostDialog} title="Closed Lost">
				<ClosedLost
					handleDealClose={handleUpdateDealState}
					closeLostDialog={handleCloseClosedLostDialog}
				/>
			</Dialog>

			<Dialog open={openClosedWonDialog} title="Closed Won">
				<ClosedWon
					handleDealWon={handleUpdateDealState}
					closeWonDialog={handleCloseClosedWonDialog}
				/>
			</Dialog>

			<DeleteDialog
				title={`Are you sure you want to delete the ${moduleName}?`}
				subtitle={`It will delete the ${moduleName} and the data associated with it. You can retrieve it from the Recycle Bin. It remains there for 90 days.`}
				open={isDeleteDialogOpened}
				onCancel={closeDeleteDialog}
				onDelete={handleDeleteDeal}
			></DeleteDialog>

			<Menu
				minWidth="200px"
				anchorEl={summaryMenuElement}
				open={openSummaryMenu}
				onClose={CloseSummaryMenu}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				style={{
					marginTop: "8px",
				}}
			>
				{getSummaryMenuOptions().map((menuOption) => (
					<MenuItem
						key={menuOption.id}
						style={{ height: "40px" }}
						onClick={menuOption.onClick}
					>
						<Stack
							direction="row"
							alignItems="center"
							spacing={1.5}
						>
							{menuOption.icon(
								20,
								20,
								theme.palette.primary.main
							)}
							<Typography
								fontSize={13}
								fontWeight={500}
								color={theme.palette.secondary.contrastText}
							>
								{menuOption.name}
							</Typography>
						</Stack>
					</MenuItem>
				))}
			</Menu>

			{isEmailComposerOpened ? (
				<EmailComposerDrawer
					onClose={closeEmailComposer}
					composerValues={{ toAddress: [contactId] }}
					sourceName={modules.DEAL}
					entityId={dealId}
					linkConversationToDeal={true}
				/>
			) : null}

			<Stack
				direction="row"
				justifyContent="center"
				alignItems="center"
				spacing={1.5}
				height="100%"
			>
				{dealStatus === dealState.won ||
				dealStatus === dealState.lost ? (
					<Can permission={PERMISSIONS.deal.edit}>
						<Button
							variant="contained"
							startIcon={ReopenIcon(
								20,
								20,
								theme.palette.secondary.contrastText
							)}
							color="secondary"
							disableElevation
							onClick={handleReopenDeal}
						>
							Reopen
						</Button>
					</Can>
				) : (
					<Stack
						direction="row"
						justifyContent="center"
						alignItems="center"
						spacing={1.5}
					>
						<Can permission={PERMISSIONS.deal.edit}>
							<Button
								variant="contained"
								startIcon={ThumbsUpIcon(
									20,
									20,
									theme.palette.secondary.contrastText
								)}
								color="secondary"
								onClick={handleOpenClosedWonDialog}
								disableElevation
							>
								Won
							</Button>
						</Can>

						<Can permission={PERMISSIONS.deal.edit}>
							<Button
								variant="contained"
								startIcon={ThumbsDownIcon(
									20,
									20,
									theme.palette.error.main
								)}
								onClick={handleOpenClosedLostDialog}
								disableElevation
								color="error"
								sx={{
									backgroundColor: hexToRgba(
										theme.palette.error.main,
										0.1
									),
									color: theme.palette.error.main,
									"&:hover": {
										backgroundColor: hexToRgba(
											theme.palette.error.main,
											0.1
										),
									},
								}}
							>
								Lost
							</Button>
						</Can>
					</Stack>
				)}

				<Can permission={PERMISSIONS.contact.view}>
					<Button
						variant="contained"
						startIcon={MailIcon(
							20,
							20,
							theme.palette.secondary.contrastText
						)}
						color="secondary"
						disableElevation
						onClick={openEmailComposer}
					>
						Email
					</Button>
				</Can>

				<Button
					variant="contained"
					startIcon={CallIcon(
						20,
						20,
						theme.palette.secondary.contrastText
					)}
					color="secondary"
					disableElevation
				>
					Call
				</Button>

				{isAddProductButtonEnabled ? (
					<Button
						variant="contained"
						startIcon={AddIcon(20, 20, "#fff")}
						disableElevation
						onClick={openAddProductsDrawer}
					>
						Product
					</Button>
				) : null}

				{getMenuOptions()?.length > 0 && (
					<IconButton onClick={OpenSummaryMenu} size="small">
						{MoreIcon(20, 20, theme.palette.primary.main)}
					</IconButton>
				)}
			</Stack>
		</React.Fragment>
	);
}
