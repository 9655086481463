import Row from "./Row";
import {
	Box,
	Button,
	Divider,
	IconButton,
	Stack,
	Typography,
	useTheme,
	Grid,
	CircularProgress,
} from "@mui/material";
import { default as CloseIcon } from "../../../assets/icons/close";
import { useForm, FormProvider } from "react-hook-form";
import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { getFormFields } from "../../../api/fields/form/formApi";
import { getFormFieldsKey } from "../../../utils/queryKeys";
import { useModuleName } from "../../../hooks/modules";

export default function AddForm({
	moduleName,
	onClose,
	setIsDuplicateCheckEnabled,
	showCheckDuplicates,
	isSaving,
	onSave,
	formData,
	isEditMode,
	isCloneMode,
	summaryFieldId,
	onUpdate,
	onDuplicateCheck,
}) {
	const theme = useTheme();
	const { getModuleName } = useModuleName();
	const viewName = getModuleName(moduleName);
	const [fields, setFields] = useState([]);
	const [subFields, setSubFields] = useState([]);
	const [source, setSource] = useState({});
	const formFieldsType = getFormFieldsKey(moduleName);
	const formMethods = useForm({
		values: formData,
		reValidateMode: false, // validation triggered only when the form Submit
	});

	const {
		status: formFieldsAPIstatus,
		isLoading: isLoadingFormFields,
		data: formFieldsData,
	} = useQuery(formFieldsType, () => getFormFields(moduleName));

	useEffect(() => {
		if (formFieldsAPIstatus === "success") {
			if (formFieldsData.fields) {
				setFields(formFieldsData.fields);
			}

			if (formFieldsData.subFields) {
				setSubFields(formFieldsData.subFields);
			}

			if (formFieldsData.source) {
				setSource(formFieldsData.source);
			}
		}
	}, [formFieldsData, formFieldsAPIstatus]);

	const getFormHeaderLabel = () => {
		if (isEditMode) {
			return "Update";
		} else if (isCloneMode) {
			return "Clone";
		}

		return "Add";
	};

	const handleSave = (formData) => {
		let isFormShouldClose = true;
		onSave(formData, isFormShouldClose);
	};

	const handleSaveAndNew = (formData) => {
		let isFormShouldClose = false;
		onSave(formData, isFormShouldClose);
		Object.keys(formData).forEach((key) => formMethods.resetField(key));
	};

	const handleCheckDuplicates = (formData) => {
		onDuplicateCheck(formData);
		setIsDuplicateCheckEnabled(true);
	};

	return (
		<React.Fragment>
			{isLoadingFormFields ? (
				<Stack
					alignItems="center"
					justifyContent="center"
					height="100vh"
				>
					<CircularProgress size="30px" />
				</Stack>
			) : (
				<Box
					style={{
						height: "100%",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Box p={2}>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							spacing={2}
						>
							<Typography fontWeight={600}>
								{`${getFormHeaderLabel()} ${viewName}`}
							</Typography>

							<IconButton
								onClick={onClose}
								size="small"
								style={{ padding: 0 }}
							>
								{CloseIcon(25, 25, theme.palette.primary.main)}
							</IconButton>
						</Stack>
					</Box>

					<Divider />

					{isSaving ? (
						<Stack
							alignItems="center"
							justifyContent="center"
							height="100vh"
						>
							<CircularProgress size="30px" />
						</Stack>
					) : (
						<FormProvider {...formMethods}>
							<Box
								p={2}
								style={{
									flex: "1 1 auto",
									minHeight: 0,
									overflowY: "auto",
								}}
							>
								<Grid
									container
									direction="row"
									justifyContent="flex-start"
									alignItems="baseline"
									spacing={2}
								>
									{fields.map((field, index) => (
										<Row
											field={field}
											subFields={subFields}
											key={index}
											source={source}
											summaryFieldId={summaryFieldId}
										/>
									))}
								</Grid>
							</Box>
						</FormProvider>
					)}
					<Divider />

					<Box p={2}>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							spacing={2}
						>
							<Stack direction="row" spacing={2}>
								<Box>
									{isEditMode ? (
										<Button
											variant="contained"
											disabled={isSaving}
											disableElevation
											onClick={formMethods.handleSubmit(
												onUpdate
											)}
										>
											Update
										</Button>
									) : (
										<Button
											variant="contained"
											disabled={isSaving}
											disableElevation
											onClick={formMethods.handleSubmit(
												handleSave
											)}
										>
											Save
										</Button>
									)}
								</Box>

								{isEditMode || isCloneMode ? null : (
									<Box>
										<Button
											variant="contained"
											disabled={isSaving}
											disableElevation
											onClick={formMethods.handleSubmit(
												handleSaveAndNew
											)}
										>
											Save & New
										</Button>
									</Box>
								)}
							</Stack>

							{showCheckDuplicates ? (
								<Box>
									<Button
										variant="contained"
										color="secondary"
										disableElevation
										onClick={formMethods.handleSubmit(
											handleCheckDuplicates
										)}
									>
										Check for Duplicates
									</Button>
								</Box>
							) : null}
						</Stack>
					</Box>
				</Box>
			)}
		</React.Fragment>
	);
}
