import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { tagsListKey } from "../../../utils/queryKeys";
import { getTagsDropdownKey } from "../../../utils/queryKeys";
import {
	createTags,
	deleteTags,
	getTagsList,
	updateTags,
	addTags,
	removeTags,
	getTagsDropdownData,
} from "../../../api/tag";
import React from "react";

const useTagsList = () => {
	return useQuery(tagsListKey, () => getTagsList(), {
		select: React.useCallback((data) => data.list, []),
	});
};

const useInvalidateTags = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(tagsListKey);
	};
};

const useCreateTagMutation = () => {
	let invalidateTagsList = useInvalidateTags();
	return useMutation(createTags, {
		onSuccess: () => {
			invalidateTagsList();
		},
	});
};

const useUpdateTagMutation = () => {
	let invalidateTagsList = useInvalidateTags();
	return useMutation(updateTags, {
		onSuccess: () => {
			invalidateTagsList();
		},
	});
};

const useDeleteTagMutation = () => {
	let invalidateTagsList = useInvalidateTags();
	return useMutation(deleteTags, {
		onSuccess: () => {
			invalidateTagsList();
		},
	});
};

const useTagDropdownList = () => {
	const tagsDropdownKey = getTagsDropdownKey();
	return useQuery(tagsDropdownKey, () => getTagsDropdownData(), {
		select: (data) => data.options,
	});
};

const useAddTags = () => {
	return useMutation(addTags, {
		onSuccess: () => {},
	});
};

const useRemoveTags = () => {
	return useMutation(removeTags, {
		onSuccess: () => {},
	});
};

export {
	useTagsList,
	useCreateTagMutation,
	useUpdateTagMutation,
	useDeleteTagMutation,
	useTagDropdownList,
	useAddTags,
	useRemoveTags,
};
