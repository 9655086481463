import React, { useState } from "react";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
} from "../../../Elements/Accordian";
import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { default as AddIcon } from "../../../../assets/icons/add";
import Files from "../../../Summary/Files";
import {
	getDealFilesKey,
	getDealTimelineKey,
} from "../../../../utils/queryKeys";

export default function DealFiles({ dealId }) {
	const theme = useTheme();
	const [expanded, setExpanded] = useState(false);
	const [isAddFileDialogOpened, setIsAddFileDialogOpened] = useState(false);
	const dealFileKey = getDealFilesKey(dealId);
	const dealTimelineKey = getDealTimelineKey(dealId);

	const openAddFileDialog = (event) => {
		event.stopPropagation();
		setIsAddFileDialogOpened(true);
	};

	return (
		<React.Fragment>
			<Box>
				<Accordion
					expanded={expanded}
					onChange={() => setExpanded((expanded) => !expanded)}
				>
					<AccordionSummary
						aria-controls="panel1d-content"
						id="panel1d-header"
					>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							width="100%"
						>
							<Typography fontSize={14} fontWeight={600}>
								Files
							</Typography>

							{expanded ? (
								<IconButton
									size="small"
									onClick={openAddFileDialog}
								>
									{AddIcon(
										20,
										20,
										theme.palette.primary.main
									)}
								</IconButton>
							) : null}
						</Stack>
					</AccordionSummary>
					<AccordionDetails
						style={{
							paddingTop: "0px",
						}}
					>
						<Files
							setIsAddFileDialogOpened={setIsAddFileDialogOpened}
							isAddFileDialogOpened={isAddFileDialogOpened}
							isAddButtonDiabled={true}
							fileKey={dealFileKey}
							timelineKey={dealTimelineKey}
							entityId={dealId}
							source="Deal"
						/>
					</AccordionDetails>
				</Accordion>
			</Box>
		</React.Fragment>
	);
}
