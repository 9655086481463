import React, { useState } from "react";
import {
	Box,
	Button,
	Paper,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useAddTags, useTagDropdownList } from "../../../hooks/services/tags";
import { notificationVariants } from "../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../utils/notification/notificationMessages";
import Dialog from "../../Elements/Dialog";
import Autocomplete from "../../Elements/Autocomplete";

const CustomPaper = function (props) {
	return (
		<Paper
			{...props}
			style={{
				...props.style,
				borderRadius: "8px",
				width: "260px",
				boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.08)",
			}}
		/>
	);
};

export default function AddTags(props) {
	const {
		isAddTagsDialogOpened,
		closeAddTagsDialog,
		selectedIds,
		onTagsAdded,
		sourceName,
	} = props;
	const theme = useTheme();

	const [selectedTags, setSelectedTags] = useState([]);
	const [tagInputValue, setTagInputValue] = useState("");

	const { data: tagsList } = useTagDropdownList();
	const addTagsMutation = useAddTags();

	const handleSelectTags = (_, values) => {
		setSelectedTags(values);
		setTagInputValue("");
	};

	const handleTagInputChange = (event) => {
		const { value } = event.target;
		if (value.trim()) {
			setTagInputValue(value);
		} else {
			setTagInputValue("");
		}
	};

	const performCancel = () => {
		closeAddTagsDialog();
		setSelectedTags([]);
	};

	const handleAddTags = () => {
		let addTagsRequestData = getAddTagsRequest();
		if (selectedTags.length !== 0) {
			addTagsMutation.mutate(addTagsRequestData, {
				onSuccess: () => {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.tagsAdded,
					});
					onTagsAdded();
				},
				onError: () => {
					enqueueSnackbar({
						variant: notificationVariants.error,
						message: notificationMessage.somethingWentWrong,
					});
				},
			});
		}
	};

	const getAddTagsRequest = () => {
		let addTagsRequestData = {
			source: sourceName,
			tagIds: selectedTags?.map((tag) => tag?.value),
			entityIds: selectedIds,
		};
		return addTagsRequestData;
	};

	const isSaveButtonDisable = () => {
		return selectedTags.length === 0;
	};

	const onBlurTagsInput = () => {
		setTagInputValue("");
	};

	const isOpenDropdownMenu = () => {
		return tagInputValue.trim().length > 0;
	};

	return (
		<React.Fragment>
			<Dialog title="Add Tags" open={isAddTagsDialogOpened}>
				<Box p={2}>
					<Stack spacing={1}>
						<Typography fontSize={13}>
							Select tags to add to the selected contacts
						</Typography>

						<Autocomplete
							freeSolo={false}
							open={isOpenDropdownMenu()}
							paperComponent={CustomPaper}
							options={tagsList || []}
							onChange={handleSelectTags}
							value={selectedTags}
							filterSelectedOptions
							noOptionsText="no results found"
							renderInput={(params) => (
								<TextField
									{...params}
									onChange={handleTagInputChange}
									value={tagInputValue}
									placeholder="Enter tag name"
									inputProps={{
										...params.inputProps,
										style: {
											fontSize: "14px",
										},
									}}
									onBlur={onBlurTagsInput}
								/>
							)}
						/>
					</Stack>

					<Stack
						spacing={2}
						pt={2}
						justifyContent="right"
						direction="row"
					>
						<Button
							variant="contained"
							color="secondary"
							disableElevation
							onClick={performCancel}
							disabled={addTagsMutation.isLoading}
						>
							Cancel
						</Button>

						<Button
							variant="contained"
							disableElevation
							onClick={handleAddTags}
							disabled={
								addTagsMutation.isLoading ||
								isSaveButtonDisable()
							}
							sx={{
								"&.Mui-disabled": {
									backgroundColor: theme.palette.primary.main,
									color: "rgba(255, 255, 255, 0.6)",
								},
							}}
						>
							Save
						</Button>
					</Stack>
				</Box>
			</Dialog>
		</React.Fragment>
	);
}
